<div class="container-fluid ">
  <div *ngIf="report.reportType != null" class="row px-4">
    <div class="col-4 pl-0">
      <!-- <div class="report_icon">
        <a href="/" class="text_color ">
        <i class="zmdi zmdi-long-arrow-left "></i>
        Back to  Reports</a>
      </div> -->
    </div>
    <!--  -->
    <div class="col pr-1">
      <div class="d-flex justify-content-end">
        <div class="">

          <div class="report_icon">
            <a [routerLink]="[ '/hrr_germline_report', reportId ]" target="_blank" class="text_color "> <i
                class="zmdi zmdi-eye eye"></i>View Report</a>
          </div>
        </div>
        <div class="ml-3">
          <div class=" text-right  ml-4" *ngIf=" this.organisationName==null ||  this.organisationName==''">
            <select class="form-control" (change)="changeReportStatus($event.target)">
              <option selected disabled> select report Delivered</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
          <div class=" text-right  ml-4" *ngIf=" this.organisationName!=null &&  this.organisationName!=''">
            <select class="form-control" (change)="changeReportAndOncoStatus($event.target)">
              <option selected disabled> Is the report completed?</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>


<div class="container-fluid patient-info_tabs position-relative reports_tabs">
  <div *ngIf="reportTypeChange" class="d-flex justify-content-center mt-5">
    <span class="visually-hidden"></span>
    <div class="spinner-border" style="width: 8rem; height: 8rem" role="status"></div>
  </div>
  <div *ngIf="!reportTypeChange">
    <header class=" pl-3  mb-3">
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <a class="nav-link active" data-toggle="tab" href="#patient-info"
            (click)="textcount('clinicalSummary-editor')">Patient Info</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-toggle="tab" href="#snv">SNV / InDels</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-toggle="tab" href="#cna" (click)="assign('cna')">CNA</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-toggle="tab" href="#notes" (click)="textcount('addNote-editor')">Additional Notes
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" (click)="assign('coverage')" data-toggle="tab" href="#coverage">Coverage</a>
      </li>
        <li class="nav-item">
          <a class="nav-link" data-toggle="tab" href="#family">Family
            Hiistory</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-toggle="tab" href="#website">Websites</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-toggle="tab" href="#interpretation"
            (click)="textcount('interpretation-editor')">Interpretation</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-toggle="tab" href="#disclaimer" (click)="textcount('editor1')">Disclaimer</a>
        </li>
      </ul>
    </header>


    <div class="tab-content">

      <!-- Patient Info -->
      <div id="patient-info" class="container-fluid tab-pane active"><br>
        <div id="tab1" class="">
          <div class="cust_shadow bg-white p-4">
            <div class="levels_section row mt-2 py-1 rounded mx-0" style="background: #f1f6f6;">
              <div class=" col-6">
                <p>
                  <label class="col-8">Select Sample Type :
                    <ng-select [items]="patientSamples" bindLabel="name" bindValue="name" [multiple]="true"
                      placeholder="Select samples" (change)="addSampleID()" [(ngModel)]="selected"></ng-select>
                  </label>
                </p>
                <p><label class="col">Ecrf Id : {{report?.patient?.ecrfId}}</label></p>
                <p><label class="col">Name : {{report?.patient?.name}}</label></p>
                <p><label class="col">Gender : {{report.patient.gender}}</label></p>
                <p><label class="col">Collected On : {{report.labDetails.sampleCollected}}</label></p>
                <p><label class="col">FFPE ID : {{report.labDetails.sampleBlockId}}</label></p>
              </div>
              <div class="col">
                <p>
                  <label class="col-8 ">Change Report Type :
                    <ng-select [items]="reportTypeList" bindLabel="name" bindValue="name" [multiple]="false"
                      placeholder="Select Report Type" (change)="changeReportType($event)"
                      [(ngModel)]="selected"></ng-select>
                  </label>
                </p>
                <p><label class="col">Age/DOB : <span
                      *ngIf="report.patient.dateOfBirth != null">{{report.patient.dateOfBirth | date:'dd/MM/yyyy'}}
                    </span> <span *ngIf="report.patient.age != null"> {{report.patient.age}} Years </span></label>
                </p>
                <p><label class="col">Report Type : {{report.reportType.name}}</label></p>
                <p><label class="col">Cancer Site : {{report.cancerType}}</label></p>
                <p><label class="col">Sample Id : {{report.labDetails.sampleId}}</label></p>
                <p><label class="col">Referring Clinician :
                    {{referringClinician}}</label></p>
                <p><label class="col">Hospital : {{report.labDetails.hospital}}</label></p>
              </div>


            </div>

            <!-- DNA / RNA Details -->
            <div *ngIf="appUser.email == 'harshi@4basecare.com'" class="levels_section row mt-2 py-1 rounded mx-0"
              style="background: #f1f6f6;">
              <div class=" col-6">
                <p>
                  <label class="col">DNA Exome Available :
                    <select class="form-control" name="dnaAvailable" (change)="dnaAvailable($event.target)"
                      [(ngModel)]="dnaAvailableValue">
                      <option selected disabled>select</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                  </label>
                </p>
                <p *ngIf="dnaAndRnaDetailsReq.dnaAvailable">
                  <label class="col">DNA Sequencing Data :
                    <input class="form-control" type="number" name="dnaSequencingData"
                      [(ngModel)]="dnaAndRnaDetailsReq.dnaSequencingData">
                  </label>
                </p>
              </div>
              <div class=" col-6">
                <p>
                  <label class="col">RNA Exome Available :
                    <select class="form-control" name="rnaAvailable" (change)="rnaAvailable($event.target)"
                      [(ngModel)]="rnaAvailableValue">
                      <option selected disabled>select</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                  </label>
                </p>
                <p *ngIf="dnaAndRnaDetailsReq.rnaAvailable">
                  <label class="col">RNA Sequencing Data :
                    <input class="form-control" type="number" name="rnaSequencingData"
                      [(ngModel)]="dnaAndRnaDetailsReq.rnaSequencingData">
                  </label>
                </p>
              </div>

              <button type="button" [disabled]="isSubmitedDnaRna" class="btn save_btn" style="float:right"
                (click)="addingRnaAndDna()">
                <span *ngIf="!isSubmitedDnaRna"> Save </span>
                <div *ngIf="isSubmitedDnaRna" class="spinner-border text-dark">
                  <span class="sr-only"></span>
                </div>
              </button>

            </div>
          </div>
        </div>

        <form class="general-form  mt-3">
          <div class="form-group row">
            <div class="col">
              <div class="d-flex justify-content-between align-items-center">
                <label>Clinical Indication:</label>
                <div style="text-align: right; padding-bottom: 5px;"
                  *ngIf="clinicalSummary!=null ||clinicalSummary!=undefined">
                  <P *ngIf="clinicalSummary.length"><strong>[{{editorText.length}}/3000]</strong></P>
                </div>
              </div>
              <ckeditor [editor]="editor" [config]="{ toolbar: [ 'heading', '|', 'bold', 'italic' , 'bulletedList'] }"
                name="clinicalSummary" [(ngModel)]="clinicalSummary" (keyup)="textcount('clinicalSummary-editor')">
              </ckeditor>
              <div class="clinicalSummary-editor" hidden="true" [innerHtml]="clinicalSummary"></div>
            </div>
          </div>

          <div class="form-group row"
            *ngIf="report.reportType.name !='BRCA 1 and 2' && report.reportType.name !='Family Testing'">
            <div class="col">
              <label>GENES OF INTEREST:</label>
              <ckeditor [editor]="editor" [config]="{ toolbar: [ 'heading', '|', 'bold', 'italic' , 'bulletedList' ] }"
                name="interestGenes" [(ngModel)]="interestGenes"></ckeditor>
            </div>
          </div>

          <div *ngIf="report.clinicalSummary != null">
            <button type="button" [disabled]="isSubmitedClinicalSummary" class="btn save_btn" style="float:right"
              (click)="addClinicalSummary()">
              <span *ngIf="!isSubmitedClinicalSummary"> Update </span>
              <div *ngIf="isSubmitedClinicalSummary" class="spinner-border text-dark">
                <span class="sr-only"></span>
              </div>
            </button>
          </div>
          <div *ngIf="report.clinicalSummary == null">
            <button type="button" [disabled]="isSubmitedClinicalSummary" class="btn save_btn" style="float:right"
              (click)="addClinicalSummary()">
              <span *ngIf="!isSubmitedClinicalSummary"> Save </span>
              <div *ngIf="isSubmitedClinicalSummary" class="spinner-border text-dark">
                <span class="sr-only"></span>
              </div>
            </button>
          </div>
        </form>
      </div>

      <!-- snv -->
      <div id="snv" class="container-fluid tab-pane fade"><br>
        <div id="tab2">
          <app-read-excel></app-read-excel>
        </div>
      </div>

      <!-- CNA -->
      <div id="cna" class="container-fluid tab-pane fade"><br>
        <div id="tab3" *ngIf="tab=='cna'">
          <app-cna-excel></app-cna-excel>
        </div>
      </div>

      <!-- Additional Notes -->
      <div id="notes" class="container-fluid tab-pane fade"><br>
        <div id="tab4">
          <form class="general-form  mt-3">
            <div class="form-group row">
              <div class="col">
                <div class="d-flex justify-content-between align-items-center">
                  <label>Additional Notes:</label>
                  <div *ngIf="addNote!=null ||addNote!=undefined">
                    <P *ngIf="addNote.length"><strong>[{{editorText.length}}/3000]</strong></P>
                  </div>
                </div>

                <ckeditor [editor]="editor" [config]="{ toolbar: [ 'heading', '|', 'bold', 'italic', 'bulletedList'] }"
                  name="addNote" [(ngModel)]="addNote" (keyup)="textcount('addNote-editor')"></ckeditor>
                <div class="addNote-editor" hidden="true" [innerHtml]="addNote"></div>
              </div>
            </div>
            <div *ngIf="report.addNotes!=null">
              <button type="button" [disabled]="isSubmitedClinicalSummary" class="btn save_btn" style="float:right"
                (click)="addNotesGermline()">
                <span *ngIf="!isSubmitedClinicalSummary"> Update </span>
                <div *ngIf="isSubmitedClinicalSummary" class="spinner-border text-dark">
                  <span class="sr-only"></span>
                </div>
              </button>
            </div>
            <div *ngIf="report.addNotes == null">
              <button type="button" [disabled]="isSubmitedClinicalSummary" class="btn save_btn" style="float:right"
                (click)="addNotesGermline()">
                <span *ngIf="!isSubmitedClinicalSummary"> Save </span>
                <div *ngIf="isSubmitedClinicalSummary" class="spinner-border text-dark">
                  <span class="sr-only"></span>
                </div>
              </button>
            </div>
          </form>
        </div>
      </div>

      <!-- coverage -->
      <div id="coverage" class="container-fluid tab-pane fade"><br>
        <div id="tab5" *ngIf="tab=='coverage'">
          <app-coverage-excel></app-coverage-excel>
        </div>
      </div>

      <!-- Family history -->
      <div id="family" class="container-fluid tab-pane fade"><br>
        <div id="tab6" class="mx-5">
          <div class="form-group row add_interpretation">
            <label class="col-sm-2 control-label">
              <a (click)="addFamily()" class="font_14 text_color">Add Family History <i
                  class="zmdi zmdi-plus ml-2"></i></a> :</label>

            <div class="col-sm-8 ">
              <div *ngFor="let add_family of addFamilyHistory.addFamilyHistorys; let i = index " class="w-75 bg-white">
                <div class="form-group row p-4 mx-0 pr-0"
                  style="border: 1px solid #d5dadf ;padding:5px;border-radius: 5px;">
                  <label for="" class="col-sm-3 col-form-label">Family Type :</label>
                  <div class="col-sm-3 position-relative mb-1 pr-0">
                    <select type="text" class="form-control" name="familyType_{{ i }}"
                      [(ngModel)]="add_family.familyType">
                      <option value="Proband">Proband</option>
                      <option value="Immediate Relatives">Immediate Relatives</option>
                      <option value="Son/Daughter">Son/Daughter</option>
                      <option value="Paternal Relatives">Paternal Relatives</option>
                      <option value="Maternal Relatives">Maternal Relatives</option>
                    </select>
                  </div>
                  <label class="col-sm-3 col-form-label mb-1">Relationship :</label>
                  <div class="col-sm-3 position-relative pl-0">
                    <input type="text" name="relationship_{{ i }}" class="form-control"
                      [(ngModel)]="add_family.relationship" />
                  </div>
                  <div class="w-100"></div>
                  <label for="" class="col-sm-3 col-form-label">CancerType :</label>
                  <div class="col-sm position-relative" style="margin-bottom:4px;">
                    <input type="text" name="cancerType_{{ i }}" class="form-control"
                      [(ngModel)]="add_family.cancerType" />
                  </div>
                  <div class="w-100"></div>
                  <label for="" class="col-sm-3 col-form-label">Diagnosis Age :</label>
                  <div class="col-sm position-relative">
                    <input type="text" class="form-control" name="diagnosisAge_{{ i }}"
                      [(ngModel)]="add_family.diagnosisAge">
                  </div>
                  <!-- btn close -->
                  <div class="btn_close" (click)="removeFamily(i,add_family)">
                    <a><i class="zmdi zmdi-close"></i></a>
                  </div>
                </div>


              </div>
              <div class="text-right w-75">
                <button *ngIf="addFamilyHistory.addFamilyHistorys.length > 0" class="btn save_btn"
                  (click)="addFamilyHistoryDetails()" [disabled]="familySaveButton">save</button>
              </div>

            </div>
          </div>
        </div>
      </div>

      <!-- website -->
      <div id="website" class="container-fluid tab-pane fade"><br>
        <div id="tab7" class="">
          <form class="general-form">
            <div class="form-group row">
              <div class="col">
                <ckeditor [editor]="editor"
                  [config]="{ toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList' ] }"
                  name="referenceWebsite" [(ngModel)]="referenceWebsite"></ckeditor>
              </div>
            </div>

            <div>
              <button type="button" [disabled]="isSubmitedReferenceWebsite" class="btn save_btn" style="float:right"
                (click)="addReferenceWebsite()">
                <span *ngIf="!isSubmitedReferenceWebsite"> Save </span>
                <div *ngIf="isSubmitedReferenceWebsite" class="spinner-border text-dark">
                  <span class="sr-only"></span>
                </div>
              </button>
            </div>
          </form>
        </div>
      </div>

      <!-- Interpratation -->
      <div id="interpretation" class="container-fluid tab-pane fade"><br>
        <div id="tab8" class="">
          <form class="general-form">

            <div class="form-group row mt-2 mb-5">
              <div class="col-3  ">
                <div class="bg-white">
                  <ng-multiselect-dropdown placeholder="Select Tag" [settings]="interpretationDropdownSettings"
                    [data]="interpretationListRes" (onSelect)="onItemSelect($event)"
                    (onDeSelect)="onItemDeSelect($event)">
                  </ng-multiselect-dropdown>
                </div>
              </div>
            </div>
            <div *ngIf="report.reportType.name=='BRCA 1 and 2'">
              <label>Is Brca 1/2 Interpretation empty ?</label> &nbsp;<span>
                <input type="checkbox" (click)="brcaInterpretationSelection($event)"
                  [checked]="bracaInterpretation"></span>
            </div>

            <div class="form-group row">
              <div class="col">
                <div style="text-align: right; padding-bottom: 5px;"
                  *ngIf="interpretation!=null ||interpretation!=undefined">
                  <P *ngIf="interpretation.length"><strong>[{{editorText.length}}/11000]</strong></P>
                </div>
                <ckeditor [editor]="editor"
                  [config]="{ toolbar: [ 'heading', '|', 'bold', 'italic' , 'bulletedList' ] }" name="interpretation"
                  [(ngModel)]="interpretation" (keyup)="textcount('interpretation-editor')"></ckeditor>
                <div class="interpretation-editor" hidden="true" [innerHtml]="interpretation"></div>
                <p style="font-size: 14px!important;padding-top: 8px;"><strong>*[</strong>A<sup>b</sup> →
                  {{sup}}<strong>]</strong><br /><strong>*[</strong>A<sub>b</sub> → {{sub}}<strong>]</strong></p>
              </div>
            </div>
            <div class="form-group row">
              <div class="col">
                <label>PMIDS:</label>
                <input type="text" class="form-control" placeholder="separate each pmId by comma(,)" name="pmIds"
                  [(ngModel)]="pmIds">
              </div>
            </div>

            <div *ngIf="report.interpretation != null">
              <button type="button" [disabled]="isSubmitedInterpretation" class="btn save_btn" style="float:right"
                (click)="addInterpretation()">
                <span *ngIf="!isSubmitedInterpretation"> Update </span>
                <div *ngIf="isSubmitedInterpretation" class="spinner-border text-dark">
                  <span class="sr-only"></span>
                </div>
              </button>
            </div>
            <div *ngIf="report.interpretation == null">
              <button type="button" [disabled]="isSubmitedInterpretation" class="btn save_btn" style="float:right"
                (click)="addInterpretation()">
                <span *ngIf="!isSubmitedInterpretation"> Save </span>
                <div *ngIf="isSubmitedInterpretation" class="spinner-border text-dark">
                  <span class="sr-only"></span>
                </div>
              </button>
            </div>
          </form>
        </div>
      </div>

      <!-- Disclaimer -->
      <div id="disclaimer" class="container-fluid tab-pane fade"><br>
        <div id="tab8" class="">
          <form class="general-form">

            <div class="form-group row mt-2 mb-5">
              <div class="col-3  ">
                <div class="bg-white">
                  <ng-multiselect-dropdown placeholder="Select Disclaimer" [settings]="disclaimerDropdownSettings"
                    [data]="disclaimerList" (onSelect)="onDisclaimerItemSelect($event)"
                    (onDeSelect)="onDisclaimerItemDeSelect($event)">
                  </ng-multiselect-dropdown>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <div class="col">
                <div style="text-align: right; padding-bottom: 5px;" *ngIf="disclaimer!=null ||disclaimer!=undefined">
                  <P *ngIf="disclaimer.length"><strong>[{{(editorText.length)}}/11000]</strong></P>
                </div>
                <ckeditor [editor]="editor" class=""
                  [config]="{ toolbar: [ 'heading', '|', 'bold', 'italic'  , 'bulletedList'] }" name="disclaimer"
                  [(ngModel)]="disclaimer" (keyup)="textcount('editor1')"></ckeditor>
                <div class="editor1" hidden="true" [innerHtml]="disclaimer"></div>
                <p style="font-size: 14px!important;padding-top: 8px;"><strong>*[</strong>A<sup>b</sup> →
                  {{sup}}<strong>]</strong><br /><strong>*[</strong>A<sub>b</sub> → {{sub}}<strong>]</strong></p>
              </div>
            </div>
            <div class="form-group row">
              <div class="col">
                <label>PMIDS:</label>
                <input type="text" class="form-control" placeholder="separate each pmId by comma(,)" name="pmIds"
                  [(ngModel)]="pmIds">
              </div>
            </div>

            <div *ngIf="report.disclaimer != null">
              <button type="button" [disabled]="isSubmitedDisclaimer" class="btn save_btn" style="float:right"
                (click)="addDisclaimer()">
                <span *ngIf="!isSubmitedDisclaimer"> Update </span>
                <div *ngIf="isSubmitedDisclaimer" class="spinner-border text-dark">
                  <span class="sr-only"></span>
                </div>
              </button>
            </div>
            <div *ngIf="report.disclaimer == null">
              <button type="button" class="btn save_btn" style="float:right" (click)="addDisclaimer()">
                <span *ngIf="!isSubmitedDisclaimer"> Save </span>
                <div *ngIf="isSubmitedDisclaimer" class="spinner-border text-dark">
                  <span class="sr-only"></span>
                </div>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
