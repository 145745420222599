<div class="container-fluid ">
  <div *ngIf="report?.reportType != null" class="row px-4">
    <div class="col-4 pl-0">
    </div>
    <!--  -->
    <div class="col pr-1">
      <div class="d-flex justify-content-end">
        <div class="">
          <div class="report_icon">
            <a [routerLink]="[ '/absolute', reportId ]" target="_blank" class="text_color "> <i
                class="zmdi zmdi-eye eye"></i>
              View Report</a>
          </div>
        </div>
        <div class="ml-3">
          <div class=" text-right  ml-4" *ngIf=" this.organisationName==null ||  this.organisationName==''">
            <select class="form-control" (change)="changeReportStatus($event.target)">
              <option selected disabled> select report Delivered</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
          <div class=" text-right  ml-4" *ngIf=" this.organisationName!=null &&  this.organisationName!=''">
            <select class="form-control" (change)="changeReportAndOncoStatus($event.target)">
              <option selected disabled> Is the report completed?</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- if report is not germline type -->
<div class="container-fluid patient-info_tabs position-relative reports_tabs">
  <div *ngIf="reportTypeChange" class="d-flex justify-content-center mt-5">
    <span class="visually-hidden"></span>
    <div class="spinner-border" style="width: 8rem; height: 8rem" role="status"></div>
  </div>
  <div *ngIf="!reportTypeChange">
    <header class=" pl-3  mb-3">
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <a class="nav-link active" data-toggle="tab" href="#patient-info"
            (click)="textcount('clinicalSummary-editors')">Patient Info</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-toggle="tab" (click)="assign('snv')" href="#snv">SNV / InDels</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-toggle="tab" (click)="assign('cna')" href="#cna">CNA</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" (click)="assign('fusion')" data-toggle="tab" href="#fusion">Fusion</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-toggle="tab" href="#summary" (click)="textcount('evidenceSummary-editors')">*
            Evidence Summary</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-toggle="tab" href="#interpretation"
            (click)="textcount('interpretation-editors')">Interpretation</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-toggle="tab" href="#Immunodata">* Immunodata</a>
        </li>
        <li *ngIf="immunoDetails.pdl1Status == 'EXPRESSION'" class="nav-item">
          <a class="nav-link" data-toggle="tab" href="#pdl1trails">PD-L1 Trials</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-toggle="tab" href="#website">Websites</a>
        </li>
      </ul>
    </header>
    <div class="tab-content">

      <!-- Patient Info -->
      <div id="patient-info" class="container-fluid tab-pane active"><br>
        <div id="tab1" class="">
          <div class="cust_shadow p-4 bg-white  rounded">
            <div class="levels_section  row mt-2 py-3 rounded mx-0" style="background: #f1f6f6;">
              <div class=" col-6">
                <p *ngIf=" this.organisationName==null ||  this.organisationName==''">
                  <label class="col-8">Select Sample Type :
                    <ng-select [items]="patientSamples" bindLabel="name" bindValue="name" [multiple]="true"
                      placeholder="Select samples" (change)="addSampleID()" [(ngModel)]="selected"></ng-select>
                  </label>
                </p>
                <p *ngIf=" this.organisationName==null ||  this.organisationName==''"><label class="col">Ecrf Id :
                    {{report?.patient?.ecrfId}}</label></p>
                <p
                  *ngIf=" this.organisationName!=null &&  this.organisationName!='' && this.organisationName=='Lifecell'">
                  <label class="col">Patient Id : {{report?.patient?.orgLabId}}</label></p>
                <p
                  *ngIf=" this.organisationName!=null &&  this.organisationName!='' && this.organisationName!='Lifecell'">
                  <label class="col">Patient Id : {{report?.patient?.organisationPatientId}}</label></p>

                <p><label class="col">Name : {{report?.patient?.name}}</label></p>
                <p><label class="col">Gender : {{report?.patient?.gender}}</label></p>
                <p><label class="col">Collected On : {{report?.labDetails?.sampleCollected}}</label></p>
                <p><label class="col" *ngIf=" this.organisationName==null ||  this.organisationName==''">FFPE ID :
                    {{report?.labDetails?.sampleBlockId}}</label></p>
              </div>
              <div class="col-6">
                <p *ngIf=" this.organisationName==null ||  this.organisationName==''">
                  <label class="col-8 ">Change Report Type :
                    <ng-select [items]="reportTypeList" bindLabel="name" bindValue="name" [multiple]="false"
                      placeholder="Select Report Type" (change)="changeReportType($event)"
                      [(ngModel)]="selected"></ng-select>
                  </label>
                </p>
                <p><label class="col">DOB/Age : <span
                      *ngIf="report?.patient?.dateOfBirth != null">{{report?.patient?.dateOfBirth |
                      date:'dd/MM/yyyy'}}</span> <span *ngIf="report?.patient?.age != null">{{report?.patient?.age}} Years
                    </span></label>
                </p>
                <p><label class="col">Report Type : Targt {{report?.reportType?.name}}</label></p>
                <p><label class="col">Cancer Site : {{report.cancerType}}</label></p>
                <p *ngIf=" this.organisationName==null ||  this.organisationName==''"><label class="col">Sample Id :
                    {{report?.labDetails?.sampleId}}</label></p>
                <p><label class="col">Sample Source : {{report?.labDetails?.sampleSource}}</label></p>
                <p><label class="col">Referring Clinician :
                    {{referringClinician}}</label></p>
                <p><label class="col">Hospital : {{report?.labDetails?.hospital}}</label></p>
              </div>
            </div>

            <!-- DNA / RNA Details -->
            <div *ngIf="appUser.email == 'harshi@4basecare.com'" class="levels_section row mt-2 py-1 rounded mx-0"
              style="background: #f1f6f6;">
              <div class=" col-6">
                <p>
                  <label class="col">DNA Exome Available :
                    <select class="form-control" name="dnaAvailable" (change)="dnaAvailable($event.target)"
                      [(ngModel)]="dnaAvailableValue">
                      <option selected disabled>select</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                  </label>
                </p>
                <p *ngIf="dnaAndRnaDetailsReq.dnaAvailable">
                  <label class="col">DNA Sequencing Data :
                    <input class="form-control" type="number" name="dnaSequencingData"
                      [(ngModel)]="dnaAndRnaDetailsReq.dnaSequencingData">
                  </label>
                </p>
              </div>
              <div class=" col-6">
                <p>
                  <label class="col">RNA Exome Available :
                    <select class="form-control" name="rnaAvailable" (change)="rnaAvailable($event.target)"
                      [(ngModel)]="rnaAvailableValue">
                      <option selected disabled>select</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                  </label>
                </p>
                <p *ngIf="dnaAndRnaDetailsReq.rnaAvailable">
                  <label class="col">RNA Sequencing Data :
                    <input class="form-control" type="number" name="rnaSequencingData"
                      [(ngModel)]="dnaAndRnaDetailsReq.rnaSequencingData">
                  </label>
                </p>
              </div>

              <button type="button" [disabled]="isSubmitedDnaRna" class="btn save_btn" style="float:right"
                (click)="addingRnaAndDna()">
                <span *ngIf="!isSubmitedDnaRna"> Save </span>
                <div *ngIf="isSubmitedDnaRna" class="spinner-border text-dark">
                  <span class="sr-only"></span>
                </div>
              </button>

            </div>
          </div>
        </div>

        <form class="general-form  mt-4">
          <div class="form-group row">
            <div class="col">
              <div class="d-flex justify-content-between align-items-center">
                <label>Clinical Summary:</label>
                <div style="text-align: right; padding-bottom: 5px;"
                  *ngIf="clinicalSummary!=null ||clinicalSummary!=undefined">
                  <P *ngIf="clinicalSummary.length"><strong>[{{clinicalSummary.length}}/3000]</strong></P>
                </div>
              </div>
              <input *ngIf="(this.organisationName==null ||  this.organisationName=='')" type="text"
                class="form-control" name="clinicalSummary" [(ngModel)]="clinicalSummary">
              <input *ngIf="(this.organisationName!=null &&  this.organisationName!='')" type="text"
                class="form-control" name="clinicalSummary" [(ngModel)]="clinicalSummary" [disabled]="true">

            </div>
          </div>
          <div
            *ngIf="report.clinicalSummary != null || report.addNotes!=null && (this.organisationName==null ||  this.organisationName=='')">
            <button type="button" [disabled]="isSubmitedClinicalSummary" class="btn save_btn" style="float:right"
              (click)="addClinicalSummary()">
              <span *ngIf="!isSubmitedClinicalSummary"> Update </span>
              <div *ngIf="isSubmitedClinicalSummary" class="spinner-border text-dark">
                <span class="sr-only"></span>
              </div>
            </button>
          </div>
          <div
            *ngIf="report.clinicalSummary == null && report.addNotes == null && (this.organisationName==null ||  this.organisationName=='')">
            <button type="button" [disabled]="isSubmitedClinicalSummary" class="btn save_btn" style="float:right"
              (click)="addClinicalSummary()">
              <span *ngIf="!isSubmitedClinicalSummary"> Save </span>
              <div *ngIf="isSubmitedClinicalSummary" class="spinner-border text-dark">
                <span class="sr-only"></span>
              </div>
            </button>
          </div>
        </form>
      </div>

      <!-- snv -->
      <div id="snv" *ngIf="tab=='snv'" class="container-fluid tab-pane fade"><br>
        <div id="tab13">
          <app-read-excel></app-read-excel>
        </div>
      </div>

      <!-- CNA -->
      <div id="cna" *ngIf="tab=='cna'" class="container-fluid tab-pane fade"><br>
        <div id="tab3">
          <app-cna-excel></app-cna-excel>
        </div>
      </div>

      <!-- Fusion -->
      <div id="fusion" *ngIf="tab=='fusion'" class="container-fluid tab-pane fade"><br>
        <div id="tab4">
          <app-fusion-excel></app-fusion-excel>
        </div>
      </div>
      <!-- Evidence Summary -->
      <div id="summary" class="container-fluid tab-pane fade"><br>
        <div id="tab5" class="">
          <form class="general-form">
            <div class="form-group row">
              <div class="col">
                <div style="text-align: right; padding-bottom: 5px;"
                  *ngIf="evidenceSummary!=null ||evidenceSummary!=undefined">
                  <P *ngIf="evidenceSummary.length"><strong>[{{editorText.length}}/3000]</strong></P>
                </div>
                <ckeditor [editor]="editor"
                  [config]="{ toolbar: [ 'heading', '|', 'bold', 'italic' , 'bulletedList' ] }" name="evidenceSummary"
                  [(ngModel)]="evidenceSummary" (keyup)="textcount('evidenceSummary-editors')"></ckeditor>
                <div class="evidenceSummary-editors" hidden="true" [innerHtml]="evidenceSummary"></div>
                <p style="font-size: 14px!important;padding-top: 8px;"><strong>*[</strong>A<sup>b</sup> →
                  {{sup}}<strong>]</strong><br /><strong>*[</strong>A<sub>b</sub> →
                  {{sub}}<strong>]</strong></p>
              </div>
            </div>

            <div class="form-group row">
              <div class="col">
                <label>PMIDS:</label>
                <input type="text" class="form-control" placeholder="separate each pmId by comma(,)" name="pmIds"
                  [(ngModel)]="pmIds">
              </div>
            </div>

            <div *ngIf="report.evidenceSummary != null">
              <button type="button" [disabled]="isSubmitedSummary" class="btn save_btn" style="float:right"
                (click)="submitSummary()">
                <span *ngIf="!isSubmitedSummary"> Update </span>
                <div *ngIf="isSubmitedSummary" class="spinner-border text-dark">
                  <span class="sr-only"></span>
                </div>
              </button>
            </div>
            <div *ngIf="report.evidenceSummary == null">
              <button type="button" [disabled]="isSubmitedSummary" class="btn save_btn" style="float:right"
                (click)="submitSummary()">
                <span *ngIf="!isSubmitedSummary"> Save </span>
                <div *ngIf="isSubmitedSummary" class="spinner-border text-dark">
                  <span class="sr-only"></span>
                </div>
              </button>
            </div>
          </form>
        </div>
      </div>

      <!-- Interpratation -->
      <div id="interpretation" class="container-fluid tab-pane fade"><br>
        <div id="tab6" class="">
          <form class="general-form">

            <div class="form-group row mt-2 mb-5">
              <div class="col-3  ">
                <div class="bg-white">
                  <ng-multiselect-dropdown placeholder="Select Tag" [settings]="interpretationDropdownSettings"
                    [data]="interpretationListRes" (onSelect)="onItemSelect($event)"
                    (onDeSelect)="onItemDeSelect($event)">
                  </ng-multiselect-dropdown>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <div class="col">
                <div style="text-align: right; padding-bottom: 5px;"
                  *ngIf="interpretation!=null ||interpretation!=undefined">
                  <P *ngIf="interpretation.length"><strong>[{{editorText.length}}/11000]</strong></P>
                </div>
                <ckeditor [editor]="editor"
                  [config]="{ toolbar: [ 'heading', '|', 'bold', 'italic' , 'bulletedList' ] }" name="interpretation"
                  [(ngModel)]="interpretation" (keyup)="textcount('interpretation-editors')"></ckeditor>
                <div class="interpretation-editors" hidden="true" [innerHtml]="interpretation"></div>
                <p style="font-size: 14px!important;padding-top: 8px;"><strong>*[</strong>A<sup>b</sup> →
                  {{sup}}<strong>]</strong><br /><strong>*[</strong>A<sub>b</sub> → {{sub}}<strong>]</strong></p>

              </div>
            </div>
            <div class="form-group row">
              <div class="col">
                <label>PMIDS:</label>
                <input type="text" class="form-control" placeholder="separate each pmId by comma(,)" name="pmIds"
                  [(ngModel)]="pmIds">
              </div>
            </div>
            <div class="col px-0" style="padding-bottom: 5px!important;">
              <ckeditor [editor]="editor"
                [config]="{ toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList' ] }"
                name="referenceWebsite" [(ngModel)]="referenceWebsite"></ckeditor>
            </div>

            <div *ngIf="report.interpretation != null">
              <button type="button" [disabled]="isSubmitedInterpretation" class="btn save_btn" style="float:right"
                (click)="addInterpretation()">
                <span *ngIf="!isSubmitedInterpretation"> Update </span>
                <div *ngIf="isSubmitedInterpretation" class="spinner-border text-dark">
                  <span class="sr-only"></span>
                </div>
              </button>
            </div>
            <div *ngIf="report.interpretation == null">
              <button type="button" [disabled]="isSubmitedInterpretation" class="btn save_btn" style="float:right"
                (click)="addInterpretation()">
                <span *ngIf="!isSubmitedInterpretation"> Save </span>
                <div *ngIf="isSubmitedInterpretation" class="spinner-border text-dark">
                  <span class="sr-only"></span>
                </div>
              </button>
            </div>
          </form>
        </div>
      </div>

      <!-- Immuno data -->
      <div id="Immunodata" class="container-fluid tab-pane fade "><br>
        <div id="tab7" class="w-50 m-auto ">
          <div class="p-4 my-5 bg-white cust_shadow rounded w-100 d-inline-block">
            <h5 class="py-2 px-4 w-100  rounded mb-3 d-block" style="background: #ddd;">Data</h5>
            <form class="w-75">
              <div class="form-group row" >
                <label for="" class="col-sm-4 col-form-label text-right">PD-L1 :</label>
                <div class="col-sm-8">
                  <select class="form-control" name="pdl1Status" [(ngModel)]="immunoDetails.pdl1Status"
                    (change)="pdl1Trails()">
                    <option value="EXPRESSION">EXPRESSION</option>
                    <option value="NO_EXPRESSION">NO_EXPRESSION</option>
                    <option value="NOT_PERFORMED">NOT_PERFORMED</option>
                  </select>
                </div>
              </div>
              <div *ngIf="immunoDetails.pdl1Status != 'NOT_PERFORMED' && immunoDetails.pdl1Status != null"
                class="form-group row">
                <label class="col-sm-4 control-label text-right">Upload PD-L1 Images:</label>
                <div class="col-sm-8">
                  <input type="file" class="form-control" (change)="uploadImage($event)">
                  <input type="file" class="form-control" (change)="uploadImage($event)">
                  <input type="file" class="form-control" (change)="uploadImage($event)">*
                  confirm select 3 images
                  <br>
                  <div *ngIf="immunoDetails.pdl1Images != null">
                    <img *ngFor='let url of imageUrls' [src]="url" height="75" width="100px" style="margin: 3px;">
                    <br />
                  </div>

                </div>
              </div>
              <div class="form-group row">
                <label for="" class="col-sm-4 col-form-label text-right">TMB :</label>
                <div class="col-sm-8">
                    <input type="text" class="form-control" name="tmb" [(ngModel)]="immunoDetails.tmb">
                </div>
            </div>
              <div class="form-group row">
                <label for="" class="col-sm-4 col-form-label text-right">MSI :</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" name="msi" [(ngModel)]="immunoDetails.msi">
                </div>
              </div>
              <div>
                <button type="button" [disabled]="isSubmitedImmunoDetails" class="btn save_btn" style="float:right"
                  (click)="addImmunoDetails()">
                  <span *ngIf="!isSubmitedImmunoDetails"> Save </span>
                  <div *ngIf="isSubmitedImmunoDetails" class="spinner-border text-dark">
                    <span class="sr-only"></span>
                  </div>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <!-- PD-L1 Trails -->
      <div *ngIf="immunoDetails.pdl1Status == 'EXPRESSION'" id="pdl1trails" class="container-fluid tab-pane fade ">
        <br>
        <div id="tab8" class="w-50 m-auto ">
          <table class="table">
            <thead class="thead-light bg-white">
              <tr>
                <th></th>
                <th>Cancer Type</th>
                <th>Drugs</th>
                <!-- <th>Action</th> -->
              </tr>
              <tr *ngFor="let clinicalTrial of clinicalTrialResList; let i = index">
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" (click)="addSelectedTrails($event,clinicalTrial.id)"
                      [checked]="clinicalTrial.selected">
                  </div>
                </td>
                <td>{{clinicalTrial.cancerType.name}}</td>
                <td>
                  <div *ngFor="let drug of clinicalTrial.drugs">
                    {{drug.name}}
                  </div>
                </td>
              </tr>

            </thead>
          </table>
        </div>
      </div>

      <!-- website -->
      <div id="website" class="container-fluid tab-pane fade"><br>
        <div id="tab9" class="">
          <form class="general-form">
            <div class="form-group row">
              <div class="col">
                <ckeditor [editor]="editor"
                  [config]="{ toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList' ] }"
                  name="referenceWebsite" [(ngModel)]="referenceWebsite"></ckeditor>
              </div>
            </div>

            <div>
              <button type="button" [disabled]="isSubmitedReferenceWebsite" class="btn save_btn" style="float:right"
                (click)="addReferenceWebsite()">
                <span *ngIf="!isSubmitedReferenceWebsite"> Save </span>
                <div *ngIf="isSubmitedReferenceWebsite" class="spinner-border text-dark">
                  <span class="sr-only"></span>
                </div>
              </button>
            </div>
          </form>
        </div>
      </div>


      <!-- Disclaimer -->
      <div id="disclaimer" class="container-fluid tab-pane fade"><br>
        <div id="tab10" class="">
          <form class="general-form">

            <div class="form-group row mt-2 mb-5">
              <div class="col-3  ">
                <div class="bg-white">
                  <ng-multiselect-dropdown placeholder="Select Disclaimer" [settings]="disclaimerDropdownSettings"
                    [data]="disclaimerList" (onSelect)="onDisclaimerItemSelect($event)"
                    (onDeSelect)="onDisclaimerItemDeSelect($event)">
                  </ng-multiselect-dropdown>
                </div>
              </div>
              <div class="col-3  ">
                <span *ngIf="isTargetFirst">(Disclaimer DB)</span>
              </div>
            </div>
            <div class="form-group row" span *ngIf="isTargetFirst">
              <div class="col">
                <div style="text-align: right; padding-bottom: 5px;" *ngIf="addNote!=null ||addNote!=undefined">
                  <P *ngIf="addNote.length"><strong>[{{editorText.length}}/11000]</strong></P>
                </div>

                <ckeditor [editor]="editor"
                  [config]="{ toolbar: [ 'heading', '|', 'bold', 'italic' , 'bulletedList' ] }" name="addNote"
                  [(ngModel)]="addNote"></ckeditor>
                <div class="addNote-editors" hidden="true" [innerHtml]="addNote"></div>
              </div>
            </div>

            <div class="form-group row" span *ngIf="!isTargetFirst">
              <div class="col">
                <div style="text-align: right; padding-bottom: 5px;" *ngIf="disclaimer!=null ||disclaimer!=undefined">
                  <P *ngIf="disclaimer.length"><strong>[{{editorText.length}}/11000]</strong></P>
                </div>

                <ckeditor [editor]="editor"
                  [config]="{ toolbar: [ 'heading', '|', 'bold', 'italic' , 'bulletedList' ] }" name="disclaimer"
                  [(ngModel)]="disclaimer" (keyup)="textcount('disclaimer-editors')"></ckeditor>
                <div class="disclaimer-editors" hidden="true" [innerHtml]="disclaimer"></div>
              </div>
            </div>
            <p style="font-size: 14px!important;padding-top: 8px;"><strong>*[</strong>A<sup>b</sup> →
              {{sup}}<strong>]</strong><br /><strong>*[</strong>A<sub>b</sub> → {{sub}}<strong>]</strong></p>


            <div *ngIf="report.disclaimer != null && !isTargetFirst">
              <button type="button" [disabled]="isSubmitedDisclaimer" class="btn save_btn" style="float:right"
                (click)="addDisclaimer()">
                <span *ngIf="!isSubmitedDisclaimer"> Update </span>
                <div *ngIf="isSubmitedDisclaimer" class="spinner-border text-dark">
                  <span class="sr-only"></span>
                </div>
              </button>
            </div>
            <div *ngIf="report.disclaimer == null && !isTargetFirst">
              <button type="button" class="btn save_btn" style="float:right" (click)="addDisclaimer()">
                <span *ngIf="!isSubmitedDisclaimer"> Save </span>
                <div *ngIf="isSubmitedDisclaimer" class="spinner-border text-dark">
                  <span class="sr-only"></span>
                </div>
              </button>

            </div>
            <div *ngIf="report.disclaimer != null && isTargetFirst">
              <button type="button" [disabled]="isSubmitedDisclaimer" class="btn save_btn" style="float:right"
                (click)="AddPMIDSAndAdditionalNotes()">
                <span *ngIf="!isSubmitedDisclaimer"> Update </span>
                <div *ngIf="isSubmitedDisclaimer" class="spinner-border text-dark">
                  <span class="sr-only"></span>
                </div>
              </button>
            </div>
            <div *ngIf="report.disclaimer == null && isTargetFirst">
              <button type="button" class="btn save_btn" style="float:right" (click)="AddPMIDSAndAdditionalNotes()">
                <span *ngIf="!isSubmitedDisclaimer"> Save </span>
                <div *ngIf="isSubmitedDisclaimer" class="spinner-border text-dark">
                  <span class="sr-only"></span>
                </div>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
