<div *ngIf="!reportData" class="d-flex justify-content-center mt-5">
  <span class="visually-hidden"></span>
  <div
    class="spinner-border"
    style="width: 8rem; height: 8rem"
    role="status"
  ></div>
</div>

<div *ngIf="reportData">
  <button
    [disabled]="downloadSpinner"
    type="button"
    class="btn btn-primary m-2 float-right download-btn"
    (click)="downloadReport()"
  >
    <span *ngIf="!downloadSpinner">
      <i class="zmdi zmdi-download"></i> Download Report
    </span>
    <div *ngIf="downloadSpinner" class="spinner-border text-dark">
      <span class="sr-only"></span>
    </div>
  </button>

  <div
    id="page"
    class="container position-relative pagess"
    style="overflow-y: clip; background-color: white"
  >
    <div class="row header">
      <div class="w-100 overflow-hidden">
        <div class="col px-0">
          <div class="cover_page">
            <img
              src="../../../assets/Germline+ Cover.png"
              alt=""
              class="img-fluid w-100"
            />
          </div>
        </div>
      </div>
      <div></div>
    </div>
  </div>

  <!-- page 1 -->
  <div
    id="page1"
    class="container position-relative pagess"
    style="background-color: white"
  >
    <div class="row header mt-1">
      <div *ngIf="report.clientType != 'client'">
        <div class="col-3 pl-0">
          <div class="logo">
            <img src="../../../assets/logo.png" alt="" style="width: 140px" />
          </div>
        </div>
      </div>
      <div class="col">
        <div class="patient-detailes ml-auto float-right">
          <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
            <li>
              Patient Id <br />
              <span>{{ report.patient.ecrfId }}</span>
            </li>
            <li class="ml-4">
              Patient Name <br />
              <span>{{ report.patient.name }}</span>
            </li>

            <li class="ml-4">
              Registration Date<br />
              <span>{{
                report.labDetails.sampleCollected | date : "dd/MM/yyyy"
              }}</span>
            </li>
            <li class="ml-4">
              Reporting Date<br />
              <span>{{ todayDate | date : "dd/MM/yyyy" }}</span>
            </li>
          </ul>
        </div>
      </div>
      <div *ngIf="report.clientType == '4basecare_client'">
        <div class="col-2" *ngIf="report.clientName == 'oncoquest'">
          <img
            src="../../../assets/oncoquest logo.png"
            alt=""
            class="onco_logo"
          />
        </div>
        <div class="col-2" *ngIf="report.clientName == 'path_labs'">
          <img src="../../../assets/pathlab.png" alt="" class="onco_logo" />
        </div>
      </div>
    </div>
    <!--  -->
    <div class="mar_top"></div>
    <!--  -->
    <div class="row">
      <div class="col-7">
        <div class="">
          <div class="w-50" style="min-height: 80px">
            <p style="color: #238eca; font-weight: 700" class="py-1">
              SCOPE OF THE TEST
            </p>
            <p>SNVs, InDels, CNAs status</p>
          </div>
          <img src="../../../assets/BorderLine.png" class="mr-2 w-75" />
        </div>
      </div>
      <div class="col">
        <div class="">
          <div class="w-50" style="min-height: 80px">
            <p style="color: #238eca; font-weight: 700" class="py-1">
              GENES OF INTEREST
            </p>
            <p [innerHtml]="report.interestGenes"></p>
          </div>
          <img src="../../../assets/BorderLine.png" class="mr-2 w-75" />
        </div>
      </div>
    </div>
    <!--  -->
    <div class="row pt-2">
      <div class="col-7">
        <h6 class="text-uppercase py-2 font-weight-bold">Report Details</h6>
        <ul class="list-unstyled">
          <li class="pb-1">
            <span class="clr-cyan">Name : </span>
            <span class="text-black">{{ report.patient.name }}</span>
          </li>
          <li class="pb-1">
            <span class="clr-cyan">Gender : </span>
            <span class="text-black">{{
              report.patient.gender | titlecase
            }}</span>
          </li>
          <li class="pb-1">
            <span class="clr-cyan">Age/DOB : </span>
            <span class="text-black">{{
              report.patient?.dateOfBirth
                ? (report.patient?.dateOfBirth | date : "dd/MM/yyyy")
                : report.patient?.age + " Years"
            }}</span>
          </li>
        </ul>
      </div>
      <div class="col">
        <ul class="list-unstyled pt-4 mt-4">
          <li class="pb-1">
            <span class="clr-cyan">Sample Source : </span>
            <span class="text-black">{{ sampleSource }}</span>
          </li>

          <li class="pb-1">
            <span class="clr-cyan">Referring Clinician : </span>
            <span class="text-black">{{referringClinician}}</span>
          </li>
          <li class="pb-1">
            <span class="clr-cyan">Hospital : </span>
            <span class="text-black">{{ labHospital }}</span>
          </li>
        </ul>
      </div>
    </div>
    <!--  -->
    <div class="row mt-3 mb-3">
      <div  style="width:100%" *ngIf="isReportDownload">
        <img [src]="familyHistoryImg" alt="" width="100%"  />
      </div>
      <div class="col" *ngIf="!isReportDownload" id="familyHistoryImg">
        <h6 class="mb-0 py-2 h6_bg">CLINICAL INDICATIONS / FAMILY HISTORY</h6>
        <p class="py-3" [innerHtml]="report.clinicalSummary"></p>

        <table class="table table_page0 ">
          <thead>
            <tr>
              <th></th>
              <th *ngFor="let familyDetails of germlineReportPdfRes.familyDetails">{{ familyDetails.familyType }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Relationship</td>
              <td *ngFor="let familyDetails of germlineReportPdfRes.familyDetails">
                {{ familyDetails.relationship }}
              </td>
            </tr>
            <tr>
              <td>Cancer Type</td>
              <td *ngFor="let familyDetails of germlineReportPdfRes.familyDetails">
                {{ familyDetails.cancerType }}
              </td>
            </tr>
            <tr>
              <td>Age of Diagnosis (in years)</td>
              <td *ngFor="let familyDetails of germlineReportPdfRes.familyDetails">
                {{ familyDetails.diagnosisAge }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!--  -->
    <div class="">
      <h6 class="py-2 rounded h6_bg">Results</h6>
    </div>
    <div class="row mt-2 py-2">
      <div class="tag" id="tag">
        <!--  -->
        <div class="mr-3 my-3">
          <div class="results_gradi mt-3">
            <ul class="d-flex list-unstyled">
              <li [ngClass]="{ zindex: isPathogenic }">
                <div
                  class="bg"
                  [ngClass]="{
                    marker_left: isPathogenic,
                    resultLine: !isPathogenic
                  }"
                >
                  <label *ngIf="isPathogenic"
                    >Pathogenic Variant Detected</label
                  >
                </div>
              </li>
              <li [ngClass]="{ zindex: isLikelyPathogenic }">
                <!-- <span>Likely Pathogenic Variant Identified</span> -->
                <div class="bg" [ngClass]="{ marker: isLikelyPathogenic }">
                  <label *ngIf="isLikelyPathogenic" for=""
                    >Likely Pathogenic Variant Detected</label
                  >
                </div>
              </li>
              <li [ngClass]="{ zindex: isVUS }">
                <!-- <span>Variant of Unknown Significance (VUS)</span> -->
                <div
                  class="bg"
                  [ngClass]="{
                    marker: isVUS,
                    marker_vus: isVUS,
                    resultLine: !isVUS
                  }"
                >
                  <label *ngIf="isVUS" for=""
                    >Variant of Unknown Significance (VUS) Detected</label
                  >
                </div>
              </li>
              <li [ngClass]="{ zindex: isOthers }">
                <!-- <span>Likely Benign/Benign/No variant</span> -->
                <div class="bg" [ngClass]="{ marker_right: isOthers }">
                  <label *ngIf="isOthers"
                    >Likely Benign/Benign/No variant Detected</label
                  >
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!--  -->
    <div class="row mt-4">
      <div class="col">
        <p class="mb-2">
          *Genetic test results are reported based on the recommendations of
          American College of Medical Genetics [1]. Please refer to Annexure for
          further information
        </p>
        <h5 class="dark-cyan font-weight-bold mb-2">Variant Details :</h5>
        <table class="page1_table">
          <thead>
            <th>Gene</th>
            <th>Genomic Alteration</th>
            <th>Amino acid change, Exon Number, Allele Burden</th>
            <th>Variant Type, Zygosity</th>
            <th>Associated disorder, Inheritance</th>
            <th>Reference</th>
            <th>Clinical Significance</th>
          </thead>
          <tbody
            *ngIf="
              germlineReportPdfRes.snvVariants.length == 0 &&
              germlineReportPdfRes.cnaVariants.length == 0
            "
          >
            <tr>
              <td></td>
              <td colspan="6" style="text-align: center !important">
                Clinically relevant genomic alterations associated with
                therapeutic significance were not detected.
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="varientTableCount <= 3">
            <tr *ngFor="let snvStarVariant of germlineReportPdfRes.snvVariants">
              <td>
                <b
                  ><i
                    ><strong>{{ snvStarVariant.refGeneX }}</strong></i
                  ></b
                >
              </td>
              <td>
                {{ snvStarVariant.genomicAlteration }}
                {{
                  snvStarVariant.selectedAAChangeknownGene != null
                    ? snvStarVariant.selectedAAChangeknownGene.split(",")[0]
                    : ""
                }}, [{{ snvStarVariant.ensembleValue }}]
              </td>
              <td>
                {{
                  snvStarVariant.selectedAAChangeknownGene != null
                    ? snvStarVariant.selectedAAChangeknownGene.split(",")[1]
                    : ""
                }}<br />
                Exon {{ snvStarVariant.exonNumber }} ,
                {{ snvStarVariant.mutantBurdenPercent * 0.01 | percent }}
              </td>
              <td>
                {{ snvStarVariant.exonicFuncknownGene }},
                {{ snvStarVariant.zygosity }}
              </td>
              <td>
                {{ snvStarVariant.cancerType }},
                {{ snvStarVariant.inheritanceMode }}
              </td>
              <td>{{ snvStarVariant.reference }}</td>
              <td>{{ snvStarVariant.significance }}</td>
            </tr>
            <tr *ngFor="let cnaStarVariant of germlineReportPdfRes.cnaVariants">
              <td>
                <b>{{ cnaStarVariant.gene }}</b>
              </td>
              <td>
                chr{{ cnaStarVariant.sample.split(":")[0] }}:g.{{
                  cnaStarVariant.sample
                    .split(":")[1]
                    .replace("-", "_")
                    .split("|")[0]
                }}
              </td>
              <td>NA</td>
              <td>
                Copy Number
                {{ cnaStarVariant.sample.split(":")[2] | titlecase }} ({{
                  cnaStarVariant.sample.split("|")[1].split(":")[0]
                }}), {{ cnaStarVariant.zygosity }}
              </td>
              <td>
                {{ cnaStarVariant.cancerType }},
                {{ cnaStarVariant.inheritanceMode }}
              </td>
              <td>{{ cnaStarVariant.reference }}</td>
              <td>{{ cnaStarVariant.significance }}</td>
            </tr>
          </tbody>
          <tbody
            *ngIf="
              varientTableCount >= 4 &&
              germlineReportPdfRes.snvVariants.length >= 3
            "
          >
            <tr
              *ngFor="
                let snvStarVariant of germlineReportPdfRes.snvVariants
                  | slice : 0 : 3
              "
            >
              <td>
                <b
                  ><i
                    ><strong>{{ snvStarVariant.refGeneX }}</strong></i
                  ></b
                >
              </td>
              <td>
                {{ snvStarVariant.genomicAlteration }}
                {{
                  snvStarVariant.selectedAAChangeknownGene != null
                    ? snvStarVariant.selectedAAChangeknownGene.split(",")[0]
                    : ""
                }}, [{{ snvStarVariant.ensembleValue }}]
              </td>
              <td>
                {{
                  snvStarVariant.selectedAAChangeknownGene != null
                    ? snvStarVariant.selectedAAChangeknownGene.split(",")[1]
                    : ""
                }}<br />
                Exon {{ snvStarVariant.exonNumber }} ,
                {{ snvStarVariant.mutantBurdenPercent * 0.01 | percent }}
              </td>
              <td>
                {{ snvStarVariant.exonicFuncknownGene }},
                {{ snvStarVariant.zygosity }}
              </td>
              <td>
                {{ snvStarVariant.cancerType }},
                {{ snvStarVariant.inheritanceMode }}
              </td>
              <td>{{ snvStarVariant.reference }}</td>
              <td>{{ snvStarVariant.significance }}</td>
            </tr>
          </tbody>
          <tbody
            *ngIf="
              varientTableCount >= 4 &&
              germlineReportPdfRes.snvVariants.length == 0
            "
          >
            <tr
              *ngFor="
                let cnaStarVariant of germlineReportPdfRes.cnaVariants
                  | slice : 0 : 3
              "
            >
              <td>
                <b>{{ cnaStarVariant.gene }}</b>
              </td>
              <td>
                chr{{ cnaStarVariant.sample.split(":")[0] }}:g.{{
                  cnaStarVariant.sample
                    .split(":")[1]
                    .replace("-", "_")
                    .split("|")[0]
                }}
              </td>
              <td>NA</td>
              <td>
                Copy Number
                {{ cnaStarVariant.sample.split(":")[2] | titlecase }} ({{
                  cnaStarVariant.sample.split("|")[1].split(":")[0]
                }}), {{ cnaStarVariant.zygosity }}
              </td>
              <td>
                {{ cnaStarVariant.cancerType }},
                {{ cnaStarVariant.inheritanceMode }}
              </td>
              <td>{{ cnaStarVariant.reference }}</td>
              <td>{{ cnaStarVariant.significance }}</td>
            </tr>
          </tbody>
          <tbody
            *ngIf="
              varientTableCount >= 4 &&
              germlineReportPdfRes.snvVariants.length == 1
            "
          >
            <tr *ngFor="let snvStarVariant of germlineReportPdfRes.snvVariants">
              <td>
                <b
                  ><i
                    ><strong>{{ snvStarVariant.refGeneX }}</strong></i
                  ></b
                >
              </td>
              <td>
                {{ snvStarVariant.genomicAlteration }}
                {{
                  snvStarVariant.selectedAAChangeknownGene != null
                    ? snvStarVariant.selectedAAChangeknownGene.split(",")[0]
                    : ""
                }}, [{{ snvStarVariant.ensembleValue }}]
              </td>
              <td>
                {{
                  snvStarVariant.selectedAAChangeknownGene != null
                    ? snvStarVariant.selectedAAChangeknownGene.split(",")[1]
                    : ""
                }}<br />
                Exon {{ snvStarVariant.exonNumber }} ,
                {{ snvStarVariant.mutantBurdenPercent * 0.01 | percent }}
              </td>
              <td>
                {{ snvStarVariant.exonicFuncknownGene }},
                {{ snvStarVariant.zygosity }}
              </td>
              <td>
                {{ snvStarVariant.cancerType }},
                {{ snvStarVariant.inheritanceMode }}
              </td>
              <td>{{ snvStarVariant.reference }}</td>
              <td>{{ snvStarVariant.significance }}</td>
            </tr>
            <tr
              *ngFor="
                let cnaStarVariant of germlineReportPdfRes.cnaVariants
                  | slice : 0 : 2
              "
            >
              <td>
                <b>{{ cnaStarVariant.gene }}</b>
              </td>
              <td>
                chr{{ cnaStarVariant.sample.split(":")[0] }}:g.{{
                  cnaStarVariant.sample
                    .split(":")[1]
                    .replace("-", "_")
                    .split("|")[0]
                }}
              </td>
              <td>NA</td>
              <td>
                Copy Number
                {{ cnaStarVariant.sample.split(":")[2] | titlecase }} ({{
                  cnaStarVariant.sample.split("|")[1].split(":")[0]
                }}), {{ cnaStarVariant.zygosity }}
              </td>
              <td>
                {{ cnaStarVariant.cancerType }},
                {{ cnaStarVariant.inheritanceMode }}
              </td>
              <td>{{ cnaStarVariant.reference }}</td>
              <td>{{ cnaStarVariant.significance }}</td>
            </tr>
          </tbody>
          <tbody
            *ngIf="
              varientTableCount >= 4 &&
              germlineReportPdfRes.snvVariants.length == 2
            "
          >
            <tr *ngFor="let snvStarVariant of germlineReportPdfRes.snvVariants">
              <td>
                <b
                  ><i
                    ><strong>{{ snvStarVariant.refGeneX }}</strong></i
                  ></b
                >
              </td>
              <td>
                {{ snvStarVariant.genomicAlteration }}
                {{
                  snvStarVariant.selectedAAChangeknownGene != null
                    ? snvStarVariant.selectedAAChangeknownGene.split(",")[0]
                    : ""
                }}, [{{ snvStarVariant.ensembleValue }}]
              </td>
              <td>
                {{
                  snvStarVariant.selectedAAChangeknownGene != null
                    ? snvStarVariant.selectedAAChangeknownGene.split(",")[1]
                    : ""
                }}<br />
                Exon {{ snvStarVariant.exonNumber }} ,
                {{ snvStarVariant.mutantBurdenPercent * 0.01 | percent }}
              </td>
              <td>
                {{ snvStarVariant.exonicFuncknownGene }},
                {{ snvStarVariant.zygosity }}
              </td>
              <td>
                {{ snvStarVariant.cancerType }},
                {{ snvStarVariant.inheritanceMode }}
              </td>
              <td>{{ snvStarVariant.reference }}</td>
              <td>{{ snvStarVariant.significance }}</td>
            </tr>
            <tr
              *ngFor="
                let cnaStarVariant of germlineReportPdfRes.cnaVariants
                  | slice : 0 : 1
              "
            >
              <td>
                <b>{{ cnaStarVariant.gene }}</b>
              </td>
              <td>
                chr{{ cnaStarVariant.sample.split(":")[0] }}:g.{{
                  cnaStarVariant.sample
                    .split(":")[1]
                    .replace("-", "_")
                    .split("|")[0]
                }}
              </td>
              <td>NA</td>
              <td>
                Copy Number
                {{ cnaStarVariant.sample.split(":")[2] | titlecase }} ({{
                  cnaStarVariant.sample.split("|")[1].split(":")[0]
                }}), {{ cnaStarVariant.zygosity }}
              </td>
              <td>
                {{ cnaStarVariant.cancerType }},
                {{ cnaStarVariant.inheritanceMode }}
              </td>
              <td>{{ cnaStarVariant.reference }}</td>
              <td>{{ cnaStarVariant.significance }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="mt-3" *ngIf="report.disclaimer != null && report.disclaimer != '<p></p>' && varientTableCount < 4">
      <h6 class="py-2 h6_bg">DISCLAIMER</h6>
      <div style="line-height: 28px; text-align: justify">
        <div [innerHtml]="report.disclaimer" style="line-height: 28px; text-align: justify"></div>
      </div>
    </div>

    <!--  -->
    <div class="row footer">
      <div class="col-12">
        <div class="pb-2 pl-4 text">
          <div class="d-block">
            <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
          </div>
          <div class="footer_body" [ngClass]="'gene-bg_blue'">
            <div class="footer_list">
              <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
              <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
              <span>Gln - Q</span><span>Gly - G</span><span>His - H</span>
              <span>Ile - I</span>
            </div>
            <div class="footer_list">
              <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
              <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
              <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
              <span>Val - V</span>
            </div>
          </div>
        </div>
        <div class="col-12 pt-1 ">
          <p style="font-size: 8px!important; line-height: 14px!important;">
            {{footerDiscription}}<br/>{{footerDiscription2}}
          </p>
        </div>
      </div>
    </div>
  </div>

  <!-- page 2 -->
  <div
    id="page2"
    class="container position-relative pagess"
    style="background-color: white"
    *ngIf="
      (germlineReportPdfRes.reportedGenes != null &&
        germlineReportPdfRes.reportedGenes.length > 0) ||
      (germlineReportPdfRes.snvStarVariants.length > 0 ||
        germlineReportPdfRes.cnaStarVariants.length > 0) ||
      (report.addNotes != null && report.addNotes != '<p></p>') ||
      (report.disclaimer != null && report.disclaimer != '<p></p>' && varientTableCount >= 4)
    "
  >
    <div class="row header mt-1">
      <div *ngIf="report.clientType != 'client'">
        <div class="col-3 pl-0">
          <div class="logo">
            <img src="../../../assets/logo.png" alt="" style="width: 140px" />
          </div>
        </div>
      </div>
      <div class="col">
        <div class="patient-detailes ml-auto float-right">
          <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
            <li>
              Patient Id <br />
              <span>{{ report.patient.ecrfId }}</span>
            </li>
            <li class="ml-4">
              Patient Name <br />
              <span>{{ report.patient.name }}</span>
            </li>

            <li class="ml-4">
              Registration Date<br />
              <span>{{
                report.labDetails.sampleCollected | date : "dd/MM/yyyy"
              }}</span>
            </li>
            <li class="ml-4">
              Reporting Date<br />
              <span>{{ todayDate | date : "dd/MM/yyyy" }}</span>
            </li>
          </ul>
        </div>
      </div>
      <div *ngIf="report.clientType == '4basecare_client'">
        <div class="col-2" *ngIf="report.clientName == 'oncoquest'">
          <img
            src="../../../assets/oncoquest logo.png"
            alt=""
            class="onco_logo"
          />
        </div>
        <div class="col-2" *ngIf="report.clientName == 'path_labs'">
          <img src="../../../assets/pathlab.png" alt="" class="onco_logo" />
        </div>
      </div>
    </div>
    <!--  -->
    <table class="page1_table" *ngIf="varientTableCount >= 4">
      <thead>
        <th>Gene</th>
        <th>Genomic Alteration</th>
        <th>Amino acid change, Exon Number, Allele Burden</th>
        <th>Variant Type, Zygosity</th>
        <th>Associated disorder, Inheritance</th>
        <th>Reference</th>
        <th>Clinical Significance</th>
      </thead>
      <tbody *ngIf="germlineReportPdfRes.snvVariants.length >= 3">
        <tr
          *ngFor="
            let snvStarVariant of germlineReportPdfRes.snvVariants
              | slice : 3 : 10
          "
        >
          <td>
            <b
              ><i
                ><strong>{{ snvStarVariant.refGeneX }}</strong></i
              ></b
            >
          </td>
          <td>
            {{ snvStarVariant.genomicAlteration }}
            {{
              snvStarVariant.selectedAAChangeknownGene != null
                ? snvStarVariant.selectedAAChangeknownGene.split(",")[0]
                : ""
            }}, [{{ snvStarVariant.ensembleValue }}]
          </td>
          <td>
            {{
              snvStarVariant.selectedAAChangeknownGene != null
                ? snvStarVariant.selectedAAChangeknownGene.split(",")[1]
                : ""
            }}<br />
            Exon {{ snvStarVariant.exonNumber }} ,
            {{ snvStarVariant.mutantBurdenPercent * 0.01 | percent }}
          </td>
          <td>
            {{ snvStarVariant.exonicFuncknownGene }},
            {{ snvStarVariant.zygosity }}
          </td>
          <td>
            {{ snvStarVariant.cancerType }},
            {{ snvStarVariant.inheritanceMode }}
          </td>
          <td>{{ snvStarVariant.reference }}</td>
          <td>{{ snvStarVariant.significance }}</td>
        </tr>
        <tr *ngFor="let cnaStarVariant of germlineReportPdfRes.cnaVariants">
          <td>
            <b>{{ cnaStarVariant.gene }}</b>
          </td>
          <td>
            chr{{ cnaStarVariant.sample.split(":")[0] }}:g.{{
              cnaStarVariant.sample
                .split(":")[1]
                .replace("-", "_")
                .split("|")[0]
            }}
          </td>
          <td>NA</td>
          <td>
            Copy Number
            {{ cnaStarVariant.sample.split(":")[2] | titlecase }} ({{
              cnaStarVariant.sample.split("|")[1].split(":")[0]
            }}), {{ cnaStarVariant.zygosity }}
          </td>
          <td>
            {{ cnaStarVariant.cancerType }},
            {{ cnaStarVariant.inheritanceMode }}
          </td>
          <td>{{ cnaStarVariant.reference }}</td>
          <td>{{ cnaStarVariant.significance }}</td>
        </tr>
      </tbody>
      <tbody
        *ngIf="
          germlineReportPdfRes.snvVariants.length == 0 &&
          germlineReportPdfRes.cnaVariants.length >= 4
        "
      >
        <tr
          *ngFor="
            let cnaStarVariant of germlineReportPdfRes.cnaVariants
              | slice : 3 : 8
          "
        >
          <td>
            <b>{{ cnaStarVariant.gene }}</b>
          </td>
          <td>
            chr{{ cnaStarVariant.sample.split(":")[0] }}:g.{{
              cnaStarVariant.sample
                .split(":")[1]
                .replace("-", "_")
                .split("|")[0]
            }}
          </td>
          <td>NA</td>
          <td>
            Copy Number
            {{ cnaStarVariant.sample.split(":")[2] | titlecase }} ({{
              cnaStarVariant.sample.split("|")[1].split(":")[0]
            }}), {{ cnaStarVariant.zygosity }}
          </td>
          <td>
            {{ cnaStarVariant.cancerType }},
            {{ cnaStarVariant.inheritanceMode }}
          </td>
          <td>{{ cnaStarVariant.reference }}</td>
          <td>{{ cnaStarVariant.significance }}</td>
        </tr>
      </tbody>
      <tbody *ngIf="germlineReportPdfRes.snvVariants.length == 1">
        <tr
          *ngFor="
            let cnaStarVariant of germlineReportPdfRes.cnaVariants
              | slice : 2 : 8
          "
        >
          <td>
            <b>{{ cnaStarVariant.gene }}</b>
          </td>
          <td>
            chr{{ cnaStarVariant.sample.split(":")[0] }}:g.{{
              cnaStarVariant.sample
                .split(":")[1]
                .replace("-", "_")
                .split("|")[0]
            }}
          </td>
          <td>NA</td>
          <td>
            Copy Number
            {{ cnaStarVariant.sample.split(":")[2] | titlecase }} ({{
              cnaStarVariant.sample.split("|")[1].split(":")[0]
            }}), {{ cnaStarVariant.zygosity }}
          </td>
          <td>
            {{ cnaStarVariant.cancerType }},
            {{ cnaStarVariant.inheritanceMode }}
          </td>
          <td>{{ cnaStarVariant.reference }}</td>
          <td>{{ cnaStarVariant.significance }}</td>
        </tr>
      </tbody>
      <tbody *ngIf="germlineReportPdfRes.snvVariants.length == 2">
        <tr
          *ngFor="
            let cnaStarVariant of germlineReportPdfRes.cnaVariants
              | slice : 1 : 8
          "
        >
          <td>
            <b>{{ cnaStarVariant.gene }}</b>
          </td>
          <td>
            chr{{ cnaStarVariant.sample.split(":")[0] }}:g.{{
              cnaStarVariant.sample
                .split(":")[1]
                .replace("-", "_")
                .split("|")[0]
            }}
          </td>
          <td>NA</td>
          <td>
            Copy Number
            {{ cnaStarVariant.sample.split(":")[2] | titlecase }} ({{
              cnaStarVariant.sample.split("|")[1].split(":")[0]
            }}), {{ cnaStarVariant.zygosity }}
          </td>
          <td>
            {{ cnaStarVariant.cancerType }},
            {{ cnaStarVariant.inheritanceMode }}
          </td>
          <td>{{ cnaStarVariant.reference }}</td>
          <td>{{ cnaStarVariant.significance }}</td>
        </tr>
      </tbody>
    </table>
    <div class="mt-3" *ngIf="report.disclaimer != null && report.disclaimer != '<p></p>' && varientTableCount >= 4">
      <h6 class="py-2 h6_bg">DISCLAIMER</h6>
      <div style="line-height: 28px; text-align: justify">
        <div
          [innerHtml]="report.disclaimer"
          style="line-height: 28px; text-align: justify"
        ></div>
      </div>
    </div>

    <div
      class="mt-3 mb-5"
      *ngIf="
        (germlineReportPdfRes.reportedGenes != null &&
        germlineReportPdfRes.reportedGenes.length > 0)
      "
    >
      <h6 class="py-2 rounded h6_bg">
        VARIANT INTERPRETATION AND CLINICAL CORRELATION
      </h6>
      <div *ngIf="varientTableCount < 4">
        <div
          *ngFor="let gene of germlineReportPdfRes.reportedGenes"
          class="col-12 mb-2"
        >
          <h4
            class="py-2"
            style="color: #000; font-size: 19px !important; font-weight: 400"
          >
            <span class="font-weight-bold" style="font-size: 19px !important"
              ><i
                ><b>{{ gene.name }}</b></i
              ></span
            >
            <!-- <b>{{gene.geneName}}</b> -->
          </h4>
          <p
            [innerHtml]="gene.description"
            style="line-height: 24px; text-align: justify"
          ></p>
        </div>
      </div>
      <div *ngIf="varientTableCount >= 4">
        <div
          *ngFor="
            let gene of germlineReportPdfRes.reportedGenes | slice : 0 : 2
          "
          class="col-12 mb-2"
        >
          <h4
            class="py-2"
            style="color: #000; font-size: 19px !important; font-weight: 400"
          >
            <span class="font-weight-bold" style="font-size: 19px !important"
              ><i
                ><b>{{ gene.name }}</b></i
              ></span
            >
            <!-- <b>{{gene.geneName}}</b> -->
          </h4>
          <p
            [innerHtml]="gene.description"
            style="line-height: 24px; text-align: justify"
          ></p>
        </div>
      </div>
    </div>

    <div class="mt-3 mb-5 row" *ngIf="(report.addNotes != null && report.addNotes != '<p></p>') && varientTableCount <= 2">
      <div class="col-12">
        <h6 class="py-2 mb-3 rounded h6_bg">ADDITIONAL NOTES</h6>
        <div class="py-2" [innerHtml]="report.addNotes"></div>
      </div>
    </div>
    <div
    class="pt-2"
    *ngIf="
      (germlineReportPdfRes.snvStarVariants.length > 0 ||
        germlineReportPdfRes.cnaStarVariants.length > 0) &&
        (varientTableCount <=1 || ((report.addNotes == null || report.addNotes == '<p></p>') && varientTableCount == 2 ))
    "
  >
    <h6
      class="py-2 mb-3 rounded h6_bg"
      *ngIf="
        germlineReportPdfRes.snvStarVariants.length > 0 ||
        germlineReportPdfRes.cnaStarVariants.length > 0
      "
    >
      ADDITIONAL VARIANTS OF UNCERTAIN SIGNIFICANCE DETECTED IN THE PATIENT
    </h6>
    <div class="row">
      <!-- <div class="col-12" *ngIf="isReportDownload">
      <img [src]="additionalvaiantImg" alt="" class="img-fluid w-90">
    </div> -->
      <div class="col">
        <table
          class="page1_table"
          *ngIf="
            germlineReportPdfRes.snvStarVariants.length > 0 ||
            germlineReportPdfRes.cnaStarVariants.length > 0
          "
        >
          <thead>
            <th>Gene</th>
            <th>Genomic Alteration</th>
            <th>Amino acid change, Exon Number, Allele Burden</th>
            <th>Variant type, Zygosity</th>
            <th>Associated disorder, Inheritance</th>
            <th>Reference</th>
            <th>Clinical Significance</th>
          </thead>
          <tbody>
            <tr
              *ngFor="
                let snvStarVariant of germlineReportPdfRes.snvStarVariants
              "
            >
              <td>
                <b
                  ><i
                    ><strong>{{ snvStarVariant.refGeneX }}</strong></i
                  ></b
                >
              </td>
              <td>
                {{ snvStarVariant.genomicAlteration }}
                {{ snvStarVariant.selectedAAChangeknownGene.split(",")[0] }},
                [{{ snvStarVariant.ensembleValue }}]
              </td>
              <td>
                {{ snvStarVariant.selectedAAChangeknownGene.split(",")[1] }},
                Exon {{ snvStarVariant.exonNumber }}, {{
                  snvStarVariant.mutantBurdenPercent * 0.01 | percent
                }}
              </td>
              <td>
                {{ snvStarVariant.exonicFuncknownGene }},
                {{ snvStarVariant.zygosity }}
              </td>
              <td>
                {{ snvStarVariant.cancerType }},
                {{ snvStarVariant.inheritanceMode }}
              </td>
              <td>{{ snvStarVariant.reference }}</td>
              <td>{{ snvStarVariant.significance }}</td>
            </tr>
            <tr
              *ngFor="let cnarVariant of germlineReportPdfRes.cnaStarVariants"
            >
              <td>
                <b>{{ cnarVariant.gene }}</b>
              </td>
              <td>
                chr{{ cnarVariant.sample.split(":")[0] }}:g.{{
                  cnarVariant.sample
                    .split(":")[1]
                    .replace("-", "_")
                    .split("|")[0]
                }}
              </td>
              <td>NA</td>
              <td>
                Copy Number
                {{ cnarVariant.sample.split(":")[2] | titlecase }} ({{
                  cnarVariant.sample.split("|")[1].split(":")[0]
                }}), {{ cnarVariant.zygosity }}
              </td>
              <td>
                {{ cnarVariant.cancerType }},
                {{ cnarVariant.inheritanceMode }}
              </td>
              <td>{{ cnarVariant.reference }}</td>
              <td>{{ cnarVariant.significance }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div
  class="mt-3 mb-5"
  *ngIf="
    (germlineReportPdfRes.reportedStarGenes !=null &&
    germlineReportPdfRes.reportedStarGenes.length > 0) &&
    (varientTableCount <=1 || ((report.addNotes == null || report.addNotes == '<p></p>') && varientTableCount == 2 ))
  "
>
  <h6 class="py-2 rounded h6_bg" *ngIf="(varientTableCount <1 || ((report.addNotes == null || report.addNotes == '<p></p>') && varientTableCount == 1 ))
    ">
    VARIANT INTERPRETATION AND CLINICAL CORRELATION
  </h6>
  <div *ngIf="(varientTableCount==0 && (report.addNotes == null || report.addNotes == '<p></p>')) ">
    <div
      *ngFor="
        let gene of germlineReportPdfRes.reportedStarGenes | slice : 0 : 2
      "
      class="col-12 mb-2"
    >
      <h4
        class="py-2"
        style="color: #000; font-size: 19px !important; font-weight: 400"
      >
        <span class="font-weight-bold" style="font-size: 19px !important"
          ><i
            ><b>{{ gene.name }}</b></i
          ></span
        >
        <!-- <b>{{gene.geneName}}</b> -->
      </h4>
      <p
        [innerHtml]="gene.description"
        style="line-height: 24px; text-align: justify"
      ></p>
    </div>
  </div>
  <div *ngIf="(varientTableCount==0 && (report.addNotes != null && report.addNotes != '<p></p>')) || (varientTableCount==1 && (report.addNotes == null || report.addNotes == '<p></p>')) ">
    <div
      *ngFor="
        let gene of germlineReportPdfRes.reportedStarGenes | slice : 0 : 1
      "
      class="col-12 mb-2"
    >
      <h4
        class="py-2"
        style="color: #000; font-size: 19px !important; font-weight: 400"
      >
        <span class="font-weight-bold" style="font-size: 19px !important"
          ><i
            ><b>{{ gene.name }}</b></i
          ></span
        >
        <!-- <b>{{gene.geneName}}</b> -->
      </h4>
      <p
        [innerHtml]="gene.description"
        style="line-height: 24px; text-align: justify"
      ></p>
    </div>
  </div>

</div>

    <!--  -->
    <div class="row footer">
      <div class="col-12">
        <div class="pb-2 pl-4 text">
          <div class="d-block">
            <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
          </div>
          <div class="footer_body" [ngClass]="'gene-bg_blue'">
            <div class="footer_list">
              <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
              <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
              <span>Gln - Q</span><span>Gly - G</span><span>His - H</span>
              <span>Ile - I</span>
            </div>
            <div class="footer_list">
              <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
              <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
              <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
              <span>Val - V</span>
            </div>
          </div>
        </div>
        <div class="col-12 pt-1 ">
          <p style="font-size: 8px!important; line-height: 14px!important;">
            {{footerDiscription}}<br/>{{footerDiscription2}}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div
    id="page2_1"
    class="container position-relative pagess"
    style="background-color: white"
    *ngIf="(varientTableCount==0 && (report.addNotes != null && report.addNotes != '<p></p>') && germlineReportPdfRes.reportedStarGenes.length > 1) ||
    (varientTableCount==0 && (report.addNotes == null || report.addNotes == '<p></p>') && germlineReportPdfRes.reportedStarGenes.length > 2)
||
    (varientTableCount>0 && (report.addNotes == null || report.addNotes == '<p></p>') && germlineReportPdfRes.reportedStarGenes.length > 0) ||
    (varientTableCount>0 && (report.addNotes != null && report.addNotes != '<p></p>')  && germlineReportPdfRes.reportedStarGenes.length > 0)
    || (varientTableCount ==3 && (report.addNotes != null && report.addNotes != '<p></p>') )

    || (varientTableCount >= 4 && varientTableCount <= 8  )">

    <div class="row header mt-1">
      <div *ngIf="report.clientType != 'client'">
        <div class="col-3 pl-0">
          <div class="logo">
            <img src="../../../assets/logo.png" alt="" style="width: 140px" />
          </div>
        </div>
      </div>
      <div class="col">
        <div class="patient-detailes ml-auto float-right">
          <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
            <li>
              Patient Id <br />
              <span>{{ report.patient.ecrfId }}</span>
            </li>
            <li class="ml-4">
              Patient Name <br />
              <span>{{ report.patient.name }}</span>
            </li>

            <li class="ml-4">
              Registration Date<br />
              <span>{{
                report.labDetails.sampleCollected | date : "dd/MM/yyyy"
              }}</span>
            </li>
            <li class="ml-4">
              Reporting Date<br />
              <span>{{ todayDate | date : "dd/MM/yyyy" }}</span>
            </li>
          </ul>
        </div>
      </div>
      <div *ngIf="report.clientType == '4basecare_client'">
        <div class="col-2" *ngIf="report.clientName == 'oncoquest'">
          <img
            src="../../../assets/oncoquest logo.png"
            alt=""
            class="onco_logo"
          />
        </div>
        <div class="col-2" *ngIf="report.clientName == 'path_labs'">
          <img src="../../../assets/pathlab.png" alt="" class="onco_logo" />
        </div>
      </div>
    </div>
    <!--  -->
    <div *ngIf="varientTableCount >= 4">
      <div  *ngFor="let gene of germlineReportPdfRes.reportedGenes | slice : 2 : 5"
        class="col-12 mb-2"
      >
        <h4
          class="py-2"
          style="color: #000; font-size: 19px !important; font-weight: 400"
        >
          <span class="font-weight-bold" style="font-size: 19px !important"
            ><i
              ><b>{{ gene.name }}</b></i
            ></span
          >
        </h4>
        <p
          [innerHtml]="gene.description"
          style="line-height: 24px; text-align: justify"
        ></p>
      </div>
    </div>

    <div class="mt-3 mb-5 row" *ngIf="(report.addNotes != null && report.addNotes != '<p></p>') && (varientTableCount == 3 || varientTableCount == 4)">
      <div class="col-12">
        <h6 class="py-2 mb-3 rounded h6_bg">ADDITIONAL NOTES</h6>
        <div class="py-2" [innerHtml]="report.addNotes"></div>
      </div>
    </div>
    <div
    class="pt-2"
    *ngIf="
      (germlineReportPdfRes.snvStarVariants.length > 0 ||
        germlineReportPdfRes.cnaStarVariants.length > 0) &&
        (varientTableCount == 3 || ((report.addNotes == null || report.addNotes == '<p></p>') && varientTableCount == 4 ) || ((report.addNotes != null && report.addNotes != '<p></p>') && varientTableCount == 2 ))
    "
  >
    <h6
      class="py-2 mb-3 rounded h6_bg"
      *ngIf="
        germlineReportPdfRes.snvStarVariants.length > 0 ||
        germlineReportPdfRes.cnaStarVariants.length > 0
      "
    >
      ADDITIONAL VARIANTS OF UNCERTAIN SIGNIFICANCE DETECTED IN THE PATIENT
    </h6>
    <div class="row">
      <!-- <div class="col-12" *ngIf="isReportDownload">
      <img [src]="additionalvaiantImg" alt="" class="img-fluid w-90">
    </div> -->
      <div class="col">
        <table
          class="page1_table"
          *ngIf="
            germlineReportPdfRes.snvStarVariants.length > 0 ||
            germlineReportPdfRes.cnaStarVariants.length > 0
          "
        >
          <thead>
            <th>Gene</th>
            <th>Genomic Alteration</th>
            <th>Amino acid change, Exon Number, Allele Burden</th>
            <th>Variant type, Zygosity</th>
            <th>Associated disorder, Inheritance</th>
            <th>Reference</th>
            <th>Clinical Significance</th>
          </thead>
          <tbody>
            <tr
              *ngFor="
                let snvStarVariant of germlineReportPdfRes.snvStarVariants
              "
            >
              <td>
                <b
                  ><i
                    ><strong>{{ snvStarVariant.refGeneX }}</strong></i
                  ></b
                >
              </td>
              <td>
                {{ snvStarVariant.genomicAlteration }}
                {{ snvStarVariant.selectedAAChangeknownGene.split(",")[0] }},
                [{{ snvStarVariant.ensembleValue }}]
              </td>
              <td>
                {{ snvStarVariant.selectedAAChangeknownGene.split(",")[1] }},
                Exon {{ snvStarVariant.exonNumber }}, {{
                  snvStarVariant.mutantBurdenPercent * 0.01 | percent
                }}
              </td>
              <td>
                {{ snvStarVariant.exonicFuncknownGene }},
                {{ snvStarVariant.zygosity }}
              </td>
              <td>
                {{ snvStarVariant.cancerType }},
                {{ snvStarVariant.inheritanceMode }}
              </td>
              <td>{{ snvStarVariant.reference }}</td>
              <td>{{ snvStarVariant.significance }}</td>
            </tr>
            <tr
              *ngFor="let cnarVariant of germlineReportPdfRes.cnaStarVariants"
            >
              <td>
                <b>{{ cnarVariant.gene }}</b>
              </td>
              <td>
                chr{{ cnarVariant.sample.split(":")[0] }}:g.{{
                  cnarVariant.sample
                    .split(":")[1]
                    .replace("-", "_")
                    .split("|")[0]
                }}
              </td>
              <td>NA</td>
              <td>
                Copy Number
                {{ cnarVariant.sample.split(":")[2] | titlecase }} ({{
                  cnarVariant.sample.split("|")[1].split(":")[0]
                }}), {{ cnarVariant.zygosity }}
              </td>
              <td>
                {{ cnarVariant.cancerType }},
                {{ cnarVariant.inheritanceMode }}
              </td>
              <td>{{ cnarVariant.reference }}</td>
              <td>{{ cnarVariant.significance }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
    <div
      class="mt-3 mb-5"
      *ngIf="
        germlineReportPdfRes.reportedStarGenes &&
        germlineReportPdfRes.reportedStarGenes.length > 0 &&
        (varientTableCount >=0 || varientTableCount <=3)
      "
    >
      <h6 class="py-2 rounded h6_bg" *ngIf="varientTableCount==2 || varientTableCount==3 || (varientTableCount ==1 && (report.addNotes == null || report.addNotes == '<p></p>')) || (varientTableCount==3 && (report.addNotes != null && report.addNotes != '<p></p>'))">
        VARIANT INTERPRETATION AND CLINICAL CORRELATION
      </h6>
      <div *ngIf="(varientTableCount ==1  && (report.addNotes == null || report.addNotes == '<p></p>')) || (varientTableCount==0 && (report.addNotes != null && report.addNotes != '<p></p>')) ">
        <div
          *ngFor="
            let gene of germlineReportPdfRes.reportedStarGenes | slice : 1 : 5
          "
          class="col-12 mb-2"
        >
          <h4
            class="py-2"
            style="color: #000; font-size: 19px !important; font-weight: 400"
          >
            <span class="font-weight-bold" style="font-size: 19px !important"
              ><i
                ><b>{{ gene.name }}</b></i
              ></span
            >
          </h4>
          <p
            [innerHtml]="gene.description"
            style="line-height: 24px; text-align: justify"
          ></p>
        </div>
      </div>
      <div *ngIf="(varientTableCount==0 && (report.addNotes == null || report.addNotes == '<p></p>'))">
        <div
          *ngFor="
            let gene of germlineReportPdfRes.reportedStarGenes | slice : 2 : 5
          "
          class="col-12 mb-2"
        >
          <h4
            class="py-2"
            style="color: #000; font-size: 19px !important; font-weight: 400"
          >
            <span class="font-weight-bold" style="font-size: 19px !important"
              ><i
                ><b>{{ gene.name }}</b></i
              ></span
            >
            <!-- <b>{{gene.geneName}}</b> -->
          </h4>
          <p
            [innerHtml]="gene.description"
            style="line-height: 24px; text-align: justify"
          ></p>
        </div>
      </div>


      <div *ngIf="(varientTableCount ==2  && (report.addNotes == null || report.addNotes == '<p></p>')) || (varientTableCount==1 && (report.addNotes != null && report.addNotes != '<p></p>')) ">
        <div
          *ngFor="
            let gene of germlineReportPdfRes.reportedStarGenes | slice : 0 : 5
          "
          class="col-12 mb-2"
        >
          <h4
            class="py-2"
            style="color: #000; font-size: 19px !important; font-weight: 400"
          >
            <span class="font-weight-bold" style="font-size: 19px !important"
              ><i
                ><b>{{ gene.name }}</b></i
              ></span
            >
            <!-- <b>{{gene.geneName}}</b> -->
          </h4>
          <p
            [innerHtml]="gene.description"
            style="line-height: 24px; text-align: justify"
          ></p>
        </div>
      </div>


      <div *ngIf="(varientTableCount==2 && (report.addNotes != null && report.addNotes != '<p></p>')) || (varientTableCount==3 && (report.addNotes == null || report.addNotes == '<p></p>'))">
        <div
          *ngFor="
            let gene of germlineReportPdfRes.reportedStarGenes | slice : 0 : 2
          "
          class="col-12 mb-2"
        >
          <h4
            class="py-2"
            style="color: #000; font-size: 19px !important; font-weight: 400"
          >
            <span class="font-weight-bold" style="font-size: 19px !important"
              ><i
                ><b>{{ gene.name }}</b></i
              ></span
            >
            <!-- <b>{{gene.geneName}}</b> -->
          </h4>
          <p
            [innerHtml]="gene.description"
            style="line-height: 24px; text-align: justify"
          ></p>
        </div>
      </div>

      <div *ngIf="(varientTableCount==3 && (report.addNotes != null && report.addNotes != '<p></p>'))">
        <div
          *ngFor="
            let gene of germlineReportPdfRes.reportedStarGenes | slice : 0 : 1
          "
          class="col-12 mb-2"
        >
          <h4
            class="py-2"
            style="color: #000; font-size: 19px !important; font-weight: 400"
          >
            <span class="font-weight-bold" style="font-size: 19px !important"
              ><i
                ><b>{{ gene.name }}</b></i
              ></span
            >
            <!-- <b>{{gene.geneName}}</b> -->
          </h4>
          <p
            [innerHtml]="gene.description"
            style="line-height: 24px; text-align: justify"
          ></p>
        </div>
      </div>



    </div>
    <!--  -->
    <div class="row footer">
      <div class="col-12">
        <div class="pb-2 pl-4 text">
          <div class="d-block">
            <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
          </div>
          <div class="footer_body" [ngClass]="'gene-bg_blue'">
            <div class="footer_list">
              <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
              <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
              <span>Gln - Q</span><span>Gly - G</span><span>His - H</span>
              <span>Ile - I</span>
            </div>
            <div class="footer_list">
              <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
              <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
              <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
              <span>Val - V</span>
            </div>
          </div>
        </div>
        <div class="col-12 pt-1 ">
          <p style="font-size: 8px!important; line-height: 14px!important;">
            {{footerDiscription}}<br/>{{footerDiscription2}}
          </p>
        </div>
      </div>
    </div>
  </div>

<div
id="page2_2"
class="container position-relative pagess"
style="background-color: white"
*ngIf="(varientTableCount==2 && (report.addNotes != null && report.addNotes != '<p></p>') && germlineReportPdfRes.reportedStarGenes.length > 2)
||
(varientTableCount==3 && (report.addNotes != null && report.addNotes != '<p></p>') && germlineReportPdfRes.reportedStarGenes.length > 1)||
 ((varientTableCount!=0 && varientTableCount>3) && !(varientTableCount<=5 && germlineReportPdfRes.reportedStarGenes.length==0 && (report.addNotes == null || report.addNotes == '<p></p>'))
 && !(varientTableCount<=4 && germlineReportPdfRes.reportedStarGenes.length==0 ))">
<div class="row header mt-1">
  <div *ngIf="report.clientType != 'client'">
    <div class="col-3 pl-0">
      <div class="logo">
        <img src="../../../assets/logo.png" alt="" style="width: 140px" />
      </div>
    </div>
  </div>
  <div class="col">
    <div class="patient-detailes ml-auto float-right">
      <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
        <li>
          Patient Id <br />
          <span>{{ report.patient.ecrfId }}</span>
        </li>
        <li class="ml-4">
          Patient Name <br />
          <span>{{ report.patient.name }}</span>
        </li>

        <li class="ml-4">
          Registration Date<br />
          <span>{{
            report.labDetails.sampleCollected | date : "dd/MM/yyyy"
          }}</span>
        </li>
        <li class="ml-4">
          Reporting Date<br />
          <span>{{ todayDate | date : "dd/MM/yyyy" }}</span>
        </li>
      </ul>
    </div>
  </div>
  <div *ngIf="report.clientType == '4basecare_client'">
    <div class="col-2" *ngIf="report.clientName == 'oncoquest'">
      <img
        src="../../../assets/oncoquest logo.png"
        alt=""
        class="onco_logo"
      />
    </div>
    <div class="col-2" *ngIf="report.clientName == 'path_labs'">
      <img src="../../../assets/pathlab.png" alt="" class="onco_logo" />
    </div>
  </div>
</div>
<!--  -->
<div *ngIf="varientTableCount > 5">
  <div  *ngFor="let gene of germlineReportPdfRes.reportedGenes | slice : 5 : 8"
    class="col-12 mb-2"
  >
    <h4
      class="py-2"
      style="color: #000; font-size: 19px !important; font-weight: 400"
    >
      <span class="font-weight-bold" style="font-size: 19px !important"
        ><i
          ><b>{{ gene.name }}</b></i
        ></span
      >
    </h4>
    <p
      [innerHtml]="gene.description"
      style="line-height: 24px; text-align: justify"
    ></p>
  </div>
</div>

<div class="mt-3 mb-5 row" *ngIf="(report.addNotes != null && report.addNotes != '<p></p>') && (varientTableCount >= 5 && varientTableCount <= 7)">
  <div class="col-12">
    <h6 class="py-2 mb-3 rounded h6_bg">ADDITIONAL NOTES</h6>
    <div class="py-2" [innerHtml]="report.addNotes"></div>
  </div>
</div>
<div
class="pt-2"
*ngIf="
  (germlineReportPdfRes.snvStarVariants.length > 0 ||
    germlineReportPdfRes.cnaStarVariants.length > 0) && (varientTableCount==5 || varientTableCount==6 || (varientTableCount==7 && (report.addNotes == null || report.addNotes == '<p></p>')) || (varientTableCount==4 && (report.addNotes != null && report.addNotes != '<p></p>')))">
<h6
  class="py-2 mb-3 rounded h6_bg">
  ADDITIONAL VARIANTS OF UNCERTAIN SIGNIFICANCE DETECTED IN THE PATIENT
</h6>
<div class="row">
  <!-- <div class="col-12" *ngIf="isReportDownload">
  <img [src]="additionalvaiantImg" alt="" class="img-fluid w-90">
</div> -->
  <div class="col">
    <table
      class="page1_table"
      *ngIf="
        germlineReportPdfRes.snvStarVariants.length > 0 ||
        germlineReportPdfRes.cnaStarVariants.length > 0
      "
    >
      <thead>
        <th>Gene</th>
        <th>Genomic Alteration</th>
        <th>Amino acid change, Exon Number, Allele Burden</th>
        <th>Variant type, Zygosity</th>
        <th>Associated disorder, Inheritance</th>
        <th>Reference</th>
        <th>Clinical Significance</th>
      </thead>
      <tbody>
        <tr
          *ngFor="
            let snvStarVariant of germlineReportPdfRes.snvStarVariants
          "
        >
          <td>
            <b
              ><i
                ><strong>{{ snvStarVariant.refGeneX }}</strong></i
              ></b
            >
          </td>
          <td>
            {{ snvStarVariant.genomicAlteration }}
            {{ snvStarVariant.selectedAAChangeknownGene.split(",")[0] }},
            [{{ snvStarVariant.ensembleValue }}]
          </td>
          <td>
            {{ snvStarVariant.selectedAAChangeknownGene.split(",")[1] }},
            Exon {{ snvStarVariant.exonNumber }}, {{
              snvStarVariant.mutantBurdenPercent * 0.01 | percent
            }}
          </td>
          <td>
            {{ snvStarVariant.exonicFuncknownGene }},
            {{ snvStarVariant.zygosity }}
          </td>
          <td>
            {{ snvStarVariant.cancerType }},
            {{ snvStarVariant.inheritanceMode }}
          </td>
          <td>{{ snvStarVariant.reference }}</td>
          <td>{{ snvStarVariant.significance }}</td>
        </tr>
        <tr
          *ngFor="let cnarVariant of germlineReportPdfRes.cnaStarVariants"
        >
          <td>
            <b>{{ cnarVariant.gene }}</b>
          </td>
          <td>
            chr{{ cnarVariant.sample.split(":")[0] }}:g.{{
              cnarVariant.sample
                .split(":")[1]
                .replace("-", "_")
                .split("|")[0]
            }}
          </td>
          <td>NA</td>
          <td>
            Copy Number
            {{ cnarVariant.sample.split(":")[2] | titlecase }} ({{
              cnarVariant.sample.split("|")[1].split(":")[0]
            }}), {{ cnarVariant.zygosity }}
          </td>
          <td>
            {{ cnarVariant.cancerType }},
            {{ cnarVariant.inheritanceMode }}
          </td>
          <td>{{ cnarVariant.reference }}</td>
          <td>{{ cnarVariant.significance }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
</div>
<div
class="mt-3 mb-5"
*ngIf="
  germlineReportPdfRes.reportedStarGenes &&
  germlineReportPdfRes.reportedStarGenes.length > 0 &&
  (varientTableCount >=2 || varientTableCount <=6)
"
>
<h6 class="py-2 rounded h6_bg" *ngIf="varientTableCount==4 || varientTableCount==5 || (( varientTableCount==6) && (report.addNotes == null || report.addNotes == '<p></p>')) ">
  VARIANT INTERPRETATION AND CLINICAL CORRELATION
</h6>
<div *ngIf="(varientTableCount ==3  && (report.addNotes == null || report.addNotes == '<p></p>')) || (varientTableCount==2 && (report.addNotes != null && report.addNotes != '<p></p>')) ">
  <div
    *ngFor="
      let gene of germlineReportPdfRes.reportedStarGenes | slice : 2 : 5
    "
    class="col-12 mb-2"
  >
    <h4
      class="py-2"
      style="color: #000; font-size: 19px !important; font-weight: 400"
    >
      <span class="font-weight-bold" style="font-size: 19px !important"
        ><i
          ><b>{{ gene.name }}</b></i
        ></span
      >
      <!-- <b>{{gene.geneName}}</b> -->
    </h4>
    <p
      [innerHtml]="gene.description"
      style="line-height: 24px; text-align: justify"
    ></p>
  </div>
</div>
<div *ngIf="(varientTableCount==3 && (report.addNotes != null && report.addNotes != '<p></p>'))">
  <div
    *ngFor="
      let gene of germlineReportPdfRes.reportedStarGenes | slice : 1 : 5
    "
    class="col-12 mb-2"
  >
    <h4
      class="py-2"
      style="color: #000; font-size: 19px !important; font-weight: 400"
    >
      <span class="font-weight-bold" style="font-size: 19px !important"
        ><i
          ><b>{{ gene.name }}</b></i
        ></span
      >
      <!-- <b>{{gene.geneName}}</b> -->
    </h4>
    <p
      [innerHtml]="gene.description"
      style="line-height: 24px; text-align: justify"
    ></p>
  </div>
</div>

<div *ngIf="(varientTableCount ==5  && (report.addNotes == null || report.addNotes == '<p></p>')) || (varientTableCount==4 && (report.addNotes != null && report.addNotes != '<p></p>')) ">
  <div
    *ngFor="
      let gene of germlineReportPdfRes.reportedStarGenes | slice : 0 : 2
    "
    class="col-12 mb-2"
  >
    <h4
      class="py-2"
      style="color: #000; font-size: 19px !important; font-weight: 400"
    >
      <span class="font-weight-bold" style="font-size: 19px !important"
        ><i
          ><b>{{ gene.name }}</b></i
        ></span
      >
      <!-- <b>{{gene.geneName}}</b> -->
    </h4>
    <p
      [innerHtml]="gene.description"
      style="line-height: 24px; text-align: justify"
    ></p>
  </div>
</div>
<div *ngIf="(varientTableCount==4 && (report.addNotes == null || report.addNotes == '<p></p>'))">
  <div
    *ngFor="
      let gene of germlineReportPdfRes.reportedStarGenes | slice : 0 : 5
    "
    class="col-12 mb-2"
  >
    <h4
      class="py-2"
      style="color: #000; font-size: 19px !important; font-weight: 400"
    >
      <span class="font-weight-bold" style="font-size: 19px !important"
        ><i
          ><b>{{ gene.name }}</b></i
        ></span
      >
      <!-- <b>{{gene.geneName}}</b> -->
    </h4>
    <p
      [innerHtml]="gene.description"
      style="line-height: 24px; text-align: justify"
    ></p>
  </div>
</div>
<div *ngIf="(varientTableCount==5 && (report.addNotes != null && report.addNotes != '<p></p>')) || (varientTableCount==6 && (report.addNotes == null || report.addNotes == '<p></p>'))">
  <div
    *ngFor="
      let gene of germlineReportPdfRes.reportedStarGenes | slice : 0 : 1
    "
    class="col-12 mb-2"
  >
    <h4
      class="py-2"
      style="color: #000; font-size: 19px !important; font-weight: 400"
    >
      <span class="font-weight-bold" style="font-size: 19px !important"
        ><i
          ><b>{{ gene.name }}</b></i
        ></span
      >
      <!-- <b>{{gene.geneName}}</b> -->
    </h4>
    <p
      [innerHtml]="gene.description"
      style="line-height: 24px; text-align: justify"
    ></p>
  </div>
</div>



</div>
<!--  -->
<div class="row footer">
  <div class="col-12">
    <div class="pb-2 pl-4 text">
      <div class="d-block">
        <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
      </div>
      <div class="footer_body" [ngClass]="'gene-bg_blue'">
        <div class="footer_list">
          <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
          <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
          <span>Gln - Q</span><span>Gly - G</span><span>His - H</span>
          <span>Ile - I</span>
        </div>
        <div class="footer_list">
          <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
          <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
          <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
          <span>Val - V</span>
        </div>
      </div>
    </div>
    <div class="col-12 pt-1 ">
      <p style="font-size: 8px!important; line-height: 14px!important;">
        {{footerDiscription}}<br/>{{footerDiscription2}}
      </p>
    </div>
  </div>
</div>
</div>

<div
id="page2_3"
class="container position-relative pagess"
style="background-color: white"
*ngIf="
(varientTableCount==4 && (report.addNotes != null && report.addNotes != '<p></p>') && germlineReportPdfRes.reportedStarGenes.length > 2) ||
((varientTableCount==6) && (report.addNotes == null || report.addNotes == '<p></p>')  && germlineReportPdfRes.reportedStarGenes.length > 1) ||
((varientTableCount==6) && (report.addNotes != null && report.addNotes != '<p></p>')  && germlineReportPdfRes.reportedStarGenes.length > 0) ||

    ((varientTableCount==5) && (report.addNotes == null || report.addNotes == '<p></p>')  && germlineReportPdfRes.reportedStarGenes.length > 2) ||
    (varientTableCount==5 && (report.addNotes != null && report.addNotes != '<p></p>')  && germlineReportPdfRes.reportedStarGenes.length > 1) ||
    (varientTableCount>6 && varientTableCount<=8 && !(varientTableCount<=8 && germlineReportPdfRes.reportedStarGenes.length==0 && (report.addNotes == null || report.addNotes == '<p></p>'))
    && !(varientTableCount<=7 && germlineReportPdfRes.reportedStarGenes.length==0 ))

"
>

<div class="row header mt-1">
  <div *ngIf="report.clientType != 'client'">
    <div class="col-3 pl-0">
      <div class="logo">
        <img src="../../../assets/logo.png" alt="" style="width: 140px" />
      </div>
    </div>
  </div>
  <div class="col">
    <div class="patient-detailes ml-auto float-right">
      <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
        <li>
          Patient Id <br />
          <span>{{ report.patient.ecrfId }}</span>
        </li>
        <li class="ml-4">
          Patient Name <br />
          <span>{{ report.patient.name }}</span>
        </li>

        <li class="ml-4">
          Registration Date<br />
          <span>{{
            report.labDetails.sampleCollected | date : "dd/MM/yyyy"
          }}</span>
        </li>
        <li class="ml-4">
          Reporting Date<br />
          <span>{{ todayDate | date : "dd/MM/yyyy" }}</span>
        </li>
      </ul>
    </div>
  </div>
  <div *ngIf="report.clientType == '4basecare_client'">
    <div class="col-2" *ngIf="report.clientName == 'oncoquest'">
      <img
        src="../../../assets/oncoquest logo.png"
        alt=""
        class="onco_logo"
      />
    </div>
    <div class="col-2" *ngIf="report.clientName == 'path_labs'">
      <img src="../../../assets/pathlab.png" alt="" class="onco_logo" />
    </div>
  </div>
</div>
<!--  -->

<div class="mt-3 mb-5 row" *ngIf="(report.addNotes != null && report.addNotes != '<p></p>') && (varientTableCount ==8) ">
  <div class="col-12">
    <h6 class="py-2 mb-3 rounded h6_bg">ADDITIONAL NOTES</h6>
    <div class="py-2" [innerHtml]="report.addNotes"></div>
  </div>
</div>
<div
  class="pt-2"
  *ngIf="
    (germlineReportPdfRes.snvStarVariants.length > 0 ||
      germlineReportPdfRes.cnaStarVariants.length > 0) &&
     ((varientTableCount ==8) || (varientTableCount==7 && (report.addNotes != null && report.addNotes != '<p></p>')))
  "
>
  <h6
    class="py-2 mb-3 rounded h6_bg">
    ADDITIONAL VARIANTS OF UNCERTAIN SIGNIFICANCE DETECTED IN THE PATIENT
  </h6>
  <div class="row">
    <!-- <div class="col-12" *ngIf="isReportDownload">
    <img [src]="additionalvaiantImg" alt="" class="img-fluid w-90">
  </div> -->
    <div class="col">
      <table
        class="page1_table"
        *ngIf="
          germlineReportPdfRes.snvStarVariants.length > 0 ||
          germlineReportPdfRes.cnaStarVariants.length > 0
        "
      >
        <thead>
          <th>Gene</th>
          <th>Genomic Alteration</th>
          <th>Amino acid change, Exon Number, Allele Burden</th>
          <th>Variant type, Zygosity</th>
          <th>Associated disorder, Inheritance</th>
          <th>Reference</th>
          <th>Clinical Significance</th>
        </thead>
        <tbody>
          <tr
            *ngFor="
              let snvStarVariant of germlineReportPdfRes.snvStarVariants
            "
          >
            <td>
              <b
                ><i
                  ><strong>{{ snvStarVariant.refGeneX }}</strong></i
                ></b
              >
            </td>
            <td>
              {{ snvStarVariant.genomicAlteration }}
              {{ snvStarVariant.selectedAAChangeknownGene.split(",")[0] }},
              [{{ snvStarVariant.ensembleValue }}]
            </td>
            <td>
              {{ snvStarVariant.selectedAAChangeknownGene.split(",")[1] }},
              Exon {{ snvStarVariant.exonNumber }}, {{
                snvStarVariant.mutantBurdenPercent * 0.01 | percent
              }}
            </td>
            <td>
              {{ snvStarVariant.exonicFuncknownGene }},
              {{ snvStarVariant.zygosity }}
            </td>
            <td>
              {{ snvStarVariant.cancerType }},
              {{ snvStarVariant.inheritanceMode }}
            </td>
            <td>{{ snvStarVariant.reference }}</td>
            <td>{{ snvStarVariant.significance }}</td>
          </tr>
          <tr
            *ngFor="let cnarVariant of germlineReportPdfRes.cnaStarVariants"
          >
            <td>
              <b>{{ cnarVariant.gene }}</b>
            </td>
            <td>
              chr{{ cnarVariant.sample.split(":")[0] }}:g.{{
                cnarVariant.sample
                  .split(":")[1]
                  .replace("-", "_")
                  .split("|")[0]
              }}
            </td>
            <td>NA</td>
            <td>
              Copy Number
              {{ cnarVariant.sample.split(":")[2] | titlecase }} ({{
                cnarVariant.sample.split("|")[1].split(":")[0]
              }}), {{ cnarVariant.zygosity }}
            </td>
            <td>
              {{ cnarVariant.cancerType }},
              {{ cnarVariant.inheritanceMode }}
            </td>
            <td>{{ cnarVariant.reference }}</td>
            <td>{{ cnarVariant.significance }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<div
  class="mt-3 mb-5"
  *ngIf="
    germlineReportPdfRes.reportedStarGenes &&
    germlineReportPdfRes.reportedStarGenes.length > 0 &&
    varientTableCount >=4
  "
>
  <h6 class="py-2 rounded h6_bg" *ngIf="(varientTableCount==8 && (report.addNotes != null && report.addNotes != '<p></p>')) ||
     varientTableCount ==7 || varientTableCount ==8 || (varientTableCount == 6  && (report.addNotes != null && report.addNotes != '<p></p>'))
  ">
    VARIANT INTERPRETATION AND CLINICAL CORRELATION
  </h6>


  <div *ngIf="(varientTableCount==4 && (report.addNotes != null && report.addNotes != '<p></p>')) || (varientTableCount==5 && (report.addNotes == null || report.addNotes == '<p></p>'))">
    <div
      *ngFor="
        let gene of germlineReportPdfRes.reportedStarGenes | slice : 2 : 5
      "
      class="col-12 mb-2"
    >
      <h4
        class="py-2"
        style="color: #000; font-size: 19px !important; font-weight: 400"
      >
        <span class="font-weight-bold" style="font-size: 19px !important"
          ><i
            ><b>{{ gene.name }}</b></i
          ></span
        >
        <!-- <b>{{gene.geneName}}</b> -->
      </h4>
      <p
        [innerHtml]="gene.description"
        style="line-height: 24px; text-align: justify"
      ></p>
    </div>
  </div>
  <div *ngIf="(varientTableCount==5 && (report.addNotes != null && report.addNotes != '<p></p>')) || (varientTableCount==6 && (report.addNotes == null || report.addNotes == '<p></p>'))">
    <div
      *ngFor="
        let gene of germlineReportPdfRes.reportedStarGenes | slice : 1 : 5
      "
      class="col-12 mb-2"
    >
      <h4
        class="py-2"
        style="color: #000; font-size: 19px !important; font-weight: 400"
      >
        <span class="font-weight-bold" style="font-size: 19px !important"
          ><i
            ><b>{{ gene.name }}</b></i
          ></span
        >
        <!-- <b>{{gene.geneName}}</b> -->
      </h4>
      <p
        [innerHtml]="gene.description"
        style="line-height: 24px; text-align: justify"
      ></p>
    </div>
  </div>
  <div *ngIf="(varientTableCount==6 && (report.addNotes != null && report.addNotes != '<p></p>')) || (varientTableCount==7 && (report.addNotes == null || report.addNotes == '<p></p>'))">
    <div
      *ngFor="
        let gene of germlineReportPdfRes.reportedStarGenes | slice : 0 : 5
      "
      class="col-12 mb-2"
    >
      <h4
        class="py-2"
        style="color: #000; font-size: 19px !important; font-weight: 400"
      >
        <span class="font-weight-bold" style="font-size: 19px !important"
          ><i
            ><b>{{ gene.name }}</b></i
          ></span
        >
        <!-- <b>{{gene.geneName}}</b> -->
      </h4>
      <p
        [innerHtml]="gene.description"
        style="line-height: 24px; text-align: justify"
      ></p>
    </div>
  </div>
  <div *ngIf="(varientTableCount==7 && (report.addNotes != null && report.addNotes != '<p></p>')) || (varientTableCount==8 && (report.addNotes == null || report.addNotes == '<p></p>'))">
    <div
      *ngFor="
        let gene of germlineReportPdfRes.reportedStarGenes | slice : 0 : 2
      "
      class="col-12 mb-2"
    >
      <h4
        class="py-2"
        style="color: #000; font-size: 19px !important; font-weight: 400"
      >
        <span class="font-weight-bold" style="font-size: 19px !important"
          ><i
            ><b>{{ gene.name }}</b></i
          ></span
        >
        <!-- <b>{{gene.geneName}}</b> -->
      </h4>
      <p
        [innerHtml]="gene.description"
        style="line-height: 24px; text-align: justify"
      ></p>
    </div>
  </div>
  <div *ngIf="(varientTableCount==8 && (report.addNotes != null && report.addNotes != '<p></p>'))">
    <div
      *ngFor="
        let gene of germlineReportPdfRes.reportedStarGenes | slice : 0 : 1
      "
      class="col-12 mb-2"
    >
      <h4
        class="py-2"
        style="color: #000; font-size: 19px !important; font-weight: 400"
      >
        <span class="font-weight-bold" style="font-size: 19px !important"
          ><i
            ><b>{{ gene.name }}</b></i
          ></span
        >
        <!-- <b>{{gene.geneName}}</b> -->
      </h4>
      <p
        [innerHtml]="gene.description"
        style="line-height: 24px; text-align: justify"
      ></p>
    </div>
  </div>


</div>
<!--  -->
<div class="row footer">
  <div class="col-12">
    <div class="pb-2 pl-4 text">
      <div class="d-block">
        <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
      </div>
      <div class="footer_body" [ngClass]="'gene-bg_blue'">
        <div class="footer_list">
          <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
          <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
          <span>Gln - Q</span><span>Gly - G</span><span>His - H</span>
          <span>Ile - I</span>
        </div>
        <div class="footer_list">
          <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
          <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
          <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
          <span>Val - V</span>
        </div>
      </div>
    </div>
    <div class="col-12 pt-1 ">
      <p style="font-size: 8px!important; line-height: 14px!important;">
        {{footerDiscription}}<br/>{{footerDiscription2}}
      </p>
    </div>
  </div>
</div>
</div>


<div
id="page2_4"
class="container position-relative pagess"
style="background-color: white"
*ngIf="(varientTableCount == 8 && germlineReportPdfRes.reportedStarGenes.length > 1 && (report.addNotes != null && report.addNotes != '<p></p>')) ||
(varientTableCount == 8 && germlineReportPdfRes.reportedStarGenes.length >2 && (report.addNotes == null || report.addNotes == '<p></p>')) ||
(varientTableCount==7 && germlineReportPdfRes.reportedStarGenes.length > 3 && (report.addNotes != null && report.addNotes != '<p></p>'))
"
>
<div class="row header mt-1">
  <div *ngIf="report.clientType != 'client'">
    <div class="col-3 pl-0">
      <div class="logo">
        <img src="../../../assets/logo.png" alt="" style="width: 140px" />
      </div>
    </div>
  </div>
  <div class="col">
    <div class="patient-detailes ml-auto float-right">
      <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
        <li>
          Patient Id <br />
          <span>{{ report.patient.ecrfId }}</span>
        </li>
        <li class="ml-4">
          Patient Name <br />
          <span>{{ report.patient.name }}</span>
        </li>

        <li class="ml-4">
          Registration Date<br />
          <span>{{
            report.labDetails.sampleCollected | date : "dd/MM/yyyy"
          }}</span>
        </li>
        <li class="ml-4">
          Reporting Date<br />
          <span>{{ todayDate | date : "dd/MM/yyyy" }}</span>
        </li>
      </ul>
    </div>
  </div>
  <div *ngIf="report.clientType == '4basecare_client'">
    <div class="col-2" *ngIf="report.clientName == 'oncoquest'">
      <img
        src="../../../assets/oncoquest logo.png"
        alt=""
        class="onco_logo"
      />
    </div>
    <div class="col-2" *ngIf="report.clientName == 'path_labs'">
      <img src="../../../assets/pathlab.png" alt="" class="onco_logo" />
    </div>
  </div>
</div>
<!--  -->



<div
  class="mt-3 mb-5"
  *ngIf="
    germlineReportPdfRes.reportedStarGenes &&
    germlineReportPdfRes.reportedStarGenes.length > 0 &&
    (varientTableCount ==8 || (varientTableCount==7 && (report.addNotes != null && report.addNotes != '<p></p>')))
  "
>


  <div *ngIf="(varientTableCount==8 && (report.addNotes != null && report.addNotes != '<p></p>'))">
    <div
      *ngFor="
        let gene of germlineReportPdfRes.reportedStarGenes | slice : 1 : 5
      "
      class="col-12 mb-2"
    >
      <h4
        class="py-2"
        style="color: #000; font-size: 19px !important; font-weight: 400"
      >
        <span class="font-weight-bold" style="font-size: 19px !important"
          ><i
            ><b>{{ gene.name }}</b></i
          ></span
        >
        <!-- <b>{{gene.geneName}}</b> -->
      </h4>
      <p
        [innerHtml]="gene.description"
        style="line-height: 24px; text-align: justify"
      ></p>
    </div>
  </div>
  <div *ngIf="(varientTableCount==8 && (report.addNotes == null || report.addNotes == '<p></p>'))">
    <div
      *ngFor="
        let gene of germlineReportPdfRes.reportedStarGenes | slice : 2 : 5
      "
      class="col-12 mb-2"
    >
      <h4
        class="py-2"
        style="color: #000; font-size: 19px !important; font-weight: 400"
      >
        <span class="font-weight-bold" style="font-size: 19px !important"
          ><i
            ><b>{{ gene.name }}</b></i
          ></span
        >
        <!-- <b>{{gene.geneName}}</b> -->
      </h4>
      <p
        [innerHtml]="gene.description"
        style="line-height: 24px; text-align: justify"
      ></p>
    </div>
  </div>

  <div *ngIf="(varientTableCount==7 && (report.addNotes != null && report.addNotes != '<p></p>'))">
    <div
      *ngFor="
        let gene of germlineReportPdfRes.reportedStarGenes | slice : 2 : 5
      "
      class="col-12 mb-2"
    >
      <h4
        class="py-2"
        style="color: #000; font-size: 19px !important; font-weight: 400"
      >
        <span class="font-weight-bold" style="font-size: 19px !important"
          ><i
            ><b>{{ gene.name }}</b></i
          ></span
        >
        <!-- <b>{{gene.geneName}}</b> -->
      </h4>
      <p
        [innerHtml]="gene.description"
        style="line-height: 24px; text-align: justify"
      ></p>
    </div>
  </div>

</div>
<!--  -->
<div class="row footer">
  <div class="col-12">
    <div class="pb-2 pl-4 text">
      <div class="d-block">
        <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
      </div>
      <div class="footer_body" [ngClass]="'gene-bg_blue'">
        <div class="footer_list">
          <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
          <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
          <span>Gln - Q</span><span>Gly - G</span><span>His - H</span>
          <span>Ile - I</span>
        </div>
        <div class="footer_list">
          <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
          <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
          <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
          <span>Val - V</span>
        </div>
      </div>
    </div>
    <div class="col-12 pt-1 ">
      <p style="font-size: 8px!important; line-height: 14px!important;">
        {{footerDiscription}}<br/>{{footerDiscription2}}
      </p>
    </div>
  </div>
</div>
</div>

  <div
    id="page2_5"
    class="container position-relative pagess"
    style="background-color: white"
    *ngIf="report.interpretation != null && report.interpretation != '<p></p>'"
  >
    <div class="row header mt-1">
      <div *ngIf="report.clientType != 'client'">
        <div class="col-3 pl-0">
          <div class="logo">
            <img src="../../../assets/logo.png" alt="" style="width: 140px" />
          </div>
        </div>
      </div>
      <div class="col">
        <div class="patient-detailes ml-auto float-right">
          <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
            <li>
              Patient Id <br />
              <span>{{ report.patient.ecrfId }}</span>
            </li>
            <li class="ml-4">
              Patient Name <br />
              <span>{{ report.patient.name }}</span>
            </li>

            <li class="ml-4">
              Registration Date<br />
              <span>{{
                report.labDetails.sampleCollected | date : "dd/MM/yyyy"
              }}</span>
            </li>
            <li class="ml-4">
              Reporting Date<br />
              <span>{{ todayDate | date : "dd/MM/yyyy" }}</span>
            </li>
          </ul>
        </div>
      </div>
      <div *ngIf="report.clientType == '4basecare_client'">
        <div class="col-2" *ngIf="report.clientName == 'oncoquest'">
          <img
            src="../../../assets/oncoquest logo.png"
            alt=""
            class="onco_logo"
          />
        </div>
        <div class="col-2" *ngIf="report.clientName == 'path_labs'">
          <img src="../../../assets/pathlab.png" alt="" class="onco_logo" />
        </div>
      </div>
    </div>
    <!--  -->
    <div
      *ngIf="
        report.interpretation != null && report.interpretation != '<p></p>'
      "
    >
      <h6 class="py-2 h6_bg mt-3">RESULT INTERPRETATION</h6>
      <div
        [innerHtml]="report.interpretation"
        style="line-height: 28px; text-align: justify"
        class="interpretationtab"
      ></div>
    </div>

    <!--  -->
    <div class="row footer">
      <div class="col-12">
        <div class="pb-2 pl-4 text">
          <div class="d-block">
            <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
          </div>
          <div class="footer_body" [ngClass]="'gene-bg_blue'">
            <div class="footer_list">
              <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
              <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
              <span>Gln - Q</span><span>Gly - G</span><span>His - H</span>
              <span>Ile - I</span>
            </div>
            <div class="footer_list">
              <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
              <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
              <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
              <span>Val - V</span>
            </div>
          </div>
        </div>
        <div class="col-12 pt-1 ">
          <p style="font-size: 8px!important; line-height: 14px!important;">
            {{footerDiscription}}<br/>{{footerDiscription2}}
          </p>
        </div>
      </div>
    </div>
  </div>

  <!-- page 4 -->
  <div
    *ngIf="
      germlineReportPdfRes.referenceArticles != null &&
      germlineReportPdfRes.referenceArticles.length > 0
    "
    id="page4"
    class="container position-relative pagess"
    style="background-color: white"
  >
    <div class="row header mt-1">
      <div *ngIf="report.clientType != 'client'">
        <div class="col-3 pl-0">
          <div class="logo">
            <img src="../../../assets/logo.png" alt="" style="width: 140px" />
          </div>
        </div>
      </div>
      <div class="col">
        <div class="patient-detailes ml-auto float-right">
          <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
            <li>
              Patient Id <br />
              <span>{{ report.patient.ecrfId }}</span>
            </li>
            <li class="ml-4">
              Patient Name <br />
              <span>{{ report.patient.name }}</span>
            </li>

            <li class="ml-4">
              Registration Date<br />
              <span>{{
                report.labDetails.sampleCollected | date : "dd/MM/yyyy"
              }}</span>
            </li>
            <li class="ml-4">
              Reporting Date<br />
              <span>{{ todayDate | date : "dd/MM/yyyy" }}</span>
            </li>
          </ul>
        </div>
      </div>
      <div *ngIf="report.clientType == '4basecare_client'">
        <div class="col-2" *ngIf="report.clientName == 'oncoquest'">
          <img
            src="../../../assets/oncoquest logo.png"
            alt=""
            class="onco_logo"
          />
        </div>
        <div class="col-2" *ngIf="report.clientName == 'path_labs'">
          <img src="../../../assets/pathlab.png" alt="" class="onco_logo" />
        </div>
      </div>
    </div>
    <!--  -->
    <div class="mt-3 mb-5">
      <h6 class="py-2 h6_bg">References</h6>

      <div class="col-12 ref_data mt-4">
        <ul class="break-word">
          <li
            *ngFor="
              let referenceArticle of germlineReportPdfRes.referenceArticles
            "
            style="
              text-align: justify !important;
              /* white-space:pre-wrap; */
              word-break: break-word;
            "
          >
            {{ referenceArticle }}
          </li>
        </ul>
      </div>
    </div>

    <!--  -->
    <div class="row footer">
      <div class="col-12">
        <div class="pb-2 pl-4 text">
          <div class="d-block">
            <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
          </div>
          <div class="footer_body" [ngClass]="'gene-bg_blue'">
            <div class="footer_list">
              <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
              <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
              <span>Gln - Q</span><span>Gly - G</span><span>His - H</span>
              <span>Ile - I</span>
            </div>
            <div class="footer_list">
              <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
              <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
              <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
              <span>Val - V</span>
            </div>
          </div>
        </div>
        <div class="col-12 pt-1 ">
          <p style="font-size: 8px!important; line-height: 14px!important;">
            {{footerDiscription}}<br/>{{footerDiscription2}}
          </p>
        </div>
      </div>
    </div>
  </div>

  <!-- page 5 -->
  <div
    id="page5"
    class="container position-relative pagess"
    style="background-color: white"
  >
    <div class="row header mt-1">
      <div *ngIf="report.clientType != 'client'">
        <div class="col-3 pl-0">
          <div class="logo">
            <img src="../../../assets/logo.png" alt="" style="width: 140px" />
          </div>
        </div>
      </div>
      <div class="col">
        <div class="patient-detailes ml-auto float-right">
          <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
            <li>
              Patient Id <br />
              <span>{{ report.patient.ecrfId }}</span>
            </li>
            <li class="ml-4">
              Patient Name <br />
              <span>{{ report.patient.name }}</span>
            </li>

            <li class="ml-4">
              Registration Date<br />
              <span>{{
                report.labDetails.sampleCollected | date : "dd/MM/yyyy"
              }}</span>
            </li>
            <li class="ml-4">
              Reporting Date<br />
              <span>{{ todayDate | date : "dd/MM/yyyy" }}</span>
            </li>
          </ul>
        </div>
      </div>
      <div *ngIf="report.clientType == '4basecare_client'">
        <div class="col-2" *ngIf="report.clientName == 'oncoquest'">
          <img
            src="../../../assets/oncoquest logo.png"
            alt=""
            class="onco_logo"
          />
        </div>
        <div class="col-2" *ngIf="report.clientName == 'path_labs'">
          <img src="../../../assets/pathlab.png" alt="" class="onco_logo" />
        </div>
      </div>
    </div>
    <!--  -->
    <div class="mt-3 mb-3">
      <h6 class="py-2 h6_bg">References</h6>

      <div class="ref_data mt-4 ml-2 pl-4">
        <h5 class="font-weight-bold">Websites</h5>
        <ul class="pl-3">
          <li>ClinVar https://www.ncbi.nlm.nih.gov/clinvar</li>
          <li>NIH- National Cancer Institute - https://www.cancer.gov/</li>
          <li>https://www.mycancergenome.org/</li>
          <li>https://www.ncbi.nlm.nih.gov/medgen/</li>
          <li>https://www.cancer.net/cancer-types</li>
          <li>https://www.mayoclinic.org/</li>
          <li>https://www.cancerresearchuk.org/</li>
          <li>http://pfam.xfam.org/</li>
          <li>https://www.uniprot.org/</li>

          <span *ngIf="report.referenceWebsites != '<p></p>'" class="">
            <div
              style="font-size: 14px !important"
              [innerHtml]="report.referenceWebsites"
            ></div>
          </span>
        </ul>
      </div>
    </div>
    <div class="img_signs above-footer">
      <div class="row justify-content-between align-items-center">
        <div class="col">

          <p style="
                text-align: center;

                font-size: 15px !important;
              ">(Electronically signed by)
          </p>
          <p style="
                  text-align: center;
                  font-weight: bolder;
                  font-size: 15px !important;
                ">
            Vidya H Veldore, PhD<br />Vice President - Clinical Operations
          </p>
        </div>
        <div class="col">
          <p style="text-align: center; font-size: 15px !important;">
            (Electronically signed by)</p>
            <p style="
            text-align: center;
            font-weight: bolder;
            font-size: 15px !important;
          ">
          Dr. Jinumary Aji John<br />Head - Clinical Reporting </p>
        </div>
        <div class="col">
          <p style="
            text-align: center;

            font-size: 15px !important;
          ">(Electronically signed by)
          </p>
          <p style="
                  text-align: center;
                  font-weight: bolder;
                  font-size: 15px !important;
                ">
            Vyomesh Javle<br />Head - Clinical Bioinformatics
          </p>
        </div>
      </div>
    </div>

    <!--  -->
    <div class="row footer">
      <div class="col-12">
        <div class="pb-2 pl-4 text">
          <div class="d-block">
            <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
          </div>
          <div class="footer_body" [ngClass]="'gene-bg_blue'">
            <div class="footer_list">
              <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
              <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
              <span>Gln - Q</span><span>Gly - G</span><span>His - H</span>
              <span>Ile - I</span>
            </div>
            <div class="footer_list">
              <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
              <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
              <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
              <span>Val - V</span>
            </div>
          </div>
        </div>
        <div class="col-12 pt-1 ">
          <p style="font-size: 8px!important; line-height: 14px!important;">
            {{footerDiscription}}<br/>{{footerDiscription2}}
          </p>
        </div>
      </div>
    </div>
  </div>

  <!-- page 6 -->
  <div
    id="page6"
    class="container position-relative pagess"
    style="background-color: white"
  >
    <div class="row header mt-1">
      <div *ngIf="report.clientType != 'client'">
        <div class="col-3 pl-0">
          <div class="logo">
            <img src="../../../assets/logo.png" alt="" style="width: 140px" />
          </div>
        </div>
      </div>
      <div class="col">
        <div class="patient-detailes ml-auto float-right">
          <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
            <li>
              Patient Id <br />
              <span>{{ report.patient.ecrfId }}</span>
            </li>
            <li class="ml-4">
              Patient Name <br />
              <span>{{ report.patient.name }}</span>
            </li>

            <li class="ml-4">
              Registration Date<br />
              <span>{{
                report.labDetails.sampleCollected | date : "dd/MM/yyyy"
              }}</span>
            </li>
            <li class="ml-4">
              Reporting Date<br />
              <span>{{ todayDate | date : "dd/MM/yyyy" }}</span>
            </li>
          </ul>
        </div>
      </div>
      <div *ngIf="report.clientType == '4basecare_client'">
        <div class="col-2" *ngIf="report.clientName == 'oncoquest'">
          <img
            src="../../../assets/oncoquest logo.png"
            alt=""
            class="onco_logo"
          />
        </div>
        <div class="col-2" *ngIf="report.clientName == 'path_labs'">
          <img src="../../../assets/pathlab.png" alt="" class="onco_logo" />
        </div>
      </div>
    </div>
    <!--  -->
     <div class="mt-3 mb-5">
      <h6 class="py-2 h6_bg">GENES EVALUATED</h6>

      <div class="mt-4 px-4 py-3">
        <p class="l_height-38">
          The following table represents the panel of 213 genes, evaluated in
          the hereditary cancer panel test, commonly known to be associated with
          different hereditary cancers and cancer syndromes
        </p>
      </div>

      <div class="gene_list px-4 mt-4">
        <table class="gene_table">
          <tbody>
            <tr style="font-style: italic">
              <td style="border-top-left-radius: 10px">ABCB11</td>
              <td>CACNA1S</td>
              <td>DICER1</td>
              <td>FAM175A</td>
              <td>HNF1A</td>
              <td>MLH1</td>
              <td>OTC</td>
              <td>PTEN</td>
              <td>SCN5A</td>
              <td>STK11</td>
              <td style="border-top-right-radius: 10px">XPA</td>
            </tr>
            <tr style="font-style: italic">
              <td>ACACB</td>
              <td>CASR</td>
              <td>DIS3L2</td>
              <td>FANCA</td>
              <td>HOXB13</td>
              <td>MLH3</td>
              <td>PALB2</td>
              <td>PTPN11</td>
              <td>SDHA</td>
              <td>SUFU</td>
              <td>XPC</td>
            </tr>
            <tr style="font-style: italic">
              <td>ACTA2</td>
              <td>CBL</td>
              <td>DKC1</td>
              <td>FANCB</td>
              <td>HRAS</td>
              <td>MRE11A</td>
              <td>PALLD</td>
              <td>RAD50</td>
              <td>CDHAF2</td>
              <td>TERC</td>
              <td>XRCC2</td>
            </tr>
            <tr style="font-style: italic">
              <td>ACTC1</td>
              <td>CD70</td>
              <td>DSC2</td>
              <td>FINCC</td>
              <td>IKZF1</td>
              <td>MSH2</td>
              <td>PAX5</td>
              <td>RAD51B</td>
              <td>SDHB</td>
              <td>TERT</td>
              <td></td>
            </tr>
            <tr style="font-style: italic">
              <td>AIP</td>
              <td>CDC73</td>
              <td>DSG2</td>
              <td>FANCD2</td>
              <td>KCNH2</td>
              <td>MSH3</td>
              <td>PCSK9</td>
              <td>RAD51C</td>
              <td>SDHC</td>
              <td>TGFBR1</td>
              <td></td>
            </tr>
            <tr style="font-style: italic">
              <td>ALK</td>
              <td>CDH1</td>
              <td>DSP</td>
              <td>FANCE</td>
              <td>KCNQ1</td>
              <td>MSH6</td>
              <td>PDGFRA</td>
              <td>RAD51D</td>
              <td>SDHD</td>
              <td>TGFBR2</td>
              <td></td>
            </tr>
            <tr style="font-style: italic">
              <td>ANKRD26</td>
              <td>CDK4</td>
              <td>DYZ3</td>
              <td>FANCF</td>
              <td>KIT</td>
              <td>MUTYH</td>
              <td>PHOX2B</td>
              <td>RAD54L</td>
              <td>SERPINA1</td>
              <td>TINF2</td>
              <td></td>
            </tr>
            <tr style="font-style: italic">
              <td>APC</td>
              <td>CDKN1B</td>
              <td>EGFR</td>
              <td>FANCG</td>
              <td>KITLG</td>
              <td>MYBPC3</td>
              <td>PKP2</td>
              <td>RAF1</td>
              <td>SHOC2</td>
              <td>TMEM127</td>
              <td></td>
            </tr>
            <tr style="font-style: italic">
              <td>APOB</td>
              <td>CDKN1C</td>
              <td>ELANE</td>
              <td>FANCI</td>
              <td>KRAS</td>
              <td>MYH1</td>
              <td>PMS1</td>
              <td>RASA2</td>
              <td>SLC25A13</td>
              <td>TMEM43</td>
              <td></td>
            </tr>
            <tr style="font-style: italic">
              <td>ATM</td>
              <td>CDKN2A</td>
              <td>EPCAN</td>
              <td>FANCL</td>
              <td>L1CAM</td>
              <td>MYH11</td>
              <td>PMS2</td>
              <td>RB1</td>
              <td>SLX4</td>
              <td>TNFRSF6</td>
              <td></td>
            </tr>
            <tr style="font-style: italic">
              <td>ATP7B</td>
              <td>CEBPA</td>
              <td>ERCC1</td>
              <td>FANCM</td>
              <td>LDLR</td>
              <td>MYH7</td>
              <td>POLD1</td>
              <td>RECQL4</td>
              <td>SMAD3</td>
              <td>TNFSF13B</td>
              <td></td>
            </tr>
            <tr style="font-style: italic">
              <td>AXIN2</td>
              <td>CEP57</td>
              <td>ERCC2</td>
              <td>FBN1</td>
              <td>LMNA</td>
              <td>MYL2</td>
              <td>POLE</td>
              <td>REST</td>
              <td>SMAD4</td>
              <td>TNNI3</td>
              <td></td>
            </tr>
            <tr style="font-style: italic">
              <td>BAP1</td>
              <td>CFTR</td>
              <td>ERCC3</td>
              <td>FH</td>
              <td>LZTR1</td>
              <td>MYL3</td>
              <td>POLH</td>
              <td>RET</td>
              <td>SMARCA4</td>
              <td>TNNT2</td>
              <td></td>
            </tr>
            <tr style="font-style: italic">
              <td>BARD1</td>
              <td>CHEK2</td>
              <td>ERCC4</td>
              <td>FLCN</td>
              <td>NMAP2KN</td>
              <td>NBN</td>
              <td>POT1</td>
              <td>RHBDF2</td>
              <td>SMARCB1</td>
              <td>TP53</td>
              <td></td>
            </tr>
            <tr style="font-style: italic">
              <td>BLM</td>
              <td>COL3A1</td>
              <td>ERCC5</td>
              <td>GALNT12</td>
              <td>MAP2K2</td>
              <td>NF1</td>
              <td>PPM1D</td>
              <td>RIT1</td>
              <td>SMARCE1</td>
              <td>TPCN2</td>
              <td></td>
            </tr>
            <tr style="font-style: italic">
              <td>BMPR1A</td>
              <td>COL7A1</td>
              <td>ETV6</td>
              <td>GATA2</td>
              <td>MAX</td>
              <td>NF2</td>
              <td>PRF1</td>
              <td>RRAS</td>
              <td>SOS1</td>
              <td>TPM1</td>
              <td></td>
            </tr>
            <tr style="font-style: italic">
              <td>BRAF</td>
              <td>CTNNA1</td>
              <td>EXO1</td>
              <td>GBA</td>
              <td>MAN1</td>
              <td>NOP56</td>
              <td>PRKG2</td>
              <td>RUNX1</td>
              <td>SOS2</td>
              <td>TSC1</td>
              <td></td>
            </tr>
            <tr style="font-style: italic">
              <td>BRCA1</td>
              <td>CTRC</td>
              <td>EXT1</td>
              <td>GLA</td>
              <td>MEN2A</td>
              <td>NRAS</td>
              <td>PRKAR1A</td>
              <td>RYR1</td>
              <td>SPINK1</td>
              <td>TSC2</td>
              <td></td>
            </tr>
            <tr style="font-style: italic">
              <td>BRCA2</td>
              <td>CYLD</td>
              <td>EXT2</td>
              <td>GPC3</td>
              <td>MEN2B</td>
              <td>NSD1</td>
              <td>PRPF8</td>
              <td>RYR2</td>
              <td>SPRED1</td>
              <td>VHL</td>
              <td></td>
            </tr>
            <tr style="font-style: italic">
              <td>BRPIP1</td>
              <td>DDB2</td>
              <td>EZH2</td>
              <td>GREM1</td>
              <td>MET</td>
              <td>NSUN2</td>
              <td>PRSS1</td>
              <td>SAMD9L</td>
              <td>SRP72</td>
              <td>WRN</td>
              <td></td>
            </tr>
            <tr style="font-style: italic">
              <td style="border-bottom-left-radius: 10px">BUB1B</td>
              <td>DDX41</td>
              <td>FAH</td>
              <td>HFE</td>
              <td>MITF</td>
              <td>NTHL1</td>
              <td>PTCH1</td>
              <td>SBDS</td>
              <td>SRY</td>
              <td>WT1</td>
              <td style="border-bottom-right-radius: 10px"></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!--  -->
    <div class="row footer">
      <div class="col-12">
        <div class="pb-2 pl-4 text">
          <div class="d-block">
            <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
          </div>
          <div class="footer_body" [ngClass]="'gene-bg_blue'">
            <div class="footer_list">
              <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
              <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
              <span>Gln - Q</span><span>Gly - G</span><span>His - H</span>
              <span>Ile - I</span>
            </div>
            <div class="footer_list">
              <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
              <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
              <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
              <span>Val - V</span>
            </div>
          </div>
        </div>
        <div class="col-12 pt-1 ">
          <p style="font-size: 8px!important; line-height: 14px!important;">
            {{footerDiscription}}<br/>{{footerDiscription2}}
          </p>
        </div>
      </div>
    </div>
  </div>

  <!--page 8-->
  <div
    id="page8"
    class="container position-relative pagess"
    style="background-color: white"
  >
    <div class="row header mt-1">
      <div *ngIf="report.clientType != 'client'">
        <div class="col-3 pl-0">
          <div class="logo">
            <img src="../../../assets/logo.png" alt="" style="width: 140px" />
          </div>
        </div>
      </div>
      <div class="col">
        <div class="patient-detailes ml-auto float-right">
          <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
            <li>
              Patient Id <br />
              <span>{{ report.patient.ecrfId }}</span>
            </li>
            <li class="ml-4">
              Patient Name <br />
              <span>{{ report.patient.name }}</span>
            </li>

            <li class="ml-4">
              Registration Date<br />
              <span>{{
                report.labDetails.sampleCollected | date : "dd/MM/yyyy"
              }}</span>
            </li>
            <li class="ml-4">
              Reporting Date<br />
              <span>{{ todayDate | date : "dd/MM/yyyy" }}</span>
            </li>
          </ul>
        </div>
      </div>
      <div *ngIf="report.clientType == '4basecare_client'">
        <div class="col-2" *ngIf="report.clientName == 'oncoquest'">
          <img
            src="../../../assets/oncoquest logo.png"
            alt=""
            class="onco_logo"
          />
        </div>
        <div class="col-2" *ngIf="report.clientName == 'path_labs'">
          <img src="../../../assets/pathlab.png" alt="" class="onco_logo" />
        </div>
      </div>
    </div>

    <div class="mt-3 mb-5">
      <h6 class="py-2 h6_bg">TEST METHODOLOGY</h6>

      <div class="mt-4 px-4 py-3">
        <p class="l_height-38">
          Genomic DNA is isolated from Whole Blood sample for library
          preparation and quantified using Qubit Fluorometer, 50 ng is taken for
          library preparation. The NGS libraries were prepared as per standard
          procedures for Illumina sequencing. The libraries were sequenced with
          mean coverage depth >150X.
        </p>
        <p class="l_height-38">
          The sequences obtained are aligned to human reference genome
          (GRCh37/hg19) and variant analysis was performed using set of
          Bioinformatics Pipeline. Only non- synonymous and splice site variants
          found in the exome panel consisting of specific set of genes were used
          for clinical interpretation. Silent variations that do not result in
          any change in amino acid in the coding region are not reported.
          Clinically relevant mutations were annotated using published
          literature and a set of databases – ClinVar (Landrum et al, 2015.),
          cbioportal (Cerami et al, 2012; Gao et al, 2013) and dbSNP. Common
          variants are filtered based on allele frequency in 1000 Genome Phase
          3(Auton et al, 2015), ExAC (Karczewski et al. 2016), dbSNP (Sherry et
          al, 2001), etc. In the absence of a clinically significant reported
          known variation(s), pathogenicity will be predicted based on in-silico
          gene prioritization tools: CADD (Rentzsch et al. 2018), SIFT (Ng PC et
          al, 2003), PolyPhen-2 (Adzhubei et al, 2013) and prioritized for
          clinical correlation. The identified pathogenic variant will be
          correlated with observed phenotypic features of the patient and
          interpreted according to American College of Medical Genetics (ACMG)
          guidelines.
        </p>
      </div>
      <div class="my-4">
        <div class="p-5">
          <img
            src="../../../assets/test methodalogy.png"
            alt=""
            class="img-fluid w-100"
            style="height: 200px"
          />
        </div>
      </div>
      <div class="mt-4  py-3">
        <div class="mt-3 mb-5">
          <h6 class="py-2 h6_bg">LIMITATIONS AND DISCLAIMER</h6>

          <div class="ref_data mt-4">
            <ul class="px-5">
              <li>
                This test has been developed, validated and performed by
                4baseCare Oncosolutions Pvt. Ltd., and this test has not been
                cleared or approved by the FDA.
              </li>
              <li>
                A comprehensive risk assessment may include other aspects of the
                patient's personal/family medical history, as well as lifestyle,
                environment and other factors. This is not included in the scope
                of this NGS testing.
              </li>
              <li>
                We are using the canonical transcript for clinical reporting
                which is usually the longest coding transcript with
                strong/multiple supporting clinical evidence. However, in rare
                cases, clinically relevant variants annotated in alternate
                complete coding transcripts could also be reported.
              </li>
              <li>
                Changes in personal/family history or additional data regarding
                specific genes/mutations may affect the cancer risk estimates
                and management recommendations within this report.
                Personal/family history should be updated with a healthcare
                provider on a regular basis
              </li>
              <li>
                Certain genes may not be covered completely, and few mutations
                could be missed. Many factors such as homopolymers, GC-rich
                regions etc. influence the quality of sequencing and coverage.
                This may result in an occasional error in sequence reads or lack
                of detection of a particular genetic alteration.
              </li>
              <li>
                As with any laboratory test, there is a small chance that this
                result may be inaccurate for a preanalytical reasons, such as an
                error during specimen collection and labeling (incorrect patient
                identification).
              </li>
              <li>
                Large insertions, deletions, duplications, inversions, repeat
                expansions and complex rearrangements cannot be characterized
                accurately by NGS as it uses short-read sequencing data. Such
                structural variants have a much higher false-positive and
                false-negative rate than seen for SNVs (single nucleotide
                variant). It is possible that the genomic region where a
                disease-causing variation exists in the proband was not captured
                using the current technologies and therefore was not detected.
              </li>
              <li>
                It is possible that a particular genetic abnormality may not be
                recognized as the underlying cause of the genetic disorder due
                to incomplete scientific knowledge about the function of all
                genes in the human genome and the impact of variants on those
                genes.
              </li>
              <li>
                Accurate interpretation of this report is dependent on detailed
                clinical history of the patient. In the event of unavailability
                of detailed clinical history, the lab cannot guarantee the
                accuracy of the interpretation.
              </li>
              <li>
                This report is strictly not a medical diagnostic report and
                shall not be construed as the medical certificate or medical
                laboratory report or diagnostic report.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="row footer">
      <div class="col-12">
        <div class="pb-2 pl-4 text">
          <div class="d-block">
            <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
          </div>
          <div class="footer_body" [ngClass]="'gene-bg_blue'">
            <div class="footer_list">
              <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
              <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
              <span>Gln - Q</span><span>Gly - G</span><span>His - H</span>
              <span>Ile - I</span>
            </div>
            <div class="footer_list">
              <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
              <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
              <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
              <span>Val - V</span>
            </div>
          </div>
        </div>
        <div class="col-12 pt-1 ">
          <p style="font-size: 8px!important; line-height: 14px!important;">
            {{footerDiscription}}<br/>{{footerDiscription2}}
          </p>
        </div>
      </div>
    </div>
  </div>

  <!-- page 9 -->
  <div
    id="page9"
    class="container position-relative pagess"
    style="background-color: white"
  >
    <div class="row header mt-1">
      <div *ngIf="report.clientType != 'client'">
        <div class="col-3 pl-0">
          <div class="logo">
            <img src="../../../assets/logo.png" alt="" style="width: 140px" />
          </div>
        </div>
      </div>
      <div class="col">
        <div class="patient-detailes ml-auto float-right">
          <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
            <li>
              Patient Id <br />
              <span>{{ report.patient.ecrfId }}</span>
            </li>
            <li class="ml-4">
              Patient Name <br />
              <span>{{ report.patient.name }}</span>
            </li>

            <li class="ml-4">
              Registration Date<br />
              <span>{{
                report.labDetails.sampleCollected | date : "dd/MM/yyyy"
              }}</span>
            </li>
            <li class="ml-4">
              Reporting Date<br />
              <span>{{ todayDate | date : "dd/MM/yyyy" }}</span>
            </li>
          </ul>
        </div>
      </div>
      <div *ngIf="report.clientType == '4basecare_client'">
        <div class="col-2" *ngIf="report.clientName == 'oncoquest'">
          <img
            src="../../../assets/oncoquest logo.png"
            alt=""
            class="onco_logo"
          />
        </div>
        <div class="col-2" *ngIf="report.clientName == 'path_labs'">
          <img src="../../../assets/pathlab.png" alt="" class="onco_logo" />
        </div>
      </div>
    </div>
    <!--  -->
    <div class="mt-3 mb-5">
      <h6 class="py-2 h6_bg">UNDERSTANDING GERMLINE+ TEST RESULTS</h6>

      <div class="mt-4 px-4 py-3">
        <p class="l_height-38">
          Understanding the genetic testing process and its results require
          support of a trained genetic counsellor. We suggest the individual to
          seek genetic counseling prior to consenting for any kind of genetic
          test to understand the purpose of the test recommended by clinician
          and its usefulness to the patient and their family.
        </p>
        <p class="l_height-38">
          We, at 4baseCare, strive to ensure that every patient and family
          members are made accessible to all possible information without
          breaching the patient’s confidentiality.<br />
          The patient’s physician may annually wish to re-analyze the results or
          recommend re-testing for any variants that may have been newly
          identified, to associate with the patient’s clinical condition. The
          patient or family members are recommended to consult their physician
          and approach us for testing services accordingly.
        </p>
        <div class="p-5 text-center">
          <img
            src="../../../assets/autosomal_img1.png"
            alt=""
            class="img-fluid w-75"
          />
        </div>
        <div class="">
          <h6 class="py-2">Annexure:</h6>
          <p>
            The classification of the variations is done based on American
            College of Medical Genetics as described below
          </p>
          <div class="row mt-4 center">
            <div class="d-flex col-12">
              <!-- <div class="col-1"> -->
              <div class="first float-right mr-4"></div>
              <!-- </div> -->
              <div
                class="col-11 w-100"
                style="border: 1px solid #238eca; border-radius: 10px 10px 0 0"
              >
                <span class="mt-2 mb-2 annexure-table">
                  <strong class="first-text">Pathogenic</strong> : A disease
                  causing variation in a gene which can explain the patient's
                  symptoms has been detected. This usually means that a
                  suspected disorder for which testing had been requested has
                  been confirmed.
                </span>
              </div>
            </div>
            <div class="d-flex col-12">
              <div class="second float-right mr-4"></div>
              <div
                class="col-11 w-100"
                style="background: #e9f4fa; border: 1px solid #238eca"
              >
                <span class="mt-2 mb-2 annexure-table">
                  <strong class="second-text">Likely Pathogenic</strong> : A
                  variant which is very likely to contribute to the development
                  of disease. However, the scientific evidence is currently
                  insufficient to prove this conclusively. Additional evidence
                  is expected to confirm this assertion of pathogenic
                </span>
              </div>
            </div>
            <div class="d-flex col-12">
              <!-- <div class="col-1"> -->
              <div class="third float-right mr-4"></div>
              <!-- </div> -->
              <div class="col-11 w-100" style="border: 1px solid #238eca">
                <span class="mt-2 mb-2 annexure-table">
                  <strong class="third-text"
                    >Variant of Uncertain Significance</strong
                  >
                  : A variant has been detected, but it is difficult to classify
                  it as either pathogenic (disease causing) or benign
                  (non-disease causing) based on current available scientific
                  evidence. Further testing of the patient or family members as
                  recommended by your clinician may be needed. It is probable
                  that their significance can be assessed only with time,
                  subject to availability of scientific evidence.
                </span>
              </div>
            </div>
            <div class="d-flex col-12">
              <!-- <div class="col-1"> -->
              <div class="fourth float-right mr-4"></div>
              <!-- </div> -->
              <div
                class="col-11 w-100"
                style="
                  background: 238eca;
                  border: 1px solid #238eca;
                  border-radius: 0 0 10px 10px;
                "
              >
                <span class="mt-2 mb-2 annexure-table">
                  <strong class="fourth-text">Benign/Likely Benign</strong> : A
                  variant termed benign has sufficient evidence that it can be
                  concluded that the variant is not the cause of the patient's
                  disorder. A variant termed likely benign has sufficient
                  evidence that it can be concluded that the variant is not the
                  cause of the patient’s disorder when combined with other
                  information.
                </span>
              </div>
            </div>

            <div class="col">
            </div>
          </div>
        </div>

        <!--  -->
      </div>
    </div>

    <!--  -->
    <div class="row footer">
      <div class="col-12">
        <div class="pb-2 pl-4 text">
          <div class="d-block">
            <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
          </div>
          <div class="footer_body" [ngClass]="'gene-bg_blue'">
            <div class="footer_list">
              <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
              <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
              <span>Gln - Q</span><span>Gly - G</span><span>His - H</span>
              <span>Ile - I</span>
            </div>
            <div class="footer_list">
              <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
              <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
              <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
              <span>Val - V</span>
            </div>
          </div>
        </div>
        <div class="col-12 pt-1 ">
          <p style="font-size: 8px!important; line-height: 14px!important;">
            {{footerDiscription}}<br/>{{footerDiscription2}}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
