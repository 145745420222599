import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Gene } from 'src/app/_interfaces.ts/admin';
import { CoverageFile } from 'src/app/_interfaces.ts/excel';
import { GermlineReportPdfRes, ReportRes } from 'src/app/_interfaces.ts/reports';
import { ReportService } from 'src/app/_services/report.service';
import html2canvas from "html2canvas";

@Component({
  selector: 'app-germline-plus-v2-report',
  templateUrl: './germline-plus-v2-report.component.html',
  styleUrls: ['./germline-plus-v2-report.component.css']
})
export class GermlinePlusV2ReportComponent implements OnInit {
  footerDiscription ="All the 4baseCare test samples are processed at 4baseCare Offshore Development Centre (ODC) - "+
                "Genomics lab inside Wipro campus in Sarjapur. This lab has been accredited by for NABL: ISO 15109:2012 "+
                "for the lab operations at the address WIPRO LIFE SCIENCES LAB, WIPRO LIMITED, SY. NO 69P,71/4P,70/BAP,134P, "+
                "76P,77P,80P,70P, 79/1P, UNIT 1, SARJAPUR ROAD, DODDAKANNELLI VILLAGE, VARTHUR HOBLI, BENGALURU, KARNATAKA, "+
                "INDIA having NABL 15189 certificate number MC-5155 and test is covered under the scope of this NABL certification."
  footerDiscription2 = "4baseCare (Genomics Tarang ODC), SJP2-S1-1F-C wing, Wipro Limited, SEZ, Sarjapur 2, SyNo.69 (P), Doddakannelli, "+
                "Sarjapura Road, Bengaluru 560035, KA, India"


  reportId!: number;
  isReportDownload:boolean=false
  familyHistoryImg:any=''
  tag:any=''
  additionalvaiantImg=''
  newReportedStarGenes:any=[]
  report = {} as ReportRes;
  reportData = false;
  statusMsg!: string;
  downloadSpinner = false;
  reportedFlag=true
  starGeneFlag=false
  starGeneCount=0
  todayDate = Date();
  germlineReportPdfRes = {} as GermlineReportPdfRes;

  data: any;

  constructor(
    private reportService: ReportService,
    private routerAct: ActivatedRoute,
    private toastr: ToastrService,
    private titleService: Title
  ) { }

  showSuccess() {
    this.toastr.success(this.statusMsg, '',
      { timeOut: 2000 }
    );
  }
  showFail() {
    this.toastr.error(this.statusMsg, '',
      { timeOut: 10000 }
    );
  }

  ngOnInit(): void {
    this.titleService.setTitle("view report");
    this.germlineReportPdfRes.reportedStarGenes=[]
    this.reportId = this.routerAct.snapshot.params['id'];
    if (this.reportId) {
      this.getReport();
    }
  }

  isPathogenic = false;
  isLikelyPathogenic = false;
  isVUS = false;
  isOthers = false;
  coverageMiddleIndex!: number;
  allcoverageFileList: CoverageFile[] = [];
  firstHalfCoverageFileList: CoverageFile[] = [];
  secondHalfCoverageFileList: CoverageFile[] = [];
  sampleSource!:string;
  varientTableCount:number=0;
  referringClinician!:string;
  is4basecare!:boolean;
  labHospital!:string;
  getReport() {
    this.reportService.germlineReportPdfResponse(this.reportId).subscribe(
      (response: any) => {
        this.reportData = true;
        this.statusMsg = response.message;
        this.showSuccess();
        this.germlineReportPdfRes = response['payLoad'];

        if(this.germlineReportPdfRes.familyDetails &&  this.germlineReportPdfRes.familyDetails.length>0 &&this.germlineReportPdfRes.familyDetails.length<4){
          let length = +(4-this.germlineReportPdfRes.familyDetails.length)
            for(let i=1;i<=length;i++){
              let familyDetails={
                  id:null,
                  familyType: 'NA',
                  relationship: 'NA',
                  cancerType: 'NA',
                  diagnosisAge: 'NA',

              }
              this.germlineReportPdfRes.familyDetails.push(familyDetails)
            }
        }
        console.log(" this.germlineReportPdfRes",this.germlineReportPdfRes);
        this.allcoverageFileList = this.germlineReportPdfRes.coverageFileList;
        this.coverageMiddleIndex = Math.ceil(this.allcoverageFileList.length / 2);
        this.firstHalfCoverageFileList = this.allcoverageFileList.splice(0, this.coverageMiddleIndex);
        this.secondHalfCoverageFileList = this.allcoverageFileList.splice(-this.coverageMiddleIndex);
        this.report = response['payLoad']['report'];
        if(this.report.patient.organisationName != null){
          if(this.report.patient.organisationName.includes("4baseCare") || this.report.patient.organisationName.includes("4basecare")){
            this.is4basecare = true;
          }
        }
        if(this.is4basecare==true && this.report?.labDetails?.hospital.includes("Cytecare")){
          this.referringClinician="Cytecare Hospitals";
          this.labHospital="Cytecare Hospitals"
        }else{
          this.referringClinician=this.report?.labDetails?.referringClinician?.replace(/,/g, ', ');
          this.labHospital=this.report?.labDetails?.hospital;
        }
        console.log("interpretation==>\n",this.report.interpretation);
        this.varientTableCount=this.germlineReportPdfRes.snvVariants.length+this.germlineReportPdfRes.cnaVariants.length;
        console.log("varientTableCount",this.varientTableCount);
        this.geneDescriptionReplace(this.germlineReportPdfRes.reportedGenes);
        let reportedGenes:any=[]
        reportedGenes=  this.germlineReportPdfRes.reportedGenes
        for (var i = 0; i < this.germlineReportPdfRes.reportedGenes?.length; i++) {
          let element:any= this.germlineReportPdfRes.reportedGenes[i]
           if(element.description?.length>1400 && this.germlineReportPdfRes.reportedGenes.length==4 && this.varientTableCount<=4){
             let data:any={name:'',description:''}
             reportedGenes.splice(i,0,data);
             this.reportedFlag=false
             this.varientTableCount=JSON.parse(JSON.stringify((this.varientTableCount+Math.abs(6-this.varientTableCount))))
           }else if(element.description?.length>1400 && this.germlineReportPdfRes.reportedGenes.length>4 && this.reportedFlag){
            if(i<4){
              let data:any={name:'',description:''}
              this.reportedFlag=false
              reportedGenes.splice(i,0,data);
            }else{
                this.newReportedStarGenes.push(element)
            }
          }
         }
         for (var i = 0; i < this.germlineReportPdfRes.reportedStarGenes?.length; i++) {
          let element:any= this.germlineReportPdfRes.reportedStarGenes[i]
           if(element.description?.length>1400 && this.germlineReportPdfRes.reportedStarGenes.length>2 && !this.starGeneFlag){
            if(i<3){
              this.starGeneFlag=true
            }
           }
         }
         this.germlineReportPdfRes.reportedGenes=reportedGenes
        if(this.germlineReportPdfRes.reportedStarGenes)
        this.geneDescriptionReplace(this.germlineReportPdfRes.reportedStarGenes);
        if (this.report.referenceWebsites != '<p></p>' || this.report.referenceWebsites != null) {
          this.report.referenceWebsites = this.report.referenceWebsites?.replace(/<ul>/g, '');
          this.report.referenceWebsites = this.report.referenceWebsites?.replace(/<\/ul>/g, '');
        }
        if (this.report.labDetails != null) {
          this.sampleSource = this.report.labDetails?.sampleSource.replace(/FFPE BlockId:/g, '');
        }
        this.assignSignificanceResult(this.report.significanceResult);
        this.getExonNumbers();
      }
    );
  }

  getExonNumbers() {

    this.germlineReportPdfRes.snvStarVariants.forEach(reportedVariant => {
      reportedVariant.aachangeknownGeneList.forEach((aachangeknownGene, index) => {
        if(reportedVariant.selectedAAChangeknownGene && reportedVariant.selectedAAChangeknownGene.length>0){
          if (aachangeknownGene.includes(reportedVariant.ensembleValue)) {
            reportedVariant.exonNumber = aachangeknownGene.split(":")[2].substring(4);
          }
        }else{
          if (aachangeknownGene == 'UNKNOWN') {
            reportedVariant.selectedAAChangeknownGene = ":::::";
          } else if (aachangeknownGene == '.') {
            reportedVariant.selectedAAChangeknownGene = ":::::";
          } else if (aachangeknownGene.includes(reportedVariant.ensembleValue)) {
            reportedVariant.selectedAAChangeknownGene = aachangeknownGene.split(":")[3];
            reportedVariant.selectedAAChangeknownGene=reportedVariant.selectedAAChangeknownGene+", "+ aachangeknownGene.split(":")[4];

            reportedVariant.exonNumber = aachangeknownGene.split(":")[2].substring(4);
          }
          if (reportedVariant.aachangeknownGeneList.length - 1 == index) {
            if (reportedVariant.selectedAAChangeknownGene == null) {
              let selectedAA:any= reportedVariant.aachangeknownGeneList[0];
              reportedVariant.selectedAAChangeknownGene = selectedAA.split(":")[3];
              reportedVariant.selectedAAChangeknownGene=reportedVariant.selectedAAChangeknownGene+", "+ selectedAA.split(":")[4];

              reportedVariant.exonNumber = reportedVariant.aachangeknownGeneList[0].split(":")[2].substring(4);
            }
          }
        }
      });
    });
    this.germlineReportPdfRes.snvVariants.forEach(reportedVariant => {
      reportedVariant.aachangeknownGeneList.forEach((aachangeknownGene, index) => {
        if(reportedVariant.selectedAAChangeknownGene && reportedVariant.selectedAAChangeknownGene.length>0){
          if (aachangeknownGene.includes(reportedVariant.ensembleValue)) {
            reportedVariant.exonNumber = aachangeknownGene.split(":")[2].substring(4);
          }
        }else{
        if (aachangeknownGene == 'UNKNOWN') {
          reportedVariant.selectedAAChangeknownGene = ":::::";
        } else if (aachangeknownGene == '.') {
          reportedVariant.selectedAAChangeknownGene = ":::::";
        } else if (aachangeknownGene.includes(reportedVariant.ensembleValue)) {
          reportedVariant.selectedAAChangeknownGene = aachangeknownGene.split(":")[3];
          reportedVariant.selectedAAChangeknownGene=reportedVariant.selectedAAChangeknownGene+", "+ aachangeknownGene.split(":")[4];
          reportedVariant.exonNumber = aachangeknownGene.split(":")[2].substring(4);
        }
        if (reportedVariant.aachangeknownGeneList.length - 1 == index) {
          if (reportedVariant.selectedAAChangeknownGene == null) {
            let selectedAA:any= reportedVariant.aachangeknownGeneList[0];
            reportedVariant.selectedAAChangeknownGene = selectedAA.split(":")[3];
            reportedVariant.selectedAAChangeknownGene=reportedVariant.selectedAAChangeknownGene+", "+ selectedAA.split(":")[4];
            reportedVariant.exonNumber = reportedVariant.aachangeknownGeneList[0].split(":")[2].substring(4);
          }
        }
      }
      });
    });
  }

  geneDescriptionReplace(gene: Gene[]) {
    for (var i = 0; i < gene?.length; i++) {
      if (gene[i].description != null) {
        gene[i].description = gene[i].description.replace(/<p>/g, '<div>');
        gene[i].description = gene[i].description.replace(/<\/p>/g, '</div>');
        gene[i].description = gene[i].description.replace(/{/g, '<sup>');
        gene[i].description = gene[i].description.replace(/}/g, '</sup>');
        gene[i].description = gene[i].description.replace(/~/g, '<sub>');
        gene[i].description = gene[i].description.replace(/!/g, '</sub>');
      }
    }
  }

  assignSignificanceResult(significanceResult: string){
    let length=this.germlineReportPdfRes.cnaStarVariants.length+this.germlineReportPdfRes.cnaVariants.length+this.germlineReportPdfRes.snvStarVariants.length+this.germlineReportPdfRes.snvVariants.length;
    let pathogenicCount=0
    let likelyPathogenicCount=0;
    let vusCount=0;
    let othersCount=0;
    console.log("length==>\n",length)
    for (let i=0; i<this.germlineReportPdfRes.cnaVariants.length;i++){
      if(this.germlineReportPdfRes.cnaVariants[i].significance == 'Pathogenic'){
        pathogenicCount++;
      }
      if(this.germlineReportPdfRes.cnaVariants[i].significance == 'Likely Pathogenic'){
        likelyPathogenicCount++;
      }
      if(this.germlineReportPdfRes.cnaVariants[i].significance == 'VUS'){
        vusCount++;
      }
      if(this.germlineReportPdfRes.cnaVariants[i].significance == 'Benign'){
        othersCount++;
      }
      if(this.germlineReportPdfRes.cnaVariants[i].significance == 'NA'||this.germlineReportPdfRes.cnaVariants[i].significance == 'Not Classified'){
        othersCount++;
      }
    }
      for (let i=0; i<this.germlineReportPdfRes.snvVariants.length;i++){
        if(this.germlineReportPdfRes.snvVariants[i].significance == 'Pathogenic'){
          pathogenicCount++;
        }
        if(this.germlineReportPdfRes.snvVariants[i].significance == 'Likely Pathogenic'){
          likelyPathogenicCount++;
        }
        if(this.germlineReportPdfRes.snvVariants[i].significance == 'VUS'){
          vusCount++;
        }
        if(this.germlineReportPdfRes.snvVariants[i].significance == 'Benign'){
          othersCount++;
        }
        if(this.germlineReportPdfRes.snvVariants[i].significance == 'NA'||this.germlineReportPdfRes.snvVariants[i].significance == 'Not Classified'){
          othersCount++;
        }
      }
      if(pathogenicCount>0){
        this.isPathogenic =true;
      }
      else if(pathogenicCount==0 && likelyPathogenicCount>0){
        this.isLikelyPathogenic=true;
      }
      else if(pathogenicCount==0 && likelyPathogenicCount==0 && vusCount>0){
        this.isVUS=true;
      }
      else{
        this.isOthers=true;
      }
  }

  downloadReport() {
    this.downloadSpinner = true;
    this.page1()
  }

  page1(){
    this.data = document.getElementById("familyHistoryImg");
      html2canvas(this.data).then(canvas => {
        var imgWidth = 208;
        var imgHeight = (canvas.height * imgWidth) / canvas.width;
        const contentDataURL = canvas.toDataURL("image/png");
        this.familyHistoryImg=contentDataURL
        this.print()
      });
  }

  page1_1(){
    this.data = document.getElementById("tag");
    html2canvas(this.data).then(canvas => {
      var imgWidth = 208;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL("image/png");
      this.tag=contentDataURL
      this.print()
    });
  }
  page2(){
    this.data = document.getElementById("additionalvaiantImg");
    html2canvas(this.data).then(canvas => {
      var imgWidth = 208;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL("image/png");
      this.additionalvaiantImg=contentDataURL
      this.print()
    });
  }

  print(){
    this.isReportDownload=true
   setTimeout(()=>{
    this.downloadSpinner = false;
    document.title="tarGT_Germline" + "-" + this.report?.patient?.name?.replace(/\./g, ' ')
    window.print()
    this.isReportDownload=false
   },3000)
  }
}
