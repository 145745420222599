<div class="card_style">
  <div class="cover_page">
    <img src="../../../assets/germline.png" alt="" class="img-fluid w-100" />
  </div>
</div>

<div class="card_style page_1">
  <div class="row_align">
    <div class="_logo">
      <img src="../../../assets/logo.png" alt="" style="width: 100px" />
    </div>

    <div class="patient_dis">
      <ul class="list-unstyled d-flex text-uppercase mb-0">
        <li>
          Patient Id <br />
          <span>IN-423-VGRB</span>
        </li>
        <li class="ml-4">
          Patient Name <br />
          <span>SAI TEJA MADUS</span>
        </li>

        <li class="ml-4">
          Registration Date<br />
          <span>22/ 01 / 2022</span>
        </li>
        <li class="ml-4">
          Reporting Date<br />
          <span>22/ 01 / 2022</span>
        </li>
      </ul>
    </div>
  </div>
  <!--  -->
  <div class="row row_align mt-4">
    <div class="left_sec col-7">
      <h6 class="typo_h6">SCOPE OF THE TEST</h6>
      <p>SNVs, InDels, CNAs, Gene Fusions status</p>
      <div>
        <img src="../../../assets/BorderLine.png" alt="" />
      </div>
    </div>
    <div class="left_sec col-5">
      <h6 class="typo_h6">GENES OF INTEREST</h6>
      <p>Gene Fusions status</p>
      <div>
        <img src="../../../assets/BorderLine.png" alt="" />
      </div>
    </div>
  </div>
  <!--  -->
  <h6 class="my-3 text-uppercase h6-font-size font-weight-bold">
    Report Details :
  </h6>
  <div class="row row_align mb-3">
    <div class="col-7 left_data">
      <ul class="list-unstyled">
        <li>Name : <span>Sai Teja Madus</span></li>
        <li>Gender :<span> Male </span></li>
        <li>Age / Dob : <span>Sai Teja Madus </span></li>
      </ul>
    </div>
    <div class="col-5 left_data">
      <ul class="list-unstyled">
        <li>Sample Source :<span> H-25/6 </span></li>
        <li>Referring Clinician : <span>KV Barghava </span></li>
        <li>
          Hospital :
          <span>Apollo Hospital, Hitech City </span>
        </li>
      </ul>
    </div>
  </div>
  <!--  -->
  <h6 class="h6_bg">CLINICAL INDICATIONS / FAMILY HISTORY</h6>
  <!--  -->
  <p class="py-2">Clinical Indication testing</p>
  <!--  -->
  <div class="first_page-table d-flex mt-3">
    <div class="box box1">
      <ul class="list-unstyled mb-0">
        <li></li>
        <li>Relationship</li>
        <li>Cancer Type</li>
        <li>Age at diagnosis (in years)</li>
      </ul>
    </div>
    <div class="box box2">
      <ul class="list-unstyled mb-0">
        <li>Proband</li>
        <li>Self</li>
        <li>Cancer Type</li>
        <li>NA</li>
      </ul>
    </div>
    <div class="box box2">
      <ul class="list-unstyled mb-0">
        <li>Proband</li>
        <li>Self</li>
        <li>Cancer Type</li>
        <li>NA</li>
      </ul>
    </div>
    <div class="box box2">
      <ul class="list-unstyled mb-0">
        <li>Proband</li>
        <li>Self</li>
        <li>Cancer Type</li>
        <li>NA</li>
      </ul>
    </div>
  </div>
  <!--  -->
  <h6 class="h6_bg mt-3">Results</h6>
  <!--  -->
  <div class="mt-3">
    <div class="results_gradi mt-3">
      <ul class="d-flex list-unstyled">
        <li>
          <span>Pathogenic Variant detected</span>
          <div class="bg"></div>
        </li>
        <li>
          <span>Likely Pathogenic Variant Identified</span>
          <div class="bg"></div>
        </li>
        <li>
          <span>Variant of Unknown Significance (VUS)</span>
          <div class="bg"></div>
        </li>
        <li>
          <span>Likely Benign/Benign/No variant</span>
          <div class="bg marker"></div>
        </li>
      </ul>
    </div>
  </div>
  <!--  -->
  <p class="my-2">
    * Genetic test results are reported based on the recommendations of American
    College of Medical Genetics [1]. Please refer to Annexure for further
    information
  </p>
  <h6 class="my-3 h6-font-size font-weight-bold typo_h6">Variant Details :</h6>
  <!-- footer -->
  <div class="row_align footer">
    <div class="">
      <div class="py-2">
        <p>
          <span class="font-weight-bold" style="font-size: 10px !important"
            >4baseCare (Genomics Tarang ODC),</span
          >
        </p>
        <p>SJP2-S1-1F- C wing, Wipro Limited, SEZ, Sarjapur 2, Sy.No.69 (P),</p>
        <p>Doddakannelli, Sarjapura Road,</p>
        <p>Bengaluru 560035, KA, India</p>
      </div>
    </div>
    <div class="mr-4">
      <p class="text-right" style="font-size: 10px">
        <i>*Please note that this is not a medical report</i>
      </p>
    </div>
  </div>
</div>
