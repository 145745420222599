import { GermlinePlusDashboardComponent } from './Germline+Report/germline-plus-dashboard/germline-plus-dashboard.component';
import { HrrSomaticReportComponent } from './HrrSomaticReport/hrr-somatic-report/hrr-somatic-report.component';
import { FamilyTestingReportComponent } from './FamilyTestingReport/family-testing-report/family-testing-report.component';
import { GermlineReportComponent } from './germline-report/germline-report.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../_services/auth.guard';
import { CnaExcelComponent } from './cna-excel/cna-excel.component';
import { DashReportsComponent } from './dash-reports/dash-reports.component';
import { FdaClinicalTrailsComponent } from './fda-clinical-trails/fda-clinical-trails.component';
import { PagesComponent } from './pages.component';
import { PatientDocumentsComponent } from './patient-documents/patient-documents.component';
import { PatientComponent } from './patient/patient.component';
import { PdfContentComponent } from './pdf-content/pdf-content.component';
import { ReadExcelComponent } from './read-excel/read-excel.component';
import { ReportsComponent } from './reports/reports.component';
import { TargetFirstReportComponent } from './FirstReport/target-first-report/target-first-report.component';
import { TrailsListComponent } from './trails-list/trails-list.component';
import { ViewCnaComponent } from './view-cna/view-cna.component';
import { ViewFusionComponent } from './view-fusion/view-fusion.component';
import { ViewReportVariantComponent } from './view-report-variant/view-report-variant.component';
import { HrrGermlineReportComponent } from './HrrGermlineReport/hrr-germline-report/hrr-germline-report.component';
import { CoreReportComponent } from './CoreReport/core-report/core-report.component';
import { DemoForpdfComponent } from './demo-forpdf/demo-forpdf.component';
import { First72ReportComponent } from './first72SolidReports/first72-report/first72-report.component';
import { First72LiquidComponent } from './first72LiquidReports/first72-liquid/first72-liquid.component';
import { LungLiquidAdvancedReportComponent } from './LungLiquidAdvancedReport/lung-liquid-advanced-report/lung-liquid-advanced-report.component';
import { BrcaReportComponent } from './Brca1and2Report/brca-report/brca-report.component';
import { First72Solid4bcComponent } from './first72SolidReports/first72-solid4bc/first72-solid4bc.component';
import { First72Liquid4bcComponent } from './first72LiquidReports/first72-liquid4bc/first72-liquid4bc.component';
import { IndiegeneComponent } from './IndiegeneReports/indiegene/indiegene.component';
import { ViewHotspotVariantComponent } from './view-hotspot-variant/view-hotspot-variant.component';
import { Pdl1DashboardComponent } from './pdl1Report/pdl1-dashboard/pdl1-dashboard.component';
import { IndiegeneDashboardComponent } from './IndiegeneReports/indiegene-dashboard/indiegene-dashboard.component';
import { First72SolidDashboardComponent } from './first72SolidReports/first72-solid-dashboard/first72-solid-dashboard.component';
import { First72LiquidDashboardComponent } from './first72LiquidReports/first72-liquid-dashboard/first72-liquid-dashboard.component';
import { BrcaDashboardComponent } from './Brca1and2Report/brca-dashboard/brca-dashboard.component';
import { LungLiquidBasicDashboardComponent } from './LungLiquidBasicReport/lung-liquid-basic-dashboard/lung-liquid-basic-dashboard.component';
import { LungLiquidBasicReportComponent } from './LungLiquidBasicReport/lung-liquid-basic-report/lung-liquid-basic-report.component';
import { FamilyTestingDashboardComponent } from './FamilyTestingReport/family-testing-dashboard/family-testing-dashboard.component';
import { LungLiquidAdvancedDashboardComponent } from './LungLiquidAdvancedReport/lung-liquid-advanced-dashboard/lung-liquid-advanced-dashboard.component';
import { FirstReportDashboardComponent } from './FirstReport/first-report-dashboard/first-report-dashboard.component';
import { HrrSomaticDashboardComponent } from './HrrSomaticReport/hrr-somatic-dashboard/hrr-somatic-dashboard.component';
import { HrrGermlineDashboardComponent } from './HrrGermlineReport/hrr-germline-dashboard/hrr-germline-dashboard.component';
import { GermlinePlusV2DashboardComponent } from './Germline+V2Report/germline-plus-v2-dashboard/germline-plus-v2-dashboard.component';
import { GermlinePlusReportComponent } from './Germline+Report/germline-plus-report/germline-plus-report.component';
import { GermlinePlusV2ReportComponent } from './Germline+V2Report/germline-plus-v2-report/germline-plus-v2-report.component';
import { CoreDashoardComponent } from './CoreReport/core-dashoard/core-dashoard.component';
import { AbsoluteDashboardComponent } from './AbsoluteReport/absolute-dashboard/absolute-dashboard.component';
import { FocusDashboardComponent } from './FocusReport/focus-dashboard/focus-dashboard.component';
import { AbsoluteReportComponent } from './AbsoluteReport/absolute-report/absolute-report.component';
import { FocusReportComponent } from './FocusReport/focus-report/focus-report.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    component: PagesComponent,
    children: [
      {
        path: '',
        component: DashReportsComponent,
      },
      {
        path: 'reports',
        component: DashReportsComponent,
      },
      {
        path: 'reports/:organisationName',
        component: DashReportsComponent,
      },
      {
        path: 'edit_patient/:id',
        component: PatientComponent,
      },
      {
        path: 'read_excel/:id',
        component: ReadExcelComponent,
      },
      {
        path: 'cna_excel/:id',
        component: CnaExcelComponent,
      },
      {
        path: 'trail_list/:id',
        component: TrailsListComponent,
      },
      {
        path: 'fda_trail_list/:id',
        component: FdaClinicalTrailsComponent,
      },
      {
        path: 'dash-reports/:id',
        component: ReportsComponent,
      },
      {
        path: 'dash-reports/PDL1/:id',
        component: Pdl1DashboardComponent,
      },
      {
        path: 'dash-reports/Indiegene/:id',
        component: IndiegeneDashboardComponent,
      },
      {
        path: 'dash-reports/First_72_Solid/:id',
        component: First72SolidDashboardComponent,
      },
      {
        path: 'dash-reports/BRCA_1_and_2/:id',
        component: BrcaDashboardComponent,
      },
      {
        path: 'dash-reports/Lung_Liquid_Basic/:id',
        component: LungLiquidBasicDashboardComponent,
      },
      {
        path: 'dash-reports/Family_Testing/:id',
        component: FamilyTestingDashboardComponent,
      },
      {
        path: 'dash-reports/Lung_Liquid_Advanced/:id',
        component: LungLiquidAdvancedDashboardComponent,
      },
      {
        path: 'dash-reports/First/:id',
        component: FirstReportDashboardComponent,
      },
      {
        path: 'dash-reports/HRR_Somatic/:id',
        component: HrrSomaticDashboardComponent,
      },
      {
        path: 'dash-reports/HRR_Germline/:id',
        component: HrrGermlineDashboardComponent,
      },
      {
        path: 'dash-reports/Germline+/:id',
        component: GermlinePlusDashboardComponent,
      },
      {
        path: 'dash-reports/Germline+_V2/:id',
        component: GermlinePlusV2DashboardComponent,
      },
      {
        path: 'dash-reports/Core/:id',
        component: CoreDashoardComponent,
      },
      {
        path: 'dash-reports/Absolute/:id',
        component: AbsoluteDashboardComponent,
      },
      {
        path: 'dash-reports/Focus/:id',
        component: FocusDashboardComponent,
      },
      {
        path: 'dash-reports/First_72_Liquid/:id',
        component: First72LiquidDashboardComponent,
      },
      {
        path: 'report_pdf',
        component: PdfContentComponent,
      },
      {
        path: 'view_variant',
        component: ViewReportVariantComponent,
      },
      {
        path: 'report/:rid/view_variant/:id',
        component: ViewReportVariantComponent,
      },
      {
        path: 'report/:rid/cna_variant/:cid',
        component: ViewCnaComponent,
      },
      {
        path: 'cna_trail_list/:cid',
        component: TrailsListComponent,
      },
      {
        path: 'cna_fda_trail_list/:cid',
        component: FdaClinicalTrailsComponent,
      },
      {
        path: 'hotspot_fda_trail_list/:hid',
        component: FdaClinicalTrailsComponent,
      },
      {
        path: 'report/:rid/fusion_variant/:fid',
        component: ViewFusionComponent,
      },
      {
        path: 'report/:rid/hotspot_variant/:fid',
        component: ViewHotspotVariantComponent,
      },
      {
        path: 'fusion_trail_list/:fid',
        component: TrailsListComponent,
      },
      {
        path: 'fusion_fda_trail_list/:fid',
        component: FdaClinicalTrailsComponent,
      },
      {
        path: 'patient-documents/:ecrfId',
        component: PatientDocumentsComponent,
      },
    ],
  },
  {
    path: 'demo',
    canActivate: [AuthGuard],
    component: DemoForpdfComponent,
  },
  {
    path: 'view_report/:id',
    canActivate: [AuthGuard],
    component: PdfContentComponent,
  },
  {
    path: 'view_report_indiegene/:id',
    component: IndiegeneComponent,
  },
  {
    path: 'first_view_report/:id',
    canActivate: [AuthGuard],
    component: TargetFirstReportComponent,
  },

  {
    path: 'first72_solid_4bc_view_report/:id',
    canActivate: [AuthGuard],
    component: First72Solid4bcComponent,
  },

  {
    path: 'first72_liquid_4bc_view_report/:id',
    canActivate: [AuthGuard],
    component: First72Liquid4bcComponent,
  },

  {
    path: 'first72_view_report/:id/:temp',
    canActivate: [AuthGuard],
    component: First72ReportComponent,
  },
  {
    path: 'first72_view_report/:id',
    canActivate: [AuthGuard],
    component: First72ReportComponent,
  },
  {
    path: 'brca1and2_view_report/:id',
    canActivate: [AuthGuard],
    component: BrcaReportComponent,
  },
  {
    path: 'first72_liquid_view_report/:id/:temp',
    canActivate: [AuthGuard],
    component: First72LiquidComponent,
  },
  {
    path: 'first72_liquid_view_report/:id',
    canActivate: [AuthGuard],
    component: First72LiquidComponent,
  },
  {
    path: 'lung_liquid_basic_report/:id',
    canActivate: [AuthGuard],
    component: LungLiquidBasicReportComponent,
  },
  {
    path: 'lung_liquid_advanced_report/:id',
    canActivate: [AuthGuard],
    component: LungLiquidAdvancedReportComponent,
  },
  {
    path: 'core_report/:id',
    canActivate: [AuthGuard],
    component: CoreReportComponent,
  },
  {
    path: 'germline_report/:id',
    canActivate: [AuthGuard],
    component: GermlineReportComponent,
  },
  {
    path: 'germline_plus_report/:id',
    canActivate: [AuthGuard],
    component: GermlinePlusReportComponent,
  },
  {
    path: 'germline_plus_v2_report/:id',
    canActivate: [AuthGuard],
    component: GermlinePlusV2ReportComponent,
  },
  {
    path: 'absolute/:id',
    canActivate: [AuthGuard],
    component: AbsoluteReportComponent,
  },
  {
    path: 'focus/:id',
    canActivate: [AuthGuard],
    component: FocusReportComponent,
  },
  {
    path: 'hrr_germline_report/:id',
    canActivate: [AuthGuard],
    component: HrrGermlineReportComponent,
  },
  {
    path: 'family_report/:id',
    component: FamilyTestingReportComponent,
  },
  {
    path: 'hrr_somatic/:id',
    component: HrrSomaticReportComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule {}
