<div *ngIf="!reportData" class="d-flex justify-content-center mt-5">
  <span class="visually-hidden"></span>
  <div
    class="spinner-border"
    style="width: 8rem; height: 8rem"
    role="status"
  ></div>
</div>
<div
  *ngIf="reportData"
  style="height: 1600px; padding: 10px; margin: 0 auto"
  class="main_wrapper"
>
  <button
    [disabled]="downloadSpinner"
    type="button"
    class="btn btn-primary m-2 float-right download-btn"
    (click)="downloadReport()"
  >
    <span *ngIf="!downloadSpinner">
      <i class="zmdi zmdi-download"></i>
      Download Report
    </span>
    <div *ngIf="downloadSpinner" class="spinner-border text-dark">
      <span class="sr-only"></span>
    </div>
  </button>
  <div
    id="page"
    class="container position-relative page"
    style="overflow-y: clip; background-color: white"
  >
    <div class="row header">
      <div class="w-100 overflow-hidden">
        <div class="col">
          <div class="cover_page">
            <img
              src="../../../assets/HRR-Somatic.png"
              alt=""
              class="img-fluid w-100"
            />
          </div>
        </div>
      </div>
      <div></div>
    </div>
  </div>

  <div
    id="page1"
    class="container position-relative page"
    style="background-color: white"
  >
    <div class="row header mt-1 py-4">
      <div>
        <div class="col-3 pl-0">
          <div class="logo">
            <img src="../../../assets/logo.png" alt="" style="width: 140px" />
          </div>
        </div>
      </div>
      <div></div>
      <div></div>
      <div class="col">
        <div class="patient-detailes ml-auto float-right">
          <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
            <li>
              Patient Id <br />
              <span>{{ report.patient.ecrfId }}</span>
            </li>
            <li class="ml-4">
              Cancer Site<br />
              <span>{{ report.cancerType }}</span>
            </li>
            <li class="ml-4">
              Sample ID <br />
              <span>{{ report.labDetails?.sampleId }}</span>
            </li>

            <li class="ml-4">
              Registration Date <br />
              <span>{{
                report.labDetails.sampleCollected | date : "dd/MM/yyyy"
              }}</span>
            </li>
          </ul>
        </div>
      </div>
       <div *ngIf="report.clientType == '4basecare_client'">
          <div class="col-2" *ngIf="report.clientName == 'oncoquest'">
            <img
              src="../../../assets/oncoquest logo.png"
              alt=""
              class="onco_logo"
            />
          </div>
          <div class="col-2" *ngIf="report.clientName == 'path_labs'">
            <img src="../../../assets/pathlab.png" alt="" class="onco_logo" />
          </div>
      </div>
      <div></div>
    </div>
    <!--  -->
    <div class="mar_top"></div>
    <!--  -->
    <div class="row">
      <div class="col-7">
        <div class="">
          <div class="w-50" style="min-height: 80px">
            <p class="py-1 font-weight-bold clr-cyan">SCOPE OF THE TEST</p>
            <p>SNVs, InDels, CNAs status</p>
          </div>
          <img src="../../../assets/BorderLine.png" class="mr-2 w-100" />
        </div>
      </div>
      <div class="col">
        <div class="">
          <div class="w-100">
            <!-- <p style="color: #208dcb;" class="py-1">
                    <span style="color: #000 !important"></span>
                  </p> -->
            <div class="w-100" style="min-height: 80px">
              <p class="py-1 font-weight-bold clr-cyan">
                <span>CLINICAL INDICATION</span>
              </p>
              <p><span [innerHtml]="report.clinicalSummary"></span></p>
              <!-- <span>Clinical indications entered in OncoBench</span> -->
            </div>
            <!-- </div> -->
            <img src="../../../assets/BorderLine.png" class="mr-2 w-100" />

            <!-- </div> -->
          </div>
        </div>
      </div>
    </div>
    <!--  -->
    <div class="row pt-2">
      <div class="col-7">
        <h6 class="text-uppercase py-2 font-weight-bold">Report Details</h6>
        <ul class="list-unstyled">
          <li class="pb-1">
            <span class="clr-cyan">Name : </span>
            <span class="text-black">{{ report.patient?.name }}</span>
          </li>
          <li class="pb-1">
            <span class="clr-cyan">Gender : </span>
            <span class="text-black">{{ report.patient?.gender }}</span>
          </li>
          <li class="pb-1">
            <span class="clr-cyan">Age/DOB : </span>
            <span class="text-black">{{
              report.patient?.dateOfBirth
                ? (report.patient?.dateOfBirth | date : "dd/MM/yyyy")
                : report.patient?.age + " Years"
            }}</span>
          </li>
          <li class="pb-1">
            <span class="clr-cyan">Reporting Date : </span>
            <span class="text-black">{{
              todayDate | date : "dd/MM/yyyy"
            }}</span>
          </li>
        </ul>
      </div>
      <div class="col">
        <ul class="list-unstyled pt-4 mt-3">
          <li class="pb-1">
            <span class="clr-cyan">Cancer Celltype : </span>
            <span class="text-black"> {{ report.cancerSubType }}</span>
          </li>
          <li class="pb-1">
            <span class="clr-cyan">Sample Source : </span>
            <span class="text-black">{{ sampleSource }}</span>
          </li>
          <li class="pb-1">
            <span class="clr-cyan">Referring Clinician :</span>
            <span class="text-black">
              {{referringClinician}}</span
            >
          </li>
          <li class="pb-1">
            <span class="clr-cyan">Hospital : </span>
            <span class="text-black"> {{ labHospital }}</span>
          </li>
        </ul>
      </div>
    </div>
    <!--  -->
    <div class="row mt-2 py-2">
      <div class="col">
        <h6 class="mb-0 text-uppercase font-weight-bold">Results</h6>
      </div>
    </div>
    <h6 class="py-2 h6_bg">Genomic findings from tumor profiling</h6>

    <!--  -->
    <div class="row">
      <div class="col-12">
        <span *ngIf="!(reportPdfRes.snvHotspotTrailResList.length == 0 &&
        reportPdfRes.cnaHotspotTrailResList.length == 0 &&
        reportPdfRes.fusionHotspotTrailResList.length == 0)
        ">
          <table class="page_1 cust_table w-100 mt-2 first_1 table_skyblue">
            <tr>
              <th>Genomic Alteration</th>
              <th>Relevant Therapies (in Same Cancer Type)</th>
              <th>Relevant Therapies (in Different Cancer)</th>
            </tr>
            <!---->
            <tr
              *ngFor="let snvHotspotTrail of reportPdfRes.snvHotspotTrailResList"
            >
              <td>
                <i>{{ snvHotspotTrail.gene }}</i
                ><span *ngIf="snvHotspotTrail.exonNumber != null">
                  Exon {{ snvHotspotTrail.exonNumber }} ({{
                    snvHotspotTrail.pposition
                  }})</span
                ><!---->
                <p *ngIf="snvHotspotTrail.exonNumber != null">
                  Allelic burden: {{ snvHotspotTrail.mutantBurdenPercent }}%
                </p>
                <!---->
              </td>
              <td>
                <table class="first_table w-100">
                  <thead class="border-bottom cust_clr">
                    <tr>
                      <th class="w-50">
                        <span class="font-weight-bold clr-cyan">Therapy</span>
                      </th>
                      <th class="w-33">
                        <span class="font-weight-bold clr-cyan"
                          >Clinical Relevance</span
                        >
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngIf="snvHotspotTrail.sameCancerType == null">
                      <td class="w-50">NA</td>
                      <td class="w-50">NA</td>
                    </tr>
                    <ng-container *ngIf="snvHotspotTrail.sameCancerType != null">
                      <tr
                        *ngFor="let item of snvHotspotTrail.sameTypeRelevantData"
                      >
                        <td class="w-50">{{ item.therapy || "NA" }}</td>
                        <td class="w-50">{{ item.association }}</td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </td>
              <td>
                <table class="first_table w-100">
                  <thead class="border-bottom cust_clr">
                    <tr>
                      <th class="w-33">
                        <span class="font-weight-bold clr-cyan">Therapy</span>
                      </th>
                      <th class="w-33">
                        <span class="font-weight-bold clr-cyan"
                          >Clinical Relevance</span
                        >
                      </th>
                      <th>
                        <span class="font-weight-bold clr-cyan"
                        >Cancer Type</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngIf="snvHotspotTrail.otherCancerType == null">
                      <td>NA</td>
                      <td>NA</td>
                      <td >NA</td>
                    </tr>
                    <ng-container *ngIf="snvHotspotTrail.otherCancerType != null">
                      <tr
                        *ngFor="let item of snvHotspotTrail.otherTypeRelevantData"
                      >
                        <td>{{ item.therapy || "NA" }}</td>
                        <td>{{ item.association }}</td>
                        <td>{{item.cancerType}}</td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </td>
            </tr>
            <!-- CNA-->
            <tr
              *ngFor="let cnaHotspotTrail of reportPdfRes.cnaHotspotTrailResList"
            >
              <td>
                <i>{{ cnaHotspotTrail.gene }}</i> {{ cnaHotspotTrail.copyNumber }}
                <!---->
              </td>
              <td>
                <table class="table first_table w-100">
                  <thead class="border-bottom cust_clr">
                    <tr>
                      <th class="w-50">
                        <span class="font-weight-bold clr-cyan">Therapy</span>
                      </th>
                      <th class="w-33">
                        <span class="font-weight-bold clr-cyan"
                          >Clinical Relevance</span
                        >
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngIf="cnaHotspotTrail.sameCancerType == null">
                      <td class="w-50">NA</td>
                      <td class="w-50">NA</td>
                    </tr>
                    <ng-container *ngIf="cnaHotspotTrail.sameCancerType != null">
                      <tr
                        *ngFor="let item of cnaHotspotTrail.sameTypeRelevantData"
                      >
                        <td class="w-50">{{ item.therapy || "NA" }}</td>
                        <td class="w-50">{{ item.association }}</td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </td>
              <td>
                <table class="table first_table w-100">
                  <thead class="border-bottom cust_clr">
                    <tr>
                      <th class="w-33">
                        <span class="font-weight-bold clr-cyan">Therapy</span>
                      </th>
                      <th class="w-33">
                        <span class="font-weight-bold clr-cyan"
                          >Clinical Relevance</span
                        >
                      </th>
                      <th>
                        <span class="font-weight-bold clr-cyan"
                        >Cancer Type</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngIf="cnaHotspotTrail.otherCancerType == null">
                      <td>NA</td>
                      <td>NA</td>
                      <td >NA</td>
                    </tr>
                    <ng-container *ngIf="cnaHotspotTrail.otherCancerType != null">
                      <tr
                        *ngFor="let item of cnaHotspotTrail.otherTypeRelevantData"
                      >
                        <td>{{ item.therapy || "NA" }}</td>
                        <td>{{ item.association }}</td>
                        <td>{{item.cancerType}}</td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </td>
            </tr>
            <!-- Fussion -->
            <tr
              *ngFor="
                let fusionHotspotTrail of reportPdfRes.fusionHotspotTrailResList
              "
            >
              <td>
                <i>{{ fusionHotspotTrail.gene }}</i>
              </td>
              <td>
                <table class="table first_table w-100">
                  <thead class="border-bottom cust_clr">
                    <tr>
                      <th class="w-50">
                        <span class="font-weight-bold clr-cyan">Therapy</span>
                      </th>
                      <th class="w-33">
                        <span class="font-weight-bold clr-cyan"
                          >Clinical Relevance</span
                        >
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngIf="fusionHotspotTrail.sameCancerType == null">
                      <td class="w-50">NA</td>
                      <td class="w-50">NA</td>
                    </tr>
                    <ng-container
                      *ngIf="fusionHotspotTrail.sameCancerType != null"
                    >
                      <tr
                        *ngFor="
                          let item of fusionHotspotTrail.sameTypeRelevantData
                        "
                      >
                        <td class="w-50">{{ item.therapy || "NA" }}</td>
                        <td class="w-50">{{ item.association }}</td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </td>
              <td>
                <table class="table first_table w-100">
                  <thead class="border-bottom cust_clr">
                    <tr>
                      <th class="w-33">
                        <span class="font-weight-bold clr-cyan">Therapy</span>
                      </th>
                      <th class="w-33">
                        <span class="font-weight-bold clr-cyan"
                          >Clinical Relevance</span
                        >
                      </th>
                      <th>
                        <span class="font-weight-bold clr-cyan"
                        >Cancer Type</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngIf="fusionHotspotTrail.otherCancerType == null">
                      <td>NA</td>
                      <td>NA</td>
                      <td >NA</td>
                    </tr>
                    <ng-container
                      *ngIf="fusionHotspotTrail.otherCancerType != null"
                    >
                      <tr
                        *ngFor="
                          let item of fusionHotspotTrail.otherTypeRelevantData
                        "
                      >
                        <td>{{ item.therapy || "NA" }}</td>
                        <td>{{ item.association }}</td>
                        <td>{{item.cancerType}}</td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </td>
            </tr>

          </table>
        </span>
        <span *ngIf="(reportPdfRes.snvHotspotTrailResList.length == 0 &&
        reportPdfRes.cnaHotspotTrailResList.length == 0 &&
        reportPdfRes.fusionHotspotTrailResList.length == 0)
        ">
        <table  class="page_1  page1_tb cust_table w-100 mt-2 first_1 table_skyblue">
          <tr>
            <th class="page1_th">Genomic Alteration</th>
            <th class="page1_th " style="text-align: center;">Relevant Therapies (in Same Cancer Type)</th>
            <th class="page1_th">Relevant Therapies (in Different Cancer)</th>
          </tr>
          <!---->

          <tr tyle="border:1px #238eca solid"
          *ngIf="
            reportPdfRes.snvHotspotTrailResList.length == 0 &&
            reportPdfRes.cnaHotspotTrailResList.length == 0 &&
            reportPdfRes.fusionHotspotTrailResList.length == 0
          "
        >
          <td colspan="3"  class="text-center mt-2 page1_td" >
            Clinically relevant genomic alterations with therapeutic significance were not detected
          </td>
        </tr>
        </table>
        </span>
      </div>
    </div>
    <!--  -->
    <div *ngIf="!firstTableFlag">
      <h6 class="py-2 mb-0 h6_bg mt-3">
        GENOMIC ALTERATION(S) DETECTED IN PATIENT
      </h6>
      <!--  -->
      <div class="row">
        <div class="col-12">
          <table
            class="page_1 cust_table w-100 mt-2 varient_details table_variant-blue"
          >
            <thead>
              <tr>
                <th>Gene</th>
                <th>Variant Location</th>
                <th>Variant Details and allelic burden</th>
                <th>Clinical Significance</th>
                <th>Variant Type</th>
                <th>Reference</th>
              </tr>
            </thead>
            <tbody
              *ngIf="
                reportPdfRes.snvReportedVariantList.length == 0 &&
                reportPdfRes.cnaReportedVariantList.length == 0 &&
                reportPdfRes.fusionReportedVariantList.length == 0
              "
            >
              <tr>
                <!-- <td></td> -->
                <td colspan="7" style="text-align: center !important;font-weight: bold;">
                  Clinically relevant genomic alterations  were not detected.
                </td>
              </tr>
            </tbody>
            <tr
              *ngFor="
                let selectedReportedVariant of reportPdfRes.snvReportedVariantList
              "
            >
              <td
                *ngIf="
                  selectedReportedVariant.significance != 'NA' &&
                  selectedReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important; overflow-wrap: anywhere"
              >
                <i>{{ selectedReportedVariant.refGeneX }}</i>
              </td>
              <td
                *ngIf="
                  selectedReportedVariant.significance != 'NA' &&
                  selectedReportedVariant.significance != 'Not Classified'
                "
                style="
                  padding-left: 3px !important;
                  width: 60px;
                  overflow-wrap: anywhere;
                "
              >
                {{ selectedReportedVariant.genomicAlteration }},<br />
                {{ selectedReportedVariant.ensembleValue }},<br />
                Exon {{ selectedReportedVariant.exonNumber }}
              </td>
              <td
                *ngIf="
                  selectedReportedVariant.significance != 'NA' &&
                  selectedReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important; overflow-wrap: anywhere"
              >
                {{ selectedReportedVariant.selectedAAChangeknownGene }} ,

                {{
                  selectedReportedVariant.mutantBurdenPercent * 0.01 | percent
                }}
              </td>
              <!---->
              <td
                *ngIf="
                  selectedReportedVariant.significance != 'NA' &&
                  selectedReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important; overflow-wrap: anywhere"
              >
                {{ selectedReportedVariant.significance }}
              </td>
              <!---->
              <td
                *ngIf="
                  selectedReportedVariant.significance != 'NA' &&
                  selectedReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important; overflow-wrap: anywhere"
              >
                {{ selectedReportedVariant.exonicFuncknownGene }}
              </td>
              <!---->
              <td
                *ngIf="
                  selectedReportedVariant.significance != 'NA' &&
                  selectedReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important; overflow-wrap: anywhere"
              >
                {{ selectedReportedVariant.reference }}
              </td>
              <!---->
            </tr>
            <!-- CNA -->
            <tr
              *ngFor="
                let cnaReportedVariant of reportPdfRes.cnaReportedVariantList
              "
            >
              <td
                *ngIf="
                  cnaReportedVariant.significance != 'NA' &&
                  cnaReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important; overflow-wrap: anywhere"
              >
                <i>{{ cnaReportedVariant.gene }}</i>
              </td>
              <!---->
              <td
                *ngIf="
                  cnaReportedVariant.significance != 'NA' &&
                  cnaReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important; overflow-wrap: anywhere"
              >
                chr{{ cnaReportedVariant.sample.split(":")[0] }}:g.{{
                  cnaReportedVariant.sample
                    .split(":")[1]
                    .replace("-", "_")
                    .split("|")[0]
                }}
              </td>
              <!---->
              <td
                *ngIf="
                  cnaReportedVariant.significance != 'NA' &&
                  cnaReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important"
              >
                NA
              </td>
              <td
                *ngIf="
                  cnaReportedVariant.significance != 'NA' &&
                  cnaReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important; overflow-wrap: anywhere"
              >
                {{ cnaReportedVariant.significance }}
              </td>
              <!---->
              <td
                *ngIf="
                  cnaReportedVariant.significance != 'NA' &&
                  cnaReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important; overflow-wrap: anywhere"
              >
                Copy Number
                {{ cnaReportedVariant.sample.split(":")[2] | titlecase }}
                ({{ cnaReportedVariant.sample.split("|")[1].split(":")[0] }})
              </td>
              <!---->
              <td
                *ngIf="
                  cnaReportedVariant.significance != 'NA' &&
                  cnaReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important; overflow-wrap: anywhere"
              >
                {{ cnaReportedVariant.reference }}
              </td>
              <!---->
              <!-- <td style="padding-left: 3px !important"></td> -->
              <!---->
            </tr>

            <!-- Fussion-->
            <tr
              *ngFor="
                let fusionReportedVariant of reportPdfRes.fusionReportedVariantList
              "
            >
              <td
                *ngIf="
                  fusionReportedVariant.significance != 'NA' &&
                  fusionReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important"
              >
                <i>{{ fusionReportedVariant.fusionGene }}</i>
              </td>
              <!---->
              <td
                *ngIf="
                  fusionReportedVariant.significance != 'NA' &&
                  fusionReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important"
              >
                {{ fusionReportedVariant.leftBreakPoint }}<br />{{
                  fusionReportedVariant.rightBreakPoint
                }}
              </td>
              <!---->
              <td
                *ngIf="
                  fusionReportedVariant.significance != 'NA' &&
                  fusionReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important"
              >
                NA
              </td>
              <!---->
              <td
                *ngIf="
                  fusionReportedVariant.significance != 'NA' &&
                  fusionReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important"
              >
                {{ fusionReportedVariant.significance }}
              </td>
              <!---->
              <td
                *ngIf="
                  fusionReportedVariant.significance != 'NA' &&
                  fusionReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important"
              >
                Gene Fusions
              </td>
              <!---->
              <td
                *ngIf="
                  fusionReportedVariant.significance != 'NA' &&
                  fusionReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important"
              >
                {{ fusionReportedVariant.reference }}
              </td>
              <!---->
            </tr>
            <!----><!---->
          </table>
          <p class="mt-3" style="font-size: 13px !important">
            <i> *NA: Not Applicable</i>
          </p>
          <!---->
        </div>
      </div>
    </div>

    <!--  -->
    <div class="row footer">
      <div class="col-12">
        <div class="pb-2 pl-4 text">
          <div class="d-block">
            <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
          </div>
          <div class="footer_body" [ngClass]="'gene-bg_blue'">
            <div class="footer_list">
              <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
              <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
              <span>Gln - Q</span><span>Gly - G</span><span>His - H</span>
              <span>Ile - I</span>
            </div>
            <div class="footer_list">
              <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
              <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
              <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
              <span>Val - V</span>
            </div>
          </div>
        </div>
        <div class="col-12 pt-1 ">
          <p style="font-size: 8px!important; line-height: 14px!important;">
            {{footerDiscription}}<br/>{{footerDiscription2}}
          </p>
        </div>
      </div>
    </div>
  </div>
  <!--  -->
  <div
    id="page1_2"
    class="container position-relative page"
    style="background-color: white"
    *ngIf="firstTableFlag"
  >
    <div class="row header mt-1 py-4">
      <div>
        <div class="col-3">
          <img src="../../../assets/logo.png" alt="" style="width: 140px" />
        </div>
      </div>
      <div></div>
      <div class="col">
        <div class="patient-detailes ml-auto float-right">
          <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
            <li>
              Patient Id <br />
              <span>{{ report.patient.ecrfId }}</span>
            </li>
            <li class="ml-4">
              Cancer Site<br />
              <span>{{ report.cancerType }}</span>
            </li>
            <li class="ml-4">
              Sample ID <br />
              <span>{{ report.labDetails?.sampleId }}</span>
            </li>

            <li class="ml-4">
              Registration Date <br />
              <span>{{
                report.labDetails.sampleCollected | date : "dd/MM/yyyy"
              }}</span>
            </li>
          </ul>
        </div>
      </div>
      <div *ngIf="report.clientType == '4basecare_client'">
        <div class="col-2" *ngIf="report.clientName == 'oncoquest'">
          <img
            src="../../../assets/oncoquest logo.png"
            alt=""
            class="onco_logo"
          />
        </div>
        <div class="col-2" *ngIf="report.clientName == 'path_labs'">
          <img src="../../../assets/pathlab.png" alt="" class="onco_logo" />
        </div>
      </div>
      <!---->
    </div>
    <!--  -->
    <div *ngIf="firstTableFlag">
      <h6 class="py-2 mb-0 h6_bg mt-3">
        GENOMIC ALTERATION(S) DETECTED IN PATIENT
      </h6>
      <!--  -->
      <div class="row">
        <div class="col-12">
          <table
            class="page_1 cust_table w-100 mt-2 varient_details table_variant-blue"
          >
            <thead>
              <tr>
                <th>Gene</th>
                <th>Variant Location</th>
                <th>Variant Details and allelic burden</th>
                <th>Clinical Significance</th>
                <th>Variant Type</th>
                <th>Reference</th>
              </tr>
            </thead>
            <tbody
              *ngIf="
                reportPdfRes.snvReportedVariantList.length == 0 &&
                reportPdfRes.cnaReportedVariantList.length == 0 &&
                reportPdfRes.fusionReportedVariantList.length == 0
              "
            >
              <tr>
                <td></td>
                <td colspan="6" style="text-align: center !important">
                  Clinically relevant genomic alterations associated with
                  therapeutic significance were not detected.
                </td>
              </tr>
            </tbody>
            <tr
              *ngFor="
                let selectedReportedVariant of reportPdfRes.snvReportedVariantList
              "
            >
              <td
                *ngIf="
                  selectedReportedVariant.significance != 'NA' &&
                  selectedReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important; overflow-wrap: anywhere"
              >
                <i>{{ selectedReportedVariant.refGeneX }}</i>
              </td>
              <td
                *ngIf="
                  selectedReportedVariant.significance != 'NA' &&
                  selectedReportedVariant.significance != 'Not Classified'
                "
                style="
                  padding-left: 3px !important;
                  width: 60px;
                  overflow-wrap: anywhere;
                "
              >
                {{ selectedReportedVariant.genomicAlteration }},<br />
                {{ selectedReportedVariant.ensembleValue }},<br />
                Exon {{ selectedReportedVariant.exonNumber }}
              </td>
              <td
                *ngIf="
                  selectedReportedVariant.significance != 'NA' &&
                  selectedReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important; overflow-wrap: anywhere"
              >
                {{ selectedReportedVariant.selectedAAChangeknownGene }} ,

                {{
                  selectedReportedVariant.mutantBurdenPercent * 0.01 | percent
                }}
              </td>
              <!---->
              <td
                *ngIf="
                  selectedReportedVariant.significance != 'NA' &&
                  selectedReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important; overflow-wrap: anywhere"
              >
                {{ selectedReportedVariant.significance }}
              </td>
              <!---->
              <td
                *ngIf="
                  selectedReportedVariant.significance != 'NA' &&
                  selectedReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important; overflow-wrap: anywhere"
              >
                {{ selectedReportedVariant.exonicFuncknownGene }}
              </td>
              <!---->
              <td
                *ngIf="
                  selectedReportedVariant.significance != 'NA' &&
                  selectedReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important; overflow-wrap: anywhere"
              >
                {{ selectedReportedVariant.reference }}
              </td>
              <!---->
            </tr>
            <!-- CNA -->
            <tr
              *ngFor="
                let cnaReportedVariant of reportPdfRes.cnaReportedVariantList
              "
            >
              <td
                *ngIf="
                  cnaReportedVariant.significance != 'NA' &&
                  cnaReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important; overflow-wrap: anywhere"
              >
                <i>{{ cnaReportedVariant.gene }}</i>
              </td>
              <!---->
              <td
                *ngIf="
                  cnaReportedVariant.significance != 'NA' &&
                  cnaReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important; overflow-wrap: anywhere"
              >
                chr{{ cnaReportedVariant.sample.split(":")[0] }}:g.{{
                  cnaReportedVariant.sample
                    .split(":")[1]
                    .replace("-", "_")
                    .split("|")[0]
                }}
              </td>
              <!---->
              <td
                *ngIf="
                  cnaReportedVariant.significance != 'NA' &&
                  cnaReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important"
              >
                NA
              </td>
              <td
                *ngIf="
                  cnaReportedVariant.significance != 'NA' &&
                  cnaReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important; overflow-wrap: anywhere"
              >
                {{ cnaReportedVariant.significance }}
              </td>
              <!---->
              <td
                *ngIf="
                  cnaReportedVariant.significance != 'NA' &&
                  cnaReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important; overflow-wrap: anywhere"
              >
                Copy Number
                {{ cnaReportedVariant.sample.split(":")[2] | titlecase }}
                ({{ cnaReportedVariant.sample.split("|")[1].split(":")[0] }})
              </td>
              <!---->
              <td
                *ngIf="
                  cnaReportedVariant.significance != 'NA' &&
                  cnaReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important; overflow-wrap: anywhere"
              >
                {{ cnaReportedVariant.reference }}
              </td>
              <!---->
              <!-- <td style="padding-left: 3px !important"></td> -->
              <!---->
            </tr>

            <!-- Fussion-->
            <tr
              *ngFor="
                let fusionReportedVariant of reportPdfRes.fusionReportedVariantList
              "
            >
              <td
                *ngIf="
                  fusionReportedVariant.significance != 'NA' &&
                  fusionReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important"
              >
                <i>{{ fusionReportedVariant.fusionGene }}</i>
              </td>
              <!---->
              <td
                *ngIf="
                  fusionReportedVariant.significance != 'NA' &&
                  fusionReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important"
              >
                {{ fusionReportedVariant.leftBreakPoint }}<br />{{
                  fusionReportedVariant.rightBreakPoint
                }}
              </td>
              <!---->
              <td
                *ngIf="
                  fusionReportedVariant.significance != 'NA' &&
                  fusionReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important"
              >
                NA
              </td>
              <!---->
              <td
                *ngIf="
                  fusionReportedVariant.significance != 'NA' &&
                  fusionReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important"
              >
                {{ fusionReportedVariant.significance }}
              </td>
              <!---->
              <td
                *ngIf="
                  fusionReportedVariant.significance != 'NA' &&
                  fusionReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important"
              >
                Gene Fusions
              </td>
              <!---->
              <td
                *ngIf="
                  fusionReportedVariant.significance != 'NA' &&
                  fusionReportedVariant.significance != 'Not Classified'
                "
                style="padding-left: 3px !important"
              >
                {{ fusionReportedVariant.reference }}
              </td>
              <!---->
            </tr>
            <!----><!---->
          </table>
          <p class="mt-3" style="font-size: 13px !important">
            <i> *NA: Not Applicable</i>
          </p>
          <!---->
        </div>
      </div>
    </div>

    <!--  -->
    <div class="row footer">
      <div class="col-12">
        <div class="pb-2 pl-4 text">
          <div class="d-block">
            <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
          </div>
          <div class="footer_body" [ngClass]="'gene-bg_blue'">
            <div class="footer_list">
              <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
              <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
              <span>Gln - Q</span><span>Gly - G</span><span>His - H</span>
              <span>Ile - I</span>
            </div>
            <div class="footer_list">
              <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
              <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
              <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
              <span>Val - V</span>
            </div>
          </div>
        </div>
        <div class="col-12 pt-1 ">
          <p style="font-size: 8px!important; line-height: 14px!important;">
            {{footerDiscription}}<br/>{{footerDiscription2}}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div
    id="page2"
    class="container position-relative page"
    style="background-color: white"
    *ngIf="
      (firstHalfCoverageFileList && firstHalfCoverageFileList.length > 0) ||
      (secondHalfCoverageFileList && secondHalfCoverageFileList.length > 0) ||
      (report.addNotes != null &&
        report.addNotes != '<p></p>' &&
        report.addNotes != '<div></div>') ||
      (reportPdfRes.reportedGenes && reportPdfRes.reportedGenes.length > 0)
    "
  >
    <div class="row header mt-1 py-4">
      <div>
        <div class="col-3">
          <img src="../../../assets/logo.png" alt="" style="width: 140px" />
        </div>
      </div>
      <div></div>
      <div class="col">
        <div class="patient-detailes ml-auto float-right">
          <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
            <li>
              Patient Id <br />
              <span>{{ report.patient.ecrfId }}</span>
            </li>
            <li class="ml-4">
              Cancer Site<br />
              <span>{{ report.cancerType }}</span>
            </li>
            <li class="ml-4">
              Sample ID <br />
              <span>{{ report.labDetails?.sampleId }}</span>
            </li>

            <li class="ml-4">
              Registration Date <br />
              <span>{{
                report.labDetails.sampleCollected | date : "dd/MM/yyyy"
              }}</span>
            </li>
          </ul>
        </div>
      </div>
      <div *ngIf="report.clientType == '4basecare_client'">
        <div class="col-2" *ngIf="report.clientName == 'oncoquest'">
          <img
            src="../../../assets/oncoquest logo.png"
            alt=""
            class="onco_logo"
          />
        </div>
        <div class="col-2" *ngIf="report.clientName == 'path_labs'">
          <img src="../../../assets/pathlab.png" alt="" class="onco_logo" />
        </div>
      </div>
      <!---->
    </div>
    <!--  -->
    <div
      class="mt-3"
      *ngIf="
        (firstHalfCoverageFileList && firstHalfCoverageFileList.length > 0) ||
        (secondHalfCoverageFileList && secondHalfCoverageFileList.length > 0)
      "
    >
      <h6 class="py-2 h6_bg ng-star-inserted">
        STATUS OF MUTATIONS IN CANCER RELATED BIOMARKERS
      </h6>
      <!---->
      <div class="row ng-star-inserted">
        <div
          class="col"
          *ngIf="
            firstHalfCoverageFileList && firstHalfCoverageFileList.length > 0
          "
        >
          <table class="page2_table gene_table">
            <thead>
              <th>Gene Name</th>
              <th>Status</th>
              <th>Coverage(%)</th>
            </thead>
            <tbody>
              <tr *ngFor="let coverageFile of firstHalfCoverageFileList">
                <td>
                  <i>{{ coverageFile.geneName }}</i>
                </td>
                <td>
                  <span
                    *ngIf="
                      coverageFile.significance != null &&
                      coverageFile.significance != 'VUS' &&
                      coverageFile.significance != 'Not Detected'
                    "
                    style="color: red"
                    ><b>{{ coverageFile.significance }}</b></span
                  >
                  <span
                    *ngIf="coverageFile.significance == 'VUS'"
                    style="color: #201dd2"
                    ><b>{{ coverageFile.significance }}</b></span
                  >
                  <span
                    *ngIf="
                      coverageFile.significance == null ||
                      coverageFile.significance == 'Not Detected'
                    "
                    >Not Detected</span
                  >
                </td>
                <td>{{ coverageFile.coveragePercent | number : "1.2-2" }}</td>
              </tr>

              <!---->
            </tbody>
          </table>
        </div>
        <div
          class="col offset-1"
          *ngIf="
            secondHalfCoverageFileList && secondHalfCoverageFileList.length > 0
          "
        >
          <table class="page2_table gene_table">
            <thead>
              <th>Gene Name</th>
              <th>Status</th>
              <th>Coverage(%)</th>
            </thead>
            <tbody>
              <tr *ngFor="let coverageFile of secondHalfCoverageFileList">
                <td>
                  <i>{{ coverageFile.geneName }}</i>
                </td>
                <td>
                  <span
                    *ngIf="
                      coverageFile.significance != null &&
                      coverageFile.significance != 'VUS'
                    "
                    style="color: red"
                    ><b>{{ coverageFile.significance }}</b></span
                  >
                  <span
                    *ngIf="coverageFile.significance == 'VUS'"
                    style="color: #201dd2"
                    ><b>{{ coverageFile.significance }}</b></span
                  >
                  <span *ngIf="coverageFile.significance == null"
                    >Not Detected</span
                  >
                </td>
                <td>{{ coverageFile.coveragePercent | number : "1.2-2" }}</td>
              </tr>

              <!---->
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!--  -->
    <div
      class="mt-5 pt-5"
      *ngIf="
        report.addNotes != null &&
        report.addNotes != '<p></p>' &&
        report.addNotes != '<div></div>'
      "
    >
      <h4 class="font-weight-bold clr-cyan uppercase">Additional Notes:</h4>
      <div
        class="py-2"
        [innerHtml]="report.addNotes"
        style="text-align: justify; font-size: 13px"
      ></div>
    </div>

    <!--  -->
    <div class="row footer">
      <div class="col-12">
        <div class="pb-2 pl-4 text">
          <div class="d-block">
            <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
          </div>
          <div class="footer_body" [ngClass]="'gene-bg_blue'">
            <div class="footer_list">
              <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
              <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
              <span>Gln - Q</span><span>Gly - G</span><span>His - H</span>
              <span>Ile - I</span>
            </div>
            <div class="footer_list">
              <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
              <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
              <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
              <span>Val - V</span>
            </div>
          </div>
        </div>
        <div class="col-12 pt-1 ">
          <p style="font-size: 8px!important; line-height: 14px!important;">
            {{footerDiscription}}<br/>{{footerDiscription2}}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div
    id="page2_11"
    class="container position-relative page"
    style="background-color: white"
    *ngIf="report.interpretation != null && report.interpretation != '<p></p>'"
  >
    <div class="row header mt-1 py-4">
      <div>
        <div class="col-3">
          <img src="../../../assets/logo.png" alt="" style="width: 140px" />
        </div>
      </div>
      <div></div>
      <div class="col">
        <div class="patient-detailes ml-auto float-right">
          <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
            <li>
              Patient Id <br />
              <span>{{ report.patient.ecrfId }}</span>
            </li>
            <li class="ml-4">
              Cancer Site<br />
              <span>{{ report.cancerType }}</span>
            </li>
            <li class="ml-4">
              Sample ID <br />
              <span>{{ report.labDetails?.sampleId }}</span>
            </li>

            <li class="ml-4">
              Registration Date <br />
              <span>{{
                report.labDetails.sampleCollected | date : "dd/MM/yyyy"
              }}</span>
            </li>
          </ul>
        </div>
      </div>
      <div *ngIf="report.clientType == '4basecare_client'">
        <div class="col-2" *ngIf="report.clientName == 'oncoquest'">
          <img
            src="../../../assets/oncoquest logo.png"
            alt=""
            class="onco_logo"
          />
        </div>
        <div class="col-2" *ngIf="report.clientName == 'path_labs'">
          <img src="../../../assets/pathlab.png" alt="" class="onco_logo" />
        </div>
      </div>
      <!---->
    </div>
    <!--  -->

    <!--  -->
    <div
      class="mt-3"
      *ngIf="
        report.interpretation != null && report.interpretation != '<p></p>'
      "
    >
      <h6 class="py-2 h6_bg">RESULT INTERPRETATION</h6>
      <div
        [innerHtml]="report.interpretation"
        class="interpretationtab remove_space"
        style="line-height: 28px; text-align: justify"
      ></div>

      <div class="row ng-star-inserted" *ngIf="report.brca1By2==true">

          <table  align="center" class="page2_11_table ">
            <thead>
              <th>Cancer type</th>
              <th>Drug</th>
            </thead>

            <tbody>
              <tr>
                <td>Breast cancer</td>
                <td>
                  <a href="https://www.fda.gov/drugs/resources-information-approved-drugs/fda-approves-olaparib-adjuvant-treatment-high-risk-early-breast-cancer">Olaparib</a>,
                  <a href="https://www.fda.gov/drugs/drug-approvals-and-databases/fda-approves-talazoparib-gbrcam-her2-negative-locally-advanced-or-metastatic-breast-cancer">Talazoparib</a>
                </td>
              </tr>
              <tr>
              <td>
                Prostate cancer
              </td>
              <td><a href="https://www.fda.gov/drugs/resources-information-approved-drugs/fda-approves-olaparib-hrr-gene-mutated-metastatic-castration-resistant-prostate-cancer">Olaparib</a>,&nbsp;
                <a href="https://www.fda.gov/drugs/resources-information-approved-drugs/fda-grants-accelerated-approval-rucaparib-brca-mutated-metastatic-castration-resistant-prostate">Rucaparib</a>
              </td>
            </tr>
            <tr>
              <td>Pancreatic Cancer</td>
              <td>
                <a href="https://www.fda.gov/drugs/resources-information-approved-drugs/fda-approves-olaparib-gbrcam-metastatic-pancreatic-adenocarcinoma">Olaparib</a>
              </td>
              </tr>
                <tr>
                  <td>Ovarian cancer</td>
                  <td><a href="https://www.fda.gov/drugs/resources-information-approved-drugs/fda-approves-niraparib-hrd-positive-advanced-ovarian-cancer">Niraparib</a>,
                    <a href="https://www.fda.gov/drugs/resources-information-approved-drugs/rucaparib">Rucaparib</a>
              </td>
            </tr>
            <tr>
              <td>Ovarian, fallopian tube or primary peritoneal cancer</td>
              <td>
                <a href="https://www.fda.gov/drugs/fda-approved-olaparib-lynparza-astrazeneca-pharmaceuticals-lp-maintenance-treatment-adult-patients">Olaparib</a>,
                <a href="https://www.fda.gov/drugs/drug-approvals-and-databases/fda-approves-olaparib-plusbevacizumab-maintenance-treatment-ovarian-fallopian-tube-or-primary">Olaparib + Bevacizumab</a>,
                <a href="https://www.fda.gov/drugs/resources-information-approved-drugs/fda-approves-rucaparib-maintenance-treatment-recurrent-ovarian-fallopian-tube-or-primary-peritoneal">Rucaparib</a>
              </td>
            </tr>
              <!---->
            </tbody>
          </table>
      </div>
    </div>

    <!--  -->
    <div class="row footer">
      <div class="col-12">
        <div class="pb-2 pl-4 text">
          <div class="d-block">
            <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
          </div>
          <div class="footer_body" [ngClass]="'gene-bg_blue'">
            <div class="footer_list">
              <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
              <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
              <span>Gln - Q</span><span>Gly - G</span><span>His - H</span>
              <span>Ile - I</span>
            </div>
            <div class="footer_list">
              <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
              <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
              <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
              <span>Val - V</span>
            </div>
          </div>
        </div>
        <div class="col-12 pt-1 ">
          <p style="font-size: 8px!important; line-height: 14px!important;">
            {{footerDiscription}}<br/>{{footerDiscription2}}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div
    id="page2"
    class="container position-relative page"
    style="background-color: white"
    *ngIf="reportPdfRes.reportedGenes && reportPdfRes.reportedGenes.length > 0"
  >
    <div class="row header mt-1 py-4">
      <div>
        <div class="col-3">
          <img src="../../../assets/logo.png" alt="" style="width: 140px" />
        </div>
      </div>
      <div></div>
      <div class="col">
        <div class="patient-detailes ml-auto float-right">
          <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
            <li>
              Patient Id <br />
              <span>{{ report.patient.ecrfId }}</span>
            </li>
            <li class="ml-4">
              Cancer Site<br />
              <span>{{ report.cancerType }}</span>
            </li>
            <li class="ml-4">
              Sample ID <br />
              <span>{{ report.labDetails?.sampleId }}</span>
            </li>

            <li class="ml-4">
              Registration Date <br />
              <span>{{
                report.labDetails.sampleCollected | date : "dd/MM/yyyy"
              }}</span>
            </li>
          </ul>
        </div>
      </div>
      <div *ngIf="report.clientType == '4basecare_client'">
        <div class="col-2" *ngIf="report.clientName == 'oncoquest'">
          <img
            src="../../../assets/oncoquest logo.png"
            alt=""
            class="onco_logo"
          />
        </div>
        <div class="col-2" *ngIf="report.clientName == 'path_labs'">
          <img src="../../../assets/pathlab.png" alt="" class="onco_logo" />
        </div>
      </div>
      <!---->
    </div>
    <!--  -->

    <!--  -->
    <div
      class="mt-3 mb-5"
      *ngIf="
        reportPdfRes.reportedGenes && reportPdfRes.reportedGenes.length > 0
      "
    >
      <h6 class="py-2 h6_bg">
        VARIANT INTERPRETATION AND CLINICAL CORRELATION
      </h6>
      <div
        *ngFor="let gene of reportPdfRes.reportedGenes | slice : 0 : 5"
        class="col-12 mb-2"
      >
        <h4
          class="py-2"
          style="color: #000; font-size: 19px !important; font-weight: 400"
        >
          <span class="font-weight-bold" style="font-size: 19px !important"
            ><i
              ><b>{{ gene.name }}</b></i
            ></span
          >
          <!-- <b>{{gene.geneName}}</b> -->
        </h4>
        <div
          [innerHtml]="gene.description"
          style="line-height: 28px; text-align: justify; font-size: 13px"
        ></div>
      </div>
    </div>

    <!--  -->
    <div class="row footer">
      <div class="col-12">
        <div class="pb-2 pl-4 text">
          <div class="d-block">
            <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
          </div>
          <div class="footer_body" [ngClass]="'gene-bg_blue'">
            <div class="footer_list">
              <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
              <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
              <span>Gln - Q</span><span>Gly - G</span><span>His - H</span>
              <span>Ile - I</span>
            </div>
            <div class="footer_list">
              <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
              <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
              <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
              <span>Val - V</span>
            </div>
          </div>
        </div>
        <div class="col-12 pt-1 ">
          <p style="font-size: 8px!important; line-height: 14px!important;">
            {{footerDiscription}}<br/>{{footerDiscription2}}
          </p>
        </div>
      </div>
    </div>
  </div>

  <div
    id="page2_1"
    class="container position-relative page"
    style="background-color: white"
    *ngIf="reportPdfRes.reportedGenes && reportPdfRes.reportedGenes.length > 5"
  >
    <div class="row header mt-1 py-4">
      <div>
        <div class="col-3">
          <img src="../../../assets/logo.png" alt="" style="width: 140px" />
        </div>
      </div>
      <div></div>
      <div class="col">
        <div class="patient-detailes ml-auto float-right">
          <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
            <li>
              Patient Id <br />
              <span>{{ report.patient.ecrfId }}</span>
            </li>
            <li class="ml-4">
              Cancer Site<br />
              <span>{{ report.cancerType }}</span>
            </li>
            <li class="ml-4">
              Sample ID <br />
              <span>{{ report.labDetails?.sampleId }}</span>
            </li>

            <li class="ml-4">
              Registration Date <br />
              <span>{{
                report.labDetails.sampleCollected | date : "dd/MM/yyyy"
              }}</span>
            </li>
          </ul>
        </div>
      </div>
      <div *ngIf="report.clientType == '4basecare_client'">
        <div class="col-2" *ngIf="report.clientName == 'oncoquest'">
          <img
            src="../../../assets/oncoquest logo.png"
            alt=""
            class="onco_logo"
          />
        </div>
        <div class="col-2" *ngIf="report.clientName == 'path_labs'">
          <img src="../../../assets/pathlab.png" alt="" class="onco_logo" />
        </div>
      </div>
      <!---->
    </div>
    <!--  -->

    <!--  -->
    <div
      class="mt-3 mb-5"
      *ngIf="
        reportPdfRes.reportedGenes && reportPdfRes.reportedGenes.length > 0
      "
    >
      <!-- <h6 class="py-2 h6_bg">
    VARIANT INTERPRETATION AND CLINICAL CORRELATION
  </h6> -->
      <div
        *ngFor="let gene of reportPdfRes.reportedGenes | slice : 5 : 10"
        class="col-12 mb-2"
      >
        <h4
          class="py-2"
          style="color: #000; font-size: 19px !important; font-weight: 400"
        >
          <span class="font-weight-bold" style="font-size: 19px !important"
            ><i
              ><b>{{ gene.name }}</b></i
            ></span
          >
          <!-- <b>{{gene.geneName}}</b> -->
        </h4>
        <div
          [innerHtml]="gene.description"
          style="line-height: 28px; text-align: justify; font-size: 13px"
        ></div>
      </div>
    </div>

    <!--  -->
    <div class="row footer">
      <div class="col-12">
        <div class="pb-2 pl-4 text">
          <div class="d-block">
            <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
          </div>
          <div class="footer_body" [ngClass]="'gene-bg_blue'">
            <div class="footer_list">
              <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
              <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
              <span>Gln - Q</span><span>Gly - G</span><span>His - H</span>
              <span>Ile - I</span>
            </div>
            <div class="footer_list">
              <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
              <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
              <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
              <span>Val - V</span>
            </div>
          </div>
        </div>
        <div class="col-12 pt-1 ">
          <p style="font-size: 8px!important; line-height: 14px!important;">
            {{footerDiscription}}<br/>{{footerDiscription2}}
          </p>
        </div>
      </div>
    </div>
  </div>
  <!--  -->

  <!-- <div
    id="page3"
    class="container position-relative page"
    *ngIf="reportPdfRes.drugsResList!=null && reportPdfRes.drugsResList!=undefined && reportPdfRes.drugsResList.length > 0"
    style="background-color: white"
  >
    <div class="row header mt-1 py-2">
      <div>
        <div class="col-3">
          <img src="../../../assets/logo.png" alt="" style="width: 140px" />
        </div>
      </div>
      <div></div>

    </div>
    <div class="mar_top"></div>
    <h6 class="py-2 h6_bg skyBlue">
      VARIANTS WITH CLINICALLY RELEVANT THERAPIES
    </h6>
    <div class="row">
      <div class="col-12">
        <table
          class="page_1 cust_table w-100 mt-3 first_2 custom-table bg_cyan"
        >
          <thead>
            <tr>
              <th>Drug Name</th>
              <th>Summary</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let drugsRes of reportPdfRes.drugsResList | slice:0:5; let i=index">
              <td>
                <ul class="ml-0 pl-2">
                  <li>
                    <i>{{drugsRes.gene}}</i> {{drugsRes.pposition}} : <br>
                      <span *ngFor="let drug of drugsRes.drugs, let i=index">
                        {{drug.name}}<span *ngIf="drugsRes.drugs.length-1 != i">,</span>
                      </span> - {{drugsRes.association}}
                      <p>{{drugsRes.cancerType}}</p>
                  </li>
                </ul>
              </td>
              <td>
                <div [innerHtml]="drugsRes.summary"></div>
              </td>
            </tr>

          </tbody>
        </table>
      </div>
    </div>
  <div class="row footer">
        <div class="col">
          <div class="py-2">
            <p>
              <span class="font-weight-bold" style="font-size: 13px !important">4baseCare (Genomics Tarang ODC),</span>
            </p>
            <p>
              SJP2-S1-1F- C wing, Wipro Limited, SEZ, Sarjapur 2, Sy.No.69 (P),
            </p>
            <p>
              Doddakannelli, Sarjapura Road,
            </p>
            <p>
              Bengaluru 560035, KA, India
            </p>
          </div>
        </div>
        <div class="col">
          <p class="text-right mt-5" style="font-size: 12px">
            <i>*Please note that this is not a medical report</i>
          </p>
        </div>
      </div>
  </div> -->
  <!--  -->
  <!-- <div
    id="page3_1"
    class="container position-relative page"
    *ngIf="reportPdfRes.drugsResList!=null && reportPdfRes.drugsResList!=undefined &&reportPdfRes.drugsResList.length > 5"
    style="background-color: white"
  >
    <div class="row header mt-1 py-2">
      <div>
        <div class="col-3">
          <img src="../../../assets/logo.png" alt="" style="width: 140px" />
        </div>
      </div>
      <div></div>

    </div>
    <div class="mar_top"></div>
    <h6 class="py-2 h6_bg skyBlue">
      VARIANTS WITH CLINICALLY RELEVANT THERAPIES
    </h6>
    <div class="row">
      <div class="col-12">
        <table
          class="page_1 cust_table w-100 mt-3 first_2 custom-table bg_cyan"
        >
          <thead>
            <tr>
              <th>Drug Name</th>
              <th>Summary</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let drugsRes of reportPdfRes.drugsResList | slice:5:10; let i=index">
              <td>
                <ul class="ml-0 pl-2">
                  <li>
                    <i>{{drugsRes.gene}}</i> {{drugsRes.pposition}} : <br>
                      <span *ngFor="let drug of drugsRes.drugs, let i=index">
                        {{drug.name}}<span *ngIf="drugsRes.drugs.length-1 != i">,</span>
                      </span> - {{drugsRes.association}}
                      <p>{{drugsRes.cancerType}}</p>
                  </li>
                </ul>
              </td>
              <td>
                <div [innerHtml]="drugsRes.summary"></div>
              </td>
            </tr>

          </tbody>
        </table>
      </div>
    </div>
  <div class="row footer">
        <div class="col">
          <div class="py-2">
            <p>
              <span class="font-weight-bold" style="font-size: 13px !important">4baseCare (Genomics Tarang ODC),</span>
            </p>
            <p>
              SJP2-S1-1F- C wing, Wipro Limited, SEZ, Sarjapur 2, Sy.No.69 (P),
            </p>
            <p>
              Doddakannelli, Sarjapura Road,
            </p>
            <p>
              Bengaluru 560035, KA, India
            </p>
          </div>
        </div>
        <div class="col">
          <p class="text-right mt-5" style="font-size: 12px">
            <i>*Please note that this is not a medical report</i>
          </p>
        </div>
      </div>
  </div> -->
  <!--  -->
  <!-- <div
  id="page3_2" *ngIf="reportPdfRes.drugsResList!=null && reportPdfRes.drugsResList!=undefined &&reportPdfRes.drugsResList.length > 10"
  class="container position-relative page"
  style="background-color: white"
>
  <div class="row header mt-1 py-2">
    <div>
      <div class="col-3">
        <img src="../../../assets/logo.png" alt="" style="width: 140px" />
      </div>
    </div>
    <div></div>

  </div>
  <div class="mar_top"></div>
  <h6 class="py-2 h6_bg skyBlue">
    VARIANTS WITH CLINICALLY RELEVANT THERAPIES
  </h6>
  <div class="row">
    <div class="col-12">
      <table
        class="page_1 cust_table w-100 mt-3 first_2 custom-table bg_cyan"
      >
        <thead>
          <tr>
            <th>Drug Name</th>
            <th>Summary</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let drugsRes of reportPdfRes.drugsResList | slice:10:15; let i=index">
            <td>
              <ul class="ml-0 pl-2">
                <li>
                  <i>{{drugsRes.gene}}</i> {{drugsRes.pposition}} : <br>
                    <span *ngFor="let drug of drugsRes.drugs, let i=index">
                      {{drug.name}}<span *ngIf="drugsRes.drugs.length-1 != i">,</span>
                    </span> - {{drugsRes.association}}
                    <p>{{drugsRes.cancerType}}</p>
                </li>
              </ul>
            </td>
            <td>
              <div [innerHtml]="drugsRes.summary"></div>
            </td>
          </tr>

        </tbody>
      </table>
    </div>
  </div>
 <div class="row footer">
        <div class="col">
          <div class="py-2">
            <p>
              <span class="font-weight-bold" style="font-size: 13px !important">4baseCare (Genomics Tarang ODC),</span>
            </p>
            <p>
              SJP2-S1-1F- C wing, Wipro Limited, SEZ, Sarjapur 2, Sy.No.69 (P),
            </p>
            <p>
              Doddakannelli, Sarjapura Road,
            </p>
            <p>
              Bengaluru 560035, KA, India
            </p>
          </div>
        </div>
        <div class="col">
          <p class="text-right mt-5" style="font-size: 12px">
            <i>*Please note that this is not a medical report</i>
          </p>
        </div>
      </div>
</div> -->
  <!-- -->
  <div
    id="page4"
    class="container position-relative page"
    style="background-color: white"
  >
    <div class="row header mt-1 py-4">
      <div>
        <div class="col-3">
          <img src="../../../assets/logo.png" alt="" style="width: 140px" />
        </div>
      </div>
      <div></div>
      <div class="col">
        <div class="patient-detailes ml-auto float-right">
          <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
            <li>
              Patient Id <br />
              <span>{{ report.patient.ecrfId }}</span>
            </li>
            <li class="ml-4">
              Cancer Site<br />
              <span>{{ report.cancerType }}</span>
            </li>
            <li class="ml-4">
              Sample ID <br />
              <span>{{ report.labDetails?.sampleId }}</span>
            </li>

            <li class="ml-4">
              Registration Date <br />
              <span>{{
                report.labDetails.sampleCollected | date : "dd/MM/yyyy"
              }}</span>
            </li>
          </ul>
        </div>
      </div>
      <div *ngIf="report.clientType == '4basecare_client'">
        <div class="col-2" *ngIf="report.clientName == 'oncoquest'">
          <img
            src="../../../assets/oncoquest logo.png"
            alt=""
            class="onco_logo"
          />
        </div>
        <div class="col-2" *ngIf="report.clientName == 'path_labs'">
          <img src="../../../assets/pathlab.png" alt="" class="onco_logo" />
        </div>
      </div>
      <!---->
    </div>
    <div class="mar_top"></div>
    <h6 class="py-2 h6_bg skyBlue">References</h6>
    <div class="row">
      <div class="col">
        <div class="disc">
          <!-- <div class="ref_data mt-4">
            <ul class="break-word" style="text-align: justify">
              <li
                *ngFor="
                  let staticreferenceArticle of reportPdfRes.staticReferenceArticles
                "
                style="
                  text-align: justify;
                  /* white-space:pre-wrap; */
                  word-break: break-word;
                "
              >
                {{ staticreferenceArticle }}
              </li>
            </ul>
          </div> -->
          <div class="ref_data mt-4">
            <ul class="break-word" style="text-align: justify">
              <li
                *ngFor="let referenceArticle of reportPdfRes.referenceArticles"
                style="
                  text-align: justify;
                  /* white-space:pre-wrap; */
                  word-break: break-word;
                "
              >
                {{ referenceArticle }}
              </li>
            </ul>
          </div>
          <!-- <ul class="ml-0 pl-4" style="text-align: justify;">
            <li>
              Bartl, T., Paspalj, V. & Grimm, C. Homologous recombination deficiency in epithelial ovarian cancer.
              memo 13, 367–370 (2020). https://doi.org/10.1007/s12254-020-00606-z
            </li>
            <li>
              Hampel, Heather, et al. "A practice guideline from the American
              College of Medical Genetics and Genomics and the National Society
              of Genetic Counselors: referral indications for cancer
              predisposition assessment." Genetics in Medicine 17.1 (2015): 70.
            </li>
            <li>
              Hoffman-Andrews, Lily. "The known unknown: the challenges of
              genetic variants of uncertain significance in clinical practice."
              Journal of Law and the Biosciences 4.3 (2017): 648.
            </li>
            <li>
              Landrum M. J. et al., ClinVar: public archive of interpretations
              of clinically relevant variants. Nucleic Acids Res.,
              44(D1):D862-8, 2015.
            </li>
            <li>
              Li, Marilyn M., et al. "Standards and guidelines for the
              interpretation and reporting of sequence variants in cancer: a
              joint consensusrecommendation of the Association for Molecular
              Pathology, American Society of Clinical Oncology, and College of
              American Pathologists." The Journal of molecular diagnostics 19.1
              (2017): 4-23.
            </li>
            <li>
              Nykamp, K., Anderson, M., Powers, M., Garcia, J., Herrera, B., Ho,
              Y. Y., Topper, S. (2017). Sherloc: a comprehensive refinement of
              the ACMG-AMP variant classification criteria. Genetics in
              medicine: official journal of the American College of Medical
              Genetics, 19(10), 1105–1117. doi:10.1038/gim.2017.37
            </li>
            <li>
              Richards S. et al. Standards and Guidelines for Interpretation of
              Sequence Variants: A joint consensus recommendation of the
              American College of Medical Genetics and Genomics and Association
              for Molecular Pathology. Genetics in Medicine (2015); 17: 405- 423
            </li>
            <li>
              Spratt, Daniel E., et al. "Racial/ethnic disparities in genomic
              sequencing." JAMA oncology 2.8 (2016): 1070-1074
            </li>
            <li>
              The AACR Project GENIE Consortium. AACR Project GENIE: powering
              precision medicine through an international consortium. Cancer
              Discovery. 2017;7(8):818-831.
            </li>
            <li>
              The UniProt Consortium. UniProt: a worldwide hub of protein
              knowledge. Nucleic Acids Research. 2019;47: D506-D515
            </li>
            <li>Zhou P, Wang J, Mishail D, Wang CY. Recent advancements in PARP inhibitors-based targeted cancer therapy.
               Precis Clin Med. 2020 Sep;3(3):187-201. doi: 10.1093/pcmedi/pbaa030. Epub 2020 Aug 31. PMID: 32983586; PMCID: PMC7501589.</li>
          </ul> -->
        </div>

      </div>
    </div>
    <div class="img_signs above-footer">
      <div class="row justify-content-between align-items-center">
        <div class="col-4 text-center">
          <p style="
                text-align: center;

                font-size: 15px !important;
              ">(Electronically signed by)
          </p>
          <p style="
                  text-align: center;
                  font-weight: bolder;
                  font-size: 15px !important;
                ">
            Vidya H Veldore, PhD<br />Vice President - Clinical Operations
          </p>
        </div>
        <div class="col-4 text-center">            <p style="text-align: center; font-size: 15px !important;">
            (Electronically signed by)</p>
            <p style="
            text-align: center;
            font-weight: bolder;
            font-size: 15px !important;
          ">
          Dr. Jinumary Aji John<br />Head - Clinical Reporting </p>
        </div>
        <div class="col-4 text-center">            <p style="
            text-align: center;

            font-size: 15px !important;
          ">(Electronically signed by)
          </p>
          <p style="
                  text-align: center;
                  font-weight: bolder;
                  font-size: 15px !important;
                ">
            Vyomesh Javle<br />Head - Clinical Bioinformatics
          </p>
        </div>
      </div>
    </div>
    <!--  -->
    <div class="row footer">
      <div class="col-12">
        <div class="pb-2 pl-4 text">
          <div class="d-block">
            <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
          </div>
          <div class="footer_body" [ngClass]="'gene-bg_blue'">
            <div class="footer_list">
              <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
              <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
              <span>Gln - Q</span><span>Gly - G</span><span>His - H</span>
              <span>Ile - I</span>
            </div>
            <div class="footer_list">
              <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
              <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
              <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
              <span>Val - V</span>
            </div>
          </div>
        </div>
        <div class="col-12 pt-1 ">
          <p style="font-size: 8px!important; line-height: 14px!important;">
            {{footerDiscription}}<br/>{{footerDiscription2}}
          </p>
        </div>
      </div>
    </div>
  </div>
  <!--  -->

  <div
    id="page4_1"
    class="container position-relative page"
    style="background-color: white"
  >
    <div class="row header mt-1 py-4">
      <div>
        <div class="col-3">
          <img src="../../../assets/logo.png" alt="" style="width: 140px" />
        </div>
      </div>
      <div></div>
      <div class="col">
        <div class="patient-detailes ml-auto float-right">
          <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
            <li>
              Patient Id <br />
              <span>{{ report.patient.ecrfId }}</span>
            </li>
            <li class="ml-4">
              Cancer Site<br />
              <span>{{ report.cancerType }}</span>
            </li>
            <li class="ml-4">
              Sample ID <br />
              <span>{{ report.labDetails?.sampleId }}</span>
            </li>

            <li class="ml-4">
              Registration Date <br />
              <span>{{
                report.labDetails.sampleCollected | date : "dd/MM/yyyy"
              }}</span>
            </li>
          </ul>
        </div>
      </div>
      <div *ngIf="report.clientType == '4basecare_client'">
        <div class="col-2" *ngIf="report.clientName == 'oncoquest'">
          <img
            src="../../../assets/oncoquest logo.png"
            alt=""
            class="onco_logo"
          />
        </div>
        <div class="col-2" *ngIf="report.clientName == 'path_labs'">
          <img src="../../../assets/pathlab.png" alt="" class="onco_logo" />
        </div>
      </div>
      <!---->
    </div>
    <div class="mar_top"></div>
    <div>
      <h6 class="py-2 h6_bg skyBlue mt-4">TEST DESCRIPTION</h6>
      <p class="mb-2" style="text-align: justify">
        HRR test is a gene panel-based screening of 15 HRR genes associated with
        different cancers collated from published scientific literature.
        Homologous Recombination and Repair (HRR) is the ability to adequately
        repair DNA double-strand breaks (DSBs) relies on HR repair, which
        reconstructs damaged DNA by copying the respective undamaged strand from
        the homologous sister chromatid. A complex set of proteins are required
        to interact within this process, including the gene products of BRCA1/2
        and several others (Bartl T. et al., 2020; Zhou P. et al., 2020).
      </p>
      <p class="mb-2" style="text-align: justify">
        We, at 4baseCare, strive to ensure that every patient and their
        respective family members are made accessible to all possible
        information without breaching the patient’s confidentiality.
      </p>
    </div>
    <!--  -->
    <h6 class="py-2 h6_bg skyBlue mt-4">
      HOMOLOGOUS RECOMBINATION REPAIR PATHWAY
    </h6>
    <!--  -->
    <div class="row">
      <div class="col-12 d-flex justify-content-center">
        <img
          src="../../../assets/hrr pathway images.png"
          alt=""
          style="height: 500px; width: 60%"
        />
      </div>
      <!-- <div class="col">
      <ul class="mt-5">
        <li>
          Genomic alteration in <i class="clr-cyan">BRCA</i> and
          <i class="clr-cyan">ATM</i> gene
        </li>
        <li>
          Pathway driving cancer
          <b>Homologous Recombination Repair pathway</b>
        </li>
        <li>Therapies targeting pathway <b>PARP inhibitors</b></li>
      </ul>
    </div> -->
    </div>
    <!--  -->
    <div class="row footer">
      <div class="col-12">
        <div class="pb-2 pl-4 text">
          <div class="d-block">
            <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
          </div>
          <div class="footer_body" [ngClass]="'gene-bg_blue'">
            <div class="footer_list">
              <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
              <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
              <span>Gln - Q</span><span>Gly - G</span><span>His - H</span>
              <span>Ile - I</span>
            </div>
            <div class="footer_list">
              <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
              <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
              <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
              <span>Val - V</span>
            </div>
          </div>
        </div>
        <div class="col-12 pt-1 ">
          <p style="font-size: 8px!important; line-height: 14px!important;">
            {{footerDiscription}}<br/>{{footerDiscription2}}
          </p>
        </div>
      </div>
    </div>
  </div>
  <!-- <div
    id="page4"
    class="container position-relative page"
    style="background-color: white"
  >
    <div class="row header mt-1">
      <div>
        <div class="col-3">
          <img src="../../../assets/logo.png" alt="" style="width: 140px" />
        </div>
      </div>
      <div></div>

    </div>
    <div class="mar_top"></div>

    <div>
      <h6 class="py-2 h6_bg skyBlue mt-4">
        CLINICAL SIGNIFICANCE OF HRR TESTING IN BREAST, OVARIAN, PANCREATIC AND
        PROSTATE CANCERS
      </h6>
      <p class="mb-4">
        BRCA-mutant cancer cells have abnormal Homologous Recombination (HR)
        repair of DNA. In these tumors, the Base Excision Repair (BER) pathway
        is important for cell survival. The Poly (ADP-ribose) polymerase (PARP)
        enzymes play a key role in BER, and PARP inhibitors (PARPi) are
        effective in causing cell death in BRCA-mutant cells while sparing
        normal cells-a concept called synthetic lethality.
      </p>
      <p class="mb-4">
        Recent clinical trials in BRCA mutant metastatic breast cancer have
        demonstrated improved outcomes with single agent PARPi’s (olaparib and
        talazoparib) over chemotherapy. Treatment of BRCA-mutant tumors with a
        PARP inhibitor (PARPi) leads to accumula- tion of DNA damage resulting
        in cell cycle arrest and apoptosis. This effect of PARPi’s in cells with
        defects in the HR pathway is an example of synthetic lethality.
      </p>
      <p class="mb-4">
        On January 12, 2018, the Food and Drug Administration granted regular
        approval to olaparib tablets (Lynparza), a poly (ADP-ribose) polymerase
        (PARP) inhibitor, for the treatment for patients with deleterious or
        suspected deleterious germline BRCA-mutated (gBRCAm), HER2-negative
        metastatic breast cancer who have been treated with chemotherapy either
        in the neoadjuvant, adjuvant, or metastatic setting.<br />
        <a href=""
          ><i
            >https://www.fda.gov/drugs/resources-information-approved-drugs/fda-approves-olaparib-germline
            BRCA-mutated-metastatic-breast-cancer</i
          ></a
        >
      </p>
      <p class="mb-4">
        As per a recent study on High Grade Serous Ovarian Carcinomas (HGOAC),
        those patients with Germline/Somatic HRR mutations had shown increased
        sensitivity to platinum drugs as compared those without HRR mutations
        (Pennington et al. 2014). In another study on triple negative breast
        cancer patients, it was observed that patients with HR deficiency could
        achieve pCR, as compared to those who were HR non-deficient (Telli et
        al. 2018). Clinical trial of ARIEL3 studies have shown that PARP
        inhibitor maintenance is associated with higher PFS benefits in BRCA
        mutated as well as HR deficient subgroups of recurrent
        platinum-sensitive high-grade serous or endometrioid ovarian, primary
        peritoneal, or fallopian tube carcinoma. This study also demonstrated
        that Rucaparib maintenance treatment significantly improved PFS of HRR
        deficient patients across all the cancers mentioned above (Coleman et.
        al. 2017).<br />
        <a href="#"
          ><i
            >https://www.fda.gov/drugs/resources-information-approved-drugs/niraparib-zejula
            (last viewed on 29th October 2019</i
          ></a
        >
      </p>
      <p class="mb-4">
        PARP inhibitor is also approved as maintenance treatment of adult
        patients with deleterious or suspected deleterious gBRCAm metastatic
        pancreatic adenocarcinoma whose disease has not progressed on at least
        16 weeks of a first-line platinum-based chemo- therapy regimen.<br />
        <a href="#"
          ><i
            >https://www.fda.gov/drugs/resources-information-approved-drugs/fda-approves-olaparib-gbrcam
            metastatic-pancreatic-adenocarcinoma</i
          ></a
        >
      </p>
      <p class="mb-4">
        PARP inhibitor is also approved in treatment of adult patients with
        deleterious or suspected deleterious germline or somatic homolo- gous
        recombination repair (HRR) gene-mutated metastatic castration resistant
        prostate cancer (mCRPC) who have progressed following prior treatment
        with enzalutamide or abiraterone.<br />
        <a href="#">
          <i>
            https://www.fda.gov/drugs/resources-information-approved-drugs/fda-approves-olaparib-hrr-gene
            mutated-metastatic-castration-resis- tant-prostate-cancer
          </i>
        </a>
      </p>
    </div>


  <div class="row footer">
        <div class="col">
          <div class="py-2">
            <p>
              <span class="font-weight-bold" style="font-size: 13px !important">4baseCare (Genomics Tarang ODC),</span>
            </p>
            <p>
              SJP2-S1-1F- C wing, Wipro Limited, SEZ, Sarjapur 2, Sy.No.69 (P),
            </p>
            <p>
              Doddakannelli, Sarjapura Road,
            </p>
            <p>
              Bengaluru 560035, KA, India
            </p>
          </div>
        </div>
        <div class="col">
          <p class="text-right mt-5" style="font-size: 12px">
            <i>*Please note that this is not a medical report</i>
          </p>
        </div>
      </div>
  </div> -->
  <!--  -->
  <div
    id="page4"
    class="container position-relative page"
    style="background-color: white"
  >
    <div class="row header mt-1 py-4">
      <div>
        <div class="col-3">
          <img src="../../../assets/logo.png" alt="" style="width: 140px" />
        </div>
      </div>
      <div></div>
      <div class="col">
        <div class="patient-detailes ml-auto float-right">
          <ul class="list-unstyled d-flex pt-3 text-uppercase patinet_info">
            <li>
              Patient Id <br />
              <span>{{ report.patient.ecrfId }}</span>
            </li>
            <li class="ml-4">
              Cancer Site<br />
              <span>{{ report.cancerType }}</span>
            </li>
            <li class="ml-4">
              Sample ID <br />
              <span>{{ report.labDetails?.sampleId }}</span>
            </li>

            <li class="ml-4">
              Registration Date <br />
              <span>{{
                report.labDetails.sampleCollected | date : "dd/MM/yyyy"
              }}</span>
            </li>
          </ul>
        </div>
      </div>
      <div *ngIf="report.clientType == '4basecare_client'">
        <div class="col-2" *ngIf="report.clientName == 'oncoquest'">
          <img
            src="../../../assets/oncoquest logo.png"
            alt=""
            class="onco_logo"
          />
        </div>
        <div class="col-2" *ngIf="report.clientName == 'path_labs'">
          <img src="../../../assets/pathlab.png" alt="" class="onco_logo" />
        </div>
      </div>
      <!---->
    </div>
    <div class="mar_top"></div>

    <div>
      <h6 class="py-2 h6_bg skyBlue mt-4">TEST METHODOLOGY</h6>
      <div class="mt-4">
        <h6 class="font-weight-bold">
          Sample preparation and Library preparation :
        </h6>
        <p class="mb-4" style="text-align: justify">
          DNA isolated from FFPE, or any other fresh tumor tissue source was
          used to perform targeted gene capture using a custom capture kit. The
          libraries were sequenced to mean >350X coverage on Illumina sequencing
          platform.
        </p>
      </div>

      <div class="mt-4">
        <h6 class="font-weight-bold">
          Bioinformatics Analysis and Reporting :
        </h6>
        <p class="mb-4" style="text-align: justify">
          The sequences obtained are aligned to human reference genome
          (GRCh37/hg19) and variant analysis was performed using set of
          Bioinformatics Pipeline. Only non-synonymous and splice site variants
          found in the clinical exome panel consisting of specific set of genes
          were used for clinical interpretation. Silent variations that do not
          result in any change in amino acid in the coding region are not
          reported.<br />

          Clinically relevant mutations were annotated using published variants
          in literature and a set of databases – ClinVar, COSMIC and dbSNP.
          Common variants are filtered based on allele frequency in 1000 Genome
          Phase 3, ExAC, dbSNP, gnomAD, etc. In the absence of a clinically
          significant reported known variation(s), pathogenicity will be
          predicted based on in-silico gene prioritization tools: CADD, SIFT,
          PolyPhen-2, Condel and Mutation taster and prioritized for clinical
          correlation. The identified pathogenic variant will be correlated with
          observed phenotypic features of the patient and interpreted according to AMP/ASCO/ACMG/CAP guidelines.
        </p>
        <!-- <p class="mb-4">
          Clinically relevant mutations were annotated using published variants
          in literature and a set of databases – ClinVar, COSMIC and dbSNP.
          Common variants are filtered based on allele frequency in 1000 Genome
          Phase 3, ExAC, dbSNP, gnomAD, etc. In the absence of a clinically
          significant reported known variation(s), pathogenicity will be
          predicted based on in-silico gene prioritization tools: CADD, SIFT,
          PolyPhen-2, Condel and Mutation taster and prioritized for clinical
          correlation. The identified pathogenic variant will be correlated with
          observed phenotypic features of the patient and interpreted according
          to American College of Medical Genetics (ACMG) guidelines.
        </p> -->
      </div>
      <!--  -->
      <div class="mt-5">
        <h6 class="py-2 h6_bg skyBlue mt-4">LIMITATIONS AND DISCLAIMER</h6>
        <ul class="pl-0 ml-3" style="text-align: justify">
          <li>
            DNA studies do not constitute a definitive test for the selected
            condition(s) in all individuals. It should be realized that there
            are possible sources of error. Errors can result from trace
            contamination, rare technical errors, rare genetic variants that
            interfere with analysis, recent scientific developments, and
            alternative classification systems. This test should be one of the
            many aspects used by the healthcare provider to help with a
            diagnosis and treatment plan.
          </li>
          <li>
            We are using the canonical transcript for clinical reporting which
            is usually the longest coding transcript with strong/multiple
            supporting evidence. However, in rare cases, clinically relevant
            variants annotated in alternate complete coding transcripts could
            also be reported.
          </li>
          <li>
            The contents of this test should be carefully assessed by the
            treating physician and further interpreted along with clinical,
            histopathological findings, contraindications, and guidelines before
            deciding the course of therapy.
          </li>
          <!-- <li>
            The chromosomal aberrations like copy number variations and
            rearrangements may not be reliably detected with this assay and must
            be confirmed by alternate method.
          </li> -->
          <li>
            Our limit of detection for HRR Somatic is 5% for SNVs, 10% for
            InDels and CNV gain ≥ 6. In addition to this, sequencing quality and
            coverage is dependent on many factors such as homopolymers, GC-rich
            regions, intrinsic quality of FFPE DNA and that might impact the
            variant detection.
          </li>
          <li>
            The CNVs detected must be confirmed by an alternate method, such as IHC, for further clinical management decisions.
            <!-- The sensitivity of this assay to detect large deletions/duplications
            of more than 10 bp or copy number variations (CNV) is 70-75%. The
            CNVs detected must be confirmed by alternate method -->
          </li>
          <li>
            Most recent block is recommended for testing as the mutation profile
            may change in response to treatment and hence differ at different
            sampling points.
          </li>
          <li>
            HRR Somatic test has been developed, validated, and performed by
            4baseCare Genomics Pvt. Ltd., and this test has not been cleared or
            approved by the FDA.
          </li>
          <li>
            Large deletions of more than 10 bp or copy number variations
            /chromosomal rearrangements cannot be assessed using this method.
          </li>
          <li>
            The identified pathogenic variant will be correlated with observed
            phenotypic features of the patient and interpreted according to
            (ASCO) guidelines.
          </li>
          <li>
            Certain genes may not be covered completely, and few mutations could
            be missed. A negative result cannot rule out the possibility that
            the tested tumor sample carries mutations not previously associated
            with cancer and hence not included in the panel.
          </li>
        </ul>
      </div>
    </div>

    <!--  -->
    <div class="row footer">
      <div class="col-12">
        <div class="pb-2 pl-4 text">
          <div class="d-block">
            <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
          </div>
          <div class="footer_body" [ngClass]="'gene-bg_blue'">
            <div class="footer_list">
              <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
              <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
              <span>Gln - Q</span><span>Gly - G</span><span>His - H</span>
              <span>Ile - I</span>
            </div>
            <div class="footer_list">
              <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
              <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
              <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
              <span>Val - V</span>
            </div>
          </div>
        </div>
        <div class="col-12 pt-1 ">
          <p style="font-size: 8px!important; line-height: 14px!important;">
            {{footerDiscription}}<br/>{{footerDiscription2}}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
