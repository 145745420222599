import { Component, OnInit } from '@angular/core';
import jspdf from "jspdf";
import html2canvas from "html2canvas";
import { ActivatedRoute } from '@angular/router';
import { ReportService } from 'src/app/_services/report.service';
import { CNVReportData, ReportPdfRes, ReportRes, RequestData } from 'src/app/_interfaces.ts/reports';
import { Title } from '@angular/platform-browser';
import { Drug, Gene } from 'src/app/_interfaces.ts/admin';
import { FilteresCSV } from 'src/app/_interfaces.ts/excel';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-absolute-report',
  templateUrl: './absolute-report.component.html',
  styleUrls: ['./absolute-report.component.css']
})
export class AbsoluteReportComponent implements OnInit {

  footerDiscription ="All the 4baseCare test samples are processed at 4baseCare Offshore Development Centre (ODC) - "+
                "Genomics lab inside Wipro campus in Sarjapur. This lab has been accredited by for NABL: ISO 15109:2012 "+
                "for the lab operations at the address WIPRO LIFE SCIENCES LAB, WIPRO LIMITED, SY. NO 69P,71/4P,70/BAP,134P, "+
                "76P,77P,80P,70P, 79/1P, UNIT 1, SARJAPUR ROAD, DODDAKANNELLI VILLAGE, VARTHUR HOBLI, BENGALURU, KARNATAKA, "+
                "INDIA having NABL 15189 certificate number MC-5155 and test is covered under the scope of this NABL certification."
  footerDiscription2 = "4baseCare (Genomics Tarang ODC), SJP2-S1-1F-C wing, Wipro Limited, SEZ, Sarjapur 2, SyNo.69 (P), Doddakannelli, "+
                "Sarjapura Road, Bengaluru 560035, KA, India"

  reportId!: number;
  reportPdfRes = {} as ReportPdfRes;
  report = {} as ReportRes;
  statusMsg!: string;
  tmb!: number;
  msi!: number;
  date = Date();
  downloadSpinner = false;
  pdl1Images: string[] = [];
  reportData = false;
  totalCnaDrugs = 0;

  snvData: RequestData[] = [];
  cnaData: RequestData[] = []
  fusionData: RequestData[] = [];
  constructor(
    private reportService: ReportService,
    private routerAct: ActivatedRoute,
    private toastr: ToastrService,
    private titleService: Title
  ) { }

  sampleSource!: string;
  ngOnInit(): void {
    this.titleService.setTitle("view report");
    this.reportId = this.routerAct.snapshot.params['id'];
    if (this.reportId) {
      this.getReport();
    }
  }


  data: any;


  evidenceSummary!: string;
  interpretation!: string;

  showSuccess() {
    this.toastr.success(this.statusMsg, '',
      { timeOut: 2000 }
    );
  }
  showFail() {
    this.toastr.error(this.statusMsg, '',
      { timeOut: 10000 }
    );
  }

  setPage1Table(reportPdfRes: ReportPdfRes) {
    // Snv
    if (reportPdfRes.starClinicalTrailList != null) {
      for (let i = 0; i < reportPdfRes.starClinicalTrailList.length; i++) {
        if (reportPdfRes.starClinicalTrailList[i].nctDrugList != null) {
          // console.log("SNV NCT ",reportPdfRes.starClinicalTrailList[i].nctDrugList.length)

          for (let j = 0; j < reportPdfRes.starClinicalTrailList[i].nctDrugList.length; j++) {
            let requestedData = {} as RequestData;
            requestedData.association = reportPdfRes.starClinicalTrailList[i].nctAssociationList[j];
            requestedData.evidance = reportPdfRes.starClinicalTrailList[i].nctEvidenceList[j];
            let drugs: string[] = [];
            reportPdfRes.starClinicalTrailList[i].nctDrugList[j].forEach(
              drug => {
                drugs.push(drug)
              });
            requestedData.drugName = drugs.toString();
            this.snvData.push(requestedData);
            // console.log("requestedData==\n",requestedData)
          }
        }
        if (reportPdfRes.starClinicalTrailList[i].drugList != null) {
          // console.log("SNV Star",reportPdfRes.starClinicalTrailList[i].drugList);
          for (let j = 0; j < reportPdfRes.starClinicalTrailList[i].drugList.length; j++) {
            let requestData = {} as RequestData;
            requestData.association = reportPdfRes.starClinicalTrailList[i].associationList[j];
            requestData.evidance = reportPdfRes.starClinicalTrailList[i].evidenceList[j];
            let drugs: string[] = [];
            reportPdfRes.starClinicalTrailList[i].drugList[j].forEach(
              drug => {
                drugs.push(drug.name)
              });
            requestData.drugName = drugs.toString();
            this.snvData.push(requestData);
            // console.log("requestedData==\n",requestData)
          }
        }
        // console.log("NCT Star selected SNV List==(this.snvNCTRequestedData)\n",this.snvNCTRequestedData);
        reportPdfRes.starClinicalTrailList[i].requriedData = this.snvData;
        console.log("SNV Nct data\n", reportPdfRes.starClinicalTrailList[i].requriedData);
        this.snvData = [];


      }
    }


    if (reportPdfRes.cnaStarClinicalTrailList != null) {
      for (let i = 0; i < reportPdfRes.cnaStarClinicalTrailList.length; i++) {
        if (reportPdfRes.cnaStarClinicalTrailList[i].nctDrugList != null) {
          // console.log("CNA NCT",reportPdfRes.cnaStarClinicalTrailList[i].nctDrugList.length)
          for (let j = 0; j < reportPdfRes.cnaStarClinicalTrailList[i].nctDrugList.length; j++) {
            let requestData = {} as RequestData;
            requestData.association = reportPdfRes.cnaStarClinicalTrailList[i].nctAssociationList[j];
            requestData.evidance = reportPdfRes.cnaStarClinicalTrailList[i].nctEvidenceList[j];
            let drugs: string[] = [];
            reportPdfRes.cnaStarClinicalTrailList[i].nctDrugList[j].forEach(
              drug => {
                drugs.push(drug)
              });
            requestData.drugName = drugs.toString();
            this.cnaData.push(requestData);
            console.log("requestedData==\n", requestData)
          }
        }
        if (reportPdfRes.cnaStarClinicalTrailList[i].drugList != null) {
          // console.log("CNA NCT",reportPdfRes.cnaStarClinicalTrailList[i].drugList.length)
          for (let j = 0; j < reportPdfRes.cnaStarClinicalTrailList[i].drugList.length; j++) {
            let requestData = {} as RequestData;
            requestData.association = reportPdfRes.cnaStarClinicalTrailList[i].associationList[j];
            requestData.evidance = reportPdfRes.cnaStarClinicalTrailList[i].evidenceList[j];
            let drugs: string[] = [];
            reportPdfRes.cnaStarClinicalTrailList[i].drugList[j].forEach(
              drug => {
                drugs.push(drug.name)
              });
            requestData.drugName = drugs.toString();
            this.cnaData.push(requestData);
            // console.log("requestedData==\n",requestData)
          }
        }
        // console.log("NCT Star selected SNV List==(this.snvNCTRequestedData)\n",this.cnaNCTRequestedData);
        reportPdfRes.cnaStarClinicalTrailList[i].requriedData = this.cnaData;
        console.log("CNA NCT data\n", reportPdfRes.cnaStarClinicalTrailList[i].requriedData);
        this.cnaData = [];
      }
    }
    if (reportPdfRes.fusionStarClinicalTrailList != null) {
      for (let i = 0; i < reportPdfRes.fusionStarClinicalTrailList.length; i++) {
        if (reportPdfRes.fusionStarClinicalTrailList[i].nctDrugList != null) {
          console.log("CNA NCT", reportPdfRes.fusionStarClinicalTrailList[i].nctDrugList.length)
          for (let j = 0; j < reportPdfRes.fusionStarClinicalTrailList[i].nctDrugList.length; j++) {
            let requestData = {} as RequestData;
            requestData.association = reportPdfRes.fusionStarClinicalTrailList[i].nctAssociationList[j];
            requestData.evidance = reportPdfRes.fusionStarClinicalTrailList[i].nctEvidenceList[j];
            let drugs: string[] = [];
            reportPdfRes.fusionStarClinicalTrailList[i].nctDrugList[j].forEach(
              drug => {
                drugs.push(drug)
              });
            requestData.drugName = drugs.toString();
            this.fusionData.push(requestData);
            // console.log("requestedData==\n",requestData)
          }
        }
        if (reportPdfRes.fusionStarClinicalTrailList[i].drugList != null) {
          // console.log("CNA NCT",reportPdfRes.fusionStarClinicalTrailList[i].drugList.length)
          for (let j = 0; j < reportPdfRes.fusionStarClinicalTrailList[i].drugList.length; j++) {
            let requestData = {} as RequestData;
            requestData.association = reportPdfRes.fusionStarClinicalTrailList[i].associationList[j];
            requestData.evidance = reportPdfRes.fusionStarClinicalTrailList[i].evidenceList[j];
            let drugs: string[] = [];
            reportPdfRes.fusionStarClinicalTrailList[i].drugList[j].forEach(
              drug => {
                drugs.push(drug.name)
              });
            requestData.drugName = drugs.toString();
            this.fusionData.push(requestData);
            // console.log("requestedData==\n",requestData)
          }
        }
        // console.log("NCT Star selected SNV List==(this.snvNCTRequestedData)\n",this.fusionNCTRequestedData);
        reportPdfRes.fusionStarClinicalTrailList[i].requriedData = this.fusionData;
        console.log("Fusion NCT data\n", reportPdfRes.fusionStarClinicalTrailList[i].requriedData);
        this.fusionData = [];


      }
    }
  }
  interpretation0:any;
  interpretation1:any;
  referringClinician!:string;
  getReport() {

    this.reportService.getPdfReportRes(this.reportId).subscribe(
      (response: any) => {
        this.reportData = true;
        this.reportPdfRes = response['payLoad'];
        this.report = response['payLoad']['report'];
        this.referringClinician=this.report?.labDetails?.referringClinician?.replace(/,/g, ', ');
        this.statusMsg = response['message'];
        this.showSuccess();
        this.setPage1Table(this.reportPdfRes);

        console.log("getPdfReportRes", this.reportPdfRes);
        this.titleService.setTitle(this.report.patient.ecrfId + " - report");
        this.drugDescriptionReplace(this.reportPdfRes.drugDetails);
        this.geneDescriptionReplace(this.reportPdfRes.genes);
        this.selectedReportedVariantListReplace(this.reportPdfRes.selectedReportedVariantList);

        if (this.report.immunoDetails?.pdl1Status == 'EXPRESSION') {
          this.pdl1Expression(this.reportPdfRes);
        }

        this.page67Table(this.reportPdfRes);
        // let re = /\n/gi;
        if(this.report.evidenceSummary!=null && this.report.evidenceSummary!=""){
          this.evidenceSummary = this.report.evidenceSummary;
          this.evidenceSummary = this.evidenceSummary.replace(/<p>/g, '<div>');
          this.evidenceSummary = this.evidenceSummary.replace(/<\/p>/g, '</div>');
          this.evidenceSummary = this.evidenceSummary.replace(/{/g, '<sup>');
          this.evidenceSummary = this.evidenceSummary.replace(/}/g, '</sup>');
          this.evidenceSummary = this.evidenceSummary.replace(/~/g, '<sub>');
          this.evidenceSummary = this.evidenceSummary.replace(/!/g, '</sub>');
        }
        // console.log("Evidnece summary-->>",this.evidenceSummary);
        if (this.report.interpretation != null) {
          this.interpretation = this.report.interpretation;
          this.interpretation = this.interpretation.replace(/<p>/g, '<div>');
          this.interpretation = this.interpretation.replace(/<\/p>/g, '</div>');
          this.interpretation = this.interpretation.replace(/{/g, '<sup>');
          this.interpretation = this.interpretation.replace(/}/g, '</sup>');
          this.interpretation = this.interpretation.replace(/~/g, '<sub>');
          this.interpretation = this.interpretation.replace(/!/g, '</sub>');
          if(this.interpretation.length < 5500){
          this.interpretation0=this.interpretation;
        }
          if (this.interpretation.length > 5500){
            this.interpretation0=this.interpretation.substring(0,5500);
          this.interpretation1=this.interpretation.substring(5500,11000)
        }
          // console.log("Interpretation===> Split \n",this.interpretation.split("1")[100]);
        }

        if(this.reportPdfRes.referenceArticles!=null){
          for(let i=0;i<this.reportPdfRes.referenceArticles.length;i++){
            this.reportPdfRes.referenceArticles[i]=this.reportPdfRes.referenceArticles[i]?.replace(/-/g,' ');
          }
        }

        if (this.report.immunoDetails != null) {
          this.tmb = this.report.immunoDetails.tmb;
          this.msi = this.report.immunoDetails.msi;
          this.report.immunoDetails.pdl1Images.forEach(elem => {
            this.getimage(elem).then(res => {
              //console.log(res);

              this.getbase64(res).then(response => {
                //  console.log(response)
                this.pdl1Images.push(response);
                // console.log(this.pdl1Images);

              })
            })
          });
        }
        if (this.report.labDetails != null) {
          // console.log("sampleScorce-->",this.report.labDetails.sampleSource)
          this.sampleSource = this.report.labDetails?.sampleSource.replace(/FFPE BlockId:/g, '');
        }
        if (this.report.referenceWebsites != '<p></p>' || this.report.referenceWebsites != null) {
          this.report.referenceWebsites = this.report.referenceWebsites?.replace(/<ul>/g, '');
          this.report.referenceWebsites = this.report.referenceWebsites?.replace(/<\/ul>/g, '');
        }

        if (this.reportPdfRes.pathwayDtoList.length > 0) {
          this.reportPdfRes.pathwayDtoList.forEach(pathway => {
            console.log("pathway.imageUrl==>",pathway.imageUrl)
            this.getimage(pathway.imageUrl).then(res => {
              //console.log(res);

              this.getbase64(res).then(response => {
                //  console.log(response)
                // this.pdl1Images.push(response);
                pathway.imageUrl = response
                // console.log(this.pdl1Images);

              })
            })
          });
        }

        this.getExonNumbers();
      },
      error => {
        console.log('oops...!', error)
        this.statusMsg = error.error.error;
        this.showFail();
      }
    );

  }

  countNA: number = 0;
  countOthers: number = 0;
  snvlength: number = 0;
  selectedReportedVariantListReplace(selctedvarient: FilteresCSV[]) {
    // console.log("--> Actual Length Slected Varients: ",selctedvarient.length);
    for (var i = 0; i < selctedvarient.length; i++) {
      this.snvlength = selctedvarient.length;
      if (selctedvarient[i].significance == 'NA' || selctedvarient[i].significance =='Not Classified') { this.countNA += 1; }
      else { this.countOthers += 1; }
    }
    // console.log("--> Count of 'NA' : ",this.countNA);
    // console.log("--> Count of 'OTHERS' : ",this.countOthers);
  }
  page1GeneDescriptioLlength: number = 0;
  geneDescriptionLength: number = 0;
  page2GeneDescriptioLlength: number = 0;

  geneDescriptionReplace(gene: Gene[]) {
    for (var i = 0; i < gene.length; i++) {
      // console.log("--> Gene Name : ",gene[i].name);
      // console.log("--> Gene Description : ",gene[i].description);
      if (gene[i].description != null) {
        gene[i].description = gene[i].description.replace(/<p>/g, '<div>');
        gene[i].description = gene[i].description.replace(/<\/p>/g, '</div>');
        gene[i].description = gene[i].description.replace(/{/g, '<sup>');
        gene[i].description = gene[i].description.replace(/}/g, '</sup>');
        gene[i].description = gene[i].description.replace(/~/g, '<sub>');
        gene[i].description = gene[i].description.replace(/!/g, '</sub>');
        // console.log("--> Gene REPLACED Description : ",gene[i].description);
      }
      // console.log("_________________________________");
    }
  }

  drugDescriptionReplace(drug: Drug[]) {
    for (var i = 0; i < drug.length; i++) {
      // console.log("--> Drug Name : ",drug[1].name);
      // console.log("--> Drug Description : ",drug[i].description);
      if (drug[i].description != null) {
        drug[i].description = drug[i].description.replace(/<p>/g, '<div>');
        drug[i].description = drug[i].description.replace(/<\/p>/g, '</div>');
        drug[i].description = drug[i].description.replace(/{/g, '<sup>');
        drug[i].description = drug[i].description.replace(/}/g, '</sup>');
        drug[i].description = drug[i].description.replace(/~/g, '<sub>');
        drug[i].description = drug[i].description.replace(/!/g, '</sub>');
        // console.log("-->Drug REPLACED Description : ",drug[i].description);
      }
      // console.log("_________________________________");
    }
  }
  getExonNumbers() {

    this.reportPdfRes.selectedReportedVariantList.forEach(reportedVariant => {
      reportedVariant.aachangeknownGeneList.forEach((aachangeknownGene, index) => {
        // console.log("==>", aachangeknownGene, reportedVariant.ensembleValue);
        if(reportedVariant.selectedAAChangeknownGene && reportedVariant.selectedAAChangeknownGene.length>0){
          if (aachangeknownGene.includes(reportedVariant.ensembleValue)) {
            if(reportedVariant.exonNumber==null){
            reportedVariant.exonNumber = aachangeknownGene.split(":")[2].substring(4);
            }
          }
        }else{
        if (aachangeknownGene == 'UNKNOWN') {
          // console.log("UNKNOWN");
          reportedVariant.selectedAAChangeknownGene = ":::::";
          // console.log("reportedVariant.selectedAAChangeknownGene UNKNOWN", reportedVariant.selectedAAChangeknownGene);
        } else if (aachangeknownGene == '.') {
          reportedVariant.selectedAAChangeknownGene = ":::::";
          // console.log("reportedVariant.selectedAAChangeknownGene dot", reportedVariant.selectedAAChangeknownGene);
        } else if (aachangeknownGene.includes(reportedVariant.ensembleValue)) {
          reportedVariant.selectedAAChangeknownGene = aachangeknownGene.split(":")[3];
          reportedVariant.selectedAAChangeknownGene=reportedVariant.selectedAAChangeknownGene+", "+ aachangeknownGene.split(":")[4];

          reportedVariant.exonNumber = aachangeknownGene.split(":")[2].substring(4);
        }

        console.log("reportedVariant.selectedAAChangeknownGene", reportedVariant.selectedAAChangeknownGene);


        if (reportedVariant.aachangeknownGeneList.length - 1 == index) {
          // console.log("reportedVariant.selectedAAChangeknownGene index", reportedVariant.selectedAAChangeknownGene);

          if (reportedVariant.selectedAAChangeknownGene == null) {
           let selectedAA:any= reportedVariant.aachangeknownGeneList[0];
            reportedVariant.selectedAAChangeknownGene = selectedAA.split(":")[3];
            reportedVariant.selectedAAChangeknownGene=reportedVariant.selectedAAChangeknownGene+", "+ selectedAA.split(":")[4];

            reportedVariant.exonNumber = reportedVariant.aachangeknownGeneList[0].split(":")[2].substring(4);
            // console.log("reportedVariant.selectedAAChangeknownGene null", reportedVariant.selectedAAChangeknownGene);
          }
        }
      }

      });
    });
  }


  snvSameReportDataList: CNVReportData[] = [];
  cnaSameReportDataList: CNVReportData[] = [];
  fusionSameReportDataList: CNVReportData[] = [];

  cnvReportDataList: CNVReportData[] = [];
  snvReportDataList: CNVReportData[] = [];
  fusionReportDataList: CNVReportData[] = [];

  pdl1ExpressionList: CNVReportData[] = [];

  pdl1Expression(reportPdfRes: ReportPdfRes) {
    for (let i = 0; i < reportPdfRes.pdl1ClinicalTrail.length; i++) {
      let rowLength = reportPdfRes.pdl1ClinicalTrail[i].drugList.length + 0;
      for (let j = 0; j < reportPdfRes.pdl1ClinicalTrail[i].drugList.length; j++) {
        let pdl1 = {} as CNVReportData;
        pdl1.gene = reportPdfRes.pdl1ClinicalTrail[i].gene;
        pdl1.geneRowCount = rowLength;
        rowLength = 0;
        pdl1.association = reportPdfRes.pdl1ClinicalTrail[i].associationList[j];
        pdl1.evidance = reportPdfRes.pdl1ClinicalTrail[i].evidenceList[j];
        pdl1.cancerType = reportPdfRes.pdl1ClinicalTrail[i].resultCancerTypeList[j].name;
        let drugs: string[] = [];
        reportPdfRes.pdl1ClinicalTrail[i].drugList[j].forEach(
          drug => {
            drugs.push(drug.name);
          }
        );
        pdl1.therepy = drugs.toString();

        pdl1.reference = "FDA Guidelines";
        this.pdl1ExpressionList.push(pdl1);
      }
    }

  }


  page67Table(reportPdfRes: ReportPdfRes) {

    // loop for same type snv
    for (let i = 0; i < reportPdfRes.sameTypeSelectedClinicalTrailList.length; i++) {
      let rowLength = reportPdfRes.sameTypeSelectedClinicalTrailList[i].drugList.length + reportPdfRes.sameTypeSelectedClinicalTrailList[i].nctDrugList.length;
      for (let j = 0; j < reportPdfRes.sameTypeSelectedClinicalTrailList[i].drugList.length; j++) {
        let cnv = {} as CNVReportData;
        cnv.gene = reportPdfRes.sameTypeSelectedClinicalTrailList[i].gene;
        cnv.geneRowCount = rowLength;
        rowLength = 0;
        cnv.association = reportPdfRes.sameTypeSelectedClinicalTrailList[i].associationList[j];
        cnv.evidance = reportPdfRes.sameTypeSelectedClinicalTrailList[i].evidenceList[j];
        cnv.snvVarinat = reportPdfRes.sameTypeSelectedClinicalTrailList[i].snvVarinat;
        let drugs: string[] = [];
        reportPdfRes.sameTypeSelectedClinicalTrailList[i].drugList[j].forEach(
          drug => {
            drugs.push(drug.name);
          }
        );
        let cancerTypes: string[] = [];
        reportPdfRes.sameTypeSelectedClinicalTrailList[i].cancerTypeList[j].forEach(
          canceType => {
            cancerTypes.push(canceType.name);
          }
        );
        cnv.therepy = drugs.toString();
        cnv.cancerType = cancerTypes.toString();
        if (reportPdfRes.sameTypeSelectedClinicalTrailList[i].evidenceList[j] == 'LEVEL_2') {
          cnv.reference = "NCCN Guidelines";
        } else {
          cnv.reference = "FDA Guidelines";
        }
        this.snvSameReportDataList.push(cnv);
      }

      for (let j = 0; j < reportPdfRes.sameTypeSelectedClinicalTrailList[i].nctDrugList.length; j++) {
        let cnv = {} as CNVReportData;
        cnv.gene = reportPdfRes.sameTypeSelectedClinicalTrailList[i].gene;
        cnv.geneRowCount = rowLength;
        rowLength = 0;
        cnv.association = reportPdfRes.sameTypeSelectedClinicalTrailList[i].nctAssociationList[j];
        cnv.evidance = reportPdfRes.sameTypeSelectedClinicalTrailList[i].nctEvidenceList[j];
        cnv.therepy = reportPdfRes.sameTypeSelectedClinicalTrailList[i].nctDrugList[j].toString();
        cnv.reference = reportPdfRes.sameTypeSelectedClinicalTrailList[i].nctIdsList[j].toString();
        cnv.snvVarinat = reportPdfRes.sameTypeSelectedClinicalTrailList[i].snvVarinat;

        let cancerTypes: string[] = [];
        reportPdfRes.sameTypeSelectedClinicalTrailList[i].nctCancerTypeList[j].forEach(
          canceType => {
            cancerTypes.push(canceType.name);
          }
        );
        cnv.cancerType = cancerTypes.toString();
        this.snvSameReportDataList.push(cnv);
      }

    }

    // loop for same type cna
    for (let i = 0; i < reportPdfRes.cnaSameTypeSelectedClinicalTrailList.length; i++) {
      let rowLength = reportPdfRes.cnaSameTypeSelectedClinicalTrailList[i].drugList.length + reportPdfRes.cnaSameTypeSelectedClinicalTrailList[i].nctDrugList.length;
      for (let j = 0; j < reportPdfRes.cnaSameTypeSelectedClinicalTrailList[i].drugList.length; j++) {
        let cnv = {} as CNVReportData;
        cnv.gene = reportPdfRes.cnaSameTypeSelectedClinicalTrailList[i].gene;
        cnv.geneRowCount = rowLength;
        rowLength = 0;
        cnv.association = reportPdfRes.cnaSameTypeSelectedClinicalTrailList[i].associationList[j];
        cnv.evidance = reportPdfRes.cnaSameTypeSelectedClinicalTrailList[i].evidenceList[j];
        cnv.snvVarinat = reportPdfRes.cnaSameTypeSelectedClinicalTrailList[i].snvVarinat;
        // console.log("CNAVarient-->",reportPdfRes.cnaSameTypeSelectedClinicalTrailList[i].snvVarinat)
        // console.log("CNV____CNAVarient-->",cnv.snvVarinat)

        let drugs: string[] = [];
        reportPdfRes.cnaSameTypeSelectedClinicalTrailList[i].drugList[j].forEach(
          drug => {
            drugs.push(drug.name);
          }
        );
        let cancerTypes: string[] = [];
        reportPdfRes.cnaSameTypeSelectedClinicalTrailList[i].cancerTypeList[j].forEach(
          canceType => {
            cancerTypes.push(canceType.name);
          }
        );
        cnv.therepy = drugs.toString();
        cnv.cancerType = cancerTypes.toString();
        if (reportPdfRes.cnaSameTypeSelectedClinicalTrailList[i].evidenceList[j] == 'LEVEL_2') {
          cnv.reference = "NCCN Guidelines";
        } else {
          cnv.reference = "FDA Guidelines";
        }
        this.cnaSameReportDataList.push(cnv);
      }

      for (let j = 0; j < reportPdfRes.cnaSameTypeSelectedClinicalTrailList[i].nctDrugList.length; j++) {
        let cnv = {} as CNVReportData;
        cnv.gene = reportPdfRes.cnaSameTypeSelectedClinicalTrailList[i].gene;
        cnv.geneRowCount = rowLength;
        rowLength = 0;
        cnv.association = reportPdfRes.cnaSameTypeSelectedClinicalTrailList[i].nctAssociationList[j];
        cnv.evidance = reportPdfRes.cnaSameTypeSelectedClinicalTrailList[i].nctEvidenceList[j];
        cnv.therepy = reportPdfRes.cnaSameTypeSelectedClinicalTrailList[i].nctDrugList[j].toString();
        cnv.reference = reportPdfRes.cnaSameTypeSelectedClinicalTrailList[i].nctIdsList[j].toString();
        cnv.snvVarinat = reportPdfRes.cnaSameTypeSelectedClinicalTrailList[i].snvVarinat;
        // console.log("CNAVarient-->",reportPdfRes.cnaSameTypeSelectedClinicalTrailList[i].snvVarinat)
        // console.log("CNV____CNAVarient-->",cnv.snvVarinat)


        let cancerTypes: string[] = [];
        reportPdfRes.cnaSameTypeSelectedClinicalTrailList[i].nctCancerTypeList[j].forEach(
          canceType => {
            cancerTypes.push(canceType.name);
          }
        );
        cnv.cancerType = cancerTypes.toString();
        this.cnaSameReportDataList.push(cnv);
      }

    }

    // loop for same type fusion
    for (let i = 0; i < reportPdfRes.fusionSameTypeSelectedClinicalTrailList.length; i++) {
      let rowLength = reportPdfRes.fusionSameTypeSelectedClinicalTrailList[i].drugList.length + reportPdfRes.fusionSameTypeSelectedClinicalTrailList[i].nctDrugList.length;
      for (let j = 0; j < reportPdfRes.fusionSameTypeSelectedClinicalTrailList[i].drugList.length; j++) {
        let cnv = {} as CNVReportData;
        cnv.gene = reportPdfRes.fusionSameTypeSelectedClinicalTrailList[i].gene;
        cnv.geneRowCount = rowLength;
        rowLength = 0;
        cnv.association = reportPdfRes.fusionSameTypeSelectedClinicalTrailList[i].associationList[j];
        cnv.evidance = reportPdfRes.fusionSameTypeSelectedClinicalTrailList[i].evidenceList[j];
        let drugs: string[] = [];
        reportPdfRes.fusionSameTypeSelectedClinicalTrailList[i].drugList[j].forEach(
          drug => {
            drugs.push(drug.name);
          }
        );
        let cancerTypes: string[] = [];
        reportPdfRes.fusionSameTypeSelectedClinicalTrailList[i].cancerTypeList[j].forEach(
          canceType => {
            cancerTypes.push(canceType.name);
          }
        );
        cnv.therepy = drugs.toString();
        cnv.cancerType = cancerTypes.toString();
        if (reportPdfRes.fusionSameTypeSelectedClinicalTrailList[i].evidenceList[j] == 'LEVEL_2') {
          cnv.reference = "NCCN Guidelines";
        } else {
          cnv.reference = "FDA Guidelines";
        }
        this.fusionSameReportDataList.push(cnv);
      }

      for (let j = 0; j < reportPdfRes.fusionSameTypeSelectedClinicalTrailList[i].nctDrugList.length; j++) {
        let cnv = {} as CNVReportData;
        cnv.gene = reportPdfRes.fusionSameTypeSelectedClinicalTrailList[i].gene;
        cnv.geneRowCount = rowLength;
        rowLength = 0;
        cnv.association = reportPdfRes.fusionSameTypeSelectedClinicalTrailList[i].nctAssociationList[j];
        cnv.evidance = reportPdfRes.fusionSameTypeSelectedClinicalTrailList[i].nctEvidenceList[j];
        cnv.therepy = reportPdfRes.fusionSameTypeSelectedClinicalTrailList[i].nctDrugList[j].toString();
        cnv.reference = reportPdfRes.fusionSameTypeSelectedClinicalTrailList[i].nctIdsList[j].toString();

        let cancerTypes: string[] = [];
        reportPdfRes.fusionSameTypeSelectedClinicalTrailList[i].nctCancerTypeList[j].forEach(
          canceType => {
            cancerTypes.push(canceType.name);
          }
        );
        cnv.cancerType = cancerTypes.toString();
        this.fusionSameReportDataList.push(cnv);
      }

    }

    // loop for other type snv
    for (let i = 0; i < reportPdfRes.selectedClinicalTrailList.length; i++) {
      let rowLength = reportPdfRes.selectedClinicalTrailList[i].drugList.length + reportPdfRes.selectedClinicalTrailList[i].nctDrugList.length;
      for (let j = 0; j < reportPdfRes.selectedClinicalTrailList[i].drugList.length; j++) {
        let cnv = {} as CNVReportData;
        cnv.gene = reportPdfRes.selectedClinicalTrailList[i].gene;
        cnv.geneRowCount = rowLength;
        rowLength = 0;
        cnv.association = reportPdfRes.selectedClinicalTrailList[i].associationList[j];
        cnv.evidance = reportPdfRes.selectedClinicalTrailList[i].evidenceList[j];
        cnv.snvVarinat = reportPdfRes.selectedClinicalTrailList[i].snvVarinat;
        let drugs: string[] = [];
        reportPdfRes.selectedClinicalTrailList[i].drugList[j].forEach(
          drug => {
            drugs.push(drug.name);
          }
        );
        let cancerTypes: string[] = [];
        reportPdfRes.selectedClinicalTrailList[i].cancerTypeList[j].forEach(
          canceType => {
            cancerTypes.push(canceType.name);
          }
        );
        cnv.therepy = drugs.toString();
        cnv.cancerType = cancerTypes.toString();
        if (reportPdfRes.selectedClinicalTrailList[i].evidenceList[j] == 'LEVEL_2') {
          cnv.reference = "NCCN Guidelines";
        } else {
          cnv.reference = "FDA Guidelines";
        }
        this.snvReportDataList.push(cnv);
      }

      for (let j = 0; j < reportPdfRes.selectedClinicalTrailList[i].nctDrugList.length; j++) {
        let cnv = {} as CNVReportData;
        cnv.gene = reportPdfRes.selectedClinicalTrailList[i].gene;
        cnv.geneRowCount = rowLength;
        rowLength = 0;
        cnv.association = reportPdfRes.selectedClinicalTrailList[i].nctAssociationList[j];
        cnv.evidance = reportPdfRes.selectedClinicalTrailList[i].nctEvidenceList[j];
        cnv.therepy = reportPdfRes.selectedClinicalTrailList[i].nctDrugList[j].toString();
        cnv.reference = reportPdfRes.selectedClinicalTrailList[i].nctIdsList[j].toString();
        cnv.snvVarinat = reportPdfRes.selectedClinicalTrailList[i].snvVarinat;

        let cancerTypes: string[] = [];
        reportPdfRes.selectedClinicalTrailList[i].nctCancerTypeList[j].forEach(
          canceType => {
            cancerTypes.push(canceType.name);
          }
        );
        cnv.cancerType = cancerTypes.toString();
        this.snvReportDataList.push(cnv);
      }

    }

    // loop for other type cna
    for (let i = 0; i < reportPdfRes.cnaSelectedClinicalTrailList.length; i++) {
      let rowLength = reportPdfRes.cnaSelectedClinicalTrailList[i].drugList.length + reportPdfRes.cnaSelectedClinicalTrailList[i].nctDrugList.length;
      for (let j = 0; j < reportPdfRes.cnaSelectedClinicalTrailList[i].drugList.length; j++) {
        let cnv = {} as CNVReportData;
        cnv.gene = reportPdfRes.cnaSelectedClinicalTrailList[i].gene;
        cnv.geneRowCount = rowLength;
        rowLength = 0;
        cnv.association = reportPdfRes.cnaSelectedClinicalTrailList[i].associationList[j];
        cnv.evidance = reportPdfRes.cnaSelectedClinicalTrailList[i].evidenceList[j];
        cnv.snvVarinat = reportPdfRes.cnaSelectedClinicalTrailList[i].snvVarinat;
        let drugs: string[] = [];
        reportPdfRes.cnaSelectedClinicalTrailList[i].drugList[j].forEach(
          drug => {
            drugs.push(drug.name);
          }
        );
        let cancerTypes: string[] = [];
        reportPdfRes.cnaSelectedClinicalTrailList[i].cancerTypeList[j].forEach(
          canceType => {
            cancerTypes.push(canceType.name);
          }
        );
        cnv.therepy = drugs.toString();
        cnv.cancerType = cancerTypes.toString();
        if (reportPdfRes.cnaSelectedClinicalTrailList[i].evidenceList[j] == 'LEVEL_2') {
          cnv.reference = "NCCN Guidelines";
        } else {
          cnv.reference = "FDA Guidelines";
        }

        this.cnvReportDataList.push(cnv);
      }

      for (let j = 0; j < reportPdfRes.cnaSelectedClinicalTrailList[i].nctDrugList.length; j++) {
        let cnv = {} as CNVReportData;
        cnv.gene = reportPdfRes.cnaSelectedClinicalTrailList[i].gene;
        cnv.geneRowCount = rowLength;
        rowLength = 0;
        cnv.association = reportPdfRes.cnaSelectedClinicalTrailList[i].nctAssociationList[j];
        cnv.evidance = reportPdfRes.cnaSelectedClinicalTrailList[i].nctEvidenceList[j];
        cnv.therepy = reportPdfRes.cnaSelectedClinicalTrailList[i].nctDrugList[j].toString();
        cnv.reference = reportPdfRes.cnaSelectedClinicalTrailList[i].nctIdsList[j].toString();
        cnv.snvVarinat = reportPdfRes.cnaSelectedClinicalTrailList[i].snvVarinat;

        let cancerTypes: string[] = [];
        reportPdfRes.cnaSelectedClinicalTrailList[i].nctCancerTypeList[j]?.forEach(
          canceType => {
            cancerTypes.push(canceType.name);
          }
        );
        cnv.cancerType = cancerTypes.toString();
        this.cnvReportDataList.push(cnv);
      }

    }

    // loop for other type fusion
    for (let i = 0; i < reportPdfRes.fusionSelectedClinicalTrailList.length; i++) {
      let rowLength = reportPdfRes.fusionSelectedClinicalTrailList[i].drugList.length + reportPdfRes.fusionSelectedClinicalTrailList[i].nctDrugList.length;
      for (let j = 0; j < reportPdfRes.fusionSelectedClinicalTrailList[i].drugList.length; j++) {
        let cnv = {} as CNVReportData;
        cnv.gene = reportPdfRes.fusionSelectedClinicalTrailList[i].gene;
        cnv.geneRowCount = rowLength;
        rowLength = 0;
        cnv.association = reportPdfRes.fusionSelectedClinicalTrailList[i].associationList[j];
        cnv.evidance = reportPdfRes.fusionSelectedClinicalTrailList[i].evidenceList[j];
        let drugs: string[] = [];
        reportPdfRes.fusionSelectedClinicalTrailList[i].drugList[j].forEach(
          drug => {
            drugs.push(drug.name);
          }
        );
        let cancerTypes: string[] = [];
        reportPdfRes.fusionSelectedClinicalTrailList[i].cancerTypeList[j].forEach(
          canceType => {
            cancerTypes.push(canceType.name);
          }
        );
        cnv.therepy = drugs.toString();
        cnv.cancerType = cancerTypes.toString();
        if (reportPdfRes.fusionSelectedClinicalTrailList[i].evidenceList[j] == 'LEVEL_2') {
          cnv.reference = "NCCN Guidelines";
        } else {
          cnv.reference = "FDA Guidelines";
        }
        this.fusionReportDataList.push(cnv);
      }

      for (let j = 0; j < reportPdfRes.fusionSelectedClinicalTrailList[i].nctDrugList.length; j++) {
        let cnv = {} as CNVReportData;
        cnv.gene = reportPdfRes.fusionSelectedClinicalTrailList[i].gene;
        cnv.geneRowCount = rowLength;
        rowLength = 0;
        cnv.association = reportPdfRes.fusionSelectedClinicalTrailList[i].nctAssociationList[j];
        cnv.evidance = reportPdfRes.fusionSelectedClinicalTrailList[i].nctEvidenceList[j];
        cnv.therepy = reportPdfRes.fusionSelectedClinicalTrailList[i].nctDrugList[j].toString();
        cnv.reference = reportPdfRes.fusionSelectedClinicalTrailList[i].nctIdsList[j].toString();

        let cancerTypes: string[] = [];
        reportPdfRes.fusionSelectedClinicalTrailList[i].nctCancerTypeList[j].forEach(
          canceType => {
            cancerTypes.push(canceType.name);
          }
        );
        cnv.cancerType = cancerTypes.toString();
        this.fusionReportDataList.push(cnv);
      }

    }

  }



  downloadReport() {

    this.downloadSpinner = true;

    let pdf = new jspdf({
      // orientation: 'landscape',
      format: 'a4'
    }); // A4 size page of PDF

    this.page(pdf);
  }

  page(pdf: jspdf) {
    this.data = document.getElementById("page");
    html2canvas(this.data).then(canvas => {
      var foreignObjectRendering: true;
      var imgWidth = 208;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL("image/png");
      var position = 0;
      pdf.addImage(contentDataURL, "JPEG", 0, position, imgWidth, imgHeight, undefined, 'SLOW');
      pdf.addPage();
      this.page1(pdf);
    });
  }

  page1(pdf: jspdf) {

    console.log("page1");

    this.data = document.getElementById("page1");
    html2canvas(this.data).then(canvas => {
      var imgWidth = 208;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL("image/png");
      var position = 0;
      pdf.addImage(contentDataURL, "JPEG", 0, position, imgWidth, imgHeight, undefined, 'SLOW');
      pdf.addPage();
      this.page2(pdf);
    });

  }

  page2(pdf: jspdf) {

    console.log("page2");

    this.data = document.getElementById("page2");
    html2canvas(this.data).then(canvas => {
      var imgWidth = 208;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL("image/png");
      var position = 0;
      pdf.addImage(contentDataURL, "JPEG", 0, position, imgWidth, imgHeight, undefined, 'SLOW');
      pdf.addPage();
      if (this.report.interpretation != null && this.report.interpretation != "<p></p>") {
        this.page3(pdf);
      } else {
        this.page4(pdf);
      }
    });

  }


  page3(pdf: jspdf) {
    console.log("page3");
    this.data = document.getElementById("page3");
    html2canvas(this.data).then(canvas => {
      var imgWidth = 208;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL("image/png");
      var position = 0;
      pdf.addImage(contentDataURL, "JPEG", 0, position, imgWidth, imgHeight, undefined, 'SLOW');
      pdf.addPage();
      if( this.interpretation.length>5500){
        this.page3_1(pdf);
      }
      this.page4(pdf);
    });

  }
  page3_1(pdf: jspdf) {
    console.log("page3_1");
    this.data = document.getElementById("page3_1");
    html2canvas(this.data).then(canvas => {
      var imgWidth = 208;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL("image/png");
      var position = 0;
      pdf.addImage(contentDataURL, "JPEG", 0, position, imgWidth, imgHeight, undefined, 'SLOW');
      pdf.addPage();
      this.page4(pdf);
    });

  }

  page4(pdf: jspdf) {
    console.log("page4")

    this.data = document.getElementById("page4");
    html2canvas(this.data).then(canvas => {
      var imgWidth = 208;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL("image/png");
      var position = 0;
      pdf.addImage(contentDataURL, "JPEG", 0, position, imgWidth, imgHeight, undefined, 'SLOW');
      pdf.addPage();
      if ( (this.report.immunoDetails.pdl1Status == 'EXPRESSION' || this.tmb > 15 || this.msi > 15)) {
        this.page5(pdf);
      } else if (this.snvSameReportDataList.length > 0 || this.cnaSameReportDataList.length > 0 || this.fusionSameReportDataList.length > 0) {
        this.page6(pdf);
      } else if (this.snvReportDataList.length > 0 || this.cnvReportDataList.length > 0 || this.fusionReportDataList.length > 0) {
        this.page7(pdf);
      } else if (this.reportPdfRes.starNCTTrailRes.length > 0) {
        this.page8(pdf);
      } else if (this.reportPdfRes.selectedReportedVariantList.length > 0 || this.reportPdfRes.cnaReportedVariantList.length > 0 || this.reportPdfRes.fusionReportedVariantList.length > 0) {
        this.page9(pdf);
      } else if (this.reportPdfRes.genes.length > 0) {
        this.page10(pdf);
      } else if (this.reportPdfRes.pathwayDtoList.length > 0) {
        this.page11(pdf);
      } else if (this.reportPdfRes.drugDetails.length > 0) {
        this.page15(pdf);
      } else {
        this.page16(pdf);
      }
    });

  }

  page5(pdf: jspdf) {

    console.log("page5");
    this.data = document.getElementById("page5");
    html2canvas(this.data).then(canvas => {
      var imgWidth = 208;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL("image/png");
      var position = 0;
      pdf.addImage(contentDataURL, "JPEG", 0, position, imgWidth, imgHeight, undefined, 'SLOW');
      pdf.addPage();
      if (this.snvSameReportDataList.length > 0 || this.cnaSameReportDataList.length > 0 || this.fusionSameReportDataList.length > 0) {
        this.page6(pdf);
      } else if (this.snvReportDataList.length > 0 || this.cnvReportDataList.length > 0 || this.fusionReportDataList.length > 0) {
        this.page7(pdf);
      } else if (this.reportPdfRes.starNCTTrailRes.length > 0) {
        this.page8(pdf);
      } else if (this.reportPdfRes.selectedReportedVariantList.length > 0 || this.reportPdfRes.cnaReportedVariantList.length > 0 || this.reportPdfRes.fusionReportedVariantList.length > 0) {
        this.page9(pdf);
      } else if (this.reportPdfRes.genes.length > 0) {
        this.page10(pdf);
      } else if (this.reportPdfRes.pathwayDtoList.length > 0) {
        this.page11(pdf);
      } else if (this.reportPdfRes.drugDetails.length > 0) {
        this.page15(pdf);
      } else {
        this.page16(pdf);
      }
    });

  }

  page6(pdf: jspdf) {
    console.log("page6")

    this.data = document.getElementById("page6");
    html2canvas(this.data).then(canvas => {
      var imgWidth = 208;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL("image/png");
      var position = 0;
      pdf.addImage(contentDataURL, "JPEG", 0, position, imgWidth, imgHeight, undefined, 'SLOW');
      pdf.addPage();
      if (this.snvReportDataList.length > 0 || this.cnvReportDataList.length > 0 || this.fusionReportDataList.length > 0) {
        this.page7(pdf);
      } else if (this, this.reportPdfRes.starNCTTrailRes.length > 0) {
        this.page8(pdf);
      } else {
        this.page9(pdf);
      }

    });

  }

  page7(pdf: jspdf) {

    console.log("page7");

    this.data = document.getElementById("page7");
    html2canvas(this.data).then(canvas => {
      var imgWidth = 208;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL("image/png");
      var position = 0;
      pdf.addImage(contentDataURL, "JPEG", 0, position, imgWidth, imgHeight, undefined, 'SLOW');
      pdf.addPage();
      if (this, this.reportPdfRes.starNCTTrailRes.length > 0) {
        this.page8(pdf);
      } else {
        this.page9(pdf);
      }

    });

  }

  page8(pdf: jspdf) {
    console.log("page8");
    this.data = document.getElementById("page8");
    html2canvas(this.data).then(canvas => {
      var imgWidth = 208;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL("image/png");
      var position = 0;
      pdf.addImage(contentDataURL, "JPEG", 0, position, imgWidth, imgHeight, undefined, 'SLOW');
      pdf.addPage();
      this.page9(pdf);
    });

  }

  page9(pdf: jspdf) {
    console.log("page9");
    this.data = document.getElementById("page9");
    html2canvas(this.data).then(canvas => {
      var imgWidth = 208;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL("image/png");
      var position = 0;
      pdf.addImage(contentDataURL, "JPEG", 0, position, imgWidth, imgHeight, undefined, 'SLOW');
      pdf.addPage();
      this.page10(pdf);
    });

  }

  page10(pdf: jspdf) {
    console.log("page10")

    this.data = document.getElementById("page10");
    html2canvas(this.data).then(canvas => {
      var imgWidth = 208;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL("image/png");
      var position = 0;
      pdf.addImage(contentDataURL, "JPEG", 0, position, imgWidth, imgHeight, undefined, 'SLOW');
      pdf.addPage();
      if (this.reportPdfRes.genes.length > 3) {
        this.page10_1(pdf);
      } else if (this.reportPdfRes.pathwayDtoList.length > 0) {
        this.page11(pdf);
      } else if (this.reportPdfRes.drugDetails.length > 0) {
        this.page15(pdf);
      } else {
        this.page16(pdf);
      }
    });

  }
  page10_1(pdf: jspdf) {
    console.log("page10_1")

    this.data = document.getElementById("page10_1");
    html2canvas(this.data).then(canvas => {
      var imgWidth = 208;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL("image/png");
      var position = 0;
      pdf.addImage(contentDataURL, "JPEG", 0, position, imgWidth, imgHeight, undefined, 'SLOW');
      pdf.addPage();
      if (this.reportPdfRes.genes.length > 6) {
        this.page10_2(pdf);
      } else {
        this.page11(pdf);
      }
    });

  }
  page10_2(pdf: jspdf) {
    console.log("page10_2")

    this.data = document.getElementById("page10_2");
    html2canvas(this.data).then(canvas => {
      var imgWidth = 208;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL("image/png");
      var position = 0;
      pdf.addImage(contentDataURL, "JPEG", 0, position, imgWidth, imgHeight, undefined, 'SLOW');
      pdf.addPage();
      if (this.reportPdfRes.genes.length > 9) {
        this.page10_3(pdf);
      } else {
        this.page11(pdf);
      }
    });

  }
  page10_3(pdf: jspdf) {
    console.log("page10_3")

    this.data = document.getElementById("page10_3");
    html2canvas(this.data).then(canvas => {
      var imgWidth = 208;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL("image/png");
      var position = 0;
      pdf.addImage(contentDataURL, "JPEG", 0, position, imgWidth, imgHeight, undefined, 'SLOW');
      pdf.addPage();
      if (this.reportPdfRes.genes.length > 12) {
        this.page10_4(pdf);
      } else {
        this.page11(pdf);
      }
    });

  }

  page10_4(pdf: jspdf) {
    console.log("page10_2")

    this.data = document.getElementById("page10_2");
    html2canvas(this.data).then(canvas => {
      var imgWidth = 208;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL("image/png");
      var position = 0;
      pdf.addImage(contentDataURL, "JPEG", 0, position, imgWidth, imgHeight, undefined, 'SLOW');
      pdf.addPage();
      if (this.reportPdfRes.genes.length > 15) {
        this.page10_5(pdf);
      } else {
        this.page11(pdf);
      }
    });

  }

  page10_5(pdf: jspdf) {
    console.log("page10_2")

    this.data = document.getElementById("page10_2");
    html2canvas(this.data).then(canvas => {
      var imgWidth = 208;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL("image/png");
      var position = 0;
      pdf.addImage(contentDataURL, "JPEG", 0, position, imgWidth, imgHeight, undefined, 'SLOW');
      pdf.addPage();
      this.page11(pdf);
    });

  }

  page11(pdf: jspdf) {
    console.log("page 11");

    this.data = document.getElementById("page11");
    html2canvas(this.data).then(canvas => {
      var imgWidth = 208;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL("image/png");
      var position = 0;
      pdf.addImage(contentDataURL, "JPEG", 0, position, imgWidth, imgHeight, undefined, 'SLOW');
      pdf.addPage();
      if (this.reportPdfRes.pathwayDtoList.length > 2) {
        this.page12(pdf);
      } else if (this.reportPdfRes.drugDetails.length > 0) {
        this.page15(pdf);
      } else {
        this.page16(pdf);
      }
    });

  }

  page12(pdf: jspdf) {

    console.log("page 12");

    this.data = document.getElementById("page12");
    html2canvas(this.data).then(canvas => {
      var imgWidth = 208;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL("image/png");
      var position = 0;
      pdf.addImage(contentDataURL, "JPEG", 0, position, imgWidth, imgHeight, undefined, 'SLOW');
      pdf.addPage();
      if (this.reportPdfRes.pathwayDtoList.length > 4) {
        this.page13(pdf);
      } else {
        this.page15(pdf);
      }

    });

  }

  page13(pdf: jspdf) {
    console.log("page13")

    this.data = document.getElementById("page13");
    html2canvas(this.data).then(canvas => {
      var imgWidth = 208;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL("image/png");
      var position = 0;
      pdf.addImage(contentDataURL, "JPEG", 0, position, imgWidth, imgHeight, undefined, 'SLOW');
      pdf.addPage();
      if (this.reportPdfRes.pathwayDtoList.length > 6) {
        this.page14(pdf);
      } else {
        this.page15(pdf);
      }
    });

  }

  page14(pdf: jspdf) {
    console.log("page14")
    this.data = document.getElementById("page14");
    html2canvas(this.data).then(canvas => {
      var imgWidth = 208;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL("image/png");
      var position = 0;
      pdf.addImage(contentDataURL, "JPEG", 0, position, imgWidth, imgHeight, undefined, 'SLOW');
      pdf.addPage();
      this.page15(pdf);
    });

  }

  page15(pdf: jspdf) {
    console.log("page 15");


    this.data = document.getElementById("page15");
    html2canvas(this.data).then(canvas => {
      var imgWidth = 208;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL("image/png");
      var position = 0;
      pdf.addImage(contentDataURL, "JPEG", 0, position, imgWidth, imgHeight, undefined, 'SLOW');
      pdf.addPage();
      if (this.reportPdfRes.drugDetails.length > 6) {
        this.page15_1(pdf);
      } else {
        this.page16(pdf);
      }

    });

  }

  page15_1(pdf: jspdf) {
    console.log("page 15_1");

    this.data = document.getElementById("page15_1");
    html2canvas(this.data).then(canvas => {
      var imgWidth = 208;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL("image/png");
      var position = 0;
      pdf.addImage(contentDataURL, "JPEG", 0, position, imgWidth, imgHeight, undefined, 'SLOW');
      pdf.addPage();
      if (this.reportPdfRes.drugDetails.length > 12) {
        this.page15_2(pdf);
      } else {
        this.page16(pdf);
      }
    });

  }

  page15_2(pdf: jspdf) {
    console.log("page 15_2");

    this.data = document.getElementById("page15_2");
    html2canvas(this.data).then(canvas => {
      var imgWidth = 208;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL("image/png");
      var position = 0;
      pdf.addImage(contentDataURL, "JPEG", 0, position, imgWidth, imgHeight, undefined, 'SLOW');
      pdf.addPage();
      if (this.reportPdfRes.drugDetails.length > 18) {
        this.page15_3(pdf);
      } else {
        this.page16(pdf);
      }
    });

  }

  page15_3(pdf: jspdf) {
    console.log("page 15_3");

    this.data = document.getElementById("page15_3");
    html2canvas(this.data).then(canvas => {
      var imgWidth = 208;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL("image/png");
      var position = 0;
      pdf.addImage(contentDataURL, "JPEG", 0, position, imgWidth, imgHeight, undefined, 'SLOW');
      pdf.addPage();
      if (this.reportPdfRes.drugDetails.length > 24) {
        this.page15_4(pdf);
      } else {
        this.page16(pdf);
      }
    });

  }

  page15_4(pdf: jspdf) {
    console.log("page 15_4");

    this.data = document.getElementById("page15_4");
    html2canvas(this.data).then(canvas => {
      var imgWidth = 208;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL("image/png");
      var position = 0;
      pdf.addImage(contentDataURL, "JPEG", 0, position, imgWidth, imgHeight, undefined, 'SLOW');
      pdf.addPage();
      if (this.reportPdfRes.drugDetails.length > 30) {
        this.page15_5(pdf);
      } else {
        this.page16(pdf);
      }
    });

  }

  page15_5(pdf: jspdf) {
    console.log("page 15_5");

    this.data = document.getElementById("page15_4");
    html2canvas(this.data).then(canvas => {
      var imgWidth = 208;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL("image/png");
      var position = 0;
      pdf.addImage(contentDataURL, "JPEG", 0, position, imgWidth, imgHeight, undefined, 'SLOW');
      pdf.addPage();
      this.page16(pdf);
    });

  }

  page16(pdf: jspdf) {
    console.log("page 16");

    this.data = document.getElementById("page16");
    html2canvas(this.data).then(canvas => {
      var imgWidth = 208;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL("image/png");
      var position = 0;
      pdf.addImage(contentDataURL, "JPEG", 0, position, imgWidth, imgHeight, undefined, 'SLOW');
      pdf.addPage();
      if (this.reportPdfRes.referenceArticles.length > 15) {
        this.page16_1(pdf);
      } else {
        this.page17(pdf);
      }
    });

  }

  page16_1(pdf: jspdf) {
    console.log("page 16_1");

    this.data = document.getElementById("page16_1");
    html2canvas(this.data).then(canvas => {
      var imgWidth = 208;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL("image/png");
      var position = 0;
      pdf.addImage(contentDataURL, "JPEG", 0, position, imgWidth, imgHeight, undefined, 'SLOW');
      pdf.addPage();
      if (this.reportPdfRes.referenceArticles.length > 30) {
        this.page16_2(pdf);
      } else {
        this.page17(pdf);
      }
    });

  }

  page16_2(pdf: jspdf) {
    console.log("page 16_2");

    this.data = document.getElementById("page16_2");
    html2canvas(this.data).then(canvas => {
      var imgWidth = 208;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL("image/png");
      var position = 0;
      pdf.addImage(contentDataURL, "JPEG", 0, position, imgWidth, imgHeight, undefined, 'SLOW');
      pdf.addPage();
      this.page17(pdf);
    });

  }

  page17(pdf: jspdf) {
    console.log("page17");

    this.data = document.getElementById("page17");
    html2canvas(this.data).then(canvas => {
      var imgWidth = 208;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL("image/png");
      var position = 0;
      pdf.addImage(contentDataURL, "JPEG", 0, position, imgWidth, imgHeight, undefined, 'SLOW');
      pdf.addPage();
      this.page19(pdf);
    });

  }

  page19(pdf: jspdf) {
    console.log("page19")

    this.data = document.getElementById("page19");
    html2canvas(this.data).then(canvas => {
      var imgWidth = 208;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL("image/png");
      var position = 0;
      pdf.addImage(contentDataURL, "JPEG", 0, position, imgWidth, imgHeight, undefined, 'SLOW');
      pdf.addPage();
      this.page20(pdf);
    });

  }

  page19_1(pdf: jspdf) {
    console.log("page19_1")
    this.data = document.getElementById("page19_1");
    html2canvas(this.data).then(canvas => {
      var imgWidth = 208;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL("image/png");
      var position = 0;
      pdf.addImage(contentDataURL, "JPEG", 0, position, imgWidth, imgHeight, undefined, 'SLOW');
      pdf.addPage();
      this.page20(pdf);
    });

  }

  page19_2(pdf: jspdf) {
    console.log("page19_2")

    this.data = document.getElementById("page19_2");
    html2canvas(this.data).then(canvas => {
      var imgWidth = 208;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL("image/png");
      var position = 0;
      pdf.addImage(contentDataURL, "JPEG", 0, position, imgWidth, imgHeight, undefined, 'SLOW');
      pdf.addPage();
      this.page20(pdf);
    });

  }

  page20(pdf: jspdf) {
    console.log("page20")

    this.data = document.getElementById("page20");
    html2canvas(this.data, { proxy: this.pdl1Images[0] }).then(canvas => {
      var imgWidth = 208;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL("image/png");
      var position = 0;
      pdf.addImage(contentDataURL, "JPEG", 0, position, imgWidth, imgHeight, undefined, 'SLOW');
        if (this.report.immunoDetails.pdl1Status != "NOT_PERFORMED") {
          pdf.addPage();
          this.page21(pdf);
        } else {
          this.savePdf(pdf);
        }

    });

  }

  page21(pdf: jspdf) {
    console.log("page21")
    this.data = document.getElementById("page21");
    console.log(this.data);
    html2canvas(this.data).then(canvas => {
      var imgWidth = 208;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL("image/png");
      var position = 0;
      pdf.addImage(contentDataURL, "JPEG", 0, position, imgWidth, imgHeight, undefined, 'SLOW');
      pdf.addPage();
      this.page22(pdf);
    });

  }

  page22(pdf: jspdf) {
    console.log("page22")
    this.data = document.getElementById("page22");
    html2canvas(this.data).then(canvas => {
      var imgWidth = 208;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL("image/png");
      var position = 0;
      pdf.addImage(contentDataURL, "JPEG", 0, position, imgWidth, imgHeight, undefined, 'SLOW');
      pdf.addPage();
      this.page23(pdf);
    });

  }

  page23(pdf: jspdf) {
    console.log("page23")
    this.data = document.getElementById("page23");
    html2canvas(this.data).then(canvas => {
      var imgWidth = 208;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL("image/png");
      var position = 0;
      pdf.addImage(contentDataURL, "JPEG", 0, position, imgWidth, imgHeight, undefined, 'SLOW');
      this.savePdf(pdf);
    });

  }
  patientName!: any
  savePdf(pdf: jspdf) {
    this.downloadSpinner = false;
    this.patientName = this.report.patient.name;
    this.patientName = this.patientName.replace(/\./g, ' ');
      pdf.save("tarGT_absolute - " + this.patientName + " - " + this.report.labDetails.sampleId);
  }


  getimage(url: string) {
    return new Promise(function (resolve, reject) {
      var xhr = new XMLHttpRequest();
      xhr.open('GET', url, true);
      xhr.withCredentials = false;
      xhr.responseType = "blob";
      xhr.onload = function () {
        if (this.status >= 200 && this.status < 300) {
          resolve(xhr.response);
        } else {
          reject({
            status: this.status,
            statusText: xhr.statusText
          });
        }
      };
      xhr.onerror = function () {
        reject({
          status: this.status,
          statusText: xhr.statusText
        });
      };
      xhr.send();
    });
  }
  getbase64(blob: any) {
    return new Promise<string>((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.readAsDataURL(blob);
    });
  }

  counter(i: number) {
    return new Array(i);
  }

}
