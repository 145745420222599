import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { FirstReportPdfRes, GeneMutationRes, RelevantData, ReportRes } from 'src/app/_interfaces.ts/reports';
import { ReportService } from 'src/app/_services/report.service';
import jspdf from "jspdf";
import html2canvas from "html2canvas";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-lung-liquid-advanced-report',
  templateUrl: './lung-liquid-advanced-report.component.html',
  styleUrls: ['./lung-liquid-advanced-report.component.css']
})
export class LungLiquidAdvancedReportComponent implements OnInit {
  // footerDiscription = "All the 4baseCare test samples are processed at 4basecare Offshore Development Centre (ODC) - Genomics lab inside Wipro campus in Sarjapur. This lab has been accredited by for NABL: ISO 15189:2012 for the lab operations at the address WIPRO LIFE SCIENCES LAB, WIPRO LIMITED, SY. NO. 69P,71/4P,78/8AP,134P,76P,77P,80P,70P, 79/1P, UNIT 1, SARJAPUR ROAD, DODDAKANNELLI VILLAGE, VARTHUR HOBLI, BENGALURU, KARNATAKA, INDIA having NABL 15189 certificate number MC-5155 and test is covered under the scope of this NABL certification"
  footerDiscription ="All the 4baseCare test samples are processed at 4baseCare Offshore Development Centre (ODC) - "+
                "Genomics lab inside Wipro campus in Sarjapur. This lab has been accredited by for NABL: ISO 15109:2012 "+
                "for the lab operations at the address WIPRO LIFE SCIENCES LAB, WIPRO LIMITED, SY. NO 69P,71/4P,70/BAP,134P, "+
                "76P,77P,80P,70P, 79/1P, UNIT 1, SARJAPUR ROAD, DODDAKANNELLI VILLAGE, VARTHUR HOBLI, BENGALURU, KARNATAKA, "+
                "INDIA having NABL 15189 certificate number MC-5155 and test is covered under the scope of this NABL certification."
  footerDiscription2 = "4baseCare (Genomics Tarang ODC), SJP2-S1-1F-C wing, Wipro Limited, SEZ, Sarjapur 2, SyNo.69 (P), Doddakannelli, "+
                "Sarjapura Road, Bengaluru 560035, KA, India"


  is4basecare = true;
  isReportDownload:boolean=false
  imgsings:any=''
  firstPageTable:any=''
  reportId!: number;
  reportPdfRes = {} as FirstReportPdfRes;
  report = {} as ReportRes;
  date = Date();
  downloadSpinner = false;
  data: any;
  reportData = false;
  statusMsg!: string;
 newsnvHotspotTrailResList:any=[]
 geneFlag=false
 variantFlag=false
  tableShow = false;
  sampleSource!: string;
  firstTableFlag:boolean=false
mixedVariants:any=[]
  snvSameRelevantData: RelevantData[] = [];
  snvOtherRelevantData: RelevantData[] = [];
geneMutationList:GeneMutationRes[]=[{
  gene:'ALK',status:'Not Detected'
},{ gene:'BRAF',status:'Not Detected'},{ gene:'EGFR',status:'Not Detected'},{ gene:'ERBB2',status:'Not Detected'},
{ gene:'FGFR3',status:'Not Detected'},{ gene:'FGFR2',status:'Not Detected'},{ gene:'KRAS',status:'Not Detected'},
{ gene:'MET',status:'Not Detected'},{ gene:'NRG2',status:'Not Detected'},{ gene:'NTRK3',status:'Not Detected'},
{ gene:'NRG1',status:'Not Detected'},{ gene:'NTRK2',status:'Not Detected'},{ gene:'NTRK1',status:'Not Detected'},
{ gene:'RET',status:'Not Detected'},{ gene:'ROS1',status:'Not Detected'},
];
fusionGeneMutationList:GeneMutationRes[]=[
{gene:'ALK',status:'Not Detected'},{gene:'FGFR2',status:'Not Detected'},{gene:'FGFR3',status:'Not Detected'},
{gene:'MET',status:'Not Detected'},{gene:'NRG2',status:'Not Detected'},{gene:'NTRK3',status:'Not Detected'},
{gene:'NRG1',status:'Not Detected'},{gene:'NTRK2',status:'Not Detected'},{gene:'NTRK1',status:'Not Detected'},
{gene:'RET',status:'Not Detected'},{gene:'ROS1',status:'Not Detected'}
];
  cnaSameRelevantData: RelevantData[] = [];
  cnaOtherRelevantData: RelevantData[] = [];

  fusionSameRelevantData: RelevantData[] = [];
  fusionOtherRelevantData: RelevantData[] = [];

  constructor(
    private reportService: ReportService,
    private routerAct: ActivatedRoute,
    private toastr: ToastrService,
    private titleService: Title
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle("view report");
    this.reportId = this.routerAct.snapshot.params['id'];
    if (this.reportId) {
      this.getReport();
    }
  }



  isTMH = false;
  tableCount=0;

  referringClinician!:string;
  labHospital!:string;
  getReport() {
    this.reportService.lungLiquidAdvancedReportPdfResponse(this.reportId).subscribe(
      (response: any) => {
        console.log(response);
        this.reportData = true;
        this.statusMsg = response.message;
        this.showSuccess();
        this.reportPdfRes = response['payLoad'];
        this.report = response['payLoad']['report'];
        if(this.report.patient.organisationName != null){
          if(this.report.patient.organisationName.includes("4baseCare") || this.report.patient.organisationName.includes("4basecare")){
            this.is4basecare = true;
          } else if(this.report.patient.organisationName.includes("Tata") || this.report.patient.organisationName.includes("Homi Bhabha")){
            this.isTMH = true;
            this.is4basecare = false;
          } else {
            this.is4basecare = false;
            this.isTMH = false;
          }
        }
        if(this.is4basecare==true && this.report?.labDetails?.hospital.includes("Cytecare")){
          this.referringClinician="Cytecare Hospitals";
          this.labHospital="Cytecare Hospitals"
        }else{
          this.referringClinician=this.report?.labDetails?.referringClinician?.replace(/,/g, ', ');
          this.labHospital=this.report?.labDetails?.hospital;
        }
        this.tableCount= this.reportPdfRes.snvHotspotTrailResList.length + this.reportPdfRes.cnaHotspotTrailResList.length + this.reportPdfRes.fusionHotspotTrailResList.length;
        this.setPage1Table(this.reportPdfRes);
        console.log("additional noted before-->", this.report.addNotes)
        if (this.report.addNotes == null || this.report.addNotes == undefined || this.report.addNotes == '') {
          this.report.addNotes = '<p></p>'
          console.log("additional noted Not requried-->", this.report.addNotes)
        }
        if (this.report.addNotes != null || this.report.addNotes == '<p></p>') {

          // console.log("actual Addnotes",this.report.addNotes)
          this.report.addNotes = this.report.addNotes.replace(/<p>/g, '<div>');
          this.report.addNotes = this.report.addNotes.replace(/<\/p>/g, '</div>');
          this.report.addNotes = this.report.addNotes.replace(/{/g, '<sup>');
          this.report.addNotes = this.report.addNotes.replace(/}/g, '</sup>');
          this.report.addNotes = this.report.addNotes.replace(/~/g, '<sub>');
          this.report.addNotes = this.report.addNotes.replace(/!/g, '</sub>');
          console.log("additional noted after-->", this.report.addNotes)
        }
        if (this.report.labDetails != null) {
          // console.log("sampleScorce-->",this.report.labDetails.sampleSource)
          this.sampleSource = this.report.labDetails?.sampleSource?.replace(/FFPE BlockId:/g, '');
        }
        if (this.reportPdfRes.cnaReportedVariantList.length > 0) {
          this.tableShow = true;
        }
        this.getExonNumbers();
        let geneLength:number=0;
        let associationListLenght:number=0;
        let drugListLength:number=0;
        if(this.reportPdfRes.geneMutationResList && this.reportPdfRes.geneMutationResList.length>0){
          this.geneMutationList.forEach((element:any)=>{
            this.reportPdfRes.geneMutationResList.forEach((ele:any)=>{
              if(element.gene==ele.gene && ele.status!=null){
                element.status=ele.status
              }
            })
          })
        }
        if(this.reportPdfRes.fusionGeneMutationResList && this.reportPdfRes.fusionGeneMutationResList.length>0){
          this.fusionGeneMutationList.forEach((element:any)=>{
            this.reportPdfRes.fusionGeneMutationResList.forEach((ele:any)=>{
              if(ele.gene.includes(element.gene) && ele.status!=null){
                element.status=ele.status
              }
            })
          })
        }
        if(this.reportPdfRes.snvHotspotTrailResList && this.reportPdfRes.snvHotspotTrailResList.length>0){
          geneLength=geneLength+this.reportPdfRes.snvHotspotTrailResList.length;
          this.reportPdfRes.snvHotspotTrailResList.forEach(element => {
            let snvData:any=element
            let maxSameassociationListLenght:number=0
            let maxSamedrugListLength:number=0
            let maxOtherassociationListLenght:number=0
            let maxOtherdrugListLength:number=0
         if(snvData['sameCancerType']!=null){
          maxSameassociationListLenght=associationListLenght+snvData['sameCancerType'].associationList.length
          maxSameassociationListLenght=drugListLength+snvData['sameCancerType'].drugList.length
         }
         else{
          maxSameassociationListLenght=associationListLenght
          maxSamedrugListLength=drugListLength
         }
          if(snvData['otherCancerType']!=null){
            maxOtherassociationListLenght=associationListLenght+snvData['otherCancerType'].associationList.length
            maxOtherdrugListLength=drugListLength+snvData['otherCancerType'].drugList.length
         }else{
          maxOtherassociationListLenght=associationListLenght
          maxOtherdrugListLength=drugListLength
         }

         if((maxSameassociationListLenght+maxSamedrugListLength)>(maxOtherassociationListLenght+maxOtherdrugListLength)){
          associationListLenght=maxSameassociationListLenght
          drugListLength=maxSamedrugListLength
         }else{
          associationListLenght=maxOtherassociationListLenght
          drugListLength=maxOtherdrugListLength
         }
          });

        }
        if(this.reportPdfRes.fusionHotspotTrailResList && this.reportPdfRes.fusionHotspotTrailResList.length>0){
          geneLength=geneLength+this.reportPdfRes.fusionHotspotTrailResList.length
          this.reportPdfRes.fusionHotspotTrailResList.forEach(element => {
            let fusionData:any=element
          if(fusionData['sameCancerType']){
            associationListLenght=associationListLenght+fusionData['sameCancerType'].associationList.length
            drugListLength=drugListLength+fusionData['sameCancerType'].drugList.length
          }

          });

        }
        console.log("size",associationListLenght," ",drugListLength)
        if(associationListLenght>=7 || drugListLength>=7 || geneLength>6){
          this.firstTableFlag=true
        }
        if(this.reportId==2976){
          // if(this.reportPdfRes.snvHotspotTrailResList.length>1){
            this.reportPdfRes.snvHotspotTrailResList.forEach((element:any)=>{
              if((element.sameTypeRelevantData !=null && element.sameTypeRelevantData.length>3 || (element.otherTypeRelevantData!=null && element.otherTypeRelevantData.length>3)) ){
                this.newsnvHotspotTrailResList.push(element)
               let index= this.reportPdfRes.snvHotspotTrailResList.indexOf(element)
               this.reportPdfRes.snvHotspotTrailResList.splice(index,1)
              }
            })
          // }
          if(this.reportPdfRes.cnaHotspotTrailResList.length>1){
            this.reportPdfRes.cnaHotspotTrailResList.forEach((element:any)=>{
              if((element.sameTypeRelevantData !=null && element.sameTypeRelevantData.length>3 || (element.otherTypeRelevantData!=null && element.otherTypeRelevantData.length>3)) ){
                this.newsnvHotspotTrailResList.push(element)
               let index= this.reportPdfRes.cnaHotspotTrailResList.indexOf(element)
               this.reportPdfRes.cnaHotspotTrailResList.splice(index,1)
              }

            })
          }
          if(this.newsnvHotspotTrailResList.length>0){
            this.geneFlag=true
          }
        }else{
            if((this.reportPdfRes.cnaHotspotTrailResList.length==0)?this.reportPdfRes.snvHotspotTrailResList.length>2:this.reportPdfRes.snvHotspotTrailResList.length>1){
          this.reportPdfRes.snvHotspotTrailResList.forEach((element:any)=>{
            if((element.sameTypeRelevantData !=null && element.sameTypeRelevantData.length>3 || (element.otherTypeRelevantData!=null && element.otherTypeRelevantData.length>3)) ){
              this.newsnvHotspotTrailResList.push(element)
             let index= this.reportPdfRes.snvHotspotTrailResList.indexOf(element)
             this.reportPdfRes.snvHotspotTrailResList.splice(index,1)
            }
          })
        }
        if((this.reportPdfRes.snvHotspotTrailResList.length==0)?this.reportPdfRes.cnaHotspotTrailResList.length>2:this.reportPdfRes.cnaHotspotTrailResList.length>1){
          this.reportPdfRes.cnaHotspotTrailResList.forEach((element:any)=>{
            if((element.sameTypeRelevantData !=null && element.sameTypeRelevantData.length>3 || (element.otherTypeRelevantData!=null && element.otherTypeRelevantData.length>3)) ){
              this.newsnvHotspotTrailResList.push(element)
             let index= this.reportPdfRes.cnaHotspotTrailResList.indexOf(element)
             this.reportPdfRes.cnaHotspotTrailResList.splice(index,1)
            }

          })
        }
        if(this.newsnvHotspotTrailResList.length>0){
          this.geneFlag=true
        }
        }
        let allLength=this.reportPdfRes.snvHotspotTrailResList.length+this.reportPdfRes.cnaHotspotTrailResList.length+this.reportPdfRes.fusionHotspotTrailResList.length
        if(this.newsnvHotspotTrailResList.length==0 && allLength>4){
          this.geneFlag=true
        }
       let variantcount=this.reportPdfRes.snvReportedVariantList.length+this.reportPdfRes.cnaReportedVariantList.length+this.reportPdfRes.fusionReportedVariantList.length
        if(this.geneFlag && variantcount>6){
          this.variantFlag=true
        }
        console.log("reportData",this.reportData,"\n",this.is4basecare)

      },
      error => {
        console.log('oops...!', error)
        this.statusMsg = error.error.error;
        this.showFail();
      });
  }
  showSuccess() {
    this.toastr.success(this.statusMsg, '',
      { timeOut: 2000 }
    );
  }
  showFail() {
    this.toastr.error(this.statusMsg, '',
      { timeOut: 10000 }
    );
  }

  count = 0;
  countOther = 0;
  WTcountSNV: boolean = false;
  otherCountSNV = false;
  getExonNumbers() {

    this.reportPdfRes.snvReportedVariantList.forEach(reportedVariant => {
      reportedVariant.aachangeknownGeneList.forEach((aachangeknownGene, index) => {
        if(reportedVariant.selectedAAChangeknownGene && reportedVariant.selectedAAChangeknownGene.length>0){
          if (aachangeknownGene.includes(reportedVariant.ensembleValue)) {

            reportedVariant.exonNumber = aachangeknownGene.split(":")[2].substring(4);
          }
        }else{
          if (aachangeknownGene == 'UNKNOWN') {
            // console.log("UNKNOWN");
            reportedVariant.selectedAAChangeknownGene = ":::::";
            // console.log("reportedVariant.selectedAAChangeknownGene UNKNOWN", reportedVariant.selectedAAChangeknownGene);
          } else if (aachangeknownGene == '.') {
            reportedVariant.selectedAAChangeknownGene = ":::::";
            // console.log("reportedVariant.selectedAAChangeknownGene dot", reportedVariant.selectedAAChangeknownGene);
          } else if (aachangeknownGene.includes(reportedVariant.ensembleValue)) {
            reportedVariant.selectedAAChangeknownGene = aachangeknownGene.split(":")[3];
            reportedVariant.selectedAAChangeknownGene=reportedVariant.selectedAAChangeknownGene+", "+ aachangeknownGene.split(":")[4];
            console.log('AAAAAa',reportedVariant.selectedAAChangeknownGene)
            reportedVariant.exonNumber = aachangeknownGene.split(":")[2].substring(4);
          }

          // console.log("reportedVariant.selectedAAChangeknownGene", reportedVariant.selectedAAChangeknownGene);


          if (reportedVariant.aachangeknownGeneList.length - 1 == index) {
            // console.log("reportedVariant.selectedAAChangeknownGene index", reportedVariant.selectedAAChangeknownGene);

            if (reportedVariant.selectedAAChangeknownGene == null) {
              let selectedAA:any= reportedVariant.aachangeknownGeneList[0];
              reportedVariant.selectedAAChangeknownGene = selectedAA.split(":")[3];
              reportedVariant.selectedAAChangeknownGene=reportedVariant.selectedAAChangeknownGene+", "+ selectedAA.split(":")[4];// reportedVariant.selectedAAChangeknownGene = reportedVariant.aachangeknownGeneList[0];
              reportedVariant.exonNumber = reportedVariant.aachangeknownGeneList[0].split(":")[2].substring(4);
              // console.log("reportedVariant.selectedAAChangeknownGene null", reportedVariant.selectedAAChangeknownGene);
            }
          }
        }
        // console.log("==>", aachangeknownGene, reportedVariant.ensembleValue);


      });
      console.log("JJJJ",reportedVariant)
      if (reportedVariant.significance == 'NA' || reportedVariant.significance =='Not Classified') {
        this.count++;
      }
      if (reportedVariant.significance != "NA" && reportedVariant.significance !='Not Classified') {
        this.countOther++;
      }

      if (this.count == 0) {
        this.WTcountSNV = true;
      }
      if (this.countOther > 0) {
        this.otherCountSNV = true;
      }
    });
    // console.log('NA.count: ', this.WTcountSNV, '\n Other.count: ', this.otherCountSNV);
  }

  setPage1Table(reportPdfRes: FirstReportPdfRes) {
    // SNV //
    if (reportPdfRes.snvHotspotTrailResList != null) {
      for (let i = 0; i < reportPdfRes.snvHotspotTrailResList.length; i++) {
        // Different Cancer type
        if (reportPdfRes.snvHotspotTrailResList[i].otherCancerType != null) {
          for (let j = 0; j < reportPdfRes.snvHotspotTrailResList[i].otherCancerType.drugList.length; j++) {
            let relevantData = {} as RelevantData;
            relevantData.association = reportPdfRes.snvHotspotTrailResList[i].otherCancerType.associationList[j];
            relevantData.cancerType = reportPdfRes.snvHotspotTrailResList[i].otherCancerType.cancerTypeList[j].name;
            let drugs: string[] = [];
            reportPdfRes.snvHotspotTrailResList[i].otherCancerType.drugList[j].forEach(
              drug => {
                drugs.push(drug.name);
              }
            );

            relevantData.therapy = drugs.toString();
            this.snvOtherRelevantData.push(relevantData);
          }
          console.log("this.snvOtherRelevantData", this.snvOtherRelevantData);
          reportPdfRes.snvHotspotTrailResList[i].otherTypeRelevantData = this.snvOtherRelevantData;
          this.snvOtherRelevantData = [];
        }

        // Same Cancer Type
        if (reportPdfRes.snvHotspotTrailResList[i].sameCancerType != null) {
          for (let j = 0; j < reportPdfRes.snvHotspotTrailResList[i].sameCancerType.drugList.length; j++) {
            let relevantData = {} as RelevantData;
            relevantData.association = reportPdfRes.snvHotspotTrailResList[i].sameCancerType.associationList[j];
            // relevantData.cancerType = reportPdfRes.cnaHotspotTrailResList[i].sameCancerType.cancerTypeList[j].name;
            let drugs: string[] = [];
            reportPdfRes.snvHotspotTrailResList[i].sameCancerType.drugList[j].forEach(
              drug => {
                drugs.push(drug.name);
              }
            );

            relevantData.therapy = drugs.toString();
            this.snvSameRelevantData.push(relevantData);
          }
          console.log("this.snvSameRelevantData", this.snvSameRelevantData);
          reportPdfRes.snvHotspotTrailResList[i].sameTypeRelevantData = this.snvSameRelevantData;
          this.snvSameRelevantData = [];
        }

      }
    }

    // CNA //
    if (reportPdfRes.cnaHotspotTrailResList != null) {
      for (let i = 0; i < reportPdfRes.cnaHotspotTrailResList.length; i++) {

        // Different Cancer type
        if (reportPdfRes.cnaHotspotTrailResList[i].otherCancerType != null) {
          for (let j = 0; j < reportPdfRes.cnaHotspotTrailResList[i].otherCancerType.drugList.length; j++) {
            let relevantData = {} as RelevantData;
            relevantData.association = reportPdfRes.cnaHotspotTrailResList[i].otherCancerType.associationList[j];
            relevantData.cancerType = reportPdfRes.cnaHotspotTrailResList[i].otherCancerType.cancerTypeList[j].name;
            let drugs: string[] = [];
            reportPdfRes.cnaHotspotTrailResList[i].otherCancerType.drugList[j].forEach(
              drug => {
                drugs.push(drug.name);
              }
            );

            relevantData.therapy = drugs.toString();
            this.cnaOtherRelevantData.push(relevantData);
          }
        }
        console.log("this.cnaOtherRelevantData", this.cnaOtherRelevantData);
        reportPdfRes.cnaHotspotTrailResList[i].otherTypeRelevantData = this.cnaOtherRelevantData;

        // Same Cancer Type
        if (reportPdfRes.cnaHotspotTrailResList[i].sameCancerType != null) {
          for (let j = 0; j < reportPdfRes.cnaHotspotTrailResList[i].sameCancerType.drugList.length; j++) {
            let relevantData = {} as RelevantData;
            relevantData.association = reportPdfRes.cnaHotspotTrailResList[i].sameCancerType.associationList[j];
            // relevantData.cancerType = reportPdfRes.cnaHotspotTrailResList[i].sameCancerType.cancerTypeList[j].name;
            let drugs: string[] = [];
            reportPdfRes.cnaHotspotTrailResList[i].sameCancerType.drugList[j].forEach(
              drug => {
                drugs.push(drug.name);
              }
            );

            relevantData.therapy = drugs.toString();
            this.cnaSameRelevantData.push(relevantData);
          }
        }
        console.log("this.cnaSameRelevantData", this.cnaSameRelevantData);
        reportPdfRes.cnaHotspotTrailResList[i].sameTypeRelevantData = this.cnaSameRelevantData;

      }
    }

    // Fusion //
    if (reportPdfRes.fusionHotspotTrailResList != null) {
      for (let i = 0; i < reportPdfRes.fusionHotspotTrailResList.length; i++) {

        // Different Cancer type
        if (reportPdfRes.fusionHotspotTrailResList[i].otherCancerType != null) {
          for (let j = 0; j < reportPdfRes.fusionHotspotTrailResList[i].otherCancerType.drugList.length; j++) {
            let relevantData = {} as RelevantData;
            relevantData.association = reportPdfRes.fusionHotspotTrailResList[i].otherCancerType.associationList[j];
            relevantData.cancerType = reportPdfRes.fusionHotspotTrailResList[i].otherCancerType.cancerTypeList[j].name;
            let drugs: string[] = [];
            reportPdfRes.fusionHotspotTrailResList[i].otherCancerType.drugList[j].forEach(
              drug => {
                drugs.push(drug.name);
              }
            );

            relevantData.therapy = drugs.toString();
            this.fusionOtherRelevantData.push(relevantData);
          }
        }
        console.log("this.fusionOtherRelevantData", this.fusionOtherRelevantData);
        reportPdfRes.fusionHotspotTrailResList[i].otherTypeRelevantData = this.fusionOtherRelevantData;

        // Same Cancer Type
        if (reportPdfRes.fusionHotspotTrailResList[i].sameCancerType != null) {
          for (let j = 0; j < reportPdfRes.fusionHotspotTrailResList[i].sameCancerType.drugList.length; j++) {
            let relevantData = {} as RelevantData;
            relevantData.association = reportPdfRes.fusionHotspotTrailResList[i].sameCancerType.associationList[j];
            // relevantData.cancerType = reportPdfRes.cnaHotspotTrailResList[i].sameCancerType.cancerTypeList[j].name;
            let drugs: string[] = [];
            reportPdfRes.fusionHotspotTrailResList[i].sameCancerType.drugList[j].forEach(
              drug => {
                drugs.push(drug.name);
              }
            );

            relevantData.therapy = drugs.toString();
            this.fusionSameRelevantData.push(relevantData);
          }
        }
        console.log("this.fusionSameRelevantData", this.fusionSameRelevantData);
        reportPdfRes.fusionHotspotTrailResList[i].sameTypeRelevantData = this.fusionSameRelevantData;

      }
    }

  }
downloadReport() {
    this.downloadSpinner = true;
    this.page1()
}

page1(){
  console.log("HELLLOOO PAGE !!!")
  let data:any = document.getElementById("firstPageTable");
  html2canvas(data).then(canvas => {
    // var foreignObjectRendering: true;
    var imgWidth = 208;
    var imgHeight = (canvas.height * imgWidth) / canvas.width;
    const contentDataURL = canvas.toDataURL("image/png");
    this.firstPageTable=contentDataURL
    // if(this.is4basecare){
    //   this.page4_img()
    // }else{

      this.print()
    // }
  });
}
page4_img(){
  let data:any = document.getElementById("imgsings");
      html2canvas(data).then(canvas => {
        // var foreignObjectRendering: true;
        var imgWidth = 208;
        var imgHeight = (canvas.height * imgWidth) / canvas.width;
        const contentDataURL = canvas.toDataURL("image/png");
        this.imgsings=contentDataURL
        this.isReportDownload=true
      this.print()
      });
}

print(){
  this.isReportDownload=true
 setTimeout(()=>{
  this.downloadSpinner = false;
  document.title="tarGT_Lung_Liquid_Advanced-" + "-" + this.report.patient.name.replace(/\./g, ' ')
  window.print()
  this.isReportDownload=false
 },3000)
}


  // page(pdf: jspdf) {
  //   this.data = document.getElementById("page");
  //   html2canvas(this.data).then(canvas => {
  //     // var foreignObjectRendering: true;
  //     var imgWidth = 208;
  //     var imgHeight = (canvas.height * imgWidth) / canvas.width;
  //     const contentDataURL = canvas.toDataURL("image/png");
  //     var position = 0;
  //     pdf.addImage(contentDataURL, "JPEG", 0, position, imgWidth, imgHeight, undefined, 'SLOW');
  //     pdf.addPage();
  //     this.page1(pdf);
  //   });
  // }

  // page1(pdf: jspdf) {
  //   this.data = document.getElementById("page1");
  //   html2canvas(this.data).then(canvas => {
  //     var imgWidth = 208;
  //     var imgHeight = (canvas.height * imgWidth) / canvas.width;
  //     const contentDataURL = canvas.toDataURL("image/png");
  //     var position = 0;
  //     pdf.addImage(contentDataURL, "JPEG", 0, position, imgWidth, imgHeight, undefined, 'SLOW');
  //     pdf.addPage();
  //     if (this.report.addNotes != '<div></div>' || this.otherCountSNV == true || this.reportPdfRes.cnaReportedVariantList.length > 0 || this.reportPdfRes.fusionReportedVariantList.length > 0) {
  //       this.page2(pdf);
  //     } else if (this.reportPdfRes.drugsResList.length > 0) {
  //       this.page3(pdf);
  //     }
  //     else {
  //       this.page4(pdf);
  //     }

  //   });
  // }

  // page2(pdf: jspdf) {
  //   this.data = document.getElementById("page2");
  //   html2canvas(this.data).then(canvas => {
  //     var imgWidth = 208;
  //     var imgHeight = (canvas.height * imgWidth) / canvas.width;
  //     const contentDataURL = canvas.toDataURL("image/png");
  //     var position = 0;
  //     pdf.addImage(contentDataURL, "JPEG", 0, position, imgWidth, imgHeight, undefined, 'SLOW');
  //     pdf.addPage();
  //     if (this.reportPdfRes.drugsResList.length > 0) {
  //       this.page3(pdf);
  //     } else {
  //       this.page4(pdf);
  //     }
  //   });
  // }

  // page3(pdf: jspdf) {
  //   this.data = document.getElementById("page3");
  //   html2canvas(this.data).then(canvas => {
  //     var imgWidth = 208;
  //     var imgHeight = (canvas.height * imgWidth) / canvas.width;
  //     const contentDataURL = canvas.toDataURL("image/png");
  //     var position = 0;
  //     pdf.addImage(contentDataURL, "JPEG", 0, position, imgWidth, imgHeight, undefined, 'SLOW');
  //     pdf.addPage();
  //     if (!this.is4basecare && this.isTMH && this.reportPdfRes.drugsResList.length > 4) {
  //       this.page3_1(pdf);
  //     }else  if (this.reportPdfRes.drugsResList.length > 5) {
  //       this.page3_1(pdf);
  //     }
  //      else {
  //       this.page4(pdf);
  //     }
  //   });
  // }

  // page3_1(pdf: jspdf) {
  //   this.data = document.getElementById("page3_1");
  //   html2canvas(this.data).then(canvas => {
  //     var imgWidth = 208;
  //     var imgHeight = (canvas.height * imgWidth) / canvas.width;
  //     const contentDataURL = canvas.toDataURL("image/png");
  //     var position = 0;
  //     pdf.addImage(contentDataURL, "JPEG", 0, position, imgWidth, imgHeight, undefined, 'SLOW');
  //     pdf.addPage();
  //     if (!this.is4basecare && this.isTMH && this.reportPdfRes.drugsResList.length > 8) {
  //       this.page3_2(pdf);
  //     }else  if (this.reportPdfRes.drugsResList.length > 10) {
  //       this.page3_1(pdf);
  //     } else {
  //       this.page4(pdf);
  //     }
  //   });
  // }

  // page3_2(pdf: jspdf) {
  //   this.data = document.getElementById("page3_2");
  //   html2canvas(this.data).then(canvas => {
  //     var imgWidth = 208;
  //     var imgHeight = (canvas.height * imgWidth) / canvas.width;
  //     const contentDataURL = canvas.toDataURL("image/png");
  //     var position = 0;
  //     pdf.addImage(contentDataURL, "JPEG", 0, position, imgWidth, imgHeight, undefined, 'SLOW');
  //     pdf.addPage();
  //     if (!this.is4basecare && this.isTMH && this.reportPdfRes.drugsResList.length > 12) {
  //       this.page3_3(pdf);
  //     } else {
  //       this.page4(pdf);
  //     }
  //   });
  // }
  // page3_3(pdf: jspdf) {
  //   this.data = document.getElementById("page3_3");
  //   html2canvas(this.data).then(canvas => {
  //     var imgWidth = 208;
  //     var imgHeight = (canvas.height * imgWidth) / canvas.width;
  //     const contentDataURL = canvas.toDataURL("image/png");
  //     var position = 0;
  //     pdf.addImage(contentDataURL, "JPEG", 0, position, imgWidth, imgHeight, undefined, 'SLOW');
  //     pdf.addPage();
  //     this.page4(pdf);
  //   });
  // }

  // page4(pdf: jspdf) {
  //   this.data = document.getElementById("page4");
  //   html2canvas(this.data).then(canvas => {
  //     var imgWidth = 208;
  //     var imgHeight = (canvas.height * imgWidth) / canvas.width;
  //     const contentDataURL = canvas.toDataURL("image/png");
  //     var position = 0;
  //     pdf.addImage(contentDataURL, "JPEG", 0, position, imgWidth, imgHeight, undefined, 'SLOW');
  //     pdf.addPage();
  //     if (this.isTMH){
  //       this.page4_1(pdf);
  //     } else {
  //       this.page5(pdf);
  //     }
  //   });
  // }

  // page4_1(pdf: jspdf) {
  //   this.data = document.getElementById("page4_1");
  //   html2canvas(this.data).then(canvas => {
  //     var imgWidth = 208;
  //     var imgHeight = (canvas.height * imgWidth) / canvas.width;
  //     const contentDataURL = canvas.toDataURL("image/png");
  //     var position = 0;
  //     pdf.addImage(contentDataURL, "JPEG", 0, position, imgWidth, imgHeight, undefined, 'SLOW');
  //     pdf.addPage();
  //     this.page5(pdf);
  //   });
  // }

  // page5(pdf: jspdf) {
  //   this.data = document.getElementById("page5");
  //   html2canvas(this.data).then(canvas => {
  //     var imgWidth = 208;
  //     var imgHeight = (canvas.height * imgWidth) / canvas.width;
  //     const contentDataURL = canvas.toDataURL("image/png");
  //     var position = 0;
  //     pdf.addImage(contentDataURL, "JPEG", 0, position, imgWidth, imgHeight, undefined, 'SLOW');
  //     this.savePdf(pdf);
  //   });
  // }

  savePdf(pdf: jspdf) {
    this.downloadSpinner = false;
    pdf.save("tarGT_Lung_Liquid_Advanced-" + this.report?.patient?.name?.replace(/\./g, ' '));
  }

}
