<div class="container-fluid ">
  <div *ngIf="report.reportType != null" class="row px-4">
    <div class="col-4 pl-0">
    </div>
    <!--  -->
    <div class="col pr-1">
      <div class="d-flex justify-content-end">
        <div class="">

          <div *ngIf="report.reportType.name =='First 72 Liquid' " class="report_icon">
            <a [routerLink]="[ '/first72_liquid_view_report', reportId, temp ]" target="_blank"
             class="text_color "> <i class="zmdi zmdi-eye eye"></i>View Report</a>
           </div>
        </div>
        <div class="ml-3">
          <div class=" text-right  ml-4" *ngIf=" this.organisationName==null ||  this.organisationName==''">
            <select class="form-control" (change)="changeReportStatus($event.target)">
              <option selected disabled> select report Delivered</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
          <div class=" text-right  ml-4" *ngIf=" this.organisationName!=null &&  this.organisationName!=''">
            <select class="form-control" (change)="changeReportAndOncoStatus($event.target)">
              <option selected disabled> Is the report completed?</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div *ngIf="report.reportType != null" class="container-fluid patient-info_tabs position-relative reports_tabs">
  <div *ngIf="reportTypeChange" class="d-flex justify-content-center mt-5">
    <span class="visually-hidden"></span>
    <div class="spinner-border" style="width: 8rem; height: 8rem" role="status"></div>
  </div>
  <div *ngIf="!reportTypeChange">
    <header class=" pl-3  mb-3">
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <a class="nav-link active" data-toggle="tab" href="#patient-info"
            (click)="textcount('clinicalSummary-editors')">Patient Info</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-toggle="tab" (click)="assign('snv')" href="#snv">SNV / InDels</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-toggle="tab" (click)="assign('cna')" href="#cna">CNA</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" (click)="assign('fusion')" data-toggle="tab" href="#fusion">Fusion</a>
        </li>

        <li class="nav-item">
          <a class="nav-link" data-toggle="tab" href="#disclaimer" (click)="textcount('disclaimer-editors')">
            <span>Additional Notes</span></a>
        </li>
      </ul>
    </header>
    <div *ngIf="(report.reportType.name =='First 72 Liquid' ) || report.reportType.name =='First 72 Solid'"
      class="d-flex justify-content-end" style="padding-top:5px!important;">
      <div class="">
        <select class="form-control" (change)="tempSelection($event)">
          <option value="temp1" selected="selected"> Template 1</option>
          <!-- <option value="temp1">Template 1</option> -->
          <option value="temp2">Template 2</option>
          <option value="temp3">Template 3</option>
        </select>
      </div>
      <div class="ml-3">
        <div class=" text-right  ml-4">
        </div>
      </div>
    </div>
    <div class="tab-content">

      <!-- Patient Info -->
      <div id="patient-info" class="container-fluid tab-pane active"><br>
        <div id="tab1" class="">
          <div class="cust_shadow p-4 bg-white  rounded">
            <div class="levels_section  row mt-2 py-3 rounded mx-0" style="background: #f1f6f6;">
              <div class=" col-6">
                <p *ngIf=" this.organisationName==null ||  this.organisationName==''">
                  <label class="col-8">Select Sample Type :
                    <ng-select [items]="patientSamples" bindLabel="name" bindValue="name" [multiple]="true"
                      placeholder="Select samples" (change)="addSampleID()" [(ngModel)]="selected"></ng-select>
                  </label>
                </p>
                <p *ngIf=" this.organisationName==null ||  this.organisationName==''"><label class="col">Ecrf Id :
                    {{report?.patient?.ecrfId}}</label></p>
                <p
                  *ngIf=" this.organisationName!=null &&  this.organisationName!='' && this.organisationName=='Lifecell'">
                  <label class="col">Patient Id : {{report?.patient?.orgLabId}}</label>
                </p>
                <p
                  *ngIf=" this.organisationName!=null &&  this.organisationName!='' && this.organisationName!='Lifecell'">
                  <label class="col">Patient Id : {{report?.patient?.organisationPatientId}}</label>
                </p>

                <p><label class="col">Name : {{report?.patient?.name}}</label></p>
                <p><label class="col">Gender : {{report.patient.gender}}</label></p>
                <p><label class="col">Collected On : {{report.labDetails.sampleCollected}}</label></p>
                <p><label class="col" *ngIf=" this.organisationName==null ||  this.organisationName==''">FFPE ID :
                    {{report.labDetails.sampleBlockId}}</label></p>
                <p><label class="col" *ngIf="is4basecare && only4bc ">Age
                    Of Block :
                    <span class="text-black"
                      *ngIf="report.labDetails.dateOfHistopathologyReport!=null">{{ageOfBlock}}<span
                        *ngIf="ageOfBlock!=null"> days</span></span>
                    <span class="text-black" *ngIf="report.labDetails.dateOfHistopathologyReport==null"></span></label>
                </p>
                <p><label class="col" *ngIf="is4basecare && only4bc">Tumour
                    Content Assessment : {{report.labDetails.tumourContentAssessment || ""}}<span
                      *ngIf="report.labDetails.tumourContentAssessment!=null">%</span></label></p>

              </div>
              <div class="col-6">
                <p *ngIf=" this.organisationName==null ||  this.organisationName==''">
                  <label class="col-8 ">Change Report Type :
                    <ng-select [items]="reportTypeList" bindLabel="name" bindValue="name" [multiple]="false"
                      placeholder="Select Report Type" (change)="changeReportType($event)"
                      [(ngModel)]="selected"></ng-select>
                  </label>
                </p>
                <p><label class="col">DOB/Age : <span
                      *ngIf="report.patient.dateOfBirth != null">{{report.patient.dateOfBirth |
                      date:'dd/MM/yyyy'}}</span> <span *ngIf="report.patient.age != null">{{report.patient.age}} Years
                    </span></label>
                </p>
                <p><label class="col">Report Type : Targt {{report.reportType.name}}</label></p>
                <p><label class="col">Cancer Site : {{report.cancerType}}</label></p>
                <p *ngIf=" this.organisationName==null ||  this.organisationName==''"><label class="col">Sample Id :
                    {{report.labDetails.sampleId}}</label></p>
                <p><label class="col">Sample Source : {{report.labDetails.sampleSource}}</label></p>
                <p><label class="col">Referring Clinician :
                    {{referringClinician}}</label></p>
                <p><label class="col">Hospital : {{report.labDetails.hospital}}</label></p>


              </div>


            </div>

            <!-- selecet cancer type for first -->
            <div class="levels_section row mt-2 py-1 rounded mx-0" style="background: #f1f6f6;">
              <div class=" col-6">
                <p *ngIf=" this.organisationName==null ||  this.organisationName==''">
                  <label class="col-8">Cancer Type :
                    <ng-multiselect-dropdown [settings]="cancerTypeDropdownSettings" [data]="cancerTypeList"
                      name="cancerType" [(ngModel)]="selectedcancertype" (onSelect)="cancerTypeSelect($event)"
                      (onDeSelect)="cancerTypeDeSelect($event)">
                    </ng-multiselect-dropdown>
                  </label>
                </p>
                <p *ngIf=" this.organisationName!=null &&  this.organisationName!=''">
                  <label class="col-8">Cancer Type : {{selectedcancertype[0].name}}
                  </label>
                </p>

              </div>
              <div class=" col-6">
                <p *ngIf="is4basecare && only4bc">
                  <label class="col-8">Result :<br />
                    <input type="checkbox" [checked]="option1" (click)="onChange($event,option1Value)">
                    <span style="padding-left:inherit!important">Clinically significant alterations detected</span>
                    <br />
                    <input type="checkbox" [checked]="option2" (click)="onChange($event,option2Value)">
                    <span style="padding-left:inherit!important">No clinically significant alterations detected</span>
                  </label>
                </p>
              </div>
            </div>
            <!-- DNA / RNA Details -->
            <div *ngIf="appUser.email == 'harshi@4basecare.com'" class="levels_section row mt-2 py-1 rounded mx-0"
              style="background: #f1f6f6;">
              <div class=" col-6">
                <p>
                  <label class="col">DNA Exome Available :
                    <select class="form-control" name="dnaAvailable" (change)="dnaAvailable($event.target)"
                      [(ngModel)]="dnaAvailableValue">
                      <option selected disabled>select</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                  </label>
                </p>
                <p *ngIf="dnaAndRnaDetailsReq.dnaAvailable">
                  <label class="col">DNA Sequencing Data :
                    <input class="form-control" type="number" name="dnaSequencingData"
                      [(ngModel)]="dnaAndRnaDetailsReq.dnaSequencingData">
                  </label>
                </p>
              </div>
              <div class=" col-6">
                <p>
                  <label class="col">RNA Exome Available :
                    <select class="form-control" name="rnaAvailable" (change)="rnaAvailable($event.target)"
                      [(ngModel)]="rnaAvailableValue">
                      <option selected disabled>select</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                  </label>
                </p>
                <p *ngIf="dnaAndRnaDetailsReq.rnaAvailable">
                  <label class="col">RNA Sequencing Data :
                    <input class="form-control" type="number" name="rnaSequencingData"
                      [(ngModel)]="dnaAndRnaDetailsReq.rnaSequencingData">
                  </label>
                </p>
              </div>

              <button type="button" [disabled]="isSubmitedDnaRna" class="btn save_btn" style="float:right"
                (click)="addingRnaAndDna()">
                <span *ngIf="!isSubmitedDnaRna"> Save </span>
                <div *ngIf="isSubmitedDnaRna" class="spinner-border text-dark">
                  <span class="sr-only"></span>
                </div>
              </button>

            </div>
          </div>
        </div>

        <form class="general-form  mt-4">
          <div class="form-group row">
            <div class="col">
              <div class="d-flex justify-content-between align-items-center">
                <label>Clinical Summary:</label>
                <div style="text-align: right; padding-bottom: 5px;"
                  *ngIf="clinicalSummary!=null ||clinicalSummary!=undefined">
                  <P *ngIf="clinicalSummary.length"><strong>[{{clinicalSummary.length}}/3000]</strong></P>
                </div>
              </div>
              <input *ngIf="(this.organisationName==null ||  this.organisationName=='')" type="text"
                class="form-control" name="clinicalSummary" [(ngModel)]="clinicalSummary">
              <input *ngIf="(this.organisationName!=null &&  this.organisationName!='')" type="text"
                class="form-control" name="clinicalSummary" [(ngModel)]="clinicalSummary" [disabled]="true">

            </div>
          </div>

          <div class="form-group row">
            <div class="col">
              <div class="d-flex justify-content-between align-items-center">
                <label>Notes:</label>
              </div>
              <ckeditor [editor]="editor" [config]="{ toolbar: [ 'heading', '|', 'bold', 'italic' , 'bulletedList' ] }"
                name="disclaimerNote" [(ngModel)]="disclaimerNote"></ckeditor>
            </div>
          </div>
          <p style="font-size: 14px!important;padding-top: 8px;"><strong>*[</strong>A<sup>b</sup>
            → {{sup}}<strong>]</strong><br /><strong>*[</strong>A<sub>b</sub> → {{sub}}<strong>]</strong></p>


          <div
            *ngIf="report.clinicalSummary != null || report.addNotes!=null && (this.organisationName==null ||  this.organisationName=='')">
            <button type="button" [disabled]="isSubmitedClinicalSummary" class="btn save_btn" style="float:right"
              (click)="addClinicalSummary()">
              <span *ngIf="!isSubmitedClinicalSummary"> Update </span>
              <div *ngIf="isSubmitedClinicalSummary" class="spinner-border text-dark">
                <span class="sr-only"></span>
              </div>
            </button>
          </div>
          <div
            *ngIf="report.clinicalSummary == null && report.addNotes == null && (this.organisationName==null ||  this.organisationName=='')">
            <button type="button" [disabled]="isSubmitedClinicalSummary" class="btn save_btn" style="float:right"
              (click)="addClinicalSummary()">
              <span *ngIf="!isSubmitedClinicalSummary"> Save </span>
              <div *ngIf="isSubmitedClinicalSummary" class="spinner-border text-dark">
                <span class="sr-only"></span>
              </div>
            </button>
          </div>
        </form>
      </div>
      <!-- hotspot -->
      <div id="hotspot" *ngIf="tab=='hotspot'" class="container-fluid tab-pane fade"><br>
        <div id="tab2">
          <app-hotspot-excel></app-hotspot-excel>
        </div>
      </div>

      <!-- snv -->
      <div id="snv" *ngIf="tab=='snv'" class="container-fluid tab-pane fade"><br>
        <div id="tab13">
          <app-read-excel></app-read-excel>
        </div>
      </div>

      <!-- CNA -->
      <div id="cna" *ngIf="tab=='cna'" class="container-fluid tab-pane fade"><br>
        <div id="tab3">
          <app-cna-excel></app-cna-excel>
        </div>
      </div>

      <!-- Fusion -->
      <div id="fusion" *ngIf="tab=='fusion'" class="container-fluid tab-pane fade"><br>
        <div id="tab4">
          <app-fusion-excel></app-fusion-excel>
        </div>
      </div>

      <!-- Disclaimer -->
      <div id="disclaimer" class="container-fluid tab-pane fade"><br>
        <div id="tab10" class="">
          <form class="general-form">

            <div class="form-group row mt-2 mb-5">
              <div class="col-3  ">
                <div class="bg-white">
                  <ng-multiselect-dropdown placeholder="Select Disclaimer" [settings]="disclaimerDropdownSettings"
                    [data]="disclaimerList" (onSelect)="onDisclaimerItemSelect($event)"
                    (onDeSelect)="onDisclaimerItemDeSelect($event)">
                  </ng-multiselect-dropdown>
                </div>
              </div>
              <div class="col-3  ">
                <span *ngIf="isTargetFirst">(Disclaimer DB)</span>
              </div>
            </div>
            <div class="form-group row" span *ngIf="isTargetFirst">
              <div class="col">
                <div style="text-align: right; padding-bottom: 5px;" *ngIf="addNote!=null ||addNote!=undefined">
                  <P *ngIf="addNote.length"><strong>[{{editorText.length}}/11000]</strong></P>
                </div>

                <ckeditor [editor]="editor"
                  [config]="{ toolbar: [ 'heading', '|', 'bold', 'italic' , 'bulletedList' ] }" name="addNote"
                  [(ngModel)]="addNote"></ckeditor>
                <div class="addNote-editors" hidden="true" [innerHtml]="addNote"></div>
              </div>
            </div>

            <div *ngIf="isTMH" class="form-group row">
              <div class="col">
                <div class="d-flex justify-content-between align-items-center">
                  <label>Disclaimer Notes:</label>
                </div>
                <ckeditor [editor]="editor"
                  [config]="{ toolbar: [ 'heading', '|', 'bold', 'italic' , 'bulletedList' ] }" name="disclaimerNote"
                  [(ngModel)]="disclaimerNote"></ckeditor>
              </div>
            </div>
            <p style="font-size: 14px!important;padding-top: 8px;"><strong>*[</strong>A<sup>b</sup> →
              {{sup}}<strong>]</strong><br /><strong>*[</strong>A<sub>b</sub> → {{sub}}<strong>]</strong></p>
            <div class="form-group row"
              *ngIf="report.reportType.name == 'First' || report.reportType.name == 'First 72 Liquid' || report.reportType.name =='First 72 Solid'">
              <div class="col">
                <label>PMIDS:</label>
                <input type="text" class="form-control" placeholder="separate each pmId by comma(,)" name="pmIds"
                  [(ngModel)]="pmIds">
              </div>
            </div>
            <div *ngIf="report.disclaimer != null && isTargetFirst">
              <button type="button" [disabled]="isSubmitedDisclaimer" class="btn save_btn" style="float:right"
                (click)="AddPMIDSAndAdditionalNotes()">
                <span *ngIf="!isSubmitedDisclaimer"> Update </span>
                <div *ngIf="isSubmitedDisclaimer" class="spinner-border text-dark">
                  <span class="sr-only"></span>
                </div>
              </button>
            </div>
            <div *ngIf="report.disclaimer == null && isTargetFirst">
              <button type="button" class="btn save_btn" style="float:right" (click)="AddPMIDSAndAdditionalNotes()">
                <span *ngIf="!isSubmitedDisclaimer"> Save </span>
                <div *ngIf="isSubmitedDisclaimer" class="spinner-border text-dark">
                  <span class="sr-only"></span>
                </div>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
