<!DOCTYPE html>
<html lang="en">

<head>
  <title>page1</title>
  <meta charset="utf-8" />
  <meta name="viewport" content="width=device-width, initial-scale=1" />
  <link href="https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;600;700&display=swap" rel="stylesheet" />
  <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css" />
  <!-- <link rel="stylesheet" href="css/style.css"> -->
  <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
  <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.16.0/umd/popper.min.js"></script>
  <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js"></script>
  <link rel="stylesheet"
    href="https://cdnjs.cloudflare.com/ajax/libs/material-design-iconic-font/2.2.0/css/material-design-iconic-font.min.css" />
</head>

<div *ngIf="!reportData" class="d-flex justify-content-center mt-5">
  <span class="visually-hidden"></span>
  <div class="spinner-border" style="width: 8rem; height: 8rem" role="status"></div>
</div>

<body *ngIf="reportData">
  <button [disabled]="downloadSpinner" type="button" class="btn btn-primary m-2 float-right download-btn"
    (click)="downloadReport()">
    <span *ngIf="!downloadSpinner">
      <i class="zmdi zmdi-download"></i> Download Report
    </span>
    <div *ngIf="downloadSpinner" class="spinner-border text-dark">
      <span class="sr-only"></span>
    </div>
  </button>

  <div id="page" style="margin: 0 auto" class="main_wrapper">
    <img src="../../../assets/cover_focus.png" style="width: 100%" />
  </div>

  <div id="page1" style="padding: 10px; margin: 0 auto" class="main_wrapper">
    <div class="container">
      <div class="row header">
        <div *ngIf="report.clientType != 'client'">
          <div class="col-3">
            <div class="logo">
              <img src="../../../assets/logo.png" alt="" style="width: 140px" />
            </div>
          </div>
        </div>
        <div *ngIf="report.clientType == 'client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
        <div class="col">
          <div class="patient-detailes ml-auto">
            <ul class="list-unstyled d-flex pt-3">
              <li>
                Patient Id<br /><span>{{ report.patient?.ecrfId }}</span>
              </li>
              <li>
                Cancer Site<br /><span>{{ report.cancerType }}</span>
              </li>
              <li>
                SAMPLE ID<br /><span>{{ report.labDetails?.sampleId }}</span>
              </li>
              <li>
                Registration Date<br /><span>{{
                  report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div *ngIf="report.clientType == '4basecare_client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="mar_top"></div>
      <!--  -->
      <div class="row">
        <div class="col-7">
          <div class="">
            <div class="w-50" style="min-height: 80px">
              <p style="color: #208dcb; font-weight: 700" class="py-1">
                SCOPE OF THE TEST
              </p>
              <p>
                SNVs, InDels, CNAs, TMB and MSI
              </p>
            </div>
            <img src="../../../assets/BorderLine.png" class="mr-2 w-75" />
          </div>
        </div>
        <div class="col" *ngIf="report.clinicalSummary != null">
          <div class="">
            <div class="w-100" style="min-height: 80px">
              <p style="color: #208dcb; font-weight: 700" class="py-1">
                <span style="color: #000 !important">{{
                  report.clinicalSummary
                  }}</span>
              </p>
            </div>
            <img src="../../../assets/BorderLine.png" class="mr-2 w-100" />
          </div>
        </div>
      </div>
      <!--  -->
      <div class="row pt-2">
        <div class="col-7">
          <h6 class="text-uppercase py-2 font-weight-bold">Report Details</h6>
          <ul class="list-unstyled">
            <li class="pb-1">
              <span class="clr-cyan">Name : </span>{{ report.patient?.name | uppercase }}
            </li>
            <li class="pb-1">
              <span class="clr-cyan">Gender : </span>{{ report.patient?.gender | titlecase }}
            </li>
            <li class="pb-1">
              <span class="clr-cyan">Age/DOB : </span><span *ngIf="report.patient.dateOfBirth != null">{{
                report.patient.dateOfBirth | date : "dd/MM/yyyy" }}
              </span>
              <span *ngIf="report.patient.age != null">
                {{ report.patient.age }} Years
              </span>
            </li>
            <li class="pb-1">
              <span class="clr-cyan">Reporting Date : </span>{{ date | date : "dd/MM/yyyy" }}
            </li>
          </ul>
        </div>
        <div class="col">
          <ul class="list-unstyled pt-4 mt-1">
            <li *ngIf="report.cancerSubType != null" class="pb-1">
              <span class="clr-cyan">Cancer Celltype : </span>{{ report.cancerSubType }}
            </li>
            <li class="pb-1">
              <span class="clr-cyan">Sample Source : </span>{{ sampleSource }}
            </li>
            <li class="pb-1">
              <span class="clr-cyan">Consulting Clinician : </span>{{referringClinician }}
            </li>
            <li class="pb-1">
              <span class="clr-cyan">Hospital : </span>{{ report.labDetails?.hospital }}
            </li>
          </ul>
        </div>
      </div>
      <!--  -->
      <div class="row mt-4 py-2">
        <div class="col">
          <h5 class="mb-0 text-uppercase f-weight_700">Results</h5>
        </div>
        <div class="col">
          <ul class="res_icons list-unstyled d-flex" style="float: right; font-style: italic">
            <li><img src="../../../assets/responsive.png" />Responsive</li>
            <li class="pl-4 mr-2">
              <img src="../../../assets/ClinicalTrial.png" />Ongoing Trial
            </li>
            <li class="pl-4 mr-2">
              <img src="../../../assets/resistence.png" />Resistance
            </li>
          </ul>
        </div>
      </div>
      <h5 class="py-2 text-center w-100 rounded" style="background: #208dcb; color: #fff">
        CLINICALLY ACTIONABLE BIOMARKERS
      </h5>
      <!--  -->
      <div class="row">
        <div class="col-12">
          <table class="page_1 cust_table w-100 mt-3">
            <tr>
              <th class="text-center">Biomarker Category</th>
              <th class="text-center">Result Status</th>
              <th class="text-center">Evidence</th>
              <th class="text-center">Effect</th>
              <th class="text-center">Therapy</th>
            </tr>
            <!-- SNV -->

            <ng-container *ngIf="(reportPdfRes?.starClinicalTrailList?.length || 0) > 0">
              <tr *ngFor="
                    let starClinicalTrail of reportPdfRes?.starClinicalTrailList;
                    let i = index
                  ">
                <td *ngIf="i == 0" [attr.rowspan]="reportPdfRes?.starClinicalTrailList?.length">
                  SNVs/InDels
                </td>
                <!-- <td *ngIf="i>0"></td> -->
                <td>
                  <i>{{ starClinicalTrail?.gene }}</i>
                  <!-- Provide variant detail (p.  ) along with The Gene name (125) -->
                  <p>
                    <span style="
                          font-size: 12px !important;
                          text-align: left !important;
                        ">{{ starClinicalTrail?.snvVarinat }}</span>
                  </p>
                </td>
                <td colspan="3" class="border_line">
                  <table style="padding: 0px !important">
                    <tr *ngFor="let item of starClinicalTrail?.requriedData">
                      <td style="width: 144px; padding: 0px !important">
                        <img *ngIf="item?.evidance == 'LEVEL_1'" class="mt-2" src="../../../assets/Level1.png"
                          style="width: 20px" />
                        <img *ngIf="item?.evidance == 'LEVEL_2'" class="mt-2" src="../../../assets/Level2.png"
                          style="width: 20px" />
                        <img *ngIf="item?.evidance == 'LEVEL_3'" class="mt-2" src="../../../assets/Level3.png"
                          style="width: 20px" />
                        <img *ngIf="item?.evidance == 'LEVEL_4'" class="mt-2" src="../../../assets/Level4.png"
                          style="width: 20px" />
                        <span *ngIf="item?.evidance == undefined">NA</span>
                      </td>

                      <td style="width: 144px; padding: 0px !important">
                        <img *ngIf="item?.association == 'RESPONSIVE'" src="../../../assets/responsive.png"
                          style="width: 15px" />
                        <img *ngIf="item?.association == 'RESISTANCE'" src="../../../assets/Resistance.png"
                          style="width: 15px" />
                        <img *ngIf="item?.association == 'ONGOING'" src="../../../assets/ClinicalTrial.png"
                          style="width: 15px" />
                        <span *ngIf="item?.association == undefined">NA</span>
                      </td>

                      <td style="
                            overflow-wrap: anywhere;
                            padding: 0px !important;
                          ">
                        {{ item?.drugName || "NA" }}
                      </td>
                    </tr>
                  </table>
                </td>
                <!-- <td colspan="3" style="padding:2px!important">
                                      <table class="table clinical_table " style="padding:2px!important">
                                        <tr *ngFor="let item of starClinicalTrail.requriedData" style="padding:2px!important">
                                          <td style=" width:25%!important;padding:2px!important;">
                                            <img *ngIf="item.evidance == 'LEVEL_3'" src="../../../assets/dot-1.png" style="width: 15px;">
                                            <img *ngIf="item.evidance == 'LEVEL_1'" class="mt-2" src="../../../assets/dot-2.png" style="width: 20px;">
                                            <span *ngIf="item.evidance == undefined">NA</span>

                                          </td>

                                          <td style="width:21%!important;padding:2px!important">
                                            <img *ngIf="item.association == 'RESPONSIVE'" src="../../../assets/responsive.png" style="width: 15px;">
                                            <img *ngIf="item.association == 'RESISTANCE'" src="../../../assets/Resistance.png" style="width: 15px;">
                                            <img *ngIf="item.association == 'ONGOING'" src="../../../assets/ClinicalTrial.png" style="width: 15px;">
                                            <span *ngIf="item.association == undefined">NA</span>
                                          </td>

                                          <td style="overflow-wrap: anywhere; padding:2px!important">{{item.drugName || "NA"}}</td>
                                        </tr>
                                      </table>
                                    </td> -->
                <!-- <td class="text-center">

                                    <p *ngFor="let evidence of starClinicalTrail.evidenceList">
                                        <img *ngIf="evidence == 'LEVEL_1'" class="mt-2" src="../../../assets/dot-2.png"
                                            style="width: 20px;">
                                    </p>
                                    <p *ngFor="let nctEvidence of starClinicalTrail.nctEvidenceList">
                                        <img *ngIf="nctEvidence == 'LEVEL_3'" src="../../../assets/dot-1.png"
                                            style="width: 15px;">
                                    </p>

                                </td>
                                <td class="text-center">

                                    <p *ngFor="let association of starClinicalTrail.associationList">
                                        <img *ngIf="association == 'RESPONSIVE'" mt-2
                                            src="../../../assets/responsive.png"
                                            style="width: 20px; margin-right: 5px;">
                                        <img *ngIf="association == 'RESISTANCE'" mt-2
                                            src="../../../assets/resistence.png"
                                            style="width: 20px; margin-right: 5px;">
                                        <img *ngIf="association == 'ONGOING'" mt-2
                                            src="../../../assets/ClinicalTrial.png"
                                            style="width: 20px; margin-right: 5px;">
                                    </p>
                                    <p *ngFor="let nctAssociation of starClinicalTrail.nctAssociationList">
                                        <img *ngIf="nctAssociation == 'RESPONSIVE'" src="../../../assets/responsive.png"
                                            style="width: 15px;">
                                        <img *ngIf="nctAssociation == 'RESISTANCE'" src="../../../assets/Resistance.png"
                                            style="width: 15px;">
                                        <img *ngIf="nctAssociation == 'ONGOING'" src="../../../assets/ClinicalTrial.png"
                                            style="width: 15px;">
                                    </p>


                                </td>
                                <td>

                                    <div *ngFor="let drugs of starClinicalTrail.drugList">
                                        <span *ngFor="let drug of drugs, let i=index">{{drug.name|titlecase}}
                                            <span *ngIf="drugs.length-1 != i">{{i ? '' : ', '}}</span>
                                        </span>
                                    </div>

                                    <div *ngFor="let drugs of starClinicalTrail.nctDrugList">
                                        <span *ngFor="let drug of drugs, let i=index">
                                            {{drug|titlecase}}<span *ngIf="drugs.length-1 != i">{{i ? '' : ''}},</span>
                                        </span>
                                    </div>


                                </td> -->
              </tr>
            </ng-container>
            <ng-container *ngIf="reportPdfRes?.starClinicalTrailList?.length == 0">
              <tr>
                <td>SNVs/InDels</td>
                <td colspan="4" style="text-align: center">
                  Clinically relevant SNV's/InDels were not detected
                </td>
              </tr>
            </ng-container>

            <!-- CNA -->
            <ng-container *ngIf="reportPdfRes.cnaStarClinicalTrailList.length > 0">
              <tr *ngFor="
                    let starClinicalTrail of reportPdfRes.cnaStarClinicalTrailList;
                    let i = index
                  ">
                <td *ngIf="i == 0" [attr.rowspan]="
                      reportPdfRes.cnaStarClinicalTrailList.length
                    ">
                  CNAs
                </td>
                <td>
                  <i>{{ starClinicalTrail.gene }}</i>
                  <p>
                    <span style="
                          font-size: 12px !important;
                          text-align: left !important;
                        ">{{ starClinicalTrail.snvVarinat }}</span>
                  </p>
                </td>
                <td colspan="3">
                  <table style="padding: 0px !important">
                    <tr *ngFor="let item of starClinicalTrail.requriedData">
                      <td style="width: 144px; padding: 0px !important">
                        <img *ngIf="item.evidance == 'LEVEL_1'" class="mt-2" src="../../../assets/Level1.png"
                          style="width: 20px" />
                        <img *ngIf="item.evidance == 'LEVEL_2'" class="mt-2" src="../../../assets/Level2.png"
                          style="width: 20px" />
                        <img *ngIf="item.evidance == 'LEVEL_3'" class="mt-2" src="../../../assets/Level3.png"
                          style="width: 20px" />
                        <img *ngIf="item.evidance == 'LEVEL_4'" class="mt-2" src="../../../assets/Level4.png"
                          style="width: 20px" />
                        <span *ngIf="item.evidance == undefined">NA</span>
                      </td>

                      <td style="width: 144px; padding: 0px !important">
                        <img *ngIf="item.association == 'RESPONSIVE'" src="../../../assets/responsive.png"
                          style="width: 15px" />
                        <img *ngIf="item.association == 'RESISTANCE'" src="../../../assets/Resistance.png"
                          style="width: 15px" />
                        <img *ngIf="item.association == 'ONGOING'" src="../../../assets/ClinicalTrial.png"
                          style="width: 15px" />
                        <span *ngIf="item.association == undefined">NA</span>
                      </td>

                      <td style="
                            overflow-wrap: anywhere;
                            padding: 0px !important;
                          ">
                        {{ item.drugName || "NA" }}
                      </td>
                    </tr>
                  </table>
                </td>
                <!-- <td class="text-center">

                                    <p *ngFor="let evidence of starClinicalTrail.evidenceList">
                                        <img *ngIf="evidence == 'LEVEL_1'" class="mt-2" src="../../../assets/dot-2.png"
                                            style="width: 20px;">
                                    </p>
                                    <p *ngFor="let nctEvidence of starClinicalTrail.nctEvidenceList">
                                        <img *ngIf="nctEvidence == 'LEVEL_3'" src="../../../assets/dot-1.png"
                                            style="width: 15px;">
                                    </p>

                                </td>
                                <td class="text-center">

                                    <p *ngFor="let association of starClinicalTrail.associationList">
                                        <img *ngIf="association == 'RESPONSIVE'" mt-2
                                            src="../../../assets/responsive.png"
                                            style="width: 20px; margin-right: 5px;">
                                        <img *ngIf="association == 'RESISTANCE'" mt-2
                                            src="../../../assets/resistence.png"
                                            style="width: 20px; margin-right: 5px;">
                                        <img *ngIf="association == 'ONGOING'" mt-2
                                            src="../../../assets/ClinicalTrial.png"
                                            style="width: 20px; margin-right: 5px;">
                                    </p>
                                    <p *ngFor="let nctAssociation of starClinicalTrail.nctAssociationList">
                                        <img *ngIf="nctAssociation == 'RESPONSIVE'" src="../../../assets/responsive.png"
                                            style="width: 15px;">
                                        <img *ngIf="nctAssociation == 'RESISTANCE'" src="../../../assets/Resistance.png"
                                            style="width: 15px;">
                                        <img *ngIf="nctAssociation == 'ONGOING'" src="../../../assets/ClinicalTrial.png"
                                            style="width: 15px;">

                                    </p>


                                </td>
                                <td>

                                    <div *ngFor="let drugs of starClinicalTrail.drugList">
                                        <span *ngFor="let drug of drugs, let i=index">{{drug.name|titlecase}}
                                            <span *ngIf="drugs.length-1 != i">{{i ? '' : ', '}}</span>
                                        </span>
                                    </div>

                                    <div *ngFor="let drugs of starClinicalTrail.nctDrugList">
                                        <span *ngFor="let drug of drugs, let i=index">
                                            {{drug|titlecase}}<span *ngIf="drugs.length-1 != i">{{i ? '' : ''}},</span>
                                        </span>
                                    </div>


                                </td> -->
              </tr>
            </ng-container>
            <ng-container *ngIf="reportPdfRes.cnaStarClinicalTrailList.length == 0">
              <tr>
                <td>CNAs</td>
                <td colspan="4" style="text-align: center">
                  Clinically relevant CNAs were not detected
                </td>
              </tr>
            </ng-container>


            <!-- PD-L1 -->


            <!-- TMB -->
            <tr *ngIf=" tmb > 20">
              <td>TMB</td>
              <td>TMB High</td>
              <td class="text-center" style="text-align: left !important">
                <img src="../../../assets/dot-2.png" style="width: 20px" />
              </td>
              <td class="text-center" style="text-align: left !important">
                <img src="../../../assets/responsive.png" style="width: 20px; margin-right: 5px" />
              </td>
              <td style="text-align: left !important">
                <div *ngFor="
                      let highTmbClinicalTrail of reportPdfRes.highTmbClinicalTrail
                    ">
                  <span *ngFor="
                        let drugs of highTmbClinicalTrail.drugList;
                        let di = index
                      ">
                    <span *ngFor="let drug of drugs; let i = index">
                      {{ drug.name
                      }}{{
                      highTmbClinicalTrail.drugList.length - 1 != di
                      ? ","
                      : ""
                      }}
                    </span>
                  </span>
                </div>
              </td>
            </tr>

            <!-- MSI -->
            <tr *ngIf="
                  (msi > 15) ||
                  (msi > 16)
                ">
              <td>MSI</td>
              <td>MSI-H</td>
              <td class="text-center" style="text-align: left !important">
                <img src="../../../assets/dot-2.png" style="width: 20px" />
              </td>
              <td class="text-center" style="text-align: left !important">
                <img src="../../../assets/responsive.png" style="width: 20px; margin-right: 5px" />
              </td>
              <td style="text-align: left !important">
                <div *ngFor="
                      let highMsiClinicalTrail of reportPdfRes.highMsiClinicalTrail
                    ">
                  <span *ngFor="
                        let drugs of highMsiClinicalTrail.drugList;
                        let di = index
                      ">
                    <span *ngFor="let drug of drugs; let i = index">{{ drug.name }}{{ i ? "" : "" }}
                      <span *ngIf="highMsiClinicalTrail.drugList.length - 1 != di">,</span>
                    </span>
                  </span>
                </div>
              </td>
            </tr>

            <!-- when all are Negative -->
            <tr *ngIf=" ((
                      msi <= 15 &&
                      tmb <= 20))
                ">
              <td>
                TMB/MSI
              </td>
              <td colspan="4" style="text-align: center !important">
                Negative
              </td>
            </tr>
          </table>
          <p style="font-size: 15px !important" class="mt-3">
            <i> *NA: Not Applicable </i>
          </p>
        </div>
      </div>

      <!--  -->

      <!--  -->
      <div class="levels_section row mt-4 py-3 rounded mx-0" style="background: #f1f6f6">
        <div class="col">
          <p>
            <img src="../../../assets/Level1.png" style="width: 10px; margin-right: 8px" />Level 1 - Biomarkers included
            in FDA-approved guidelines
          </p>
          <p class="mb-0">
            <img src="../../../assets/Level3.png" style="width: 10px; margin-right: 8px" />Level 3 - Biomarkers from
            clinical trials & well-powered studies
          </p>
        </div>
        <div class="col text-right">
          <p>
            <img src="../../../assets/Level2.png" style="width: 10px; margin-right: 8px" />Level 2 - Biomarkers included
            in professional guidelines
          </p>
          <p class="mb-0">
            <img src="../../../assets/Level4.png" style="width: 10px; margin-right: 8px" />Level 4 - Biomarkers with
            Preclinical / biological evidence
          </p>
        </div>
        <div class="w-100"></div>
        <div class="col px-4 mt-3">
          <p class="mb-0">
            Level 1, 2 and 3 corresponds to Tier 1 and Tier 2 classification
            of AMP/CAP/ASCO guidelines for the patient's cancer type and other
            cancers respectively.
          </p>
          <p class="mb-0">
            Please refer
            <span class="clr-cyan font-weight-bold">‘Categories of Clinical and/or Experimental Evidence’</span>
            in <span class="font-weight-bold">supplementary section</span> for
            details
          </p>
        </div>
      </div>
      <!-- <div class="text-center py-5 mt-3">
                        <img src="../../../assets/Infographic - 1.png" alt="" class="w-85">
                    </div> -->
      <!-- </div>
            </div> -->
      <!--  -->
      <div class="row footer">
        <div class="col-12">
          <div class="pb-2 pl-4 text">
            <div class="d-block">
              <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
            </div>
            <div class="footer_body" [ngClass]="'gene-bg_blue'">
              <div class="footer_list">
                <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                <span>Gln - Q</span><span>Gly - G</span><span>His - H</span>
                <span>Ile - I</span>
              </div>
              <div class="footer_list">
                <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                <span>Val - V</span>
              </div>
            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p style="font-size: 8px!important; line-height: 14px!important;">
              {{footerDiscription}}<br />{{footerDiscription2}}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="page2" style="padding: 10px; margin: 0 auto" class="main_wrapper">
    <div class="container">
      <div class="row header">
        <div *ngIf="report.clientType != 'client'">
          <div class="col-3">
            <div class="logo">
              <img src="../../../assets/logo.png" alt="" style="width: 140px" />
            </div>
          </div>
        </div>
        <div *ngIf="report.clientType == 'client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
        <div class="col">
          <div class="patient-detailes ml-auto">
            <ul class="list-unstyled d-flex pt-3">
              <li>
                Patient Id<br /><span>{{ report.patient?.ecrfId }}</span>
              </li>
              <li>
                Cancer Site<br /><span>{{ report.cancerType }}</span>
              </li>
              <li>
                SAMPLE ID<br /><span>{{ report.labDetails?.sampleId }}</span>
              </li>
              <li>
                Registration Date<br /><span>{{
                  report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div *ngIf="report.clientType == '4basecare_client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="mar_top"></div>
      <!--  -->
      <div class="row mt-2">
        <div class="col">
          <h5 class="text-center rounded py-2 font-weight-bold" style="border: 1px solid #208dcb; color: #208dcb">
            SCIENTIFIC EVIDENCE BASED SUMMARY OF THE GENOMIC FINDINGS
          </h5>
          <div class="py-5" [innerHtml]="evidenceSummary" style="line-height: 28px; text-align: justify"></div>
          <!-- <p class="mb-3">
                        {{report.evidenceSummary}}
                    </p> -->
          <!-- <pre>{{report.evidenceSummary}}</pre> -->
        </div>
      </div>
      <!--  -->
      <div class="row" *ngIf="reportPdfRes.variantPathwayList.length > 0">
        <div class="col-12 mb-3">
          <h5 class="mt-3 mb-2 text-center rounded py-2 font-weight-bold"
            style="border: 1px solid #208dcb; color: #208dcb">
            CLINICALLY RELEVANT VARIANTS AND ASSOCIATED PATHWAYS
          </h5>
        </div>
        <div class="col mt-2">
          <table class="Clinical-relavent_table w-100 mt-2">
            <tr>
              <th>Gene Name</th>
              <th>Variant</th>
              <th>Exon Number</th>
              <th>Mutant Allele Burden (%)</th>
              <th>Associated Pathway</th>
            </tr>
            <tr *ngFor="let variantPathway of reportPdfRes.variantPathwayList">
              <td>
                <i>{{ variantPathway.gene }}</i>
              </td>
              <td>
                <span *ngIf="
                      variantPathway.variant != null ||
                      variantPathway.variant != ' ' ||
                      variantPathway.variant != ''
                    ">{{ variantPathway.variant }}</span>
                <span *ngIf="
                      variantPathway.variant == null ||
                      variantPathway.variant == ' ' ||
                      variantPathway.variant == ''
                    ">NA</span>
              </td>

              <td>{{ variantPathway.exonNumber || "NA" }}</td>
              <td *ngIf="variantPathway.mutantBurdenPercent != null">
                {{ variantPathway.mutantBurdenPercent }}
              </td>
              <td *ngIf="variantPathway.mutantBurdenPercent == null">NA</td>
              <td>{{ variantPathway.associatedPathway }}</td>
            </tr>
          </table>
          <p style="font-size: 15px !important" class="mt-3">
            <i> *NA: Not Applicable </i>
          </p>
        </div>
      </div>

      <!-- footer -->
      <div class="row footer">
        <div class="col-12">
          <div class="pb-2 pl-4 text">
            <div class="d-block">
              <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
            </div>
            <div class="footer_body" [ngClass]="'gene-bg_blue'">
              <div class="footer_list">
                <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                <span>Gln - Q</span><span>Gly - G</span><span>His - H</span>
                <span>Ile - I</span>
              </div>
              <div class="footer_list">
                <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                <span>Val - V</span>
              </div>
            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p style="font-size: 8px!important; line-height: 14px!important;">
              {{footerDiscription}}<br />{{footerDiscription2}}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="page3" *ngIf="
        report.interpretation != null && report.interpretation != '<p></p>'
      " style="padding: 10px; margin: 0 auto" class="main_wrapper">
    <div class="container">
      <div class="row header">
        <div *ngIf="report.clientType != 'client'">
          <div class="col-3">
            <div class="logo">
              <img src="../../../assets/logo.png" alt="" style="width: 140px" />
            </div>
          </div>
        </div>
        <div *ngIf="report.clientType == 'client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
        <div class="col">
          <div class="patient-detailes ml-auto">
            <ul class="list-unstyled d-flex pt-3">
              <li>
                Patient Id<br /><span>{{ report.patient?.ecrfId }}</span>
              </li>
              <li>
                Cancer Site<br /><span>{{ report.cancerType }}</span>
              </li>
              <li>
                SAMPLE ID<br /><span>{{ report.labDetails?.sampleId }}</span>
              </li>
              <li>
                Registration Date<br /><span>{{
                  report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div *ngIf="report.clientType == '4basecare_client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="mar_top"></div>
      <!--  -->
      <div class="row mt-2">
        <div class="col">
          <h5 class="mb-4 text-center rounded py-2" style="font-weight: 500; background-color: #208dcb; color: #fff">
            RESULT INTERPRETATION
          </h5>
          <div [innerHtml]="interpretation0" style="line-height: 28px; text-align: justify"></div>
          <!-- <p class="mb-3">
                        {{report.interpretation}}
                    </p> -->
          <!-- <pre>{{report.interpretation}}</pre> -->
        </div>
      </div>
      <!--  -->

      <!-- footer -->
      <div class="row footer">
        <div class="col-12">
          <div class="pb-2 pl-4 text">
            <div class="d-block">
              <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
            </div>
            <div class="footer_body" [ngClass]="'gene-bg_blue'">
              <div class="footer_list">
                <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                <span>Gln - Q</span><span>Gly - G</span><span>His - H</span>
                <span>Ile - I</span>
              </div>
              <div class="footer_list">
                <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                <span>Val - V</span>
              </div>
            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p style="font-size: 8px!important; line-height: 14px!important;">
              {{footerDiscription}}<br />{{footerDiscription2}}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="page3_1" *ngIf="interpretation1 != null && interpretation1 != '<p></p>'"
    style="padding: 10px; margin: 0 auto" class="main_wrapper">
    <div class="container">
      <div class="row header">
        <div *ngIf="report.clientType != 'client'">
          <div class="col-3">
            <div class="logo">
              <img src="../../../assets/logo.png" alt="" style="width: 140px" />
            </div>
          </div>
        </div>
        <div *ngIf="report.clientType == 'client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
        <div class="col">
          <div class="patient-detailes ml-auto">
            <ul class="list-unstyled d-flex pt-3">
              <li>
                Patient Id<br /><span>{{ report.patient?.ecrfId }}</span>
              </li>
              <li>
                Cancer Site<br /><span>{{ report.cancerType }}</span>
              </li>
              <li>
                SAMPLE ID<br /><span>{{ report.labDetails?.sampleId }}</span>
              </li>
              <li>
                Registration Date<br /><span>{{
                  report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div *ngIf="report.clientType == '4basecare_client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="mar_top"></div>
      <!--  -->
      <div class="row mt-2">
        <div class="col">
          <h5 class="mb-4 text-center rounded py-2" style="font-weight: 500; background-color: #208dcb; color: #fff">
            RESULT INTERPRETATION
          </h5>
          <!-- <h1>{{interpretation1.length}}</h1> -->
          <div [innerHtml]="interpretation1" style="line-height: 28px; text-align: justify"></div>
          <!-- <p class="mb-3">
                    {{report.interpretation}}
                </p> -->
          <!-- <pre>{{report.interpretation}}</pre> -->
        </div>
      </div>
      <!--  -->

      <!-- footer -->
      <div class="row footer">
        <div class="col-12">
          <div class="pb-2 pl-4 text">
            <div class="d-block">
              <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
            </div>
            <div class="footer_body" [ngClass]="'gene-bg_blue'">
              <div class="footer_list">
                <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                <span>Gln - Q</span><span>Gly - G</span><span>His - H</span>
                <span>Ile - I</span>
              </div>
              <div class="footer_list">
                <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                <span>Val - V</span>
              </div>
            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p style="font-size: 8px!important; line-height: 14px!important;">
              {{footerDiscription}}<br />{{footerDiscription2}}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="page4" style="padding: 10px; margin: 0 auto" class="main_wrapper">
    <div class="container">
      <div class="row header">
        <div *ngIf="report.clientType != 'client'">
          <div class="col-3">
            <div class="logo">
              <img src="../../../assets/logo.png" alt="" style="width: 140px" />
            </div>
          </div>
        </div>
        <div *ngIf="report.clientType == 'client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
        <div class="col">
          <div class="patient-detailes ml-auto">
            <ul class="list-unstyled d-flex pt-3">
              <li>
                Patient Id<br /><span>{{ report.patient?.ecrfId }}</span>
              </li>
              <li>
                Cancer Site<br /><span>{{ report.cancerType }}</span>
              </li>
              <li>
                SAMPLE ID<br /><span>{{ report.labDetails?.sampleId }}</span>
              </li>
              <li>
                Registration Date<br /><span>{{
                  report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div *ngIf="report.clientType == '4basecare_client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="mar_top"></div>
      <!--  -->
      <div class="row mt-2">
        <div class="col">
          <h5 class="mb-2 text-center rounded py-2"
            style="font-weight: 500; background-color: #208dcb; color: #fff">
            IMMUNOTHERAPY HIGHLIGHTS
          </h5>

        </div>
      </div>
      <!--  -->
      <div class="row">
        <div class="col">
          <table class="immuno_table w-100">
            <tr>
              <th>
                Biomarkers
                <span style="
                      color: #208dcb;
                      font-weight: bold;
                      font-size: 17px !important;
                    ">(Technique)</span>
              </th>
              <th>Impact</th>
              <th>Status</th>
            </tr>

            <!-- PD-L1 -->


            <!-- TMB -->
            <tr style="text-align: justify">
              <td>
                Tumor Mutational Burden<br />
                <span style="color: #208dcb">TMB (NGS)</span>
              </td>
              <td>
                <span style="color: #208dcb; font-weight: bold">TMB</span> is
                a measure of the mutation load in a tumor sample. High TMB is
                associated with production of more neo-antigens which may be
                recognized by the immune system and inciting an anti-tumor
                response. It is also associated with better response rate with
                immune-checkpoint inhibitors. A sample is categorized as High
                TMB if the TMB is found to be >20 mutations/ megabase.
              </td>
              <td *ngIf="tmb > 20">
                <img src="../../../assets/PD-L1 Expression.png" class="mr-2" />
                <span style="color: #208dcb; font-weight: bold">HIGH</span>
              </td>
              <td *ngIf="tmb < 20">
                <img src="../../../assets/LOW.png" class="mr-2" /> LOW
              </td>
              <td *ngIf="tmb == 20">
                <img src="../../../assets/STABLE.png" class="mr-2" />STABLE
              </td>
            </tr>

            <!-- MSI  -->
            <tr>
              <td>
                Microsatellite Status<br />
                <span style="color: #208dcb">MSI-H/MSS (NGS)</span>
              </td>
              <td>
                <span style="color: #208dcb; font-weight: bold"> MSI-H</span>
                refers to hypermutable phenotype caused by the loss of DNA
                mismatch repair activity.
              </td>
              <td *ngIf="msi > 15">
                <img src="../../../assets/PD-L1 Expression.png" class="mr-2" />
                <span style="color: #208dcb; font-weight: bold">HIGH</span>
              </td>
              <td *ngIf="msi <= 15">
                <img src="../../../assets/STABLE.png" class="mr-2" />STABLE
              </td>
            </tr>
          </table>
        </div>
      </div>


      <!-- footer -->
      <div class="row footer">
        <div class="col-12">
          <div class="pb-2 pl-4 text">
            <div class="d-block">
              <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
            </div>
            <div class="footer_body" [ngClass]="'gene-bg_blue'">
              <div class="footer_list">
                <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                <span>Gln - Q</span><span>Gly - G</span><span>His - H</span>
                <span>Ile - I</span>
              </div>
              <div class="footer_list">
                <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                <span>Val - V</span>
              </div>
            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p style="font-size: 8px!important; line-height: 14px!important;">
              {{footerDiscription}}<br />{{footerDiscription2}}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="page5" *ngIf="
        (
          (report.immunoDetails?.pdl1Status == 'EXPRESSION' ||
            tmb > 20 ||
            msi > 15))
      " style="padding: 10px; margin: 0 auto" class="main_wrapper">
    <div class="container">
      <div class="row header">
        <div *ngIf="report.clientType != 'client'">
          <div class="col-3">
            <div class="logo">
              <img src="../../../assets/logo.png" alt="" style="width: 140px" />
            </div>
          </div>
        </div>
        <div *ngIf="report.clientType == 'client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo" sty>
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>

        <div class="col">
          <div class="patient-detailes ml-auto">
            <ul class="list-unstyled d-flex pt-3">
              <li>
                Patient Id<br /><span>{{ report.patient?.ecrfId }}</span>
              </li>
              <li>
                Cancer Site<br /><span>{{ report.cancerType }}</span>
              </li>
              <li>
                SAMPLE ID<br /><span>{{ report.labDetails?.sampleId }}</span>
              </li>
              <li>
                Registration Date<br /><span>{{
                  report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div *ngIf="report.clientType == '4basecare_client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="mar_top"></div>
      <!--  -->
      <div class="row">
        <div class="col">
          <h5 class="py-2 text-center px-0 rounded" style="background: #208dcb; color: #fff; font-weight: 500">
            RELEVANT THERAPIES FOR IMMUNOTHERAPY BIOMARKERS
          </h5>
        </div>
      </div>
      <div class="row my-3">
        <div class="col">
          <ul class="res_icons list-unstyled d-flex" style="float: right; font-style: italic">
            <li><img src="../../../assets/responsive.png" />Responsive</li>
            <li class="pl-4 mr-2">
              <img src="../../../assets/ClinicalTrial.png" />Ongoing Trial
            </li>
            <li class="pl-4 mr-2">
              <img src="../../../assets/resistence.png" />Resistance
            </li>
          </ul>
        </div>
      </div>
      <!--  -->

      <div class="row">
        <div class="col-12">
          <table class="page-5_table rel_immuno_biomarkers w-100 mt-3">
            <tr>
              <th>Markers</th>
              <th>Therapy</th>
              <th>Cancer Type</th>
              <th class="text-center">Effect</th>
              <th class="text-center">Evidence</th>
              <th>Reference(s)</th>
            </tr>

            <!-- <tr *ngFor="let pdl1ClinicalTrail of reportPdfRes.pdl1ClinicalTrail">
                            <td [attr.rowspan]="reportPdfRes.pdl1ClinicalTrail[0].associationList">
                                <h2 style=" font-weight: normal;">PD-L1</h2>
                            </td>
                            <td>
                                <div *ngFor="let drugs of pdl1ClinicalTrail.drugList">
                                    <div *ngFor="let drug of drugs, let i=index">
                                        <span>{{drug.name}}</span>
                                        <span *ngIf="drugs.length-1 != i">,</span>
                                    </div>
                                </div>

                            </td>
                            <td>
                                <div *ngFor="let cancerType of pdl1ClinicalTrail.resultCancerTypeList">
                                    <p>{{cancerType.name}}</p>
                                </div>
                            </td>
                            <td class="text-center">
                                <div *ngFor="let association of pdl1ClinicalTrail.associationList">

                                    <img *ngIf="association == 'RESPONSIVE'" src="../../../assets/responsive.png"
                                        style="width: 15px;">
                                    <img *ngIf="association == 'RESISTANCE'" src="../../../assets/Resistance.png"
                                        style="width: 15px;">
                                    <img *ngIf="association == 'ONGOING'" src="../../../assets/ClinicalTrial.png"
                                        style="width: 15px;">
                                      </div>
                            </td>
                            <td class="text-center">
                                <div *ngFor="let cancerType of pdl1ClinicalTrail.associationList">
                                    <img src="../../../assets/dot-2.png" style="width: 15px;">
                                </div>
                            </td>
                            <td>
                                <p *ngFor="let cancerType of pdl1ClinicalTrail.associationList">FDA Guidelines</p>
                            </td>
                        </tr> -->

            <!-- Pdl1 expression -->
            <tr *ngFor="let pdl1Expression of pdl1ExpressionList">
              <ng-container *ngIf="pdl1Expression.geneRowCount != 0">
                <td [attr.rowSpan]="pdl1Expression.geneRowCount">
                  <h2 style="font-weight: normal">
                    {{ pdl1Expression.gene }}
                  </h2>
                </td>
              </ng-container>
              <td>{{ pdl1Expression.therepy }}</td>
              <td>{{ pdl1Expression.cancerType }}</td>
              <td style="text-align: left !important">
                <img *ngIf="pdl1Expression.association == 'RESPONSIVE'" src="../../../assets/responsive.png"
                  style="width: 15px" />
                <img *ngIf="pdl1Expression.association == 'RESISTANCE'" src="../../../assets/Resistance.png"
                  style="width: 15px" />
                <img *ngIf="pdl1Expression.association == 'ONGOING'" src="../../../assets/ClinicalTrial.png"
                  style="width: 15px" />
              </td>
              <td style="text-align: left !important">
                <img *ngIf="pdl1Expression.evidance == 'LEVEL_1'" src="../../../assets/dot-2.png" style="width: 15px" />
                <img *ngIf="pdl1Expression.evidance == 'LEVEL_3'" src="../../../assets/dot-1.png" style="width: 15px" />
                <p *ngIf="pdl1Expression.evidance == 'LEVEL_2'">NA</p>
                <p *ngIf="pdl1Expression.evidance == 'LEVEL_4'">NA</p>
              </td>
              <td>{{ pdl1Expression.reference }}</td>
            </tr>
            <!--  -->
            <tr *ngFor="
                  let highTmbClinicalTrail of reportPdfRes.highTmbClinicalTrail
                ">
              <td [attr.rowspan]="
                    reportPdfRes.highTmbClinicalTrail[0].associationList
                  ">
                <h2 style="font-weight: normal">TMB</h2>
              </td>

              <td>
                <div *ngFor="let drugs of highTmbClinicalTrail.drugList">
                  <span *ngFor="let drug of drugs; let i = index">
                    {{ drug.name
                    }}<span *ngIf="drugs.length - 1 != i">,</span>
                  </span>
                </div>
              </td>
              <td>
                <div *ngFor="
                      let cancerType of highTmbClinicalTrail.resultCancerTypeList
                    ">
                  <p>{{ cancerType.name }}</p>
                </div>
              </td>
              <td style="text-align: left !important">
                <div *ngFor="
                      let association of highTmbClinicalTrail.associationList
                    ">
                  <img *ngIf="association == 'RESPONSIVE'" src="../../../assets/responsive.png" style="width: 15px" />
                  <img *ngIf="association == 'RESISTANCE'" src="../../../assets/Resistance.png" style="width: 15px" />
                  <img *ngIf="association == 'ONGOING'" src="../../../assets/ClinicalTrial.png" style="width: 15px" />
                </div>
              </td>
              <td style="text-align: left !important">
                <div *ngFor="
                      let cancerType of highTmbClinicalTrail.associationList
                    ">
                  <img src="../../../assets/dot-2.png" style="width: 15px" />
                </div>
              </td>
              <td>
                <div *ngFor="
                      let cancerType of highTmbClinicalTrail.associationList
                    ">
                  FDA Guidelines
                </div>
              </td>
            </tr>
            <!-- MSI -->
            <tr *ngFor="
                  let highMsiClinicalTrail of reportPdfRes.highMsiClinicalTrail
                ">
              <td [attr.rowspan]="
                    reportPdfRes.highMsiClinicalTrail[0].associationList
                  ">
                <h2 style="font-weight: normal">MSI</h2>
              </td>

              <td>
                <div *ngFor="let drugs of highMsiClinicalTrail.drugList">
                  <span *ngFor="let drug of drugs; let i = index">
                    {{ drug.name
                    }}<span *ngIf="drugs.length - 1 != i">,</span>
                  </span>
                </div>
              </td>
              <td>
                <div *ngFor="
                      let cancerType of highMsiClinicalTrail.resultCancerTypeList
                    ">
                  <p>{{ cancerType.name }}</p>
                </div>
              </td>
              <td style="text-align: left !important">
                <div *ngFor="
                      let association of highMsiClinicalTrail.associationList
                    ">
                  <img *ngIf="association == 'RESPONSIVE'" src="../../../assets/responsive.png" style="width: 15px" />
                  <img *ngIf="association == 'RESISTANCE'" src="../../../assets/Resistance.png" style="width: 15px" />
                  <img *ngIf="association == 'ONGOING'" src="../../../assets/ClinicalTrial.png" style="width: 15px" />
                </div>
              </td>
              <td style="text-align: left !important">
                <div *ngFor="
                      let cancerType of highMsiClinicalTrail.associationList
                    ">
                  <img src="../../../assets/dot-2.png" style="width: 15px" />
                </div>
              </td>
              <td>
                <div *ngFor="
                      let cancerType of highMsiClinicalTrail.associationList
                    ">
                  FDA Guidelines
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>

      <!--  -->
      <div class="levels_section row mt-4 py-3 rounded mx-0" style="background: #f1f6f6">
        <div class="col">
          <p>
            <img src="../../../assets/Level1.png" style="width: 10px; margin-right: 8px" />Level 1 - Biomarkers included
            in FDA-approved guidelines
          </p>
          <p class="mb-0">
            <img src="../../../assets/Level3.png" style="width: 10px; margin-right: 8px" />Level 3 - Biomarkers from
            clinical trials & well-powered studies
          </p>
        </div>
        <div class="col text-right">
          <p>
            <img src="../../../assets/Level2.png" style="width: 10px; margin-right: 8px" />Level 2 - Biomarkers included
            in professional guidelines
          </p>
          <p class="mb-0">
            <img src="../../../assets/Level4.png" style="width: 10px; margin-right: 8px" />Level 4 - Biomarkers with
            Preclinical / biological evidence
          </p>
        </div>
        <div class="w-100"></div>
        <div class="col px-4 mt-3">
          <p class="mb-0">
            Level 1, 2 and 3 corresponds to Tier 1 and Tier 2 classification
            of AMP/CAP/ASCO guidelines for the patient's cancer type and other
            cancers respectively.
          </p>
          <p class="mb-0">
            Please refer
            <span class="clr-cyan font-weight-bold">‘Categories of Clinical and/or Experimental Evidence’</span>
            in <span class="font-weight-bold">supplementary section</span> for
            details
          </p>
        </div>
      </div>
      <!--  -->

      <!--  -->
      <div class="row footer">
        <div class="col-12">
          <div class="pb-2 pl-4 text">
            <div class="d-block">
              <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
            </div>
            <div class="footer_body" [ngClass]="'gene-bg_blue'">
              <div class="footer_list">
                <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                <span>Gln - Q</span><span>Gly - G</span><span>His - H</span>
                <span>Ile - I</span>
              </div>
              <div class="footer_list">
                <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                <span>Val - V</span>
              </div>
            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p style="font-size: 8px!important; line-height: 14px!important;">
              {{footerDiscription}}<br />{{footerDiscription2}}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- table to be displayed when the same cancer type is > 0 in length -->
  <div id="page6" *ngIf="
        snvSameReportDataList.length > 0 ||
        cnaSameReportDataList.length > 0 ||
        fusionSameReportDataList.length > 0
      " style="overflow-wrap: break-word; padding: 10px; margin: 0 auto" class="main_wrapper">
    <div class="container">
      <div class="row header">
        <div *ngIf="report.clientType != 'client'">
          <div class="col-3">
            <div class="logo">
              <img src="../../../assets/logo.png" alt="" style="width: 140px" />
            </div>
          </div>
        </div>
        <div *ngIf="report.clientType == 'client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
        <div class="col">
          <div class="patient-detailes ml-auto">
            <ul class="list-unstyled d-flex pt-3">
              <li>
                Patient Id<br /><span>{{ report.patient?.ecrfId }}</span>
              </li>
              <li>
                Cancer Site<br /><span>{{ report.cancerType }}</span>
              </li>
              <li>
                SAMPLE ID<br /><span>{{ report.labDetails?.sampleId }}</span>
              </li>
              <li>
                Registration Date<br /><span>{{
                  report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div *ngIf="report.clientType == '4basecare_client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="mar_top"></div>
      <!--  -->
      <div class="row">
        <div class="col">
          <h5 class="py-2 text-center px-0 rounded" style="background: #208dcb; color: #fff; font-weight: 500">
            RELEVANT THERAPIES IN SAME CANCER TYPE
          </h5>
        </div>
      </div>
      <!--  -->
      <!--  -->
      <div class="row my-3">
        <!-- <div class="col">
                    <h6 class="mb-0 font-weight-bold">Relevant Therapies in same
                        Cancer Type</h6>
                </div> -->
        <div class="col">
          <ul class="res_icons list-unstyled d-flex mr-2" style="float: right; font-style: italic">
            <li><img src="../../../assets/responsive.png" />Responsive</li>
            <li class="pl-5">
              <img src="../../../assets/ClinicalTrial.png" />Ongoing Trial
            </li>
            <li class="pl-5">
              <img src="../../../assets/resistence.png" />Resistance
            </li>
          </ul>
        </div>
      </div>
      <!--  -->
      <div class="row">
        <div class="col-12">
          <table class="page-6_table w-100 mt-1">
            <tr>
              <th>Gene</th>
              <th>Therapy</th>
              <th>Cancer Type</th>
              <th style="text-align: center">Effect</th>
              <th style="text-align: center">Evidence</th>
              <th>Reference(s)</th>
            </tr>

            <!-- SNV Type -->
            <tr *ngFor="let cnaOtherCancer of snvSameReportDataList">
              <ng-container *ngIf="cnaOtherCancer.geneRowCount != 0">
                <td [attr.rowSpan]="cnaOtherCancer.geneRowCount">
                  <i>{{ cnaOtherCancer.gene }}</i>
                  <p style="
                        font-size: 12px !important;
                        text-align: left !important;
                      ">
                    {{ cnaOtherCancer.snvVarinat }}
                  </p>
                </td>
              </ng-container>
              <td>{{ cnaOtherCancer.therepy }}</td>
              <td>{{ cnaOtherCancer.cancerType }}</td>
              <td style="text-align: left !important">
                <img *ngIf="cnaOtherCancer.association == 'RESPONSIVE'" src="../../../assets/responsive.png"
                  style="width: 15px" />
                <img *ngIf="cnaOtherCancer.association == 'RESISTANCE'" src="../../../assets/Resistance.png"
                  style="width: 15px" />
                <img *ngIf="cnaOtherCancer.association == 'ONGOING'" src="../../../assets/ClinicalTrial.png"
                  style="width: 15px" />
              </td>
              <td style="text-align: left !important">
                <img *ngIf="cnaOtherCancer.evidance == 'LEVEL_1'" class="mt-2" src="../../../assets/Level1.png"
                  style="width: 20px" />
                <img *ngIf="cnaOtherCancer.evidance == 'LEVEL_2'" class="mt-2" src="../../../assets/Level2.png"
                  style="width: 20px" />
                <img *ngIf="cnaOtherCancer.evidance == 'LEVEL_3'" class="mt-2" src="../../../assets/Level3.png"
                  style="width: 20px" />
                <img *ngIf="cnaOtherCancer.evidance == 'LEVEL_4'" class="mt-2" src="../../../assets/Level4.png"
                  style="width: 20px" />

                <!-- <p *ngIf="cnaOtherCancer.evidance == 'LEVEL_2'">NA</p>
                                    <p *ngIf="cnaOtherCancer.evidance == 'LEVEL_4'">NA</p> -->
              </td>
              <td>{{ cnaOtherCancer.reference }}</td>
            </tr>

            <!-- CNA Type -->
            <tr *ngFor="let cnaOtherCancer of cnaSameReportDataList">
              <ng-container *ngIf="cnaOtherCancer.geneRowCount != 0">
                <td [attr.rowSpan]="cnaOtherCancer.geneRowCount">
                  <i>{{ cnaOtherCancer.gene }}</i>
                  <p style="
                        font-size: 12px !important;
                        text-align: left !important;
                      ">
                    {{ cnaOtherCancer.snvVarinat }}
                  </p>
                </td>
              </ng-container>
              <td>{{ cnaOtherCancer.therepy }}</td>
              <td>{{ cnaOtherCancer.cancerType }}</td>
              <td style="text-align: left !important">
                <img *ngIf="cnaOtherCancer.association == 'RESPONSIVE'" src="../../../assets/responsive.png"
                  style="width: 15px" />
                <img *ngIf="cnaOtherCancer.association == 'RESISTANCE'" src="../../../assets/Resistance.png"
                  style="width: 15px" />
                <img *ngIf="cnaOtherCancer.association == 'ONGOING'" src="../../../assets/ClinicalTrial.png"
                  style="width: 15px" />
              </td>
              <td style="text-align: left !important">
                <img *ngIf="cnaOtherCancer.evidance == 'LEVEL_1'" class="mt-2" src="../../../assets/Level1.png"
                  style="width: 20px" />
                <img *ngIf="cnaOtherCancer.evidance == 'LEVEL_2'" class="mt-2" src="../../../assets/Level2.png"
                  style="width: 20px" />
                <img *ngIf="cnaOtherCancer.evidance == 'LEVEL_3'" class="mt-2" src="../../../assets/Level3.png"
                  style="width: 20px" />
                <img *ngIf="cnaOtherCancer.evidance == 'LEVEL_4'" class="mt-2" src="../../../assets/Level4.png"
                  style="width: 20px" />

                <!-- <p *ngIf="cnaOtherCancer.evidance == 'LEVEL_2'">NA</p>
                                    <p *ngIf="cnaOtherCancer.evidance == 'LEVEL_4'">NA</p> -->
              </td>
              <td>{{ cnaOtherCancer.reference }}</td>
            </tr>

            <!-- Fusion Type -->
            <tr *ngFor="let cnaOtherCancer of fusionSameReportDataList">
              <ng-container *ngIf="cnaOtherCancer.geneRowCount != 0">
                <td [attr.rowSpan]="cnaOtherCancer.geneRowCount">
                  <i>{{ cnaOtherCancer.gene }}</i>
                </td>
              </ng-container>
              <td>{{ cnaOtherCancer.therepy }}</td>
              <td>{{ cnaOtherCancer.cancerType }}</td>
              <td style="text-align: left !important">
                <img *ngIf="cnaOtherCancer.association == 'RESPONSIVE'" src="../../../assets/responsive.png"
                  style="width: 15px" />
                <img *ngIf="cnaOtherCancer.association == 'RESISTANCE'" src="../../../assets/Resistance.png"
                  style="width: 15px" />
                <img *ngIf="cnaOtherCancer.association == 'ONGOING'" src="../../../assets/ClinicalTrial.png"
                  style="width: 15px" />
              </td>
              <td style="text-align: left !important">
                <img *ngIf="cnaOtherCancer.evidance == 'LEVEL_1'" class="mt-2" src="../../../assets/Level1.png"
                  style="width: 20px" />
                <img *ngIf="cnaOtherCancer.evidance == 'LEVEL_2'" class="mt-2" src="../../../assets/Level2.png"
                  style="width: 20px" />
                <img *ngIf="cnaOtherCancer.evidance == 'LEVEL_3'" class="mt-2" src="../../../assets/Level3.png"
                  style="width: 20px" />
                <img *ngIf="cnaOtherCancer.evidance == 'LEVEL_4'" class="mt-2" src="../../../assets/Level4.png"
                  style="width: 20px" />

                <!-- <p *ngIf="cnaOtherCancer.evidance == 'LEVEL_2'">NA</p>
                                    <p *ngIf="cnaOtherCancer.evidance == 'LEVEL_4'">NA</p> -->
              </td>
              <td>{{ cnaOtherCancer.reference }}</td>
            </tr>
          </table>
        </div>
      </div>
      <!--  -->
      <div class="levels_section row mt-4 py-3 rounded mx-0" style="background: #f1f6f6">
        <div class="col">
          <p>
            <img src="../../../assets/Level1.png" style="width: 10px; margin-right: 8px" />Level 1 - Biomarkers included
            in FDA-approved guidelines
          </p>
          <p class="mb-0">
            <img src="../../../assets/Level3.png" style="width: 10px; margin-right: 8px" />Level 3 - Biomarkers from
            clinical trials & well-powered studies
          </p>
        </div>
        <div class="col text-right">
          <p>
            <img src="../../../assets/Level2.png" style="width: 10px; margin-right: 8px" />Level 2 - Biomarkers included
            in professional guidelines
          </p>
          <p class="mb-0">
            <img src="../../../assets/Level4.png" style="width: 10px; margin-right: 8px" />Level 4 - Biomarkers with
            Preclinical / biological evidence
          </p>
        </div>
        <div class="w-100"></div>
        <div class="col px-4 mt-3">
          <p class="mb-0">
            Level 1, 2 and 3 corresponds to Tier 1 and Tier 2 classification
            of AMP/CAP/ASCO guidelines for the patient's cancer type and other
            cancers respectively.
          </p>
          <p class="mb-0">
            Please refer
            <span class="clr-cyan font-weight-bold">‘Categories of Clinical and/or Experimental Evidence’</span>
            in <span class="font-weight-bold">supplementary section</span> for
            details
          </p>
        </div>
      </div>
      <!--  -->

      <!--  -->
      <div class="row footer">
        <div class="col-12">
          <div class="pb-2 pl-4 text">
            <div class="d-block">
              <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
            </div>
            <div class="footer_body" [ngClass]="'gene-bg_blue'">
              <div class="footer_list">
                <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                <span>Gln - Q</span><span>Gly - G</span><span>His - H</span>
                <span>Ile - I</span>
              </div>
              <div class="footer_list">
                <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                <span>Val - V</span>
              </div>
            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p style="font-size: 8px!important; line-height: 14px!important;">
              {{footerDiscription}}<br />{{footerDiscription2}}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="page7" *ngIf="
        this.snvReportDataList.length > 0 ||
        this.cnvReportDataList.length > 0 ||
        this.fusionReportDataList.length > 0
      " style="padding: 10px; margin: 0 auto" class="main_wrapper">
    <div class="container">
      <div class="row header">
        <div *ngIf="report.clientType != 'client'">
          <div class="col-3">
            <div class="logo">
              <img src="../../../assets/logo.png" alt="" style="width: 140px" />
            </div>
          </div>
        </div>
        <div *ngIf="report.clientType == 'client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
        <div class="col">
          <div class="patient-detailes ml-auto">
            <ul class="list-unstyled d-flex pt-3">
              <li>
                Patient Id<br /><span>{{ report.patient?.ecrfId }}</span>
              </li>
              <li>
                Cancer Site<br /><span>{{ report.cancerType }}</span>
              </li>
              <li>
                SAMPLE ID<br /><span>{{ report.labDetails?.sampleId }}</span>
              </li>
              <li>
                Registration Date<br /><span>{{
                  report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div *ngIf="report.clientType == '4basecare_client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="mar_top"></div>
      <!--  -->

      <div class="row">
        <div class="col">
          <h5 class="py-2 text-center px-0 rounded" style="background: #208dcb; color: #fff; font-weight: 500">
            RELEVANT THERAPIES IN DIFFERENT CANCER TYPES
          </h5>
        </div>
      </div>
      <!--  -->
      <!--  -->
      <div class="row my-3">
        <!-- <div class="col">
                    <h6 class="mb-0 font-weight-bold">Relevant Therapies in Different
                        Cancer Types</h6>
                </div> -->
        <div class="col">
          <ul class="res_icons list-unstyled d-flex mr-2" style="float: right; font-style: italic">
            <li><img src="../../../assets/responsive.png" />Responsive</li>
            <li class="pl-5">
              <img src="../../../assets/ClinicalTrial.png" />Ongoing Trial
            </li>
            <li class="pl-5">
              <img src="../../../assets/resistence.png" />Resistance
            </li>
          </ul>
        </div>
      </div>

      <!--  -->
      <div class="row">
        <div class="col-12">
          <table class="page-6_table page-7 w-100 mt-1">
            <tr>
              <th>Gene</th>
              <th>Therapy</th>
              <th>Cancer Type</th>
              <th style="text-align: center">Effect</th>
              <th style="text-align: center">Evidence</th>
              <th>Reference(s)</th>
            </tr>

            <!-- SNV Type -->
            <tr *ngFor="let cnaOtherCancer of snvReportDataList">
              <ng-container *ngIf="cnaOtherCancer.geneRowCount != 0">
                <td [attr.rowSpan]="cnaOtherCancer.geneRowCount">
                  <i>{{ cnaOtherCancer.gene }}</i>
                  <p style="
                        font-size: 12px !important;
                        text-align: left !important;
                      ">
                    {{ cnaOtherCancer.snvVarinat }}
                  </p>
                </td>
              </ng-container>
              <td>{{ cnaOtherCancer.therepy }}</td>
              <td>{{ cnaOtherCancer.cancerType }}</td>
              <td style="text-align: left !important">
                <img *ngIf="cnaOtherCancer.association == 'RESPONSIVE'" src="../../../assets/responsive.png"
                  style="width: 15px" />
                <img *ngIf="cnaOtherCancer.association == 'RESISTANCE'" src="../../../assets/Resistance.png"
                  style="width: 15px" />
                <img *ngIf="cnaOtherCancer.association == 'ONGOING'" src="../../../assets/ClinicalTrial.png"
                  style="width: 15px" />
              </td>
              <td style="text-align: left !important">
                <img *ngIf="cnaOtherCancer.evidance == 'LEVEL_1'" class="mt-2" src="../../../assets/Level1.png"
                  style="width: 20px" />
                <img *ngIf="cnaOtherCancer.evidance == 'LEVEL_2'" class="mt-2" src="../../../assets/Level2.png"
                  style="width: 20px" />
                <img *ngIf="cnaOtherCancer.evidance == 'LEVEL_3'" class="mt-2" src="../../../assets/Level3.png"
                  style="width: 20px" />
                <img *ngIf="cnaOtherCancer.evidance == 'LEVEL_4'" class="mt-2" src="../../../assets/Level4.png"
                  style="width: 20px" />

                <!-- <p *ngIf="cnaOtherCancer.evidance == 'LEVEL_2'">NA</p>
                                        <p *ngIf="cnaOtherCancer.evidance == 'LEVEL_4'">NA</p> -->
              </td>
              <td>{{ cnaOtherCancer.reference }}</td>
            </tr>

            <!-- CNA Type -->
            <tr *ngFor="let cnaOtherCancer of cnvReportDataList">
              <ng-container *ngIf="cnaOtherCancer.geneRowCount != 0">
                <td [attr.rowSpan]="cnaOtherCancer.geneRowCount">
                  <i>{{ cnaOtherCancer.gene }}</i>
                  <p style="
                        font-size: 12px !important;
                        text-align: left !important;
                      ">
                    {{ cnaOtherCancer.snvVarinat }}
                  </p>
                </td>
              </ng-container>
              <td>{{ cnaOtherCancer.therepy }}</td>
              <td>{{ cnaOtherCancer.cancerType }}</td>
              <td style="text-align: left !important">
                <img *ngIf="cnaOtherCancer.association == 'RESPONSIVE'" src="../../../assets/responsive.png"
                  style="width: 15px" />
                <img *ngIf="cnaOtherCancer.association == 'RESISTANCE'" src="../../../assets/Resistance.png"
                  style="width: 15px" />
                <img *ngIf="cnaOtherCancer.association == 'ONGOING'" src="../../../assets/ClinicalTrial.png"
                  style="width: 15px" />
              </td>
              <td style="text-align: left !important">
                <img *ngIf="cnaOtherCancer.evidance == 'LEVEL_1'" class="mt-2" src="../../../assets/Level1.png"
                  style="width: 20px" />
                <img *ngIf="cnaOtherCancer.evidance == 'LEVEL_2'" class="mt-2" src="../../../assets/Level2.png"
                  style="width: 20px" />
                <img *ngIf="cnaOtherCancer.evidance == 'LEVEL_3'" class="mt-2" src="../../../assets/Level3.png"
                  style="width: 20px" />
                <img *ngIf="cnaOtherCancer.evidance == 'LEVEL_4'" class="mt-2" src="../../../assets/Level4.png"
                  style="width: 20px" />

                <!-- <p *ngIf="cnaOtherCancer.evidance == 'LEVEL_2'">NA</p>
                                        <p *ngIf="cnaOtherCancer.evidance == 'LEVEL_4'">NA</p>
                            </td> -->
              </td>

              <td>{{ cnaOtherCancer.reference }}</td>
            </tr>

            <!-- Fusion Type -->
            <tr *ngFor="let cnaOtherCancer of fusionReportDataList">
              <ng-container *ngIf="cnaOtherCancer.geneRowCount != 0">
                <td [attr.rowSpan]="cnaOtherCancer.geneRowCount">
                  <i>{{ cnaOtherCancer.gene }}</i>
                </td>
              </ng-container>
              <td>{{ cnaOtherCancer.therepy }}</td>
              <td>{{ cnaOtherCancer.cancerType }}</td>
              <td style="text-align: left !important">
                <img *ngIf="cnaOtherCancer.association == 'RESPONSIVE'" src="../../../assets/responsive.png"
                  style="width: 15px" />
                <img *ngIf="cnaOtherCancer.association == 'RESISTANCE'" src="../../../assets/Resistance.png"
                  style="width: 15px" />
                <img *ngIf="cnaOtherCancer.association == 'ONGOING'" src="../../../assets/ClinicalTrial.png"
                  style="width: 15px" />
              </td>
              <td style="text-align: left !important">
                <img *ngIf="cnaOtherCancer.evidance == 'LEVEL_1'" class="mt-2" src="../../../assets/Level1.png"
                  style="width: 20px" />
                <img *ngIf="cnaOtherCancer.evidance == 'LEVEL_2'" class="mt-2" src="../../../assets/Level2.png"
                  style="width: 20px" />
                <img *ngIf="cnaOtherCancer.evidance == 'LEVEL_3'" class="mt-2" src="../../../assets/Level3.png"
                  style="width: 20px" />
                <img *ngIf="cnaOtherCancer.evidance == 'LEVEL_4'" class="mt-2" src="../../../assets/Level4.png"
                  style="width: 20px" />

                <!-- <p *ngIf="cnaOtherCancer.evidance == 'LEVEL_2'">NA</p>
                                        <p *ngIf="cnaOtherCancer.evidance == 'LEVEL_4'">NA</p> -->
              </td>
              <td>{{ cnaOtherCancer.reference }}</td>
            </tr>
          </table>
        </div>
      </div>
      <!--  -->
      <div class="levels_section row mt-4 py-3 rounded mx-0" style="background: #f1f6f6">
        <div class="col">
          <p>
            <img src="../../../assets/Level1.png" style="width: 10px; margin-right: 8px" />Level 1 - Biomarkers included
            in FDA-approved guidelines
          </p>
          <p class="mb-0">
            <img src="../../../assets/Level3.png" style="width: 10px; margin-right: 8px" />Level 3 - Biomarkers from
            clinical trials & well-powered studies
          </p>
        </div>
        <div class="col text-right">
          <p>
            <img src="../../../assets/Level2.png" style="width: 10px; margin-right: 8px" />Level 2 - Biomarkers included
            in professional guidelines
          </p>
          <p class="mb-0">
            <img src="../../../assets/Level4.png" style="width: 10px; margin-right: 8px" />Level 4 - Biomarkers with
            Preclinical / biological evidence
          </p>
        </div>
        <div class="w-100"></div>
        <div class="col px-4 mt-3">
          <p class="mb-0">
            Level 1, 2 and 3 corresponds to Tier 1 and Tier 2 classification
            of AMP/CAP/ASCO guidelines for the patient's cancer type and other
            cancers respectively.
          </p>
          <p class="mb-0">
            Please refer
            <span class="clr-cyan font-weight-bold">‘Categories of Clinical and/or Experimental Evidence’</span>
            in <span class="font-weight-bold">supplementary section</span> for
            details
          </p>
        </div>
      </div>
      <!--  -->

      <!--  -->
      <div class="row footer">
        <div class="col-12">
          <div class="pb-2 pl-4 text">
            <div class="d-block">
              <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
            </div>
            <div class="footer_body" [ngClass]="'gene-bg_blue'">
              <div class="footer_list">
                <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                <span>Gln - Q</span><span>Gly - G</span><span>His - H</span>
                <span>Ile - I</span>
              </div>
              <div class="footer_list">
                <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                <span>Val - V</span>
              </div>
            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p style="font-size: 8px!important; line-height: 14px!important;">
              {{footerDiscription}}<br />{{footerDiscription2}}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="page8" *ngIf="reportPdfRes.starNCTTrailRes.length > 0" style="padding: 10px; margin: 0 auto"
    class="main_wrapper">
    <div class="container">
      <div class="row header">
        <div *ngIf="report.clientType != 'client'">
          <div class="col-3">
            <div class="logo">
              <img src="../../../assets/logo.png" alt="" style="width: 140px" />
            </div>
          </div>
        </div>
        <div *ngIf="report.clientType == 'client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
        <div class="col">
          <div class="patient-detailes ml-auto">
            <ul class="list-unstyled d-flex pt-3">
              <li>
                Patient Id<br /><span>{{ report.patient?.ecrfId }}</span>
              </li>
              <li>
                Cancer Site<br /><span>{{ report.cancerType }}</span>
              </li>
              <li>
                SAMPLE ID<br /><span>{{ report.labDetails?.sampleId }}</span>
              </li>
              <li>
                Registration Date<br /><span>{{
                  report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div *ngIf="report.clientType == '4basecare_client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="mar_top"></div>
      <!--  -->
      <div class="row">
        <div class="col">
          <h5 class="py-2 text-center px-0 rounded" style="background: #208dcb; color: #fff; font-weight: 500">
            CLINICAL TRIAL(S)
          </h5>
        </div>
      </div>
      <!--  -->
      <div *ngFor="let starNCTTrail of reportPdfRes.starNCTTrailRes">
        <div class="row mt-3">
          <div class="col-12">
            <h4 class="mt-3 mb-2" style="font-weight: bold; color: #208dcb">
              Study Title
            </h4>
            <p class="mb-2">{{ starNCTTrail.studyTitle }}</p>
          </div>
          <div class="col-12 mt-3">
            <h4 class="mt-3 mb-2" style="font-weight: bold; color: #208dcb">
              Brief Summary
            </h4>
            <p class="mb-2">{{ starNCTTrail.briefSummary }}</p>
          </div>
        </div>
        <div class="row my-4">
          <div class="col">
            <h4 class="mt-3 mb-2" style="font-weight: bold; color: #208dcb">
              Study Phase
            </h4>
            <p class="mb-2">{{ starNCTTrail.studyPhase }}</p>
          </div>
          <div class="col">
            <h4 class="mt-3 mb-2" style="font-weight: bold; color: #208dcb">
              NCT Number
            </h4>
            <p class="mb-2">{{ starNCTTrail.nctNumber }}</p>
          </div>
          <div class="col">
            <h4 class="mt-3 mb-2" style="font-weight: bold; color: #208dcb">
              Recruitment Status
            </h4>
            <p class="mb-2">{{ starNCTTrail.recruitmentStatus }}</p>
          </div>
          <div class="w-100"></div>
          <div class="col-12 mt-3">
            <h4 class="mt-3 mb-2" style="font-weight: bold; color: #208dcb">
              Intervention
            </h4>
            <h6 class="mb-0 font-weight-bold" style="color: #555">
              Experimental:
              {{
              starNCTTrail.interventionName
              .replace("[", "")
              .replace("]", "")
              }}
            </h6>
            <!-- <p class="mb-2">{{starNCTTrail.interventionDescription}}</p> -->
          </div>
          <div class="w-100"></div>
          <div class="col-4 mt-4">
            <h4 class="mt-3 mb-2" style="font-weight: bold; color: #208dcb">
              Last updated
            </h4>
            <p class="mb-2">{{ starNCTTrail.lastUpdated }}</p>
          </div>
          <div class="col-4 mt-4">
            <h4 class="mt-3 mb-2" style="font-weight: bold; color: #208dcb">
              Location
            </h4>
            <p class="mb-2">
              {{ starNCTTrail.locationFacility }},
              {{ starNCTTrail.locationCity }},
              {{ starNCTTrail.locationState }},
              {{ starNCTTrail.locationCountry }}
            </p>
          </div>
          <div class="col-12 mt-4">
            <h4 class="mt-3 mb-2" style="font-weight: bold; color: #208dcb">
              Biomarker
            </h4>
            <p class="mb-2">{{ starNCTTrail.biomarker }}</p>
          </div>
          <div class="col-12 mt-4">
            <h4 class="mt-3 mb-2" style="font-weight: bold; color: #208dcb">
              Condition
            </h4>
            <p class="mb-2">{{ starNCTTrail.condition }}</p>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="row footer">
        <div class="col-12">
          <div class="pb-2 pl-4 text">
            <div class="d-block">
              <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
            </div>
            <div class="footer_body" [ngClass]="'gene-bg_blue'">
              <div class="footer_list">
                <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                <span>Gln - Q</span><span>Gly - G</span><span>His - H</span>
                <span>Ile - I</span>
              </div>
              <div class="footer_list">
                <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                <span>Val - V</span>
              </div>
            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p style="font-size: 8px!important; line-height: 14px!important;">
              {{footerDiscription}}<br />{{footerDiscription2}}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- the table not to be displayed when all the snv varients are of wild type and cna and fusion varients are empty -->
  <div id="page9" *ngIf="
        (reportPdfRes.selectedReportedVariantList.length > 0 &&
          countOthers != 0) ||
        reportPdfRes.cnaReportedVariantList.length > 0 ||
        reportPdfRes.fusionReportedVariantList.length > 0
      " style="padding: 10px; margin: 0 auto" class="main_wrapper">
    <div class="container">
      <div class="row header">
        <div *ngIf="report.clientType != 'client'">
          <div class="col-3">
            <div class="logo">
              <img src="../../../assets/logo.png" alt="" style="width: 140px" />
            </div>
          </div>
        </div>
        <div *ngIf="report.clientType == 'client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
        <div class="col">
          <div class="patient-detailes ml-auto">
            <ul class="list-unstyled d-flex pt-3">
              <li>
                Patient Id<br /><span>{{ report.patient?.ecrfId }}</span>
              </li>
              <li>
                Cancer Site<br /><span>{{ report.cancerType }}</span>
              </li>
              <li>
                SAMPLE ID<br /><span>{{ report.labDetails?.sampleId }}</span>
              </li>
              <li>
                Registration Date<br /><span>{{
                  report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div *ngIf="report.clientType == '4basecare_client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="mar_top"></div>
      <!--  -->
      <div class="row">
        <div class="col">
          <h5 class="py-2 text-center px-0 rounded" style="background: #208dcb; color: #fff; font-weight: 500">
            CLINICALLY RELEVANT GENOMIC ALTERATION(S)
          </h5>
        </div>
      </div>
      <!--  -->
      <!--  -->
      <div class="row mt-3 mb-2">
        <div class="col">
          <h4 class="mb-0 font-weight-bold" style="color: #208dcb">
            Variant Details
          </h4>
        </div>
      </div>
      <!--  -->
      <div class="row">
        <div class="col-12">
          <table class="page-9 w-100">
            <tr>
              <th>Gene</th>
              <th>
                Genomic Alteration,<br />Transcript ID,<br />Exon Number
              </th>
              <th>cDNA Change, Protein Change, Mutant Allele Burden</th>
              <th>Clinical Significance</th>
              <th>Consequence</th>
              <th>Reference(s)</th>
            </tr>

            <tr *ngFor="
                  let selectedReportedVariant of reportPdfRes.selectedReportedVariantList
                ">
              <td *ngIf="
                    selectedReportedVariant.significance != 'NA' &&
                    selectedReportedVariant.significance != 'Not Classified'
                  ">
                <i>{{ selectedReportedVariant.refGeneX }}</i>
              </td>
              <td *ngIf="
                    selectedReportedVariant.significance != 'NA' &&
                    selectedReportedVariant.significance != 'Not Classified'
                  ">
                {{ selectedReportedVariant.genomicAlteration }},<br />
                {{ selectedReportedVariant.ensembleValue }},<br />
                Exon {{ selectedReportedVariant.exonNumber }}
              </td>
              <td *ngIf="
                    selectedReportedVariant.significance != 'NA' &&
                    selectedReportedVariant.significance != 'Not Classified'
                  ">
                {{
                selectedReportedVariant.selectedAAChangeknownGene.split(
                ","
                )[0]
                }},
                {{
                selectedReportedVariant.selectedAAChangeknownGene.split(
                ","
                )[1]
                }},
                {{
                selectedReportedVariant.mutantBurdenPercent * 0.01 | percent
                }}
              </td>
              <td *ngIf="
                    selectedReportedVariant.significance != 'NA' &&
                    selectedReportedVariant.significance != 'Not Classified'
                  ">
                {{ selectedReportedVariant.significance }}
              </td>
              <td *ngIf="
                    selectedReportedVariant.significance != 'NA' &&
                    selectedReportedVariant.significance != 'Not Classified'
                  ">
                {{ selectedReportedVariant.exonicFuncknownGene }}
              </td>
              <td *ngIf="
                    selectedReportedVariant.significance != 'NA' &&
                    selectedReportedVariant.significance != 'Not Classified'
                  ">
                {{ selectedReportedVariant.reference }}
              </td>
            </tr>

            <tr *ngFor="
                  let cnaReportedVariant of reportPdfRes.cnaReportedVariantList
                ">
              <td *ngIf="
                    cnaReportedVariant.significance != 'NA' &&
                    cnaReportedVariant.significance != 'Not Classified'
                  ">
                <i>{{ cnaReportedVariant.gene }}</i>
              </td>
              <td *ngIf="
                    cnaReportedVariant.significance != 'NA' &&
                    cnaReportedVariant.significance != 'Not Classified'
                  ">
                chr{{ cnaReportedVariant.sample.split(":")[0] }}:g.{{
                cnaReportedVariant.sample
                .split(":")[1]
                .replace("-", "_")
                .split("|")[0]
                }}
              </td>
              <td *ngIf="
                    cnaReportedVariant.significance != 'NA' &&
                    cnaReportedVariant.significance != 'Not Classified'
                  ">
                NA
              </td>
              <td *ngIf="
                    cnaReportedVariant.significance != 'NA' &&
                    cnaReportedVariant.significance != 'Not Classified'
                  ">
                {{ cnaReportedVariant.significance }}
              </td>
              <td *ngIf="
                    cnaReportedVariant.significance != 'NA' &&
                    cnaReportedVariant.significance != 'Not Classified'
                  ">
                Copy Number
                {{ cnaReportedVariant.sample.split(":")[2] | titlecase }} ({{
                cnaReportedVariant.sample.split("|")[1].split(":")[0]
                }})
              </td>
              <td *ngIf="
                    cnaReportedVariant.significance != 'NA' &&
                    cnaReportedVariant.significance != 'Not Classified'
                  ">
                {{ cnaReportedVariant.reference }}
              </td>
            </tr>

            <tr *ngFor="
                  let fusionVariant of reportPdfRes.fusionReportedVariantList
                ">
              <td *ngIf="
                    fusionVariant.significance != 'NA' &&
                    fusionVariant.significance != 'Not Classified'
                  ">
                <i>{{ fusionVariant.fusionGene.replace("--", "-") }}</i>
              </td>
              <td *ngIf="
                    fusionVariant.significance != 'NA' &&
                    fusionVariant.significance != 'Not Classified'
                  ">
                Left break {{ fusionVariant.leftBreakPoint }} ({{
                fusionVariant.leftExon
                }})<br />Rigth break {{ fusionVariant.rightBreakPoint }} ({{
                fusionVariant.rightExon
                }})
              </td>
              <td *ngIf="
                    fusionVariant.significance != 'NA' &&
                    fusionVariant.significance != 'Not Classified'
                  ">
                NA
              </td>
              <td *ngIf="
                    fusionVariant.significance != 'NA' &&
                    fusionVariant.significance != 'Not Classified'
                  ">
                {{ fusionVariant.significance }}
              </td>
              <td *ngIf="
                    fusionVariant.significance != 'NA' &&
                    fusionVariant.significance != 'Not Classified'
                  ">
                Gene Fusion
              </td>
              <td *ngIf="
                    fusionVariant.significance != 'NA' &&
                    fusionVariant.significance != 'Not Classified'
                  ">
                {{ fusionVariant.reference }}
              </td>
            </tr>
          </table>
        </div>
      </div>
      <p style="font-size: 15px !important" class="mt-3">
        <i> *NA: Not Applicable </i>
      </p>

      <!--  -->
      <div class="row footer">
        <div class="col-12">
          <div class="pb-2 pl-4 text">
            <div class="d-block">
              <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
            </div>
            <div class="footer_body" [ngClass]="'gene-bg_blue'">
              <div class="footer_list">
                <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                <span>Gln - Q</span><span>Gly - G</span><span>His - H</span>
                <span>Ile - I</span>
              </div>
              <div class="footer_list">
                <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                <span>Val - V</span>
              </div>
            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p style="font-size: 8px!important; line-height: 14px!important;">
              {{footerDiscription}}<br />{{footerDiscription2}}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--  -->

  <div id="page10" *ngIf="reportPdfRes.genes.length > 0" style="padding: 10px; margin: 0 auto" class="main_wrapper">
    <div class="container">
      <div class="row header">
        <div *ngIf="report.clientType != 'client'">
          <div class="col-3">
            <div class="logo">
              <img src="../../../assets/logo.png" alt="" style="width: 140px" />
            </div>
          </div>
        </div>
        <div *ngIf="report.clientType == 'client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
        <div class="col">
          <div class="patient-detailes ml-auto">
            <ul class="list-unstyled d-flex pt-3">
              <li>
                Patient Id<br /><span>{{ report.patient?.ecrfId }}</span>
              </li>
              <li>
                Cancer Site<br /><span>{{ report.cancerType }}</span>
              </li>
              <li>
                SAMPLE ID<br /><span>{{ report.labDetails?.sampleId }}</span>
              </li>
              <li>
                Registration Date<br /><span>{{
                  report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div *ngIf="report.clientType == '4basecare_client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="mar_top"></div>
      <!--  -->
      <div class="row">
        <div class="col">
          <h5 class="mb-2 py-2 text-center px-0 rounded" style="background: #208dcb; color: #fff; font-weight: 500">
            GENE DESCRIPTION(S)
          </h5>
        </div>
      </div>
      <!--  -->
      <div class="row mt-3">
        <div *ngFor="let gene of reportPdfRes.genes | slice : 0 : 3" class="col-12 mb-2">
          <h4 class="py-2" style="color: #000; font-size: 19px !important; font-weight: 400">
            <span class="font-weight-bold" style="font-size: 19px !important"><i><b>{{ gene.name }}</b></i></span>
            –
            <b>{{ gene.geneName }}</b>
          </h4>
          <div [innerHtml]="gene.description" style="line-height: 28px; text-align: justify"></div>
          <!-- <p style="line-height: 28px;text-align:justify;" [innerHtml]='gene.description'></p> -->
        </div>
      </div>

      <!--  -->
      <div class="row footer">
        <div class="col-12">
          <div class="pb-2 pl-4 text">
            <div class="d-block">
              <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
            </div>
            <div class="footer_body" [ngClass]="'gene-bg_blue'">
              <div class="footer_list">
                <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                <span>Gln - Q</span><span>Gly - G</span><span>His - H</span>
                <span>Ile - I</span>
              </div>
              <div class="footer_list">
                <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                <span>Val - V</span>
              </div>
            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p style="font-size: 8px!important; line-height: 14px!important;">
              {{footerDiscription}}<br />{{footerDiscription2}}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--  -->

  <div id="page10_1" *ngIf="reportPdfRes.genes.length > 3" style="padding: 10px; margin: 0 auto" class="main_wrapper">
    <div class="container">
      <div class="row header">
        <div *ngIf="report.clientType != 'client'">
          <div class="col-3">
            <div class="logo">
              <img src="../../../assets/logo.png" alt="" style="width: 140px" />
            </div>
          </div>
        </div>
        <div *ngIf="report.clientType == 'client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
        <div class="col">
          <div class="patient-detailes ml-auto">
            <ul class="list-unstyled d-flex pt-3">
              <li>
                Patient Id<br /><span>{{ report.patient?.ecrfId }}</span>
              </li>
              <li>
                Cancer Site<br /><span>{{ report.cancerType }}</span>
              </li>
              <li>
                SAMPLE ID<br /><span>{{ report.labDetails?.sampleId }}</span>
              </li>
              <li>
                Registration Date<br /><span>{{
                  report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div *ngIf="report.clientType == '4basecare_client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="mar_top"></div>
      <!--  -->
      <div class="row">
        <div class="col">
          <h5 class="mb-2 py-2 text-center px-0 rounded" style="background: #208dcb; color: #fff; font-weight: 500">
            GENE DESCRIPTION(S)
          </h5>
        </div>
      </div>
      <!--  -->
      <div class="row mt-3">
        <div *ngFor="let gene of reportPdfRes.genes | slice : 3 : 6" class="col-12 mb-2">
          <h4 class="py-2" style="color: #000; font-size: 19px !important; font-weight: 400">
            <span class="font-weight-bold" style="font-size: 19px !important"><i><b>{{ gene.name }}</b></i></span>
            –
            <b>{{ gene.geneName }}</b>
          </h4>
          <div [innerHtml]="gene.description" style="line-height: 28px; text-align: justify"></div>
          <!-- <p style="line-height: 28px;text-align:justify;" [innerHtml]='gene.description'></p> -->
        </div>
      </div>

      <!--  -->
      <div class="row footer">
        <div class="col-12">
          <div class="pb-2 pl-4 text">
            <div class="d-block">
              <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
            </div>
            <div class="footer_body" [ngClass]="'gene-bg_blue'">
              <div class="footer_list">
                <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                <span>Gln - Q</span><span>Gly - G</span><span>His - H</span>
                <span>Ile - I</span>
              </div>
              <div class="footer_list">
                <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                <span>Val - V</span>
              </div>
            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p style="font-size: 8px!important; line-height: 14px!important;">
              {{footerDiscription}}<br />{{footerDiscription2}}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--  -->

  <div id="page10_2" *ngIf="reportPdfRes.genes.length > 6" style="padding: 10px; margin: 0 auto" class="main_wrapper">
    <div class="container">
      <div class="row header">
        <div *ngIf="report.clientType != 'client'">
          <div class="col-3">
            <div class="logo">
              <img src="../../../assets/logo.png" alt="" style="width: 140px" />
            </div>
          </div>
        </div>
        <div *ngIf="report.clientType == 'client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
        <div class="col">
          <div class="patient-detailes ml-auto">
            <ul class="list-unstyled d-flex pt-3">
              <li>
                Patient Id<br /><span>{{ report.patient?.ecrfId }}</span>
              </li>
              <li>
                Cancer Site<br /><span>{{ report.cancerType }}</span>
              </li>
              <li>
                SAMPLE ID<br /><span>{{ report.labDetails?.sampleId }}</span>
              </li>
              <li>
                Registration Date<br /><span>{{
                  report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div *ngIf="report.clientType == '4basecare_client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="mar_top"></div>
      <!--  -->
      <div class="row">
        <div class="col">
          <h5 class="mb-2 py-2 text-center px-0 rounded" style="background: #208dcb; color: #fff; font-weight: 500">
            GENE DESCRIPTION(S)
          </h5>
        </div>
      </div>
      <!--  -->
      <div class="row mt-3">
        <div *ngFor="let gene of reportPdfRes.genes | slice : 6 : 9" class="col-12 mb-2">
          <h4 class="py-2" style="color: #000; font-size: 19px !important; font-weight: 400">
            <span class="font-weight-bold" style="font-size: 19px !important"><i><b>{{ gene.name }}</b></i></span>
            –
            <b>{{ gene.geneName }}</b>
          </h4>
          <div [innerHtml]="gene.description" style="line-height: 28px; text-align: justify"></div>
          <!-- <p style="line-height: 28px;text-align:justify;" [innerHtml]='gene.description'></p> -->
        </div>
      </div>

      <!--  -->
      <div class="row footer">
        <div class="col-12">
          <div class="pb-2 pl-4 text">
            <div class="d-block">
              <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
            </div>
            <div class="footer_body" [ngClass]="'gene-bg_blue'">
              <div class="footer_list">
                <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                <span>Gln - Q</span><span>Gly - G</span><span>His - H</span>
                <span>Ile - I</span>
              </div>
              <div class="footer_list">
                <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                <span>Val - V</span>
              </div>
            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p style="font-size: 8px!important; line-height: 14px!important;">
              {{footerDiscription}}<br />{{footerDiscription2}}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--  -->
  <div id="page10_3" *ngIf="reportPdfRes.genes.length > 9" style="padding: 10px; margin: 0 auto" class="main_wrapper">
    <div class="container">
      <div class="row header">
        <div *ngIf="report.clientType != 'client'">
          <div class="col-3">
            <div class="logo">
              <img src="../../../assets/logo.png" alt="" style="width: 140px" />
            </div>
          </div>
        </div>
        <div *ngIf="report.clientType == 'client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
        <div class="col">
          <div class="patient-detailes ml-auto">
            <ul class="list-unstyled d-flex pt-3">
              <li>
                Patient Id<br /><span>{{ report.patient?.ecrfId }}</span>
              </li>
              <li>
                Cancer Site<br /><span>{{ report.cancerType }}</span>
              </li>
              <li>
                SAMPLE ID<br /><span>{{ report.labDetails?.sampleId }}</span>
              </li>
              <li>
                Registration Date<br /><span>{{
                  report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div *ngIf="report.clientType == '4basecare_client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="mar_top"></div>
      <!--  -->
      <div class="row">
        <div class="col">
          <h5 class="mb-2 py-2 text-center px-0 rounded" style="background: #208dcb; color: #fff; font-weight: 500">
            GENE DESCRIPTION(S)
          </h5>
        </div>
      </div>
      <!--  -->
      <div class="row mt-3">
        <div *ngFor="let gene of reportPdfRes.genes | slice : 9 : 12" class="col-12 mb-2">
          <h4 class="py-2" style="color: #000; font-size: 19px !important; font-weight: 400">
            <span class="font-weight-bold" style="font-size: 19px !important"><i><b>{{ gene.name }}</b></i></span>
            –
            <b>{{ gene.geneName }}</b>
          </h4>
          <div [innerHtml]="gene.description" style="line-height: 28px; text-align: justify"></div>
          <!-- <p style="line-height: 28px;text-align:justify;" [innerHtml]='gene.description'></p> -->
        </div>
      </div>

      <!--  -->
      <div class="row footer">
        <div class="col-12">
          <div class="pb-2 pl-4 text">
            <div class="d-block">
              <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
            </div>
            <div class="footer_body" [ngClass]="'gene-bg_blue'">
              <div class="footer_list">
                <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                <span>Gln - Q</span><span>Gly - G</span><span>His - H</span>
                <span>Ile - I</span>
              </div>
              <div class="footer_list">
                <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                <span>Val - V</span>
              </div>
            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p style="font-size: 8px!important; line-height: 14px!important;">
              {{footerDiscription}}<br />{{footerDiscription2}}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="page10_4" *ngIf="reportPdfRes.genes.length > 12" style="padding: 10px; margin: 0 auto" class="main_wrapper">
    <div class="container">
      <div class="row header">
        <div *ngIf="report.clientType != 'client'">
          <div class="col-3">
            <div class="logo">
              <img src="../../../assets/logo.png" alt="" style="width: 140px" />
            </div>
          </div>
        </div>
        <div *ngIf="report.clientType == 'client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
        <div class="col">
          <div class="patient-detailes ml-auto">
            <ul class="list-unstyled d-flex pt-3">
              <li>
                Patient Id<br /><span>{{ report.patient?.ecrfId }}</span>
              </li>
              <li>
                Cancer Site<br /><span>{{ report.cancerType }}</span>
              </li>
              <li>
                SAMPLE ID<br /><span>{{ report.labDetails?.sampleId }}</span>
              </li>
              <li>
                Registration Date<br /><span>{{
                  report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div *ngIf="report.clientType == '4basecare_client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="mar_top"></div>
      <!--  -->
      <div class="row">
        <div class="col">
          <h5 class="mb-2 py-2 text-center px-0 rounded" style="background: #208dcb; color: #fff; font-weight: 500">
            GENE DESCRIPTION(S)
          </h5>
        </div>
      </div>
      <!--  -->
      <div class="row mt-3">
        <div *ngFor="let gene of reportPdfRes.genes | slice : 12 : 15" class="col-12 mb-2">
          <h4 class="py-2" style="color: #000; font-size: 19px !important; font-weight: 400">
            <span class="font-weight-bold" style="font-size: 19px !important"><i><b>{{ gene.name }}</b></i></span>
            –
            <b>{{ gene.geneName }}</b>
          </h4>
          <div [innerHtml]="gene.description" style="line-height: 28px; text-align: justify"></div>
          <!-- <p style="line-height: 28px;text-align:justify;" [innerHtml]='gene.description'></p> -->
        </div>
      </div>

      <!--  -->
      <div class="row footer">
        <div class="col-12">
          <div class="pb-2 pl-4 text">
            <div class="d-block">
              <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
            </div>
            <div class="footer_body" [ngClass]="'gene-bg_blue'">
              <div class="footer_list">
                <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                <span>Gln - Q</span><span>Gly - G</span><span>His - H</span>
                <span>Ile - I</span>
              </div>
              <div class="footer_list">
                <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                <span>Val - V</span>
              </div>
            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p style="font-size: 8px!important; line-height: 14px!important;">
              {{footerDiscription}}<br />{{footerDiscription2}}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="page10_5" *ngIf="reportPdfRes.genes.length > 15" style="padding: 10px; margin: 0 auto" class="main_wrapper">
    <div class="container">
      <div class="row header">
        <div *ngIf="report.clientType != 'client'">
          <div class="col-3">
            <div class="logo">
              <img src="../../../assets/logo.png" alt="" style="width: 140px" />
            </div>
          </div>
        </div>
        <div *ngIf="report.clientType == 'client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
        <div class="col">
          <div class="patient-detailes ml-auto">
            <ul class="list-unstyled d-flex pt-3">
              <li>
                Patient Id<br /><span>{{ report.patient?.ecrfId }}</span>
              </li>
              <li>
                Cancer Site<br /><span>{{ report.cancerType }}</span>
              </li>
              <li>
                SAMPLE ID<br /><span>{{ report.labDetails?.sampleId }}</span>
              </li>
              <li>
                Registration Date<br /><span>{{
                  report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div *ngIf="report.clientType == '4basecare_client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="mar_top"></div>
      <!--  -->
      <div class="row">
        <div class="col">
          <h5 class="mb-2 py-2 text-center px-0 rounded" style="background: #208dcb; color: #fff; font-weight: 500">
            GENE DESCRIPTION(S)
          </h5>
        </div>
      </div>
      <!--  -->
      <div class="row mt-3">
        <div *ngFor="let gene of reportPdfRes.genes | slice : 15 : 18" class="col-12 mb-2">
          <h4 class="py-2" style="color: #000; font-size: 19px !important; font-weight: 400">
            <span class="font-weight-bold" style="font-size: 19px !important"><i><b>{{ gene.name }}</b></i></span>
            –
            <b>{{ gene.geneName }}</b>
          </h4>
          <div [innerHtml]="gene.description" style="line-height: 28px; text-align: justify"></div>
          <!-- <p style="line-height: 28px;text-align:justify;" [innerHtml]='gene.description'></p> -->
        </div>
      </div>

      <!--  -->
      <div class="row footer">
        <div class="col-12">
          <div class="pb-2 pl-4 text">
            <div class="d-block">
              <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
            </div>
            <div class="footer_body" [ngClass]="'gene-bg_blue'">
              <div class="footer_list">
                <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                <span>Gln - Q</span><span>Gly - G</span><span>His - H</span>
                <span>Ile - I</span>
              </div>
              <div class="footer_list">
                <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                <span>Val - V</span>
              </div>
            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p style="font-size: 8px!important; line-height: 14px!important;">
              {{footerDiscription}}<br />{{footerDiscription2}}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--  -->

  <div id="page11" *ngIf="reportPdfRes.pathwayDtoList.length > 0" style="padding: 10px; margin: 0 auto"
    class="main_wrapper">
    <div class="container">
      <div class="row header">
        <div *ngIf="report.clientType != 'client'">
          <div class="col-3">
            <div class="logo">
              <img src="../../../assets/logo.png" alt="" style="width: 140px" />
            </div>
          </div>
        </div>
        <div *ngIf="report.clientType == 'client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
        <div class="col">
          <div class="patient-detailes ml-auto">
            <ul class="list-unstyled d-flex pt-3">
              <li>
                Patient Id<br /><span>{{ report.patient?.ecrfId }}</span>
              </li>
              <li>
                Cancer Site<br /><span>{{ report.cancerType }}</span>
              </li>
              <li>
                SAMPLE ID<br /><span>{{ report.labDetails?.sampleId }}</span>
              </li>
              <li>
                Registration Date<br /><span>{{
                  report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div *ngIf="report.clientType == '4basecare_client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="mar_top"></div>
      <!--  -->
      <div class="row">
        <div class="col">
          <h5 class="py-2 text-center px-0 rounded" style="background: #208dcb; color: #fff; font-weight: 500">
            PATHWAY(S) INVOLVED IN CANCER
          </h5>
        </div>
      </div>
      <!--  -->
      <div class="row mt-4">
        <div class="col-7">
          <h5 class="mb-1 font-weight-bold py-2">
            {{ reportPdfRes.pathwayDtoList[0]?.name }}
          </h5>
          <div class="path_cancer">
            <img [src]="reportPdfRes.pathwayDtoList[0]?.imageUrl" class="w-100 h-100" />
          </div>
        </div>
        <div class="col py-5 mt-5" style="font-size: 16px !important"
          [innerHtml]="reportPdfRes.pathwayDtoList[0]?.description">
          <div></div>
        </div>
      </div>

      <div class="row mt-4" *ngIf="reportPdfRes.pathwayDtoList.length > 1">
        <div class="col-7">
          <h5 class="mb-1 font-weight-bold py-2">
            {{ reportPdfRes.pathwayDtoList[1]?.name }}
          </h5>
          <div class="path_cancer">
            <img [src]="reportPdfRes.pathwayDtoList[1]?.imageUrl" class="w-100 h-100" />
          </div>
        </div>
        <div class="col py-5 mt-5" style="font-size: 16px !important"
          [innerHtml]="reportPdfRes.pathwayDtoList[1]?.description">
          <div></div>
        </div>
      </div>
      <!--  -->
      <div class="row footer">
        <div class="col-12">
          <div class="pb-2 pl-4 text">
            <div class="d-block">
              <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
            </div>
            <div class="footer_body" [ngClass]="'gene-bg_blue'">
              <div class="footer_list">
                <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                <span>Gln - Q</span><span>Gly - G</span><span>His - H</span>
                <span>Ile - I</span>
              </div>
              <div class="footer_list">
                <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                <span>Val - V</span>
              </div>
            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p style="font-size: 8px!important; line-height: 14px!important;">
              {{footerDiscription}}<br />{{footerDiscription2}}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="page12" *ngIf="reportPdfRes.pathwayDtoList.length > 2" style="padding: 10px; margin: 0 auto"
    class="main_wrapper">
    <div class="container">
      <div class="row header">
        <div *ngIf="report.clientType != 'client'">
          <div class="col-3">
            <div class="logo">
              <img src="../../../assets/logo.png" alt="" style="width: 140px" />
            </div>
          </div>
        </div>
        <div *ngIf="report.clientType == 'client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
        <div class="col">
          <div class="patient-detailes ml-auto">
            <ul class="list-unstyled d-flex pt-3">
              <li>
                Patient Id<br /><span>{{ report.patient?.ecrfId }}</span>
              </li>
              <li>
                Cancer Site<br /><span>{{ report.cancerType }}</span>
              </li>
              <li>
                SAMPLE ID<br /><span>{{ report.labDetails?.sampleId }}</span>
              </li>
              <li>
                Registration Date<br /><span>{{
                  report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div *ngIf="report.clientType == '4basecare_client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="mar_top"></div>
      <!--  -->
      <div class="row">
        <div class="col">
          <h5 class="py-2 text-center px-0 rounded" style="background: #208dcb; color: #fff; font-weight: 500">
            PATHWAYS INVOLVED IN CANCER
          </h5>
        </div>
      </div>
      <!--  -->
      <div class="row mt-4">
        <div class="col-7">
          <h5 class="mb-1 font-weight-bold py-2">
            {{ reportPdfRes.pathwayDtoList[2]?.name }}
          </h5>
          <div class="path_cancer">
            <img [src]="reportPdfRes.pathwayDtoList[2]?.imageUrl" class="w-100 h-100" />
          </div>
        </div>
        <div class="col py-5 mt-5" style="font-size: 16px !important"
          [innerHtml]="reportPdfRes.pathwayDtoList[2]?.description">
          <div></div>
        </div>
      </div>

      <div class="row mt-4" *ngIf="reportPdfRes.pathwayDtoList.length > 3">
        <div class="col-7">
          <h5 class="mb-1 font-weight-bold py-2">
            {{ reportPdfRes.pathwayDtoList[3]?.name }}
          </h5>
          <div class="path_cancer">
            <img [src]="reportPdfRes.pathwayDtoList[3]?.imageUrl" class="w-100 h-100" />
          </div>
        </div>
        <div class="col py-5 mt-5" style="font-size: 16px !important"
          [innerHtml]="reportPdfRes.pathwayDtoList[3]?.description">
          <div></div>
        </div>
      </div>
      <!--  -->
      <div class="row footer">
        <div class="col-12">
          <div class="pb-2 pl-4 text">
            <div class="d-block">
              <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
            </div>
            <div class="footer_body" [ngClass]="'gene-bg_blue'">
              <div class="footer_list">
                <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                <span>Gln - Q</span><span>Gly - G</span><span>His - H</span>
                <span>Ile - I</span>
              </div>
              <div class="footer_list">
                <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                <span>Val - V</span>
              </div>
            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p style="font-size: 8px!important; line-height: 14px!important;">
              {{footerDiscription}}<br />{{footerDiscription2}}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="page13" *ngIf="reportPdfRes.pathwayDtoList.length > 4" style="padding: 10px; margin: 0 auto"
    class="main_wrapper">
    <div class="container">
      <div class="row header">
        <div *ngIf="report.clientType != 'client'">
          <div class="col-3">
            <div class="logo">
              <img src="../../../assets/logo.png" alt="" style="width: 140px" />
            </div>
          </div>
        </div>
        <div *ngIf="report.clientType == 'client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
        <div class="col">
          <div class="patient-detailes ml-auto">
            <ul class="list-unstyled d-flex pt-3">
              <li>
                Patient Id<br /><span>{{ report.patient?.ecrfId }}</span>
              </li>
              <li>
                Cancer Site<br /><span>{{ report.cancerType }}</span>
              </li>
              <li>
                SAMPLE ID<br /><span>{{ report.labDetails?.sampleId }}</span>
              </li>
              <li>
                Registration Date<br /><span>{{
                  report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div *ngIf="report.clientType == '4basecare_client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
      </div>

      <div class="mar_top"></div>

      <div class="row">
        <div class="col">
          <h5 class="py-2 text-center px-0 rounded" style="background: #208dcb; color: #fff; font-weight: 500">
            PATHWAYS INVOLVED IN CANCER
          </h5>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-7">
          <h5 class="mb-1 font-weight-bold py-2">
            {{ reportPdfRes.pathwayDtoList[4]?.name }}
          </h5>
          <div class="path_cancer">
            <img [src]="reportPdfRes.pathwayDtoList[4]?.imageUrl" class="w-100 h-100" />
          </div>
        </div>
        <div class="col py-5 mt-5" style="font-size: 16px !important"
          [innerHtml]="reportPdfRes.pathwayDtoList[4]?.description">
          <div></div>
        </div>
      </div>

      <div class="row mt-4" *ngIf="reportPdfRes.pathwayDtoList.length > 5">
        <div class="col-7">
          <h5 class="mb-1">{{ reportPdfRes.pathwayDtoList[5]?.name }}</h5>
          <div class="path_cancer">
            <img [src]="reportPdfRes.pathwayDtoList[5]?.imageUrl" class="w-100 h-100" />
          </div>
        </div>
        <div class="col pl-5" style="font-size: 16px !important"
          [innerHtml]="reportPdfRes.pathwayDtoList[5]?.description">
          <div></div>
        </div>
      </div>

      <div class="row footer">
        <div class="col-12">
          <div class="pb-2 pl-4 text">
            <div class="d-block">
              <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
            </div>
            <div class="footer_body" [ngClass]="'gene-bg_blue'">
              <div class="footer_list">
                <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                <span>Gln - Q</span><span>Gly - G</span><span>His - H</span>
                <span>Ile - I</span>
              </div>
              <div class="footer_list">
                <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                <span>Val - V</span>
              </div>
            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p style="font-size: 8px!important; line-height: 14px!important;">
              {{footerDiscription}}<br />{{footerDiscription2}}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="page14" *ngIf="reportPdfRes.pathwayDtoList.length > 6" style="padding: 10px; margin: 0 auto"
    class="main_wrapper">
    <div class="container">
      <div class="row header">
        <div *ngIf="report.clientType != 'client'">
          <div class="col-3">
            <div class="logo">
              <img src="../../../assets/logo.png" alt="" style="width: 140px" />
            </div>
          </div>
        </div>
        <div *ngIf="report.clientType == 'client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
        <div class="col">
          <div class="patient-detailes ml-auto">
            <ul class="list-unstyled d-flex pt-3">
              <li>
                Patient Id<br /><span>{{ report.patient?.ecrfId }}</span>
              </li>
              <li>
                Cancer Site<br /><span>{{ report.cancerType }}</span>
              </li>
              <li>
                SAMPLE ID<br /><span>{{ report.labDetails?.sampleId }}</span>
              </li>
              <li>
                Registration Date<br /><span>{{
                  report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div *ngIf="report.clientType == '4basecare_client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
      </div>

      <div class="mar_top"></div>

      <div class="row">
        <div class="col">
          <h5 class="py-2 text-center px-0 rounded" style="background: #208dcb; color: #fff; font-weight: 500">
            PATHWAYS INVOLVED IN CANCER
          </h5>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-7">
          <h5 class="mb-1">{{ reportPdfRes.pathwayDtoList[6].name }}</h5>
          <div class="path_cancer">
            <img [src]="reportPdfRes.pathwayDtoList[6].imageUrl" class="w-100 h-100" />
          </div>
        </div>
        <div class="col pl-5" style="font-size: 16px !important"
          [innerHtml]="reportPdfRes.pathwayDtoList[6].description">
          <div></div>
        </div>
      </div>

      <div class="row mt-4" *ngIf="reportPdfRes.pathwayDtoList.length > 7">
        <div class="col-7">
          <h5 class="mb-1">{{ reportPdfRes.pathwayDtoList[7]?.name }}</h5>
          <div class="path_cancer">
            <img [src]="reportPdfRes.pathwayDtoList[7]?.imageUrl" class="w-100 h-100" />
          </div>
        </div>
        <div class="col pl-5" style="font-size: 16px !important"
          [innerHtml]="reportPdfRes.pathwayDtoList[7]?.description">
          <div></div>
        </div>
      </div>

      <div class="row footer">
        <div class="col-12">
          <div class="pb-2 pl-4 text">
            <div class="d-block">
              <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
            </div>
            <div class="footer_body" [ngClass]="'gene-bg_blue'">
              <div class="footer_list">
                <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                <span>Gln - Q</span><span>Gly - G</span><span>His - H</span>
                <span>Ile - I</span>
              </div>
              <div class="footer_list">
                <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                <span>Val - V</span>
              </div>
            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p style="font-size: 8px!important; line-height: 14px!important;">
              {{footerDiscription}}<br />{{footerDiscription2}}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="page15" *ngIf="reportPdfRes.drugDetails.length > 0" style="padding: 10px; margin: 0 auto"
    class="main_wrapper">
    <div class="container">
      <div class="row header">
        <div *ngIf="report.clientType != 'client'">
          <div class="col-3">
            <div class="logo">
              <img src="../../../assets/logo.png" alt="" style="width: 140px" />
            </div>
          </div>
        </div>
        <div *ngIf="report.clientType == 'client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
        <div class="col">
          <div class="patient-detailes ml-auto">
            <ul class="list-unstyled d-flex pt-3">
              <li>
                Patient Id<br /><span>{{ report.patient?.ecrfId }}</span>
              </li>
              <li>
                Cancer Site<br /><span>{{ report.cancerType }}</span>
              </li>
              <li>
                SAMPLE ID<br /><span>{{ report.labDetails?.sampleId }}</span>
              </li>
              <li>
                Registration Date<br /><span>{{
                  report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div *ngIf="report.clientType == '4basecare_client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="mar_top"></div>
      <!--  -->
      <div class="row">
        <div class="col">
          <h5 class="py-2 text-center px-0 rounded" style="background: #208dcb; color: #fff; font-weight: 500">
            RELEVANT THERAPIES
          </h5>
        </div>
      </div>
      <!--  -->
      <div class="row mt-3">
        <div *ngFor="
              let drug of reportPdfRes.drugDetails | slice : 0 : 6;
              let i = index
            " class="col-12 mb-3">
          <h4 style="color: #208dcb; font-weight: 600">{{ drug.name }}</h4>
          <p [innerHtml]="drug.description"></p>
        </div>
      </div>

      <!--  -->
      <div class="row footer">
        <div class="col-12">
          <div class="pb-2 pl-4 text">
            <div class="d-block">
              <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
            </div>
            <div class="footer_body" [ngClass]="'gene-bg_blue'">
              <div class="footer_list">
                <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                <span>Gln - Q</span><span>Gly - G</span><span>His - H</span>
                <span>Ile - I</span>
              </div>
              <div class="footer_list">
                <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                <span>Val - V</span>
              </div>
            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p style="font-size: 8px!important; line-height: 14px!important;">
              {{footerDiscription}}<br />{{footerDiscription2}}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="page15_1" *ngIf="reportPdfRes.drugDetails.length > 6" style="padding: 10px; margin: 0 auto"
    class="main_wrapper">
    <div class="container">
      <div class="row header">
        <div *ngIf="report.clientType != 'client'">
          <div class="col-3">
            <div class="logo">
              <img src="../../../assets/logo.png" alt="" style="width: 140px" />
            </div>
          </div>
        </div>
        <div *ngIf="report.clientType == 'client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
        <div class="col">
          <div class="patient-detailes ml-auto">
            <ul class="list-unstyled d-flex pt-3">
              <li>
                Patient Id<br /><span>{{ report.patient?.ecrfId }}</span>
              </li>
              <li>
                Cancer Site<br /><span>{{ report.cancerType }}</span>
              </li>
              <li>
                SAMPLE ID<br /><span>{{ report.labDetails?.sampleId }}</span>
              </li>
              <li>
                Registration Date<br /><span>{{
                  report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div *ngIf="report.clientType == '4basecare_client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="mar_top"></div>
      <!--  -->
      <div class="row">
        <div class="col">
          <h5 class="py-2 text-center px-0 rounded" style="background: #208dcb; color: #fff; font-weight: 500">
            RELEVANT THERAPIES
          </h5>
        </div>
      </div>
      <!--  -->
      <div class="row mt-3">
        <div *ngFor="
              let drug of reportPdfRes.drugDetails | slice : 6 : 12;
              let i = index
            " class="col-12 mb-3">
          <h4 style="color: #208dcb; font-weight: 600">{{ drug.name }}</h4>
          <p class="left_space" [innerHtml]="drug.description"></p>
        </div>
      </div>

      <!--  -->
      <div class="row footer">
        <div class="col-12">
          <div class="pb-2 pl-4 text">
            <div class="d-block">
              <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
            </div>
            <div class="footer_body" [ngClass]="'gene-bg_blue'">
              <div class="footer_list">
                <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                <span>Gln - Q</span><span>Gly - G</span><span>His - H</span>
                <span>Ile - I</span>
              </div>
              <div class="footer_list">
                <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                <span>Val - V</span>
              </div>
            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p style="font-size: 8px!important; line-height: 14px!important;">
              {{footerDiscription}}<br />{{footerDiscription2}}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="page15_2" *ngIf="reportPdfRes.drugDetails.length > 12" style="padding: 10px; margin: 0 auto"
    class="main_wrapper">
    <div class="container">
      <div class="row header">
        <div *ngIf="report.clientType != 'client'">
          <div class="col-3">
            <div class="logo">
              <img src="../../../assets/logo.png" alt="" style="width: 140px" />
            </div>
          </div>
        </div>
        <div *ngIf="report.clientType == 'client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
        <div class="col">
          <div class="patient-detailes ml-auto">
            <ul class="list-unstyled d-flex pt-3">
              <li>
                Patient Id<br /><span>{{ report.patient?.ecrfId }}</span>
              </li>
              <li>
                Cancer Site<br /><span>{{ report.cancerType }}</span>
              </li>
              <li>
                SAMPLE ID<br /><span>{{ report.labDetails?.sampleId }}</span>
              </li>
              <li>
                Registration Date<br /><span>{{
                  report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div *ngIf="report.clientType == '4basecare_client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="mar_top"></div>
      <!--  -->
      <div class="row">
        <div class="col">
          <h5 class="py-2 text-center px-0 rounded" style="background: #208dcb; color: #fff; font-weight: 500">
            RELEVANT THERAPIES
          </h5>
        </div>
      </div>
      <!--  -->
      <div class="row mt-3">
        <div *ngFor="
              let drug of reportPdfRes.drugDetails | slice : 12 : 18;
              let i = index
            " class="col-12 mb-3">
          <h4 style="color: #208dcb; font-weight: 600">{{ drug.name }}</h4>
          <p [innerHtml]="drug.description"></p>
        </div>
      </div>

      <!--  -->
      <div class="row footer">
        <div class="col-12">
          <div class="pb-2 pl-4 text">
            <div class="d-block">
              <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
            </div>
            <div class="footer_body" [ngClass]="'gene-bg_blue'">
              <div class="footer_list">
                <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                <span>Gln - Q</span><span>Gly - G</span><span>His - H</span>
                <span>Ile - I</span>
              </div>
              <div class="footer_list">
                <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                <span>Val - V</span>
              </div>
            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p style="font-size: 8px!important; line-height: 14px!important;">
              {{footerDiscription}}<br />{{footerDiscription2}}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="page15_3" *ngIf="reportPdfRes.drugDetails.length > 18" style="padding: 10px; margin: 0 auto"
    class="main_wrapper">
    <div class="container">
      <div class="row header">
        <div *ngIf="report.clientType != 'client'">
          <div class="col-3">
            <div class="logo">
              <img src="../../../assets/logo.png" alt="" style="width: 140px" />
            </div>
          </div>
        </div>
        <div *ngIf="report.clientType == 'client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
        <div class="col">
          <div class="patient-detailes ml-auto">
            <ul class="list-unstyled d-flex pt-3">
              <li>
                Patient Id<br /><span>{{ report.patient?.ecrfId }}</span>
              </li>
              <li>
                Cancer Site<br /><span>{{ report.cancerType }}</span>
              </li>
              <li>
                SAMPLE ID<br /><span>{{ report.labDetails?.sampleId }}</span>
              </li>
              <li>
                Registration Date<br /><span>{{
                  report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div *ngIf="report.clientType == '4basecare_client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="mar_top"></div>
      <!--  -->
      <div class="row">
        <div class="col">
          <h5 class="py-2 text-center px-0 rounded" style="background: #208dcb; color: #fff; font-weight: 500">
            RELEVANT THERAPIES
          </h5>
        </div>
      </div>
      <!--  -->
      <div class="row mt-3">
        <div *ngFor="
              let drug of reportPdfRes.drugDetails | slice : 18 : 24;
              let i = index
            " class="col-12 mb-3">
          <h4 style="color: #208dcb; font-weight: 600">{{ drug.name }}</h4>
          <p [innerHtml]="drug.description"></p>
        </div>
      </div>

      <!--  -->
      <div class="row footer">
        <div class="col-12">
          <div class="pb-2 pl-4 text">
            <div class="d-block">
              <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
            </div>
            <div class="footer_body" [ngClass]="'gene-bg_blue'">
              <div class="footer_list">
                <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                <span>Gln - Q</span><span>Gly - G</span><span>His - H</span>
                <span>Ile - I</span>
              </div>
              <div class="footer_list">
                <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                <span>Val - V</span>
              </div>
            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p style="font-size: 8px!important; line-height: 14px!important;">
              {{footerDiscription}}<br />{{footerDiscription2}}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="page15_4" *ngIf="reportPdfRes.drugDetails.length > 24" style="padding: 10px; margin: 0 auto"
    class="main_wrapper">
    <div class="container">
      <div class="row header">
        <div *ngIf="report.clientType != 'client'">
          <div class="col-3">
            <div class="logo">
              <img src="../../../assets/logo.png" alt="" style="width: 140px" />
            </div>
          </div>
        </div>
        <div *ngIf="report.clientType == 'client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
        <div class="col">
          <div class="patient-detailes ml-auto">
            <ul class="list-unstyled d-flex pt-3">
              <li>
                Patient Id<br /><span>{{ report.patient?.ecrfId }}</span>
              </li>
              <li>
                Cancer Site<br /><span>{{ report.cancerType }}</span>
              </li>
              <li>
                SAMPLE ID<br /><span>{{ report.labDetails?.sampleId }}</span>
              </li>
              <li>
                Registration Date<br /><span>{{
                  report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div *ngIf="report.clientType == '4basecare_client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="mar_top"></div>
      <!--  -->
      <div class="row">
        <div class="col">
          <h5 class="py-2 text-center px-0 rounded" style="background: #208dcb; color: #fff; font-weight: 500">
            RELEVANT THERAPIES
          </h5>
        </div>
      </div>
      <!--  -->
      <div class="row mt-3">
        <div *ngFor="
              let drug of reportPdfRes.drugDetails | slice : 24 : 30;
              let i = index
            " class="col-12 mb-3">
          <h4 style="color: #208dcb; font-weight: 600">{{ drug.name }}</h4>
          <p [innerHtml]="drug.description"></p>
        </div>
      </div>

      <!--  -->
      <div class="row footer">
        <div class="col-12">
          <div class="pb-2 pl-4 text">
            <div class="d-block">
              <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
            </div>
            <div class="footer_body" [ngClass]="'gene-bg_blue'">
              <div class="footer_list">
                <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                <span>Gln - Q</span><span>Gly - G</span><span>His - H</span>
                <span>Ile - I</span>
              </div>
              <div class="footer_list">
                <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                <span>Val - V</span>
              </div>
            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p style="font-size: 8px!important; line-height: 14px!important;">
              {{footerDiscription}}<br />{{footerDiscription2}}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="page15_5" *ngIf="reportPdfRes.drugDetails.length > 30" style="padding: 10px; margin: 0 auto"
    class="main_wrapper">
    <div class="container">
      <div class="row header">
        <div *ngIf="report.clientType != 'client'">
          <div class="col-3">
            <div class="logo">
              <img src="../../../assets/logo.png" alt="" style="width: 140px" />
            </div>
          </div>
        </div>
        <div *ngIf="report.clientType == 'client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
        <div class="col">
          <div class="patient-detailes ml-auto">
            <ul class="list-unstyled d-flex pt-3">
              <li>
                Patient Id<br /><span>{{ report.patient?.ecrfId }}</span>
              </li>
              <li>
                Cancer Site<br /><span>{{ report.cancerType }}</span>
              </li>
              <li>
                SAMPLE ID<br /><span>{{ report.labDetails?.sampleId }}</span>
              </li>
              <li>
                Registration Date<br /><span>{{
                  report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div *ngIf="report.clientType == '4basecare_client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="mar_top"></div>
      <!--  -->
      <div class="row">
        <div class="col">
          <h5 class="py-2 text-center px-0 rounded" style="background: #208dcb; color: #fff; font-weight: 500">
            RELEVANT THERAPIES
          </h5>
        </div>
      </div>
      <!--  -->
      <div class="row mt-3">
        <div *ngFor="
              let drug of reportPdfRes.drugDetails | slice : 30 : 36;
              let i = index
            " class="col-12 mb-3">
          <h4 style="color: #208dcb; font-weight: 600">{{ drug.name }}</h4>
          <p [innerHtml]="drug.description"></p>
        </div>
      </div>

      <!--  -->
      <div class="row footer">
        <div class="col-12">
          <div class="pb-2 pl-4 text">
            <div class="d-block">
              <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
            </div>
            <div class="footer_body" [ngClass]="'gene-bg_blue'">
              <div class="footer_list">
                <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                <span>Gln - Q</span><span>Gly - G</span><span>His - H</span>
                <span>Ile - I</span>
              </div>
              <div class="footer_list">
                <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                <span>Val - V</span>
              </div>
            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p style="font-size: 8px!important; line-height: 14px!important;">
              {{footerDiscription}}<br />{{footerDiscription2}}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="page16" style="padding: 10px; margin: 0 auto" class="main_wrapper">
    <div class="container">
      <div class="row header">
        <div *ngIf="report.clientType != 'client'">
          <div class="col-3">
            <div class="logo">
              <img src="../../../assets/logo.png" alt="" style="width: 140px" />
            </div>
          </div>
        </div>
        <div *ngIf="report.clientType == 'client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
        <div class="col">
          <div class="patient-detailes ml-auto">
            <ul class="list-unstyled d-flex pt-3">
              <li>
                Patient Id<br /><span>{{ report.patient?.ecrfId }}</span>
              </li>
              <li>
                Cancer Site<br /><span>{{ report.cancerType }}</span>
              </li>
              <li>
                SAMPLE ID<br /><span>{{ report.labDetails?.sampleId }}</span>
              </li>
              <li>
                Registration Date<br /><span>{{
                  report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div *ngIf="report.clientType == '4basecare_client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="mar_top"></div>
      <!--  -->
      <div class="row">
        <div class="col">
          <h5 class="py-2 my-4 text-center px-0 rounded" style="background: #208dcb; color: #fff; font-weight: 500">
            REFERENCES
          </h5>
        </div>
      </div>
      <!--  -->
      <div class="row mt-3">
        <div class="col-12 mb-3">
          <ul class="pl-4 mt-2 res_art">
            <li *ngFor="
                  let referenceArticle of reportPdfRes.referenceArticles
                    | slice : 0 : 15
                " style="white-space: pre-wrap">
              {{ referenceArticle }}
            </li>
          </ul>
        </div>
      </div>

      <div class="row footer">
        <div class="col-12">
          <div class="pb-2 pl-4 text">
            <div class="d-block">
              <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
            </div>
            <div class="footer_body" [ngClass]="'gene-bg_blue'">
              <div class="footer_list">
                <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                <span>Gln - Q</span><span>Gly - G</span><span>His - H</span>
                <span>Ile - I</span>
              </div>
              <div class="footer_list">
                <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                <span>Val - V</span>
              </div>
            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p style="font-size: 8px!important; line-height: 14px!important;">
              {{footerDiscription}}<br />{{footerDiscription2}}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="page16_1" *ngIf="(reportPdfRes?.referenceArticles || []).length > 15" style="padding: 10px; margin: 0 auto"
    class="main_wrapper">
    <div class="container">
      <div class="row header">
        <div *ngIf="report.clientType != 'client'">
          <div class="col-3">
            <div class="logo">
              <img src="../../../assets/logo.png" alt="" style="width: 140px" />
            </div>
          </div>
        </div>
        <div *ngIf="report.clientType == 'client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
        <div class="col">
          <div class="patient-detailes ml-auto">
            <ul class="list-unstyled d-flex pt-3">
              <li>
                Patient Id<br /><span>{{ report.patient?.ecrfId }}</span>
              </li>
              <li>
                Cancer Site<br /><span>{{ report.cancerType }}</span>
              </li>
              <li>
                SAMPLE ID<br /><span>{{ report.labDetails?.sampleId }}</span>
              </li>
              <li>
                Registration Date<br /><span>{{
                  report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div *ngIf="report.clientType == '4basecare_client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="mar_top"></div>
      <!--  -->
      <div class="row">
        <div class="col">
          <h5 class="py-2 my-4 text-center px-0 rounded" style="background: #208dcb; color: #fff; font-weight: 500">
            REFERENCES
          </h5>
        </div>
      </div>
      <!--  -->
      <div class="row mt-3">
        <div class="col-12 mb-3">
          <ul class="pl-4 mt-2 res_art">
            <li *ngFor="
                  let referenceArticle of reportPdfRes.referenceArticles
                    | slice : 15 : 30
                " style="white-space: pre-wrap">
              {{ referenceArticle }}
            </li>
          </ul>
        </div>
      </div>

      <div class="row footer">
        <div class="col-12">
          <div class="pb-2 pl-4 text">
            <div class="d-block">
              <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
            </div>
            <div class="footer_body" [ngClass]="'gene-bg_blue'">
              <div class="footer_list">
                <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                <span>Gln - Q</span><span>Gly - G</span><span>His - H</span>
                <span>Ile - I</span>
              </div>
              <div class="footer_list">
                <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                <span>Val - V</span>
              </div>
            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p style="font-size: 8px!important; line-height: 14px!important;">
              {{footerDiscription}}<br />{{footerDiscription2}}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="page16_2" *ngIf="(reportPdfRes?.referenceArticles || []).length > 30" style="padding: 10px; margin: 0 auto"
    class="main_wrapper">
    <div class="container">
      <div class="row header">
        <div *ngIf="report.clientType != 'client'">
          <div class="col-3">
            <div class="logo">
              <img src="../../../assets/logo.png" alt="" style="width: 140px" />
            </div>
          </div>
        </div>
        <div *ngIf="report.clientType == 'client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
        <div class="col">
          <div class="patient-detailes ml-auto">
            <ul class="list-unstyled d-flex pt-3">
              <li>
                Patient Id<br /><span>{{ report.patient?.ecrfId }}</span>
              </li>
              <li>
                Cancer Site<br /><span>{{ report.cancerType }}</span>
              </li>
              <li>
                SAMPLE ID<br /><span>{{ report.labDetails?.sampleId }}</span>
              </li>
              <li>
                Registration Date<br /><span>{{
                  report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div *ngIf="report.clientType == '4basecare_client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="mar_top"></div>
      <!--  -->
      <div class="row">
        <div class="col">
          <h5 class="py-2 my-4 text-center px-0 rounded" style="background: #208dcb; color: #fff; font-weight: 500">
            REFERENCES
          </h5>
        </div>
      </div>
      <!--  -->
      <div class="row mt-3">
        <div class="col-12 mb-3">
          <ul class="pl-4 mt-2 res_art">
            <li *ngFor="
                  let referenceArticle of reportPdfRes.referenceArticles
                    | slice : 30 : 45
                " style="white-space: pre-wrap">
              {{ referenceArticle }}
            </li>
          </ul>
        </div>
      </div>

      <div class="row footer">
        <div class="col-12">
          <div class="pb-2 pl-4 text">
            <div class="d-block">
              <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
            </div>
            <div class="footer_body" [ngClass]="'gene-bg_blue'">
              <div class="footer_list">
                <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                <span>Gln - Q</span><span>Gly - G</span><span>His - H</span>
                <span>Ile - I</span>
              </div>
              <div class="footer_list">
                <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                <span>Val - V</span>
              </div>
            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p style="font-size: 8px!important; line-height: 14px!important;">
              {{footerDiscription}}<br />{{footerDiscription2}}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="page17" style="padding: 10px; margin: 0 auto" class="main_wrapper">
    <div class="container">
      <div class="row header">
        <div *ngIf="report.clientType != 'client'">
          <div class="col-3">
            <div class="logo">
              <img src="../../../assets/logo.png" alt="" style="width: 140px" />
            </div>
          </div>
        </div>
        <div *ngIf="report.clientType == 'client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
        <div class="col">
          <div class="patient-detailes ml-auto">
            <ul class="list-unstyled d-flex pt-3">
              <li>
                Patient Id<br /><span>{{ report.patient?.ecrfId }}</span>
              </li>
              <li>
                Cancer Site<br /><span>{{ report.cancerType }}</span>
              </li>
              <li>
                SAMPLE ID<br /><span>{{ report.labDetails?.sampleId }}</span>
              </li>
              <li>
                Registration Date<br /><span>{{
                  report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div *ngIf="report.clientType == '4basecare_client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="mar_top"></div>
      <!--  -->
      <div class="row">
        <div class="col">
          <h5 class="py-2 my-4 text-center px-0 rounded" style="background: #208dcb; color: #fff; font-weight: 500">
            REFERENCES
          </h5>
        </div>
      </div>
      <!--  -->
      <div class="row mt-3">
        <div class="col-12 mb-3">
          <h4 class="mt-4 mb-2" style="color: #208dcb; font-weight: 600">
            Websites:
          </h4>
          <ul class="pl-4 res_art">
            <!-- <li>COSMIC - Catalogue Of Somatic Mutations in Cancer - https://cancer.sanger.ac.uk/cosmic</li> -->
            <li>ClinVar https://www.ncbi.nlm.nih.gov/clinvar</li>
            <li>NIH- Clinical Trials - https://clinicaltrials.gov/</li>
            <li>NIH- National Cancer Institute - https://www.cancer.gov/</li>
            <li>https://www.mycancergenome.org/</li>
            <li>https://www.drugbank.ca/</li>
            <li>https://www.ncbi.nlm.nih.gov/medgen/</li>
            <li>https://www.cancer.net/cancer-types</li>
            <li>http://www.cancer-genetics.org/</li>
            <li>https://www.genecards.org/</li>

            <span *ngIf="report.referenceWebsites != '<p></p>'" class="">
              <div style="font-size: 16px !important" [innerHtml]="report.referenceWebsites"></div>
            </span>
          </ul>
        </div>
      </div>

      <!--  -->


      <div class="img_signs above-footer">
        <div class="row justify-content-between align-items-center">
          <div class="col-4 text-center">
            <p style="
                    text-align: center;

                    font-size: 15px !important;
                  ">(Electronically signed by)
            </p>
            <p style="
                      text-align: center;
                      font-weight: bolder;
                      font-size: 15px !important;
                    ">
              Vidya H Veldore, PhD<br />Vice President - Clinical Operations
            </p>
          </div>
          <div class="col-4 text-center">
            <p style="text-align: center; font-size: 15px !important;">
              (Electronically signed by)</p>
            <p style="
                text-align: center;
                font-weight: bolder;
                font-size: 15px !important;
              ">
              Dr. Jinumary Aji John<br />Head - Clinical Reporting </p>
          </div>
          <div class="col-4 text-center">
            <p style="
                text-align: center;

                font-size: 15px !important;
              ">(Electronically signed by)
            </p>
            <p style="
                      text-align: center;
                      font-weight: bolder;
                      font-size: 15px !important;
                    ">
              Vyomesh Javle<br />Head - Clinical Bioinformatics
            </p>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="row footer">
        <div class="col-12">
          <div class="pb-2 pl-4 text">
            <div class="d-block">
              <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
            </div>
            <div class="footer_body" [ngClass]="'gene-bg_blue'">
              <div class="footer_list">
                <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                <span>Gln - Q</span><span>Gly - G</span><span>His - H</span>
                <span>Ile - I</span>
              </div>
              <div class="footer_list">
                <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                <span>Val - V</span>
              </div>
            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p style="font-size: 8px!important; line-height: 14px!important;">
              {{footerDiscription}}<br />{{footerDiscription2}}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="page19" style="padding: 10px; margin: 0 auto" class="main_wrapper">
    <div class="container">
      <div class="row header">
        <div *ngIf="report.clientType != 'client'">
          <div class="col-3">
            <div class="logo">
              <img src="../../../assets/logo.png" alt="" style="width: 140px" />
            </div>
          </div>
        </div>
        <div *ngIf="report.clientType == 'client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
        <div class="col">
          <div class="patient-detailes ml-auto">
            <ul class="list-unstyled d-flex pt-3">
              <li>
                Patient Id<br /><span>{{ report.patient?.ecrfId }}</span>
              </li>
              <li>
                Cancer Site<br /><span>{{ report.cancerType }}</span>
              </li>
              <li>
                SAMPLE ID<br /><span>{{ report.labDetails?.sampleId }}</span>
              </li>
              <li>
                Registration Date<br /><span>{{
                  report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div *ngIf="report.clientType == '4basecare_client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="mar_top"></div>
      <!--  -->
      <div class="row">
        <div class="col">
          <div class="">
            <h5 class="py-2 text-center px-0 rounded my-4" style="background: #208dcb; color: #fff; font-weight: 500">
              TEST DESCRIPTION
            </h5>

            <p class="py-2">
              TAR<span style="font-weight: bold">GT</span>
              <span style="color: #208dcb; padding-left: 5px">{{
                report.reportType.name
                }}</span>
              is a Next Generation Sequencing based test which identifies
              genetic alterations in a comprehensive panel of well curated 352
              tumor genes which can impact response to approved therapy for a
              particular cancer type. Some of the alterations detected may
              have bearing on prognosis and/or therapeutic options and may
              provide relevant information that allows doctor to consider
              various lines of targeted treatment for the patient.
            </p>


          </div>
          <div class="mt-4">
            <h5 class="py-2 my-4 text-center px-0 rounded mb-2"
              style="background: #208dcb; color: #fff; font-weight: 500">
              TEST METHODOLOGY
            </h5>
            <div>
              <h5 class="pt-2 py-2" style="color: #000; font-weight: bold">
                Sample preparation and Library preparation :
              </h5>
              <p class="py-2">
                DNA isolated from FFPE or any other fresh tumor tissue source
                was used to perform targeted gene capture using a custom
                capture kit (as per manufacturer recommendation using standard
                procedure). The libraries were sequenced to mean >300X
                coverage on Illumina sequencing platform.
              </p>

              <h5 class="mt-4 mb-3" style="color: #000; font-weight: bold">
                Bioinformatics Analysis and Reporting :
              </h5>
              <p clsas="py-2">
                The sequences obtained are aligned to human reference genome
                (GRCh37/hg19) and variant analysis was performed using set of
                Bioinformatics Pipeline. Only non- synonymous and splice site
                variants found in the exome panel consisting of specific set
                of genes were used for clinical interpretation. Silent
                variations that do not result in any change in amino acid in
                the coding region are not reported. Clinically relevant
                mutations were annotated using published variants in
                literature and a set of databases – ClinVar (Landrum et al,
                2015.), cbioportal (Cerami et al, 2012; Gao et al, 2013) and
                dbSNP. Common variants are filtered based on allele frequency
                in 1000 Genome Phase 3(Auton et al, 2015), ExAC (Karczewski et
                al. 2016), dbSNP (Sherry et al, 2001), etc. In the absence of
                a clinically significant reported known variation(s),
                pathogenicity will be predicted based on in-silico gene
                prioritization tools: CADD (Rentzsch et al. 2018), SIFT (Ng PC
                et al, 2003), PolyPhen-2 (Adzhubei et al, 2013) and
                prioritized for clinical correlation. The identified
                pathogenic variant will be correlated with observed phenotypic
                features of the patient and interpreted according to American
                College of Medical Genetics (ACMG) and AMP/CAP/ASCO
                guidelines.
              </p>
            </div>

            <div class="mt-2">
              <h5 class="py-2 text-center px-0 rounded my-4" style="background: #208dcb; color: #fff; font-weight: 500">
                CATEGORIES OF CLINICAL AND/OR EXPERIMENTAL EVIDENCE
              </h5>
              <p>
                Somatic variants are classified into four levels based on
                their level of clinical significance in cancer diagnosis,
                prognosis, and/or therapeutics as per international
                guidelines:
                <span style="color: #208dcb; font-weight: bold">ACMG, ASCO, AMP, CAP, NCCN and ESMO.</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="row">
        <div class="col">
          <div class="text-center py-5">
            <img src="../../../assets/Infographic - 1.png" alt="" class="w-65" />
          </div>
        </div>
      </div>

      <!--  -->
      <div class="row footer">
        <div class="col-12">
          <div class="pb-2 pl-4 text">
            <div class="d-block">
              <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
            </div>
            <div class="footer_body" [ngClass]="'gene-bg_blue'">
              <div class="footer_list">
                <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                <span>Gln - Q</span><span>Gly - G</span><span>His - H</span>
                <span>Ile - I</span>
              </div>
              <div class="footer_list">
                <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                <span>Val - V</span>
              </div>
            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p style="font-size: 8px!important; line-height: 14px!important;">
              {{footerDiscription}}<br />{{footerDiscription2}}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>



  <div id="page19_2" style="padding: 10px; margin: 0 auto"
    class="main_wrapper">
    <div class="container">
      <div class="row header">
        <div *ngIf="report.clientType != 'client'">
          <div class="col-3">
            <div class="logo">
              <img src="../../../assets/logo.png" alt="" style="width: 140px" />
            </div>
          </div>
        </div>
        <div *ngIf="report.clientType == 'client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
        <div class="col">
          <div class="patient-detailes ml-auto">
            <ul class="list-unstyled d-flex pt-3">
              <li>
                Patient Id<br /><span>{{ report.patient?.ecrfId }}</span>
              </li>
              <li>
                Cancer Site<br /><span>{{ report.cancerType }}</span>
              </li>
              <li>
                SAMPLE ID<br /><span>{{ report.labDetails?.sampleId }}</span>
              </li>
              <li>
                Registration Date<br /><span>{{
                  report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div *ngIf="report.clientType == '4basecare_client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="mar_top"></div>
      <!--  -->
      <div class="row">
        <div class="col">
          <div class="">
            <h5 class="py-2 text-center px-0 rounded" style="background: #208dcb; color: #fff; font-weight: 500">
              GENES EVALUATED
            </h5>
          </div>
        </div>
      </div>
      <!--  -->

      <!--  -->
      <div class="row">
        <div class="col">
          <h5 class="font-weight-bold my-3">
            The following table represents the panel of 352 genes, evaluated
            in the <span class="font-weight-normol">TAR</span><b>GT</b>
            <span style="color: #208dcb"> Focus</span> test, commonly known to
            be associated with different cancers and cancer syndromes
          </h5>
          <table class="gene_list">
            <tr>
              <td>ABCB1</td>
              <td>BRAF</td>
              <td>CDKN2C</td>
              <td>EPHA3</td>
              <td>FGF3</td>
              <td>HSD3B1</td>
              <td>MAP3K1</td>
              <td>NFE2L2</td>
              <td>PIM1</td>
              <td>RNF43</td>
              <td>SUFU</td>
            </tr>
            <tr>
              <td>ABCC1</td>
              <td>BRCA1</td>
              <td>CEBPA</td>
              <td>EPHB1</td>
              <td>FGF4</td>
              <td>103</td>
              <td>MAP3K13</td>
              <td>NFKBIA</td>
              <td>PLK1</td>
              <td>ROS1</td>
              <td>SULT1A1</td>
            </tr>

            <tr>
              <td>ABL1</td>
              <td>BRCA2</td>
              <td>CHEK1</td>
              <td>EPHB4</td>
              <td>FGF6</td>
              <td>IDH1</td>
              <td>MAPK1</td>
              <td>NKX2-1</td>
              <td>PMS2</td>
              <td>RPTOR</td>
              <td>SYK</td>
            </tr>

            <tr>
              <td>ACVR1B</td>
              <td>BRD4</td>
              <td>CHEK2</td>
              <td>ERBB2</td>
              <td>FGFR1</td>
              <td>IDH2</td>
              <td>MCL1</td>
              <td>NOTCH1</td>
              <td>POLD1</td>
              <td>RRM1</td>
              <td>TBX3</td>
            </tr>

            <tr>
              <td>AKT1</td>
              <td>BRIP1</td>
              <td>CIC</td>
              <td>ERBB3</td>
              <td>FGFR2</td>
              <td>IGF1R</td>
              <td>MDM2</td>
              <td>NOTCH2</td>
              <td>POLE</td>
              <td>RSP02</td>
              <td>TEK</td>
            </tr>

            <tr>
              <td>AKT2</td>
              <td>BTG1</td>
              <td>CREBBP</td>
              <td>ERBB4</td>
              <td>FGFR3</td>
              <td>IKBKE</td>
              <td>MDM4</td>
              <td>NOTCH3</td>
              <td>PPARG</td>
              <td>SDC4</td>
              <td>TGFBR2</td>
            </tr>

            <tr>
              <td>AKT3</td>
              <td>BTG2</td>
              <td>CRKL</td>
              <td>ERCC1</td>
              <td>FGFR4</td>
              <td>IKZF1</td>
              <td>MED12</td>
              <td>NPM1</td>
              <td>PPP2R1A</td>
              <td>SDHA</td>
              <td>TIPARP</td>
            </tr>

            <tr>
              <td>ALK</td>
              <td>BTK</td>
              <td>CSF1R</td>
              <td>ERCC2</td>
              <td>FH</td>
              <td>INPP4B</td>
              <td>MEF2B</td>
              <td>NRAS</td>
              <td>PPP2R2A</td>
              <td>SDHB</td>
              <td>TMPRSS2</td>
            </tr>

            <tr>
              <td>ALOX12B</td>
              <td>CALR</td>
              <td>CSF3R</td>
              <td>ERCC4</td>
              <td>FLCN</td>
              <td>IRF2</td>
              <td>MEN1</td>
              <td>NTSC2</td>
              <td>PRDM1</td>
              <td>SDHC</td>
              <td>TNFAIP3</td>
            </tr>

            <tr>
              <td>AMER1</td>
              <td>CARD11</td>
              <td>CTCF</td>
              <td>ERCC5</td>
              <td>FLT1</td>
              <td>IRF4</td>
              <td>MERTK</td>
              <td>NTRK1</td>
              <td>PRKAR1A</td>
              <td>SOHO</td>
              <td>TNFRSF14</td>
            </tr>

            <tr>
              <td>APC</td>
              <td>CASP8</td>
              <td>CTNNA1</td>
              <td>ERG</td>
              <td>FLT3</td>
              <td>IRS2</td>
              <td>MET</td>
              <td>NTRK2</td>
              <td>PRKCD</td>
              <td>SETD2</td>
              <td>TOP1</td>
            </tr>

            <tr>
              <td>AR</td>
              <td>CBFB</td>
              <td>CTNNB1</td>
              <td>ERRFl1</td>
              <td>FLT4</td>
              <td>JAK1</td>
              <td>MITF</td>
              <td>NTRK3</td>
              <td>PRKCI</td>
              <td>SF3B1</td>
              <td>TOP2A</td>
            </tr>

            <tr>
              <td>ARAF</td>
              <td>CBL</td>
              <td>CUL3</td>
              <td>ESR1</td>
              <td>FOXL2</td>
              <td>JAK2</td>
              <td>MKNK1</td>
              <td>NUTM1</td>
              <td>PTCH1</td>
              <td>SGK1</td>
              <td>TOP2B</td>
            </tr>

            <tr>
              <td>ARFRP1</td>
              <td>CCND1</td>
              <td>CUL4A</td>
              <td>ETV4</td>
              <td>FUBP1</td>
              <td>JAK3</td>
              <td>MLH1</td>
              <td>P2RY8</td>
              <td>PTEN</td>
              <td>SLC29A1</td>
              <td>TP53</td>
            </tr>

            <tr>
              <td>ARID1A</td>
              <td>CCND2</td>
              <td>CXCR4</td>
              <td>ETV5</td>
              <td>GABRA6</td>
              <td>JUN</td>
              <td>MPL</td>
              <td>PALB2</td>
              <td>PTPN11</td>
              <td>SLC34A2</td>
              <td>TPMT</td>
            </tr>

            <tr>
              <td>ASXL1</td>
              <td>CCND3</td>
              <td>CYP17A1</td>
              <td>ETV6</td>
              <td>GATA1</td>
              <td>KDM5A</td>
              <td>MRE11A</td>
              <td>PARK2</td>
              <td>PTPRO</td>
              <td>SLC01B1</td>
              <td>TSC1</td>
            </tr>

            <tr>
              <td>ATM</td>
              <td>CCNE1</td>
              <td>CYP2B6</td>
              <td>EWSR1</td>
              <td>GATA3</td>
              <td>KDM5C</td>
              <td>MSH2</td>
              <td>PARK2</td>
              <td>PARP1</td>
              <td>SMAD2</td>
              <td>TSC2</td>
            </tr>

            <tr>
              <td>ATR</td>
              <td>CD22</td>
              <td>CYP2C19</td>
              <td>EZH2</td>
              <td>GATA4</td>
              <td>KDM6A</td>
              <td>MSH3</td>
              <td>PARP2</td>
              <td>RAC1</td>
              <td>SMAD4</td>
              <td>TYMP</td>
            </tr>

            <tr>
              <td>ATRX</td>
              <td>CD274</td>
              <td>CYP2C8</td>
              <td>EZR</td>
              <td>GATA6</td>
              <td>KOR</td>
              <td>MSH6</td>
              <td>PARP3</td>
              <td>RAD21</td>
              <td>SMARCA4</td>
              <td>TYR03</td>
            </tr>

            <tr>
              <td>AURKA</td>
              <td>CD274</td>
              <td>CYP206</td>
              <td>FAM46C</td>
              <td>GID4</td>
              <td>KEAP1</td>
              <td>MST1R</td>
              <td>PAX5</td>
              <td>RAD5O</td>
              <td>SMARCB1</td>
              <td>U2AF1</td>
            </tr>

            <tr>
              <td>AURKB</td>
              <td>CD74</td>
              <td>DAXX</td>
              <td>FANCA</td>
              <td>GNA11</td>
              <td>KEL</td>
              <td>MTAP</td>
              <td>PBRM1</td>
              <td>RAD51</td>
              <td>SMO</td>
              <td>VEGFA</td>
            </tr>

            <tr>
              <td>AXIN1</td>
              <td>CD79A</td>
              <td>DDR1</td>
              <td>FANCC</td>
              <td>GNA13</td>
              <td>KIT</td>
              <td>MTHFR</td>
              <td>PDCD1</td>
              <td>RAD51B</td>
              <td>SNCAIP</td>
              <td>VHL</td>
            </tr>

            <tr>
              <td>AXL</td>
              <td>CD79B</td>
              <td>DDR2</td>
              <td>FANCD2</td>
              <td>GNAQ</td>
              <td>KLHL6</td>
              <td>MTOR</td>
              <td>PDCD1LG2</td>
              <td>RAD51C</td>
              <td>SOCS1</td>
              <td>WHSC1</td>
            </tr>

            <tr>
              <td>BAP1</td>
              <td>CDC73</td>
              <td>DHFR</td>
              <td>FANCG</td>
              <td>GNAS</td>
              <td>KMT2A</td>
              <td>MUTYH</td>
              <td>PDGFRA</td>
              <td>RAD52</td>
              <td>SOX9</td>
              <td>WHSC1L1</td>
            </tr>

            <tr>
              <td>BARD1</td>
              <td>CDH1</td>
              <td>DIS3</td>
              <td>FANCL</td>
              <td>GRM3</td>
              <td>KMT2D</td>
              <td>MYB</td>
              <td>PDGFRB</td>
              <td>RAD54L</td>
              <td>SPARC</td>
              <td>WT1</td>
            </tr>

            <tr>
              <td>BCL2</td>
              <td>CDK12</td>
              <td>DNMT3A</td>
              <td>FAS</td>
              <td>GSK3B</td>
              <td>KRAS</td>
              <td>MYC</td>
              <td>PDK1</td>
              <td>RAF1</td>
              <td>SPEN</td>
              <td>XPA</td>
            </tr>

            <tr>
              <td>BCL2L1</td>
              <td>CDK4</td>
              <td>DOT1L</td>
              <td>FBXW7</td>
              <td>GSTP1</td>
              <td>LTK</td>
              <td>MYCL</td>
              <td>PIK3C2B</td>
              <td>RARA</td>
              <td>SPOP</td>
              <td>XPC</td>
            </tr>

            <tr>
              <td>BCL2L2</td>
              <td>CDK6</td>
              <td>OPYD</td>
              <td>FGF10</td>
              <td>H3F3A</td>
              <td>LYN2</td>
              <td>MYCN</td>
              <td>PIK3C2G</td>
              <td>RB1</td>
              <td>SRC</td>
              <td>XP01</td>
            </tr>

            <tr>
              <td>BCL6</td>
              <td>CDK8</td>
              <td>EEO</td>
              <td>FGF12</td>
              <td>HDAC1</td>
              <td>MAF</td>
              <td>MYD88</td>
              <td>PIK3CA</td>
              <td>RBM10</td>
              <td>STAG2</td>
              <td>XRCC1</td>
            </tr>

            <tr>
              <td>BCOR</td>
              <td>CDKN1A</td>
              <td>EGFR</td>
              <td>FGF14</td>
              <td>HGF</td>
              <td>MAP2K1</td>
              <td>NBN</td>
              <td>PIK3CB</td>
              <td>REL</td>
              <td>STAT3</td>
              <td>XRCC2</td>
            </tr>

            <tr>
              <td>BCORL1</td>
              <td>CDKN1B</td>
              <td>EMSY</td>
              <td>FGF19</td>
              <td>HNF1A</td>
              <td>MAP2K2</td>
              <td>NF1</td>
              <td>PIK3R1</td>
              <td>RET</td>
              <td>STK11</td>
              <td>ZNF217</td>
            </tr>

            <tr>
              <td>BCR</td>
              <td>CDKN2A</td>
              <td>EP300</td>
              <td>FGF23</td>
              <td>HRAS</td>
              <td>MAP2K4</td>
              <td>NF2</td>
              <td>PIK3R2</td>
              <td>RICTOR</td>
              <td>STK11IP</td>
              <td>ZNF703</td>
            </tr>
          </table>
        </div>
      </div>

      <!--  -->
      <div class="row footer">
        <div class="col-12">
          <div class="pb-2 pl-4 text">
            <div class="d-block">
              <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
            </div>
            <div class="footer_body" [ngClass]="'gene-bg_blue'">
              <div class="footer_list">
                <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                <span>Gln - Q</span><span>Gly - G</span><span>His - H</span>
                <span>Ile - I</span>
              </div>
              <div class="footer_list">
                <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                <span>Val - V</span>
              </div>
            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p style="font-size: 8px!important; line-height: 14px!important;">
              {{footerDiscription}}<br />{{footerDiscription2}}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="page20" style="padding: 10px; margin: 0 auto" class="main_wrapper">
    <div class="container">
      <div class="row header">
        <div *ngIf="report.clientType != 'client'">
          <div class="col-3">
            <div class="logo">
              <img src="../../../assets/logo.png" alt="" style="width: 140px" />
            </div>
          </div>
        </div>
        <div *ngIf="report.clientType == 'client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
        <div class="col">
          <div class="patient-detailes ml-auto">
            <ul class="list-unstyled d-flex pt-3">
              <li>
                Patient Id<br /><span>{{ report.patient?.ecrfId }}</span>
              </li>
              <li>
                Cancer Site<br /><span>{{ report.cancerType }}</span>
              </li>
              <li>
                SAMPLE ID<br /><span>{{ report.labDetails?.sampleId }}</span>
              </li>
              <li>
                Registration Date<br /><span>{{
                  report.labDetails?.sampleCollected | date : "dd/MM/yyyy"
                  }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div *ngIf="report.clientType == '4basecare_client'">
          <div *ngIf="report.clientName == 'oncoquest'" class="col-3">
            <div class="logo">
              <img src="../../../assets/oncoquest logo.png" alt="" style="width: 100px" />
            </div>
          </div>
          <div *ngIf="report.clientName == 'path_labs'" class="col-3">
            <div class="logo">
              <img src="../../../assets/pathlab.png" alt="" style="width: 100px" />
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="mar_top"></div>
      <!--  -->
      <div class="row">
        <div class="col">
          <h5 class="py-2 mt-4 mb-5 text-center px-0 rounded"
            style="background: #208dcb; color: #fff; font-weight: 500">
            LIMITATIONS AND DISCLAIMER
          </h5>

          <ul class="mt-4 pl-4 res-art">
            <li>
              TAR<span style="font-weight: bold">GT</span>
              <span style="color: #208dcb; padding-left: 5px">{{
                report.reportType.name
                }}</span>
              test has been developed, validated and performed by 4baseCare
              OncoSolutions Pvt. Ltd and this test has not been cleared or
              approved by the FDA.
            </li>
            <li>
              TAR<span style="font-weight: bold">GT</span>
              <span style="color: #208dcb; padding-left: 5px">{{
                report.reportType.name
                }}</span>
              test should be one of the many aspects used by the healthcare
              provider to help with a diagnosis and treatment plan. There are
              possible sources of error which can result from trace
              contamination, rare technical errors, rare genetic variants that
              interfere with analysis, recent scientific developments, and
              alternative classification systems.
            </li>
            <li>
              We are using the canonical transcript for clinical reporting
              which is usually the longest coding transcript with
              strong/multiple supporting evidence. However, in rare cases,
              clinically relevant variants annotated in alternate complete
              coding transcripts could also be reported.
            </li>
            <li>
              The contents of this test should be carefully assessed by the
              treating physician and further interpreted along with clinical,
              histopathological findings, contraindications and guidelines
              before deciding the course of therapy.
            </li>
            <li>
              The chromosomal aberrations like Copy Number Alterations and
              rearrangements may not be reliably detected with this assay and
              have to be confirmed by alternate method.
            </li>
            <li>
              The sensitivity of this assay to detect large
              deletions/duplications of more than 10 bp or Copy Number
              Alterations (CNAs) is 70-75%. The CNAs detected have to be
              confirmed by alternate method.
            </li>
            <li>
              The MSI-H/MSS designation by this test is based on genome wide
              analysis of microsatellite loci and not based on the 5 or 7 MSI
              loci and its clinical validity has not been very well
              established using NGS methods. Reflex testing by alternate
              methods such as by IHC or PCR is suggested for reconfirmation
              and clinical decision making.
            </li>
            <li>
              The identified pathogenic variant will be correlated with
              observed phenotypic features of the patient and interpreted
              according to standard of care guidelines.
            </li>
            <!-- <li>The identified pathogenic variant will be correlated with observed phenotypic features of
                              the patient and interpreted according to (ASCO)
                              guidelines.</li> -->
            <li>
              Most recent block is recommended for testing as the mutation
              profile may change in response to treatment and hence differ at
              different sampling points.
            </li>
            <li>
              Certain genes may not be covered completely, and few mutations
              could be missed. A negative result cannot rule out the
              possibility that the tested tumor sample carries mutations not
              previously associated with cancer and hence not included in the
              panel.
            </li>
            <li>
              A false negative result due to poor coverage and depth in
              certain genes cannot be ruled out. This could be due to
              intrinsic nature of the patient sample(FFPE DNA/RNA).
            </li>

          </ul>
        </div>
      </div>
      <!--  -->

      <!--  -->
      <div class="row footer">
        <div class="col-12">
          <div class="pb-2 pl-4 text">
            <div class="d-block">
              <span class="font-weight-bold" style="font-size: 13px !important">Amino acids Table:</span>
            </div>
            <div class="footer_body" [ngClass]="'gene-bg_blue'">
              <div class="footer_list">
                <span>Ala - A</span><span>Arg - R</span><span>Asn - N</span>
                <span>Asp - D</span><span>Cys - C</span><span>Glu - E</span>
                <span>Gln - Q</span><span>Gly - G</span><span>His - H</span>
                <span>Ile - I</span>
              </div>
              <div class="footer_list">
                <span>Leu - L</span><span>Lys - K</span><span>Met - M</span>
                <span>Phe - F</span><span>Pro - P</span><span>Ser - S</span>
                <span>Thr - T</span><span>Trp - W</span><span>Tyr - Y</span>
                <span>Val - V</span>
              </div>
            </div>
          </div>
          <div class="col-12 pt-1 ">
            <p style="font-size: 8px!important; line-height: 14px!important;">
              {{footerDiscription}}<br />{{footerDiscription2}}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>




</body>

</html>
