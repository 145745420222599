import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ToastrService } from 'ngx-toastr';
import {
  CancerType,
  ClinicalTrialRes,
  PDL1IHCDTO,
} from 'src/app/_interfaces.ts/admin';
import { AppUser } from 'src/app/_interfaces.ts/app_user';
import {
  DnaAndRnaDetailsReq,
  ImmunoDetails,
  ReportRes,
  SampleIdData,
} from 'src/app/_interfaces.ts/reports';
import { ClinicalTrailService } from 'src/app/_services/clinical-trail.service';
import { DataAdminService } from 'src/app/_services/data-admin.service';
import { FamilyHistoryService } from 'src/app/_services/family-history.service';
import { ReportService } from 'src/app/_services/report.service';

@Component({
  selector: 'app-pdl1-dashboard',
  templateUrl: './pdl1-dashboard.component.html',
  styleUrls: ['./pdl1-dashboard.component.css'],
})
export class Pdl1DashboardComponent implements OnInit {
  sup = 'A{b} → Superscript';
  sub = 'A~b! → Subscript';
  editor = ClassicEditor;

  organisationName!: string;
  reportPDL1Status: any;
  reportSubType: any;
  appUser = {} as AppUser;
  cancerSiteDropdownSettings: any;
  selectedCancerSite: any;
  reportId!: number;
  evidenceSummary!: string;
  reportTypeChange = false;
  clinicalSummary: string = '';
  interestGenes = '';
  addNote!: string;
  specimen: string = '';
  macroscopicFindings: string = '';
  microscopicDescription: string = '';
  impression: string = '';
  editorText: string = '';
  pmIds!: string;
  report = {} as ReportRes;
  isSubmitedSummary = false;
  isSubmitedClinicalSummary = false;
  isSubmitedAdditionalNotes = false;
  isSubmitedIhc = false;
  isSubmitedDnaRna = false;
  immunoDetails = {} as ImmunoDetails;
  sampleIdData = {} as SampleIdData;
  isSubmitedImmunoDetails = false;
  interpretation!: string;
  isSubmitedReferenceWebsite = false;
  referenceWebsite!: string;
  isSubmitedInterpretation = false;
  isSubmitedDisclaimer = false;
  cancerSiteList: any = [];
  reportTypeList: any = [];
  imageUrl!: string;
  imageUrls: string[] = [];
  statusMsg!: string;
  clinicalTrialResList: ClinicalTrialRes[] = [];
  isSelectedTrail!: boolean;
  cancerTypeDropdownSettings = {};
  cancerTypeList!: CancerType[];
  selectedcancertype: CancerType[] = [];
  pdl1Ihc = {} as PDL1IHCDTO;

  patientSamples = [
    { id: 1, name: '-B' },
    { id: 2, name: '-F' },
    { id: 3, name: '-Z' },
  ];
  selected = [];

  intertext1: string = '';
  intertext2: string = '';
  constructor(
    private reportService: ReportService,
    private router: Router,
    private routerAct: ActivatedRoute,
    private dataAdminService: DataAdminService,
    private toastr: ToastrService,
    private trailService: ClinicalTrailService,
    private titleService: Title,
    private familyHistoryService: FamilyHistoryService
  ) {}

  showSuccess() {
    this.toastr.success(this.statusMsg, '', { timeOut: 2000 });
  }

  showError() {
    this.toastr.error(this.statusMsg, '', { timeOut: 3000 });
  }

  ngOnInit(): void {
    this.appUser = JSON.parse(localStorage.getItem('user') || '{}');
    this.organisationName = localStorage.getItem('organisationName') || '';

    this.reportId = this.routerAct.snapshot.params['id'];
    if (this.reportId) {
      this.getReport();
    }
    this.getReportTypeList();
    this.getCancerTypes();

    this.cancerTypeDropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };

    this.cancerSiteDropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 1,
      allowSearchFilter: true,
    };
  }

  getCancerSiteList() {
    this.dataAdminService.getCancerSiteList().subscribe((res: any) => {
      this.cancerSiteList = res['payLoad'];
    });
  }
  getReportTypeList() {
    this.dataAdminService.getAllReportTypes().subscribe((res: any) => {
      this.reportTypeList = res['payLoad'];
      console.log('Report Type List=>', res['payLoad']);
    });
  }


  changeReportStatus(event: any) {
    if (event.value == 'Yes') {
      this.reportService
        .changeReportStatus(this.reportId, 'REPORTED')
        .subscribe((response: any) => {
          console.log(response);
          this.statusMsg = response['message'];
          this.showSuccess();
          this.router.navigate(['/reports']);
        });
    }
  }

  changeReportAndOncoStatus(event: any) {
    if (event.value == 'Yes') {
      this.reportService
        .changeReportStatus(this.reportId, 'REPORTED')
        .subscribe((response: any) => {
          console.log(response);
          this.statusMsg = response['message'];
          this.showSuccess();
          this.router.navigate([
            '/first72_view_report/' + this.reportId + '/temp1',
          ]);
        });
      this.reportService
        .updateOncoBenchStatus(this.report.patient.ecrfId)
        .subscribe((response: any) => {
          console.log(response);
          this.statusMsg = response['message'];
          this.showSuccess();
          // this.router.navigate(['/first72_view_report/'+this.reportId+'/no_temp']);
        });
    }
  }


  addClinicalSummary() {
    this.isSubmitedClinicalSummary = true;
    if (
      this.clinicalSummary == null ||
      this.clinicalSummary == undefined ||
      this.clinicalSummary == ''
    ) {
      this.clinicalSummary = ' ';
    }
    this.reportService
      .addClinicalSummary(this.reportId, this.clinicalSummary)
      .subscribe((response: any) => {
        console.log(response);
        this.isSubmitedClinicalSummary = false;
        this.statusMsg = response['message'];
        this.showSuccess();
      });

  }



  addIHCDetails() {
    this.isSubmitedIhc = true;
    this.pdl1Ihc.specimen = this.specimen;
    this.pdl1Ihc.macroscopicFindings = this.macroscopicFindings;
    this.pdl1Ihc.microscopicDescription = this.microscopicDescription;
    this.pdl1Ihc.impression = this.impression;
    console.log(this.pdl1Ihc);

    this.reportService
      .addPdl1IhcDetails(this.report.id, this.pdl1Ihc)
      .subscribe((response: any) => {
        this.isSubmitedIhc = false;
        this.statusMsg = response['message'];
        this.showSuccess();
        console.log(response);
      });
  }
  cancerSiteDeSelect(cancerSite: any) {
    this.reportService
      .addFirstCancerSite(this.reportId, 0)
      .subscribe((response: any) => {
        console.log(response);
      });
  }

  cancerSiteSelect(cancerSite: any) {
    this.reportService
      .addFirstCancerSite(this.reportId, cancerSite.id)
      .subscribe((response: any) => {
        console.log(response);
      });
  }

  cancerTypeSelect(cancerType: any) {
    console.log('select cancer', cancerType.id);
    this.reportService
      .addFirstCancerType(this.reportId, cancerType.id)
      .subscribe((response: any) => {
        console.log(response);
      });
  }

  cancerTypeDeSelect(cancerType: any) {
    console.log('de-select cancer', cancerType.id);
    this.reportService
      .addFirstCancerType(this.reportId, 0)
      .subscribe((response: any) => {
        console.log(response);
      });
  }

  selectedInterpretations: string[] = [];
  originalPmids: string = '';
  addedPmids: any = '';
  selectedInterpretationPmids: string[] = [];
  selectedReferneceLink: string[] = [];
  addedRefernceLink: string = '';
  originalRefernce: string = '';

  deletedPMID!: string;
  deletedRefernceLink!: string;

  textcount(classname: any) {
    var dom = document.getElementsByClassName(classname);
    var data: any = dom[0];
    this.editorText = data.outerText;
  }

  uploadImage(event: any) {
    if (this.imageUrls.length == 3) {
      this.imageUrls = [];
    }
    const files = event.target.files;
    // this.images.push(event.target.files);
    // const files = this.images;
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const formdata: FormData = new FormData();
      formdata.append('file', file);
      this.dataAdminService.uploadImage(formdata).subscribe((response: any) => {
        this.imageUrl = response['payLoad'];
        this.imageUrls.push(this.imageUrl);
        console.log('imageUrls\n', this.imageUrls);
      });
    }
  }

  imagesPresent!: boolean;


  addSampleID() {
    this.sampleIdData.ecrfId = this.report.patient.ecrfId;
    this.sampleIdData.samples = this.selected;
    this.sampleIdData.testPackage = this.report.testPackage;
    this.sampleIdData.sampleCollected = this.report.labDetails.sampleCollected;
    console.log(this.sampleIdData);

    this.reportService
      .addSampleID(this.sampleIdData)
      .subscribe((response: any) => {
        console.log(response);
        this.statusMsg = response['message'];
        this.showSuccess();
        this.getReport();
      });
  }

  pdl1Trails() {
    if (
      this.immunoDetails.pdl1Status == 'EXPRESSION' ||
      this.report.reportPDL1Status == 'Positive'
    ) {
      this.dataAdminService
        .getAllClinicalTrailByGene('PD-L1')
        .subscribe((response: any) => {
          this.clinicalTrialResList = response['payLoad'];
          console.log('clinical trail db', this.clinicalTrialResList);
          this.selectedPDL1Trails();
        });
    }
  }
  isAllSelectedOrDeSelected = false;
  selectAndDeSelectAllTrails(event: any) {
    this.isAllSelectedOrDeSelected = event['target']['checked'];

    this.clinicalTrialResList.forEach((trial) => {
      this.trailService
        .addPDL1Trails(this.reportId, trial.id, this.isAllSelectedOrDeSelected)
        .subscribe((response: any) => {
          console.log(response);
        });
    });
    this.pdl1Trails();
  }
  selectedPDL1TrailIds: number[] = [];
  selectedPDL1Trails() {
    this.trailService
      .getSelectedPDL1Trails(this.reportId)
      .subscribe((response: any) => {
        this.selectedPDL1TrailIds = response['payLoad'];
        console.log('getSelectedPDL1Trails', this.selectedPDL1TrailIds);
        this.clinicalTrialResList.forEach((element) => {
          if (this.selectedPDL1TrailIds.includes(element.id)) {
            element.selected = true;
          }
        });
      });
  }

  addSelectedTrails(event: any, internalId: number) {
    this.isSelectedTrail = event['target']['checked'];
    this.trailService
      .addPDL1Trails(this.reportId, internalId, this.isSelectedTrail)
      .subscribe((response: any) => {
        console.log(response);
      });
  }





  addReportSubType(event: any) {
    console.log('selected=>', event.name);
    this.reportService
      .addReportSubType(this.reportId, this.reportSubType)
      .subscribe((response: any) => {
        this.reportTypeChange = false;
        console.log('addReportType response', response);
        if (response['success']) {
          // this.getReport();
          window.location.reload();
        }
      });
  }
  addReportStatus(event: any) {
    console.log('selected=>', event.name);
    this.reportService
      .addReportStatus(this.reportId, this.reportPDL1Status)
      .subscribe((response: any) => {
        this.reportTypeChange = false;
        console.log('addReportType response', response);
        if (response['success']) {
          // this.getReport();
          window.location.reload();
        }
      });
  }


  referringClinician!: string;
  ageOfBlock!: any;
  getReport() {
    this.reportService.getReport(this.reportId).subscribe((response: any) => {
      this.report = response['payLoad'];
      if (this.report.reportPDL1Status == 'Positive') {
        this.pdl1Trails();
      }
      console.log('clientType--->', this.report.clientType);
      console.log('report ==>', this.report);
      if (this.organisationName != null && this.organisationName != '') {
        this.getAndSetOncoBenchPatientsDetails(this.report?.patient?.ecrfId);
      }

      if (this.report.patient.organisationName != null) {

      if (this.report.reportType != null) {
        this.titleService.setTitle(
          this.report.patient.ecrfId + ' - ' + this.report.reportType.name
        );

        if (this.report.reportType.name == 'PDL1') {
          this.reportPDL1Status = this.report.reportPDL1Status;
          this.reportSubType = this.report.reportPDL1SubType;
          this.specimen = this.report.specimen;
          this.macroscopicFindings = this.report.macroscopicFindings;
          this.microscopicDescription = this.report.microscopicDescription;
          this.impression = this.report.impression;
        }
      }
      if (this.report.firstCancerType != null) {
        this.selectedcancertype.push(this.report.firstCancerType);
      }

      this.clinicalSummary = this.report.clinicalSummary;
      this.interestGenes = this.report.interestGenes;
      this.addNote = this.report.addNotes;
      this.evidenceSummary = this.report.evidenceSummary;
      this.interpretation = this.report.interpretation;
      this.referringClinician = this.report?.labDetails?.referringClinician?.replace(/,/g, ', ');
      if (this.report.labDetails != null) {
        this.dnaAndRnaDetailsReq.dnaAvailable = this.report.labDetails.dnaAvailable;
        if (this.report.labDetails.dnaAvailable) {
          this.dnaAvailableValue = "yes";
        } else if (!this.report.labDetails.dnaAvailable) {
          this.dnaAvailableValue = "no";
        }
        this.dnaAndRnaDetailsReq.dnaSequencingData = this.report.labDetails.dnaSequencingData;
        this.dnaAndRnaDetailsReq.rnaAvailable = this.report.labDetails.rnaAvailable;
        if (this.report.labDetails.rnaAvailable) {
          this.rnaAvailableValue = "yes";
        } else if (!this.report.labDetails.rnaAvailable) {
          this.rnaAvailableValue = "no";
        }
        this.dnaAndRnaDetailsReq.rnaSequencingData = this.report.labDetails.rnaSequencingData;
        if (this.report.labDetails.sampleSource != null || this.report.labDetails.sampleSource != undefined) {
          this.report.labDetails.sampleSource = this.report.labDetails.sampleSource.replace(/FFPE BlockId:/g, '')
        }
      }
    }
    });
  }

  getCancerTypes() {
    this.dataAdminService.cancerTypeList().subscribe((response: any) => {
      this.cancerTypeList = response['payLoad'];
    });
  }
  getAndSetOncoBenchPatientsDetails(ecrfId: string) {
    this.reportService
      .getAndSetOncoBenchPatientsDetails(ecrfId)
      .subscribe((response: any) => {
        this.report = response['payLoad'];
        console.log('patient==>', this.report);
        this.clinicalSummary = this.report.clinicianIndication;
        this.report.labDetails.sampleId = this.report.labDetails.sampleId;
        this.report.labDetails.sampleSource =
          this.report.labDetails.sampleSource;
      });
  }
  dnaAndRnaDetailsReq = {} as DnaAndRnaDetailsReq;
  dnaAvailableValue!: string;
  rnaAvailableValue!: string;
  addingRnaAndDna() {
    this.isSubmitedDnaRna = true;
    this.reportService.addingRnaAndDna(this.reportId, this.dnaAndRnaDetailsReq).subscribe(
      (response: any) => {
        console.log(response);
        this.isSubmitedDnaRna = false;
        this.statusMsg = response['message'];
        this.showSuccess();
      }
    );
  }
  dnaAvailable(event: any) {
    if (event.value == 'yes') {
      this.dnaAndRnaDetailsReq.dnaAvailable = true;
    } else {
      this.dnaAndRnaDetailsReq.dnaAvailable = false;
    }

    console.log(this.dnaAndRnaDetailsReq);
  }

  rnaAvailable(event: any) {
    if (event.value == 'yes') {
      this.dnaAndRnaDetailsReq.rnaAvailable = true;
    } else {
      this.dnaAndRnaDetailsReq.rnaAvailable = false;
    }
    console.log(this.dnaAndRnaDetailsReq);
  }
}
